/* eslint-disable no-underscore-dangle */
/* eslint-disable no-template-curly-in-string */
import { isValidPhoneNumber } from 'react-phone-number-input'
import isEmpty from 'lodash/isEmpty'
import * as Yup from 'yup'

import isValidNpi from './npi'

Yup.setLocale({
  mixed: {
    required: 'Required',
  },
  string: {
    min: 'Must be at least ${min} characters long',
    email: 'Must be a valid e-mail',
  },
})

Yup.addMethod(Yup.string, 'phone', function phoneValidation() {
  return this.test(
    'phone',
    'Invalid phone number',
    value =>
      // eslint-disable-next-line no-underscore-dangle
      (this._nullable && !value) || isValidPhoneNumber(value),
  )
})

Yup.addMethod(Yup.string, 'npi', function npiValidation() {
  return this.test('npi', 'Invalid NPI', value => {
    return (this._nullable && !value) || isValidNpi(value)
  })
})

export { Yup }

export function allValuesRequiredCheck(values) {
  const errors = {}
  Object.keys(values).forEach(key => {
    if (!values[key]) errors[key] = 'Required'
  })
  return errors
}

export function loginValidations(values) {
  const errors = allValuesRequiredCheck(values)
  if (isEmpty(errors)) {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    }
  }
  return errors
}

export function createYupSchema(schema, config) {
  const { id, validationType, label, validations = [] } = config
  if (!Yup[validationType]) {
    return schema
  }

  let validator = Yup[validationType]()

  validations.forEach(validation => {
    const { params = [], type } = validation
    if (!validator[type]) return

    validator = validator[type](...params)
  })

  validator = validator.label(label)

  schema[id] = validator

  return schema
}
