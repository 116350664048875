import React from 'react'
import PropTypes from 'prop-types'
import { Input, Form } from 'antd'
import { useField } from 'formik'

const { Search } = Input
const { Item } = Form

/**
 * For information about the props for this component head over to ant design
 *
 * https://ant.design/components/input/#components-input-demo-search-input
 */
export default function SearchBar({ containerClassName, searching, ...rest }) {
  const [field] = useField(rest)
  const { fullWidth, ...props } = { ...field, ...rest }
  return (
    <Item className={containerClassName}>
      <Search
        placeholder="Search"
        {...props} // eslint-disable-line react/jsx-props-no-spreading
        allowClear
        loading={searching}
      />
    </Item>
  )
}

SearchBar.propTypes = {
  containerClassName: PropTypes.string,
  searching: PropTypes.bool,
}

SearchBar.defaultProps = {
  containerClassName: undefined,
  searching: false,
}
