import { Select as AntDSelect } from 'antd'
import { useField } from 'formik'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'
import Item from './Item'

const { Option, OptGroup } = AntDSelect

const useStyles = createUseStyles({
  fullWidth: {
    '& > div.ant-form-item-control-wrapper:first-child': {
      display: 'block',
    },
  },
})
export default function Select({
  containerClassName,
  id,
  label,
  labelPropName,
  options,
  valuePropName,
  helperText,
  fullWidth,
  labelCol,
  labelAlign,
  wrapperCol,
  hasGroups,
  ...rest
}) {
  const [field, { error, touched }, { setValue, setTouched }] = useField(rest)
  const handleBlur = useCallback(() => setTouched(true), [setTouched])
  const hasError = Boolean(touched && error)

  const classes = useStyles()
  const classNames = classnames(
    containerClassName,
    fullWidth ? classes.fullWidth : null,
  )

  const getOptions = opts =>
    opts.map(option => {
      const key = typeof option === 'string' ? option : option[valuePropName]
      const optionLabel =
        typeof option === 'string' ? option : option[labelPropName]
      return <Option key={key}>{optionLabel}</Option>
    })

  return (
    <Item
      label={label}
      htmlFor={id}
      hasFeedback={hasError}
      validateStatus={hasError ? 'error' : ''}
      help={hasError && error}
      className={classNames}
      helperText={helperText}
      labelCol={labelCol}
      labelAlign={labelAlign}
      wrapperCol={wrapperCol}
    >
      <AntDSelect
        id={id}
        onChange={setValue}
        onBlur={handleBlur}
        value={field.value}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {hasGroups
          ? Object.keys(options).map(opt => (
              <OptGroup label={opt}>{getOptions(options[opt])}</OptGroup>
            ))
          : getOptions(options)}
      </AntDSelect>
    </Item>
  )
}

Select.propTypes = {
  containerClassName: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  labelPropName: PropTypes.string,
  helperText: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  valuePropName: PropTypes.string,
  fullWidth: PropTypes.bool,
  labelCol: PropTypes.shape({}),
  labelAlign: PropTypes.string,
  wrapperCol: PropTypes.shape({}),
  hasGroups: PropTypes.bool,
}

Select.defaultProps = {
  containerClassName: undefined,
  id: undefined,
  label: undefined,
  labelPropName: 'name',
  options: undefined,
  helperText: undefined,
  valuePropName: 'id',
  fullWidth: false,
  labelCol: undefined,
  labelAlign: 'left',
  wrapperCol: undefined,
  hasGroups: false,
}
