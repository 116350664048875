import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import api from '../../api'
import AsyncSelect from './AsyncSelect'

export default function PatientSelect({ practiceId, showInactive, ...rest }) {
  const fetchOptions = useCallback(
    q =>
      api.patients.getPatients({ q, practiceId, showInactive }).then(res =>
        res.body.map(patient => {
          const formatedBirthDate = moment(patient.birthDate).format(
            'MM-DD-YYYY',
          )
          const fullName = `${patient.firstName} ${patient.lastName}`
          let title = `${fullName} - ${formatedBirthDate}`
          if (!patient.active) {
            title = `${title} (Inactive)`
          }

          return { id: patient.id, name: title }
        }),
      ),
    [practiceId, showInactive],
  )

  return (
    <AsyncSelect
      asyncSearch
      fetchOptions={fetchOptions}
      initialFetch={false}
      showSearch
      optionFilterProp="children"
      placeholder="Start typing to find a patient"
      {...rest}
    />
  )
}

PatientSelect.propTypes = {
  practiceId: PropTypes.string.isRequired,
  showInactive: PropTypes.bool,
}

PatientSelect.defaultProps = {
  showInactive: false,
}
