import queryString from 'query-string'
import apiClient from './api-client'

function list({ page, practiceId }) {
  return apiClient.get(
    queryString.stringifyUrl({
      url: `practices/${practiceId}/cycles`,
      query: { page },
    }),
    { withHeaders: true },
  )
}

function createCycles({ practiceId, startsAt, firstLongPeriodIndex }) {
  return apiClient.post(`practices/${practiceId}/cycles`, {
    starts_at: startsAt,
    first_long_period_index: firstLongPeriodIndex,
  })
}

function updateCycles({ practiceId, cycleId, startsAt, endsAt }) {
  return apiClient.patch(`practices/${practiceId}/cycles`, {
    startsAt,
    endsAt,
    cycleId,
  })
}

function process(cycleId) {
  return apiClient.post(`cycles/${cycleId}/pre-authorizations/process`)
}

function get(id) {
  return apiClient.get(`cycles/${id}`)
}

export default {
  createCycles,
  get,
  list,
  process,
  updateCycles,
}
