import apiClient from './api-client'

function getDiseases(id) {
  return apiClient.get(`patients/${id}/diseases`)
}

function getAllDiseases() {
  return apiClient.get(`/diseases`)
}

function addDiseaseToPatient(patiendId, id, disease) {
  return apiClient.post(`patients/${patiendId}/diseases/${id}`)
}

function removeDiseaseFromPatient(patiendId, id, disease) {
  return apiClient.destroy(`patients/${patiendId}/diseases/${id}`)
}

export default {
  getAllDiseases,
  getDiseases,
  addDiseaseToPatient,
  removeDiseaseFromPatient,
}
