import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import {
  useFetchPreAuthorizations,
  usePreAuthorizations,
} from '../../redux/slices/pre-authorizations'
import AsyncSelect from './AsyncSelect'

export default function PreAuthorizationSelect({ cycleId, ...props }) {
  const fetchOptions = useFetchPreAuthorizations(cycleId, 'all')
  const preAuthorizations = usePreAuthorizations(cycleId)
  const options = useMemo(
    () =>
      preAuthorizations.map(({ id, patient }) => ({
        id,
        name: `${patient.firstName} ${patient.lastName}`,
      })),
    [preAuthorizations],
  )

  return (
    <AsyncSelect
      fetchOptions={fetchOptions}
      options={options}
      showSearch
      allowClear
      optionFilterProp="children"
      placeholder="Select patient"
      {...props}
    />
  )
}

PreAuthorizationSelect.propTypes = {
  cycleId: PropTypes.string.isRequired,
}
