/* eslint-disable react/jsx-props-no-spreading */
import { Col, Divider, Form, Icon, Popover, Row } from 'antd'
import { Formik } from 'formik'
import mapValues from 'lodash/mapValues'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useMemo, useState, useEffect } from 'react'
import { createUseStyles, useTheme } from 'react-jss'

import {
  HOME_MONITOR_MODELS,
  PATIENTS_PLACEHOLDERS,
  PATIENTS_LABELS,
} from '../../lib/constants'
import { formElementPropsGenerator } from '../../lib/forms'
import api from '../../api'
import { Yup } from '../../lib/validations'
import {
  useIsAdmin,
  useFeatureEnabled,
  useCurrentUserPracticeId,
} from '../../redux/slices/session'
import {
  Checkbox,
  DatePicker,
  Input,
  PhoneNumber,
  SubmitButton,
} from '../Forms'
import CountrySelect from '../Forms/CountrySelect'
import InsuranceProviderSelect from '../Forms/InsuranceProviderSelect'
import MedicalProviderSelect from '../Forms/MedicalProviderSelect'
import DeviceModelSelect from '../Forms/DeviceModelSelect'
import PracticeSelect from '../Forms/PracticeSelect'
import Switch, { VALUE_LABELS_SCHEMA } from '../Forms/Switch'
import USStateSelect from '../Forms/USStateSelect'
import { patientShape } from '../prop-types'
import IcdCodesSelect from '../Forms/IcdCodesSelect'
import Select from '../Forms/Select'
import useDiseases from '../../hooks/useDiseases'
import MultipleCheckboxes from '../Forms/MultipleCheckboxes'
import DiseasesEdit from './DiseasesEdit'

const useStyles = createUseStyles({
  questions: {
    '& .ant-form-item-control-wrapper': {
      display: 'inline-block',
    },
    '& .ant-form-item-label': {
      display: 'inline-block',
      verticalAlign: 'inherit',
    },
    '& .ant-form-extra': {
      display: 'inline-block',
      marginLeft: 10,
    },
  },
})

const INITIAL_VALUES = mapValues(PATIENTS_LABELS, field => '')
INITIAL_VALUES.practiceId = undefined
INITIAL_VALUES.insuranceProviderId = undefined
INITIAL_VALUES.medicalProviderId = undefined
INITIAL_VALUES.birthDate = undefined
INITIAL_VALUES.addressState = undefined
INITIAL_VALUES.addressCountry = 'United States'
INITIAL_VALUES.atrialFibrilationFlutterDisease = false
INITIAL_VALUES.anticoagulatedDisease = false
INITIAL_VALUES.enrolled = false
INITIAL_VALUES.paired = false
INITIAL_VALUES.currentDeviceAttributes = {
  deviceModelId: undefined,
  serialNumber: '',
}

INITIAL_VALUES.icdCodeIds = []
INITIAL_VALUES.alertRecipientIds = []
INITIAL_VALUES.practiceMrn = undefined
INITIAL_VALUES.deviceIcdCodeId = undefined
INITIAL_VALUES.remoteMonitoring = true
INITIAL_VALUES.diseaseIds = []

const DEFAULT_DOB = moment().subtract(40, 'years')

const propsFor = formElementPropsGenerator({
  formName: 'patient-form',
  labels: PATIENTS_LABELS,
  placeholders: PATIENTS_PLACEHOLDERS,
})

function buildValidationSchema(isNew = false, forAdmin = false) {
  const shape = {
    firstName: Yup.string()
      .min(2)
      .required(),
    lastName: Yup.string()
      .min(2)
      .required(),
    birthDate: Yup.date()
      .max(new Date())
      .required(),
    email: Yup.string()
      .nullable()
      .email(),
    mobilePhone: Yup.string().phone(),
    homePhone: Yup.string()
      .nullable()
      .phone(),
    addressStreet: Yup.string().required(),
    addressApt: Yup.string().nullable(),
    addressCity: Yup.string().required(),
    addressState: Yup.string().when('addressCountry', {
      is: 'United States',
      then: Yup.string().required(),
      otherwise: Yup.string().nullable(),
    }),
    addressCountry: Yup.string(),
    addressZipCode: Yup.string().required(),
    homeMonitorSerialNumber: Yup.string().nullable(),
    remoteMonitoring: Yup.bool().default(true),
    diseaseIds: Yup.array(),
    medicalProviderId: Yup.string().nullable(),
  }
  if (isNew) {
    shape.currentDeviceAttributes = Yup.object().shape({
      deviceModelId: Yup.string().required(),
      serialNumber: Yup.string().required(),
    })
  }
  if (forAdmin && isNew) {
    shape.practiceId = Yup.string().required()
  }
  return Yup.object().shape(shape)
}

const labelCol = {
  sm: { span: 8 },
}

const wrapperCol = {
  sm: { span: 15 },
}

const actionsWrapperCol = {
  sm: { span: 4, offset: 10 },
}

const devicePopupContent = (
  <div>
    It can be found at:
    <ul>
      <li>An older device interrogation</li>
      <li>Patients device ID CARD</li>
      <li>By doing an in-office device interrogation</li>
    </ul>
  </div>
)

const formatPatientData = patientData => {
  const { insuranceProviderId, deviceIcdCode, ...rest } = patientData

  if (!patientData.remoteMonitoring) {
    rest.enrolled = false
    rest.paired = false
    rest.homeMonitorSerialNumber = null
  }

  return {
    ...rest,
    alertRecipientIds: patientData.alertRecipients
      ? patientData.alertRecipients.map(recipient => {
          return recipient.id.toString()
        })
      : [],
    icdCodeIds: patientData.icdCodes
      ? patientData.icdCodes.map(code => {
          return code.id.toString()
        })
      : [],
    deviceIcdCodeId:
      deviceIcdCode?.id.toString() || INITIAL_VALUES.deviceIcdCodeId,
    insuranceProviderId:
      insuranceProviderId || INITIAL_VALUES.insuranceProviderId,
    medicalProviderId:
      patientData?.medicalProvider?.id || INITIAL_VALUES.medicalProviderId,
  }
}

export default function PatientForm({ patientData, onSubmit }) {
  const classes = useStyles()
  const initialValues = patientData
    ? formatPatientData(patientData)
    : INITIAL_VALUES
  const isAdmin = useIsAdmin()
  const inOfficeChecksEnabled = useFeatureEnabled('in_office_checks')
  const validationSchema = useMemo(
    () => buildValidationSchema(!patientData, isAdmin),
    [isAdmin, patientData],
  )

  const [filteredICDCodes, setFilteredICDCodes] = useState([])
  const diseases = useDiseases()

  const onSelectDeviceModel = value => {
    api.icdCodes.getIcdCodes('all', { device_model_id: value }).then(list => {
      setFilteredICDCodes(list)
    })
  }

  useEffect(() => {
    if (patientData?.currentDevice)
      api.icdCodes
        .getIcdCodes('all', { patient_device_id: patientData.currentDevice.id })
        .then(list => {
          setFilteredICDCodes(list)
        })
  }, [patientData])

  const icdItem = code => ({
    id: code.id,
    label: `${code.code} - ${code.description}`,
  })

  const DiseasesForm = () => {
    if (patientData) {
      return <DiseasesEdit patientId={patientData.id} />
    }
    return (
      <MultipleCheckboxes
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...propsFor('diseaseIds')}
        options={diseases.map(disease => ({
          label: disease.name,
          value: disease.id,
        }))}
        wrapperCol={{ span: 21 }}
        labelCol={{ span: 3 }}
        labelAlign="left"
      />
    )
  }

  const currentUserPracticeId = useCurrentUserPracticeId()
  const { brandPrimary } = useTheme()

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid, submitCount, values }) => {
          const practiceId =
            patientData?.practiceId ||
            values?.practiceId ||
            currentUserPracticeId

          return (
            <Form
              onSubmit={handleSubmit}
              layout="horizontal"
              labelCol={labelCol}
              wrapperCol={wrapperCol}
            >
              {isAdmin && !patientData && (
                <Col span={12}>
                  <Form.Item
                    labelCol={{ span: 8 }}
                    label={PATIENTS_LABELS.practiceId}
                  >
                    <PracticeSelect
                      {...propsFor('practiceId')}
                      autoFocus
                      label=""
                    />
                  </Form.Item>
                </Col>
              )}
              <Divider orientation="left">Personal Information</Divider>
              <Row>
                <Col span={12}>
                  <Input {...propsFor('firstName')} autoFocus={!isAdmin} />
                </Col>
                <Col span={12}>
                  <Input {...propsFor('lastName')} />
                </Col>
                <Col span={12}>
                  <DatePicker
                    {...propsFor('birthDate')}
                    showToday={false}
                    allowClear={false}
                    defaultPickerValue={DEFAULT_DOB}
                  />
                </Col>
                <Col span={12}>
                  <Input {...propsFor('email')} />
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <PhoneNumber {...propsFor('mobilePhone')} />
                </Col>
                <Col span={12}>
                  <PhoneNumber {...propsFor('homePhone')} />
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Input {...propsFor('practiceMrn')} />
                </Col>
              </Row>
              {inOfficeChecksEnabled && (
                <Row>
                  <Col span={8} offset={4}>
                    <Checkbox
                      {...propsFor('inOfficeChecks')}
                      checked
                      disabled
                    />
                  </Col>
                  <Col span={8}>
                    <Checkbox {...propsFor('remoteMonitoring')} />
                  </Col>
                </Row>
              )}
              <Divider orientation="left">Address</Divider>
              <Row>
                <Col span={12}>
                  <Input {...propsFor('addressStreet')} />
                </Col>
                <Col span={12}>
                  <Input {...propsFor('addressApt')} />
                </Col>
                <Col span={12}>
                  <Input {...propsFor('addressCity')} />
                </Col>
                <Col span={12}>
                  <USStateSelect {...propsFor('addressState')} />
                </Col>
                <Col span={12}>
                  <CountrySelect {...propsFor('addressCountry')} />
                </Col>
                <Col span={12}>
                  <Input {...propsFor('addressZipCode')} />
                </Col>
              </Row>
              <Divider orientation="left">Insurance Information</Divider>
              <Row>
                <Col span={12}>
                  <Form.Item
                    labelCol={{ span: 8 }}
                    label={PATIENTS_LABELS.insuranceProviderId}
                  >
                    <InsuranceProviderSelect
                      {...propsFor('insuranceProviderId')}
                      value={values.insuranceProvider}
                      label=""
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Input {...propsFor('insurancePolicyNumber')} />
                </Col>
              </Row>

              <Divider orientation="left">Medical Providers</Divider>
              <Row>
                <Col span={12}>
                  <Form.Item
                    labelCol={{ span: 8 }}
                    label={PATIENTS_LABELS.medicalProviderId}
                  >
                    {practiceId && (
                      <MedicalProviderSelect
                        {...propsFor('medicalProviderId')}
                        practiceId={practiceId}
                        displayNPI
                        label=""
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Col span={24}>
                    <Input {...propsFor('pcpName')} />
                  </Col>
                  <Col span={24}>
                    <Input {...propsFor('pcpPhoneNumber')} />
                  </Col>
                  <Col span={24}>
                    <Input {...propsFor('pcpFaxNumber')} />
                  </Col>
                  {practiceId && (
                    <Col span={24}>
                      <Form.Item
                        labelCol={{ span: 8 }}
                        label={PATIENTS_LABELS.alertsRecipientId}
                      >
                        <MedicalProviderSelect
                          {...propsFor('alertRecipientIds')}
                          label=""
                          practiceId={practiceId}
                          mode="multiple"
                          alertRecipients
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Col>
              </Row>

              <Divider orientation="left">Cardiac Device</Divider>
              <>
                {!patientData && (
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        labelCol={{ span: 8 }}
                        label={
                          <Popover content={devicePopupContent}>
                            {
                              PATIENTS_LABELS.currentDeviceAttributes
                                .deviceModelId
                            }{' '}
                            <Icon type="question-circle" />
                          </Popover>
                        }
                      >
                        <DeviceModelSelect
                          {...propsFor('currentDeviceAttributes.deviceModelId')}
                          label=""
                          onSelect={onSelectDeviceModel}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Input
                        {...propsFor('currentDeviceAttributes.serialNumber')}
                        label={
                          <Popover content={devicePopupContent}>
                            {
                              PATIENTS_LABELS.currentDeviceAttributes
                                .serialNumber
                            }{' '}
                            <Icon type="question-circle" />
                          </Popover>
                        }
                      />
                    </Col>
                  </Row>
                )}
                {filteredICDCodes.length > 0 && (
                  <Row>
                    <Col span={24}>
                      <IcdCodesSelect
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...propsFor('icdCodeIds')}
                        label="ICD Code for 93297 and 93298"
                        id="patient-form-deviceIcdCodesId"
                        name="deviceIcdCodeId"
                        options={filteredICDCodes.map(icdItem)}
                        wrapperCol={{ span: 10 }}
                        required
                      />
                    </Col>
                  </Row>
                )}
              </>
              {!values.remoteMonitoring && (
                <Row>
                  <Col span={12} style={{ color: brandPrimary }}>
                    <Icon
                      type="exclamation-circle"
                      style={{ marginRight: 5 }}
                    />{' '}
                    Remote monitoring is not enabled
                  </Col>
                </Row>
              )}
              {!!values.remoteMonitoring && (
                <Row className={classes.questions}>
                  <Col span={24}>
                    <Switch
                      {...propsFor('enrolled')}
                      extra={
                        <a
                          href="https://drive.google.com/open?id=1tvgU-yz5kV3TfIzCWfnzvSk4XMc9SExA"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          (Instructions for enrollment)
                        </a>
                      }
                      labelCol={null}
                      wrapperCol={null}
                      labelAlign="left"
                      valueLabelsSchema={VALUE_LABELS_SCHEMA.yesNo}
                    />
                  </Col>
                  <Col span={24}>
                    <Switch
                      {...propsFor('paired')}
                      extra={
                        <a
                          href="https://drive.google.com/open?id=1_wc245VHZGFkQ7klfT4Ouf8rmOBYZb_9"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          (Instructions for pairing)
                        </a>
                      }
                      labelCol={null}
                      wrapperCol={null}
                      labelAlign="left"
                      valueLabelsSchema={VALUE_LABELS_SCHEMA.yesNo}
                    />
                  </Col>
                  <Col span={19}>
                    <Form.Item
                      labelAlign="left"
                      labelCol={{ span: 5 }}
                      label={
                        <Popover content="It can be found on the box or the back of the home monitor.">
                          {PATIENTS_LABELS.homeMonitorSerialNumber}{' '}
                          <Icon type="question-circle" />
                        </Popover>
                      }
                    >
                      <Select
                        {...propsFor('homeMonitorSerialNumber')}
                        label={false}
                        wrapperCol={{ span: 16 }}
                        hasGroups
                        options={HOME_MONITOR_MODELS}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
              <Divider orientation="left">Diseases and History</Divider>
              <DiseasesForm />

              <Row>
                <div className="icd-codes">
                  <Row style={{ marginTop: 10 }}>
                    <Col span={20}>
                      <IcdCodesSelect
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...propsFor('icdCodeIds')}
                        wrapperCol={{ span: 20 }}
                        mode="multiple"
                      />
                    </Col>
                  </Row>
                </div>
              </Row>
              <SubmitButton
                isSubmitting={isSubmitting}
                isValid={isValid}
                title={patientData ? 'Save Patient' : 'Create Patient'}
                submitCount={submitCount}
                wrapperCol={actionsWrapperCol}
              />
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

PatientForm.propTypes = {
  patientData: patientShape,
  onSubmit: PropTypes.func.isRequired,
}

PatientForm.defaultProps = {
  patientData: undefined,
}
