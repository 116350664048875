import { createSelector } from '@reduxjs/toolkit'
import KeyMirror from 'keymirror'
import { denormalize, normalize } from 'normalizr'
import { useDispatch, useSelector } from 'react-redux'
import api from '../../api'
import { alertsSchema } from '../normalizr'
import { getPaginationHeaders, useAsyncAction } from '../utils'
import createSlice from '../create-slice'
import { getEntities } from './entities'
import { useIsAdmin } from './session'

const UNACKNOWLEDGED_STATUSES = KeyMirror({
  loading: null,
  finished: null,
  error: null,
})

const alertsInitialState = {
  alertsIds: [],
  pageSize: 0,
  total: 0,
  unacknowledged: {
    status: 'initial',
    count: 0,
  },
}

const alertsSlice = createSlice({
  name: 'alerts',
  initialState: alertsInitialState,
  reducers: {
    setAlerts: (state, { payload: { result, pageSize, total } }) => {
      state.alertsIds = result
      state.pageSize = pageSize
      state.total = total
    },
    setUnacknowledgedCount(state, { payload }) {
      state.unacknowledged.count = payload
      state.unacknowledged.status = UNACKNOWLEDGED_STATUSES.finished
    },
    startUnacknowledgedCountFetch(state) {
      state.unacknowledged.status = UNACKNOWLEDGED_STATUSES.loading
    },
    markUnacknowledgedError(state) {
      state.unacknowledged.status = UNACKNOWLEDGED_STATUSES.error
    },
  },
})

const { actions } = alertsSlice

const selectState = state => state[alertsSlice.name]

const unacknowledgedSelector = createSelector(
  selectState,
  alerts => alerts.unacknowledged,
)

export function useAlerts() {
  const { alertsIds } = useSelector(selectState)
  const entities = useSelector(getEntities)
  const alerts = denormalize(alertsIds, [alertsSchema], entities)
  return alerts
}

export function useAlertsPagination() {
  const { pageSize, total } = useSelector(selectState)
  return { pageSize, total }
}

export function useUnacknowledgedAlertsCount() {
  const isAdmin = useIsAdmin()
  const dispatch = useDispatch()

  if (!isAdmin) dispatch(fetchUnacknowledgedCount())

  const { count } = useSelector(unacknowledgedSelector)

  return { count, reset: () => dispatch(fetchUnacknowledgedCount(true)) }
}

function fetchUnacknowledgedCount(force) {
  return async (dispatch, getState) => {
    const unacknowledged = unacknowledgedSelector(getState())
    if (
      !force &&
      [
        UNACKNOWLEDGED_STATUSES.loading,
        UNACKNOWLEDGED_STATUSES.finished,
      ].includes(unacknowledged.status)
    ) {
      return undefined
    }
    dispatch(actions.startUnacknowledgedCountFetch())
    try {
      const { count } = await api.alerts.getUnacknowledgedCount()
      dispatch(actions.setUnacknowledgedCount(count))
      return count
    } catch (err) {
      dispatch(actions.markUnacknowledgedError())
      return undefined
    }
  }
}

export function fetchAlerts(
  page,
  practiceId,
  acknowledged,
  medicalProviderId,
  deviceTypeId,
) {
  return async dispatch => {
    const { body, headers } = await api.alerts.list({
      page,
      practiceId,
      acknowledged,
      medicalProviderId,
      deviceTypeId,
    })
    dispatch(
      actions.setAlerts({
        ...normalize(body, [alertsSchema]),
        ...getPaginationHeaders(headers),
      }),
    )
  }
}

export function useFetchAlerts({
  page,
  practiceId,
  acknowledged,
  medicalProviderId,
  deviceTypeId,
} = {}) {
  return useAsyncAction(
    fetchAlerts,
    page,
    practiceId,
    acknowledged,
    medicalProviderId,
    deviceTypeId,
  )
}

export default alertsSlice
