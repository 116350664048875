import {
  Button,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Dropdown,
  Icon,
  List,
  Menu,
  message,
  Modal,
  Row,
  Tag,
} from 'antd'
import React, { useCallback, useState, useEffect, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { formatPhoneNumber } from 'react-phone-number-input'
import { useHistory, useParams } from 'react-router-dom'
import queryString from 'query-string'
import api from '../../api'
import { useAsync } from '../../hooks'
import { formatDOB } from '../../lib/datetime'
import { useIsAdmin } from '../../redux/slices/session'
import { toPath } from '../../routes'
import Comments from '../Comments'
import { ListError, PageHeader } from '../Common'
import Loading from '../Loading'
import Empty from '../Common/Empty'
import { formatMedicalProvider } from '../Forms/MedicalProviderSelect'
import ChangeDeviceModal from './ChangeDeviceModal'
import FullRemovalModal from './FullRemovalModal'
import WelcomeLetter from './WelcomeLetter'
import TopContent from './TopContent'
import LeadInfo from './LeadInfo'
import EstimatedLongevity from './EstimatedLongevity'
import DiseasesList from './DiseasesList'
import Timeline from './Timeline'

const useStyles = createUseStyles({
  actions: {
    marginTop: 30,
    textAlign: 'center',
  },
  tag: {
    verticalAlign: 'middle',
    marginBottom: 5,
  },
  dangerZoneButton: {
    marginLeft: 'auto',
  },
  modalButtons: {
    textAlign: 'right',
  },
  comments: {
    marginTop: 30,
  },
})

export default function Details() {
  const history = useHistory()
  const { id } = useParams()
  const isAdmin = useIsAdmin()
  const [deviceChangeVisible, setDeviceChangeVisible] = useState(false)
  const [practice, setPractice] = useState({
    id: '',
  })
  const [medicalProviders, setMedicalProviders] = useState(null)

  const goToEdit = useCallback(
    () => history.push(toPath.patients.patient.edit({ id })),
    [history, id],
  )
  const goToAlert = () =>
    history.push(
      toPath.alerts.new().concat('?', queryString.stringify({ patientId: id })),
    )

  const goToOfficeCheck = () => {
    history.push(
      toPath.practices.officeChecks
        .new({
          practiceId: patient.practiceId,
        })
        .concat('?', queryString.stringify({ patientId: id })),
    )
  }

  const [removalOptionsVisible, setRemovalOptionsVisible] = useState(false)
  const hideRemovalOptions = useCallback(
    () => setRemovalOptionsVisible(false),
    [],
  )
  const showRemovalOptions = useCallback(
    () => setRemovalOptionsVisible(true),
    [],
  )

  const [remindersOptionsVisible, setRemindersOptionsVisible] = useState(false)
  const hideRemindersOptions = useCallback(
    () => setRemindersOptionsVisible(false),
    [],
  )
  const showRemindersOptions = useCallback(
    () => setRemindersOptionsVisible(true),
    [],
  )

  const [preferencesModalVisible, setPreferencesModalVisible] = useState(false)
  const hidePreferencesModal = useCallback(
    () => setPreferencesModalVisible(false),
    [],
  )
  const showPreferencesModal = useCallback(
    () => setPreferencesModalVisible(true),
    [],
  )
  const [fullRemovalVisible, setFullRemovalVisible] = useState(false)
  const [remindTransmissions, setRemindTransmissions] = useState(false)
  const [notifyDataReception, setNotifyDataReception] = useState(false)
  const hideFullRemoval = useCallback(() => setFullRemovalVisible(false), [])
  const showFullRemoval = useCallback(() => setFullRemovalVisible(true), [])
  const getPatient = useCallback(async () => {
    const patient = await api.patients.get(id)
    setRemindTransmissions(patient.preferences.remindTransmissions)
    setNotifyDataReception(patient.preferences.notifyDataReception)

    return patient
  }, [id])
  const { pending, value: patient, error, clearError, execute } = useAsync(
    getPatient,
  )

  // Load practice to get the logo.
  useEffect(() => {
    ;(async () => {
      if (patient?.practice?.id) {
        setPractice(await api.practices.getPractice(patient.practice.id))
        if (!medicalProviders) {
          setMedicalProviders(
            await api.practices.getMedicalProviders(patient.practice.id),
          )
        }
      }
    })()
  }, [medicalProviders, patient])

  const medicalProvider = useMemo(() => {
    const provider = medicalProviders?.find(
      p => p.id === patient?.medicalProvider?.id,
    )

    return provider ? formatMedicalProvider(provider) : ''
  }, [medicalProviders, patient])

  const handleDeviceChanged = useCallback(
    changed => {
      if (changed) execute()
      setDeviceChangeVisible(false)
    },
    [execute],
  )
  const deactivate = useCallback(async () => {
    try {
      await api.patients.destroy(id)
      hideRemovalOptions()
      message.success('Patient successfully deactivated')
      execute()
    } catch (e) {
      message.error(`Could not deactivate patient: ${e.message}`)
    }
  }, [execute, hideRemovalOptions, id])
  const reactivate = useCallback(async () => {
    try {
      await api.patients.update(id, { active: true })
      message.success('Patient successfully reactivated')
      execute()
    } catch (e) {
      message.error(`Could not reactivate patient: ${e.message}`)
    }
  }, [execute, id])
  const approve = useCallback(async () => {
    try {
      await api.patients.update(id, { approved: true })
      message.success('Patient successfully approved')
      execute()
    } catch (e) {
      message.error(`Could not approve patient: ${e.message}`)
    }
  }, [execute, id])
  const unapprove = useCallback(async () => {
    try {
      await api.patients.update(id, { approved: false })
      message.success('Patient successfully unapproved')
      execute()
    } catch (e) {
      message.error(`Could not unapprove patient: ${e.message}`)
    }
  }, [execute, id])
  const remindCall = useCallback(async () => {
    try {
      await api.patients.remind([id], 'voice')
      hideRemindersOptions()
      message.success('Reminder call sent')
      execute()
    } catch (e) {
      message.error(`Could not remind patient: ${e.message}`)
    }
  }, [execute, hideRemindersOptions, id])
  const remindSms = useCallback(async () => {
    try {
      await api.patients.remind([id], 'sms')
      hideRemindersOptions()
      message.success('Reminder SMS sent')
      execute()
    } catch (e) {
      message.error(`Could not remind patient: ${e.message}`)
    }
  }, [execute, hideRemindersOptions, id])

  const cancelPreferences = useCallback(() => {
    setRemindTransmissions(patient.preferences.remindTransmissions)
    setNotifyDataReception(patient.preferences.notifyDataReception)
    setPreferencesModalVisible(false)
  }, [patient])

  const savePreferences = useCallback(async () => {
    try {
      await api.patients.savePreferences(id, {
        remindTransmissions,
        notifyDataReception,
      })
      await getPatient()
      hidePreferencesModal()
      message.success('Preferences saved sucessfully')
    } catch (e) {
      message.error(`Could not save preferences: ${e.message}`)
    }
  }, [
    hidePreferencesModal,
    id,
    remindTransmissions,
    notifyDataReception,
    getPatient,
  ])

  const handleDataReceptionChange = e => {
    setNotifyDataReception(e.target.checked)
  }

  const handleReminderChange = e => {
    setRemindTransmissions(e.target.checked)
  }

  const { pending: deactivating, execute: handleDeactivate } = useAsync(
    deactivate,
    false,
  )
  const { pending: makingReminderCall, execute: makeReminderCall } = useAsync(
    remindCall,
    false,
  )
  const { pending: sendingReminderSms, execute: sendReminderSms } = useAsync(
    remindSms,
    false,
  )
  const classes = useStyles()

  if (pending) return <Loading large />
  if (error) {
    return <ListError description={error.message} onClose={clearError} />
  }
  const { currentDevice: device } = patient

  const showWelcomeLetterButton =
    Number.isInteger(patient?.letterStatus) && patient?.welcomeLetterTemplate

  return (
    <div>
      <PageHeader
        title={
          <>
            {`${patient.firstName} ${patient.lastName}`}{' '}
            {!patient.active && <Tag className={classes.tag}>deactivated</Tag>}
            {patient.approved && (
              <Tag className={classes.tag} color="green">
                approved
              </Tag>
            )}
            {!patient.approved && (
              <Tag className={classes.tag} color="yellow">
                pending approval
              </Tag>
            )}
          </>
        }
        subTitle={isAdmin && patient.practice && patient.practice.name}
        onBack={history.goBack}
        extra={
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1" onClick={goToEdit}>
                  <Icon type="edit" /> Edit
                </Menu.Item>
                <Menu.Item key="2" onClick={() => setDeviceChangeVisible(true)}>
                  <Icon type="heart" /> Change Cardiac Device
                </Menu.Item>
                {isAdmin && !patient.approved && (
                  <Menu.Item key="3" onClick={approve}>
                    <Icon type="like" /> Approve
                  </Menu.Item>
                )}
                {isAdmin && patient.approved && (
                  <Menu.Item key="4" onClick={unapprove}>
                    <Icon type="dislike" /> Unapprove
                  </Menu.Item>
                )}
                {isAdmin && !patient.active && (
                  <Menu.Item key="5" onClick={reactivate}>
                    <Icon type="api" /> Reactivate
                  </Menu.Item>
                )}
                {isAdmin && (
                  <Menu.Item key="6" onClick={showRemovalOptions}>
                    <Icon type="delete" /> Removal options
                  </Menu.Item>
                )}
                {isAdmin && (
                  <Menu.Item
                    key="7"
                    onClick={showRemindersOptions}
                    disabled={!remindTransmissions}
                  >
                    <Icon type="phone" /> Send reminder
                  </Menu.Item>
                )}
                {isAdmin && patient.active && (
                  <Menu.Item key="8" onClick={goToAlert}>
                    <Icon type="alert" /> Create Alert
                  </Menu.Item>
                )}
                {isAdmin && patient.active && (
                  <Menu.Item key="9" onClick={goToOfficeCheck}>
                    <Icon type="snippets" /> Create Office Check
                  </Menu.Item>
                )}
              </Menu>
            }
          >
            <Button>
              Actions <Icon type="down" />
            </Button>
          </Dropdown>
        }
      />

      <TopContent remoteMonitoring={patient.remoteMonitoring} />
      <WelcomeLetter
        patient={{
          ...patient,
          welcome_letter_template: 'manual_active',
        }}
        practice={practice}
        showWelcomeLetterButton={showWelcomeLetterButton}
      />
      <Divider orientation="left">Personal Information</Divider>
      <Descriptions column={2} size="small">
        <Descriptions.Item label="First Name">
          {patient.firstName}
        </Descriptions.Item>
        <Descriptions.Item label="Last Name">
          {patient.lastName}
        </Descriptions.Item>
        <Descriptions.Item label="Date of birth">
          {formatDOB(patient.birthDate)}
        </Descriptions.Item>
        <Descriptions.Item label="E-mail">{patient.email}</Descriptions.Item>
        <Descriptions.Item label="Mobile phone">
          {formatPhoneNumber(patient.mobilePhone)}
        </Descriptions.Item>
        <Descriptions.Item label="Home phone">
          {formatPhoneNumber(patient.homePhone)}
        </Descriptions.Item>
        <Descriptions.Item label="MRN">{patient.practiceMrn}</Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">Address</Divider>
      <Descriptions column={2} size="small">
        <Descriptions.Item label="Street">
          {patient.addressStreet}
        </Descriptions.Item>
        <Descriptions.Item label="Apt/Suite">
          {patient.addressApt}
        </Descriptions.Item>
        <Descriptions.Item label="City">
          {patient.addressCity}
        </Descriptions.Item>
        <Descriptions.Item label="State">
          {patient.addressState}
        </Descriptions.Item>
        <Descriptions.Item label="Country">
          {patient.addressCountry}
        </Descriptions.Item>
        <Descriptions.Item label="Zip code">
          {patient.addressZipCode}
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">Insurance Information</Divider>
      <Descriptions column={2} size="small">
        <Descriptions.Item label="Insurance Provider">
          {patient.insuranceProvider ? patient.insuranceProvider.name : ''}
        </Descriptions.Item>
        <Descriptions.Item label="Insurance Policy #">
          {patient.insurancePolicyNumber}
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">Medical Provider</Divider>
      <Row>
        <Col span={12}>
          <Descriptions column={2} size="small">
            <Descriptions.Item label="Provider">
              {medicalProvider}
            </Descriptions.Item>
          </Descriptions>
        </Col>
        <Col span={12}>
          <Descriptions column={1} size="small">
            <Descriptions.Item label="PCP name">
              {patient.pcpName}
            </Descriptions.Item>
            <Descriptions.Item label="PCP phone number">
              {patient.pcpPhoneNumber}
            </Descriptions.Item>
            <Descriptions.Item label="PCP fax number">
              {patient.pcpFaxNumber}
            </Descriptions.Item>
            <div className="alerts-recipients">
              <div className="ant-descriptions-item-label ant-descriptions-item-colon">
                Alerts Recipients
              </div>
              <Descriptions.Item
                label="Alerts Recipients"
                labelStyle={{ verticalAlign: 'top', position: 'relative' }}
              >
                <List
                  size="small"
                  dataSource={patient.alertRecipients}
                  renderItem={recipient => (
                    <List.Item>{`${recipient.firstName} ${recipient.lastName} (${recipient.email})`}</List.Item>
                  )}
                />
              </Descriptions.Item>
            </div>
          </Descriptions>
        </Col>
      </Row>

      <Divider orientation="left">Cardiac Device</Divider>
      <Row>
        <Col span={8}>
          <Descriptions column={2} size="small">
            <Descriptions.Item label="Model">
              {device.modelName}
            </Descriptions.Item>
            <Descriptions.Item label="Company">
              {device.modelManufacturer}
            </Descriptions.Item>
            <Descriptions.Item label="Type">
              {device.modelType}
            </Descriptions.Item>
            <Descriptions.Item label="Serial Number">
              {device.serialNumber}
            </Descriptions.Item>
            {patient.remoteMonitoring && (
              <>
                <Descriptions.Item label="Enrolled">
                  {patient.enrolled ? 'Yes' : 'No'}
                </Descriptions.Item>
                <Descriptions.Item label="Paired">
                  {patient.paired ? 'Yes' : 'No'}
                </Descriptions.Item>
                <Descriptions.Item label="Monitor Model Number">
                  {patient.homeMonitorSerialNumber}
                </Descriptions.Item>
              </>
            )}
          </Descriptions>
        </Col>

        <Col span={8}>
          <EstimatedLongevity
            estimatedBatteryLongevity={
              patient.currentDevice.estimatedBatteryLongevity
            }
          />
        </Col>
        <Col span={8}>
          <LeadInfo currentLead={patient.currentLead} />
        </Col>
      </Row>
      <Divider orientation="left">Diseases and History</Divider>
      <DiseasesList patientId={id} />
      <div className="icd-codes">
        <Descriptions size="large">
          <Descriptions.Item label="ICD Codes">
            <List
              size="small"
              dataSource={
                patient.deviceIcdCode
                  ? patient.icdCodes.concat([patient.deviceIcdCode])
                  : patient.icdCodes
              }
              renderItem={code => (
                <List.Item>{`${code.code} - ${code.description}`}</List.Item>
              )}
            />
          </Descriptions.Item>
        </Descriptions>
      </div>
      <Divider orientation="left">Notification Preferences</Divider>
      <Descriptions column={3} size="small">
        <Descriptions.Item label="Remind transmissions">
          {remindTransmissions ? 'Yes' : 'No'}
        </Descriptions.Item>
        <Descriptions.Item label="Notify data reception">
          {notifyDataReception ? 'Yes' : 'No'}
        </Descriptions.Item>
        <Descriptions.Item>
          <Button onClick={showPreferencesModal} type="primary">
            Change
          </Button>
          <Modal
            title="Preferences"
            visible={preferencesModalVisible}
            onCancel={cancelPreferences}
            onOk={savePreferences}
            cancelText={null}
            okType="primary"
          >
            <List title="Notifications">
              <List.Item>
                <Checkbox
                  checked={remindTransmissions}
                  onChange={handleReminderChange}
                >
                  Remind missed transmissions
                </Checkbox>
              </List.Item>
              <List.Item>
                <Checkbox
                  checked={notifyDataReception}
                  onChange={handleDataReceptionChange}
                >
                  Notify data reception
                </Checkbox>
              </List.Item>
            </List>
          </Modal>
        </Descriptions.Item>
      </Descriptions>

      <Divider orientation="left">
        Alerts, Remote Reports, and Office Checks
      </Divider>

      {id && practice?.id ? (
        <Timeline patientId={id} practiceId={practice.id} />
      ) : (
        <Empty />
      )}

      <Modal
        title="Removal options"
        visible={removalOptionsVisible}
        onCancel={hideRemovalOptions}
        cancelText={null}
        okType="secondary"
        footer={null}
      >
        <List title="Deactivate">
          <List.Item>
            <div>
              <strong>Deactivate this patient</strong>
              <p>
                Won&apos;t be visible by default in the patient list but the
                information will be available.
              </p>
            </div>
            <Button
              type="danger"
              ghost
              icon="close-circle"
              className={`delete-button ${classes.dangerZoneButton}`}
              onClick={handleDeactivate}
              loading={deactivating}
            >
              Deactivate
            </Button>
          </List.Item>
          <List.Item>
            <div>
              <strong>Full remove this patient</strong>
              <p>
                The patient will be deleted from the database. This action
                cannot be reverted.
              </p>
            </div>
            <Button
              type="danger"
              ghost
              icon="delete"
              className={`hard-delete-button ${classes.dangerZoneButton}`}
              onClick={showFullRemoval}
            >
              Full removal
            </Button>
          </List.Item>
        </List>
        <div className={classes.modalButtons}>
          <Divider />
          <Button type="secondary" onClick={hideRemovalOptions}>
            Close
          </Button>
        </div>
      </Modal>
      <Modal
        title="Patient reminders"
        visible={remindersOptionsVisible}
        onCancel={hideRemindersOptions}
        cancelText={null}
        okType="secondary"
        footer={null}
      >
        <List title="Reminders">
          <List.Item>
            <div>
              <strong>Make a reminder phone call</strong>
              <p>
                A reminder call will be made to the patient&apos;s phone number.
              </p>
            </div>
            <Button
              type="primary"
              ghost
              icon="phone"
              className={`delete-button ${classes.dangerZoneButton}`}
              onClick={makeReminderCall}
              loading={makingReminderCall}
            >
              Make reminder call
            </Button>
          </List.Item>
          <List.Item>
            <div>
              <strong>Send an SMS reminder</strong>
              <p>
                An SMS message will be sent to the patient&apos;s mobile phone
                number.
              </p>
            </div>
            <Button
              type="primary"
              ghost
              icon="message"
              className={`hard-delete-button ${classes.dangerZoneButton}`}
              onClick={sendReminderSms}
              loading={sendingReminderSms}
            >
              Send reminder SMS
            </Button>
          </List.Item>
        </List>
        <div className={classes.modalButtons}>
          <Divider />
          <Button type="secondary" onClick={hideRemindersOptions}>
            Close
          </Button>
        </div>
      </Modal>
      <ChangeDeviceModal
        patientId={id}
        onDeviceChanged={handleDeviceChanged}
        visible={deviceChangeVisible}
      />
      <FullRemovalModal
        patientId={id}
        onCancel={hideFullRemoval}
        visible={fullRemovalVisible}
      />
      <Comments
        collectionName="patients"
        id={patient.id}
        containerClassName={classes.comments}
      />
      <style>
        {`
          .icd-codes .ant-descriptions-item-content {
            display: block;
          }
        `}
      </style>
    </div>
  )
}
