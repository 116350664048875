/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, message, Card } from 'antd'
import { Formik, useFormikContext } from 'formik'
import humps from 'humps'
import { useHistory, useParams } from 'react-router-dom'
import startCase from 'lodash/startCase'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import { createUseStyles } from 'react-jss'
import { formElementPropsGenerator } from '../../lib/forms'
import { SubmitButton } from '../Forms'
import api from '../../api'
import Checkbox from '../Forms/Checkbox'
import QuestionForm from '../Reports/QuestionForm'
import {
  formatInitialValues,
  formatSavedValues,
  getLabels,
  getPlaceholders,
  buildValidationSchema,
  formatBatteryValue,
} from '../Reports/form-utils'
import FormItem from '../Reports/FormItem'

function filterCycle30Fields(formSections) {
  return formSections.filter(section => section.restrictedTo !== 'cycle_30')
}

const useStyles = createUseStyles({
  sectionCard: {
    marginTop: '16px',
    marginBottom: '16px',
  },
})

// eslint-disable-next-line react/prop-types
function FormatBatteryValue({ fieldId }) {
  const { values, setFieldValue } = useFormikContext()
  const [debouncedBattery, setDebouncedBattery] = useState(
    formatBatteryValue(values[fieldId] || ''),
  )

  // Keep a debounced battery value.
  const debounceBattery = useCallback(
    debounce(
      nextValue => setDebouncedBattery(formatBatteryValue(nextValue)),
      2000,
    ),
    [],
  )

  useEffect(() => {
    // Start debouncing if the battery input changes
    debounceBattery(values[fieldId])
    return () => {
      // Stop watching on component destruction
      debounceBattery.cancel()
    }
  }, [
    values,
    setFieldValue,
    setDebouncedBattery,
    debouncedBattery,
    debounceBattery,
    fieldId,
  ])

  // Keep battery field updated with debounced value
  useEffect(() => {
    setFieldValue(fieldId, debouncedBattery)
  }, [debouncedBattery, fieldId, setFieldValue])

  return null
}

export default function ReportForm({ report, practiceId, noValidation }) {
  const history = useHistory()
  const { id } = useParams()
  const classes = useStyles()
  const [answeredQuestion, setAnsweredQuestion] = useState(false)
  const [filterFields, setFilterFields] = useState(report.filteredFields)

  const {
    formSections: initialFormSections,
    fieldValues,
    critical,
    device,
    additionalQuestion,
    hasFile,
    hasLongCycleAndDeviceWithFullLength,
  } = report
  const showQuestionForm = !hasFile && !answeredQuestion && additionalQuestion
  const formSections = filterFields
    ? filterCycle30Fields(initialFormSections)
    : initialFormSections

  // FIXME: fields should come from api
  let batteryField = formSections
    .find(section => section.name === 'battery')
    .fields.find(field => field.itemName === 'estimated_battery_longevity')
  batteryField = {
    ...batteryField,
    dataType: 'text',
    description: `Express the longevity in years. If it’s under a year, express it in months.`,
  }
  delete batteryField.options

  const handleEdit = useCallback(
    async ({ critical: criticalField, ...values }) => {
      const reportValues = formatSavedValues(values, formSections)
      try {
        if (filterFields) {
          await api.reports.turnOff(id)
        }

        await api.officeChecks.saveReportForm(id, practiceId, {
          reportValues,
          critical: criticalField,
        })

        history.push(`/practices/${practiceId}/office-checks/${id}`)
        message.success('BioBridge report has been edited')
      } catch (e) {
        message.error(
          `There was an error editing a BioBridge report: ${e.message}`,
        )
      }
    },
    [filterFields, formSections, history, id, practiceId],
  )
  const handleQuestion = useCallback(
    async ({ question }) => {
      try {
        if (question === 'yes') {
          setAnsweredQuestion(true)
          return
        }

        if (question === 'no' && hasLongCycleAndDeviceWithFullLength) {
          setFilterFields(true)
          setAnsweredQuestion(true)
          return
        }

        await api.reports.turnOff(id, true)
        message.success('BioBridge report has been rejected')
        history.push('/unreviewed-reports')
      } catch (e) {
        message.error(
          `There was an error rejected a BioBridge report: ${e.message}`,
        )
      }
    },
    [hasLongCycleAndDeviceWithFullLength, history, id],
  )

  if (showQuestionForm) {
    return (
      <QuestionForm
        additionalQuestion={additionalQuestion}
        handleQuestion={handleQuestion}
      />
    )
  }

  const validationSchema = buildValidationSchema(formSections)
  const formType = humps.camelize(
    get(device, 'deviceModel.deviceType.formType'),
  )
  const propsFor = formElementPropsGenerator({
    formName: 'report-edit-form',
    labels: { ...getLabels(formSections), critical: 'Requires Attention' },
    placeholders: getPlaceholders(formSections),
  })

  return (
    <Formik
      onSubmit={handleEdit}
      initialValues={{
        ...formatInitialValues(fieldValues, formSections, formType),
        critical,
      }}
      validationSchema={noValidation ? null : validationSchema}
    >
      {({ handleSubmit, isSubmitting, isValid, submitCount }) => (
        <Form onSubmit={handleSubmit} noValidation>
          <FormatBatteryValue fieldId={batteryField.itemId} />
          {formSections.map(section => (
            <Card
              type="inner"
              title={startCase(section.name)}
              key={section.name}
              className={classes.sectionCard}
            >
              <div>
                {section.fields.map(field => (
                  <FormItem
                    propsFor={propsFor}
                    key={field.itemId}
                    field={field}
                    formType={formType}
                  />
                ))}
              </div>
            </Card>
          ))}
          <Card
            type="inner"
            title="Requires Attention"
            className={classes.sectionCard}
          >
            <Checkbox {...propsFor('critical')} />
          </Card>
          <SubmitButton
            isSubmitting={isSubmitting}
            isValid={isValid}
            submitCount={submitCount}
            title="Save Report"
          />
        </Form>
      )}
    </Formik>
  )
}

ReportForm.propTypes = {
  report: PropTypes.shape().isRequired,
  practiceId: PropTypes.string.isRequired,
  noValidation: PropTypes.bool,
}

ReportForm.defaultProps = {
  noValidation: false,
}
