import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'antd'
import noop from 'lodash/noop'

/**
 * Shows an error in a page that shows a list of items
 *
 * @param {Object} props
 * @param {String} props.message
 * @param {String} props.description
 * @param {Function} props.onClose Callback when user
 * wants to close the error
 */
function ListError({ message, description, isClosable, onClose }) {
  return (
    <Alert
      message={message}
      description={description}
      type="error"
      closable={isClosable}
      onClose={onClose}
    />
  )
}

ListError.propTypes = {
  message: PropTypes.string,
  description: PropTypes.string,
  isClosable: PropTypes.bool,
  onClose: PropTypes.func,
}

ListError.defaultProps = {
  message: 'There was an error',
  description: 'Please try again later',
  isClosable: true,
  onClose: noop,
}

export default ListError
