import { message, Typography, Alert } from 'antd'
import React, { useCallback, useState } from 'react'
import { Link } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import api from '../../../api'
import SessionLayout from '../SessionLayout'
import SendInstructionsForm from './SendInstructionsForm'

const { Paragraph } = Typography

const useStyles = createUseStyles({
  successContainer: {
    width: '300px',
    marginBottom: '20px',
  },
})

export default function PasswordRecovery() {
  const classes = useStyles()
  const [success, setSuccess] = useState(false)

  const onSubmit = useCallback(async ({ email }) => {
    try {
      await api.passwordReset.create(email)
      setSuccess(true)
    } catch (e) {
      message.error('Oops, we had a problem. Please try submitting again')
    }
  }, [])

  return (
    <SessionLayout>
      {success ? (
        <Alert
          className={classes.successContainer}
          type="success"
          showIcon
          message="You will receive an email with instructions on how to reset your password in a few minutes."
        />
      ) : (
        <>
          <Paragraph>
            To reset your password, enter the email address you use to sign:
          </Paragraph>
          <SendInstructionsForm onSubmit={onSubmit} />
        </>
      )}
      <Link to="/">Login</Link>
    </SessionLayout>
  )
}
