import React from 'react'
import { PageHeader } from '../../Common'
import PracticeSelectView from '../../Practices/PracticeSelectView'
import { toPath } from '../../../routes'

export default function OfficeCheckAuthorizations() {
  return (
    <div>
      <PageHeader title="Office Checks Authorizations" />
      <PracticeSelectView routeMethod={toPath.practices.officeAuthorizations} />
    </div>
  )
}
