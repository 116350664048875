import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Table, Button, Divider, Popconfirm, message } from 'antd'
import { PageHeader } from '../../Common'
import { toPath } from '../../../routes'
import { useAsync } from '../../../hooks'
import AddNewItemButton from '../../Common/AddNewItemButton'

import {
  useFetchLetterPhoneNumbers,
  useLetterPhoneNumbers,
} from '../../../redux/slices/letter-phone-numbers'
import api from '../../../api'

export default function PhoneNumbers() {
  const { isFetching, execute: fetchPhoneNumbers } = useAsync(
    useFetchLetterPhoneNumbers(true),
  )
  const phoneNumbers = useLetterPhoneNumbers()

  const history = useHistory()
  const goToEdit = useCallback(
    id =>
      history.push(toPath.welcomeLetters.phoneNumbers.phoneNumber.edit({ id })),
    [history],
  )

  const { pending: isDeleting, execute: handleDelete } = useAsync(async id => {
    try {
      await api.letterPhoneNumbers.deleteLetterPhoneNumber(id)
      message.success('Phone number successfully removed')
    } catch (e) {
      message.error(
        `There was an error removing this phone number: ${e.message}`,
      )
    }

    fetchPhoneNumbers()
  }, false)

  const columns = [
    {
      title: 'Area Code',
      dataIndex: 'areaCode',
      key: 'areaCode',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      align: 'center',
      title: 'Actions',
      key: 'actions',
      width: '100px',
      render: (_text, record) => {
        return (
          <span>
            <Button
              onClick={() => goToEdit(record.id)}
              type="link"
              icon="edit"
            />
            <Divider type="vertical" />
            <Popconfirm
              title="Are you sure you want to delete this phone number?"
              onConfirm={() => handleDelete(record.id)}
              okText="Yes"
              cancelText="No"
              placement="topRight"
            >
              <Button type="link" icon="delete" className="delete-button" />
            </Popconfirm>
          </span>
        )
      },
    },
  ]

  return (
    <div>
      <PageHeader
        title="Phone Numbers"
        extra={
          <AddNewItemButton
            itemName="phone number"
            path={toPath.welcomeLetters.phoneNumbers.new()}
            icon="phone"
          />
        }
      />
      <Table
        size="middle"
        rowKey="id"
        columns={columns}
        dataSource={phoneNumbers}
        loading={isFetching || isDeleting}
      />
    </div>
  )
}
