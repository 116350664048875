import moment from 'moment'
import React, { useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { message } from 'antd'

import { PageHeader } from '../Common'
import { toPath } from '../../routes'

import { usePractice } from '../../redux/slices/practices'
import { useFeatureGuard } from '../../redux/slices/session'

import OfficeCheckForm from './Form'
import CreateOfficeCheck from './UseCase/CreateOfficeCheck'

export default function NewOfficeCheck() {
  const history = useHistory()

  const [patient, setPatient] = useState()
  const { practiceId } = useParams()
  const practice = usePractice(practiceId)

  const onSubmit = useCallback(
    async (
      {
        patientId,
        firstName,
        lastName,
        dateOfBirth,
        practiceMrn,
        deviceModelId,
        deviceSerialNumber,
        dateOfCheck,
        remoteMonitoring,
      },
      { setErrors },
    ) => {
      const showErrors = errors =>
        typeof errors === 'string' ? message.error(errors) : setErrors(errors)

      const isToday = dateOfCheck && dateOfCheck.isSame(moment(), 'day')

      try {
        const [
          patientCreationResult,
          preAuthorizationCreationResult,
        ] = await CreateOfficeCheck(patient?.id || patientId, practiceId, {
          firstName,
          lastName,
          dateOfBirth,
          practiceMrn,
          deviceModelId,
          deviceSerialNumber,
          dateOfCheck,
          remoteMonitoring,
        })

        if (
          patientCreationResult.isSuccess() &&
          preAuthorizationCreationResult.isSuccess()
        ) {
          setPatient(undefined)

          message.success('Office check successfully created')

          if (isToday) {
            history.replace(
              toPath.practices.officeChecks.officeCheck.edit({
                practiceId,
                id: preAuthorizationCreationResult.object.id,
              }),
            )
          } else {
            history.replace(toPath.practices.officeChecks({ practiceId }))
          }

          return
        }

        if (!patientCreationResult.isSuccess()) {
          showErrors(patientCreationResult.getErrors())

          return
        }

        if (!preAuthorizationCreationResult.isSuccess()) {
          setPatient(patientCreationResult.object)
          showErrors(preAuthorizationCreationResult.getErrors())
        }
      } catch (e) {
        message.error(`Something went wrong. ${e.message}`)
        throw e
      }
    },
    [history, patient, practiceId],
  )

  useFeatureGuard('in_office_checks')

  return (
    <div>
      <PageHeader title="Add Office Check" subTitle={practice?.name} />
      <OfficeCheckForm practiceId={practiceId} onSubmit={onSubmit} />
    </div>
  )
}
