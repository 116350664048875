import React, { useEffect } from 'react'
import { Typography } from 'antd'
import { createUseStyles } from 'react-jss'
import { useLogout } from '../../redux/slices/session'
import SessionLayout from './SessionLayout'

const { Paragraph } = Typography

const useStyles = createUseStyles({
  redirectNotice: {
    marginBottom: '10px',
    color: '#5175b3',
    textAlign: 'center',
  },
})

export default function Logout() {
  const classes = useStyles()
  const logout = useLogout('/logged-out')

  useEffect(() => {
    const timer = setTimeout(logout, 3000)

    return () => clearTimeout(timer)
  })

  return (
    <SessionLayout>
      <Paragraph className={classes.redirectNotice}>
        Please wait while we log you out....
      </Paragraph>
    </SessionLayout>
  )
}
