import PropTypes from 'prop-types'
import { Button, Icon, message, Switch, Table } from 'antd'
import React, { useCallback, useEffect, useMemo } from 'react'
import get from 'lodash/get'
import { useDispatch } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { useIsAdmin } from '../../redux/slices/session'
import { useAsync } from '../../hooks'
import {
  updatePreAuthorization,
  useFetchPreAuthorizations,
  usePreAuthorizations,
  usePreAuthorizationsPagination,
} from '../../redux/slices/pre-authorizations'
import { ListError } from '../Common'
import { toPath } from '../../routes'
import { formatDOB } from '../../lib/datetime'
import useExportExcel from '../../hooks/useExportExcel'
import DownloadLink from '../Common/DownloadLink'

const useStyles = createUseStyles({
  present: {
    color: 'green',
  },
  notPresent: {
    color: 'orange',
  },
  rejected: {
    textAlign: 'center',
    color: 'red',
  },
})

const BASE_COLUMNS = [
  {
    title: 'Patient',
    dataIndex: 'patient.lastName',
    render(text, preAuthorization) {
      return (
        <Link to={toPath.patients.patient({ id: preAuthorization.patient.id })}>
          {preAuthorization.patient.lastName}
          {', '}
          {preAuthorization.patient.firstName}
        </Link>
      )
    },
  },
  {
    title: 'DOB',
    key: 'dob',
    dataIndex: 'patient.birthDate',
    render: formatDOB,
  },
  {
    title: 'Device Type',
    dataIndex: 'device.deviceModel.deviceType.name',
    key: 'deviceType',
  },
  {
    title: 'CPT Code',
    dataIndex: 'cptCode',
    key: 'cptCode',
  },
  {
    title: 'ICD-10 Code',
    dataIndex: 'icd10Code',
    key: 'icd10Code',
  },
  {
    title: 'Tech DOS',
    key: 'billingDate',
    render(text, preAuthorization) {
      if (!preAuthorization.billingDate) return ''
      return formatDOB(preAuthorization.billingDate)
    },
  },
  {
    title: 'Pro DOS',
    key: 'billingDate',
    render(text, preAuthorization) {
      if (!preAuthorization.signedAt) return '-'
      return formatDOB(preAuthorization.signedAt)
    },
  },
  {
    title: 'Auth #',
    key: 'auth',
    render(_text, preAuthorization) {
      const {
        insuranceAuthorizationFilePath,
        insuranceAuthorizationNumber,
      } = preAuthorization
      if (!insuranceAuthorizationFilePath) return insuranceAuthorizationNumber

      return (
        <DownloadLink path={insuranceAuthorizationFilePath}>
          <span>{insuranceAuthorizationNumber}</span>
        </DownloadLink>
      )
    },
  },
]

export default function PreAuthorizations({
  cycleId,
  onPageChange,
  page,
  filters,
  rowSelection,
}) {
  const classes = useStyles()
  const isAdmin = useIsAdmin()
  const preAuthorizations = usePreAuthorizations(cycleId)
  const { pageSize, total } = usePreAuthorizationsPagination(cycleId)
  const { pending, error, clearError, execute } = useAsync(
    useFetchPreAuthorizations(cycleId, page, filters),
    false,
  )
  const dispatch = useDispatch()

  useEffect(() => {
    execute(cycleId, page, filters)
  }, [execute, cycleId, page, filters])

  const handleAuthorizedChange = useCallback(
    async (id, authorized) => {
      try {
        await dispatch(updatePreAuthorization(id, { authorized }))
        message.success('Remote authorization successfully updated')
      } catch (e) {
        message.error(
          get(
            e,
            'response.errors.authorized',
            'Oops, we had a problem. Please try submitting again',
          ),
        )
      }
    },
    [dispatch],
  )

  const columns = useMemo(() => {
    const filesColumns = [
      {
        title: 'PDF',
        dataIndex: 'hasReportFile',
        key: 'hasReportFile',
        align: 'center',
        render(text, { hasReportFile }) {
          return (
            <>
              {hasReportFile === null ? (
                <span>-</span>
              ) : (
                <Icon
                  type={`${hasReportFile ? 'check' : 'close'}-circle`}
                  className={classes[hasReportFile ? 'present' : 'notPresent']}
                />
              )}
            </>
          )
        },
      },
      {
        title: 'Full',
        dataIndex: 'hasReportRawFile',
        key: 'hasReportRawFile',
        align: 'center',
        render(text, { hasReportRawFile }) {
          return (
            <>
              {hasReportRawFile === null ? (
                <span>-</span>
              ) : (
                <Icon
                  type={`${hasReportRawFile ? 'check' : 'close'}-circle`}
                  className={
                    classes[hasReportRawFile ? 'present' : 'notPresent']
                  }
                />
              )}
            </>
          )
        },
      },
    ]

    return [
      {
        title: 'MRN',
        dataIndex: 'patient.practiceMrn',
        key: 'patientMrn',
      },
      ...BASE_COLUMNS,
      {
        title: 'ICM Only',
        key: 'rejected',
        className: classes.rejected,
        render(_text, preAuthorization) {
          const { rejectedAt } = preAuthorization

          return rejectedAt ? <Icon type="check-circle" /> : null
        },
      },
      {
        title: 'Authorized',
        key: 'authorized',
        render: (text, record) => {
          return (
            <Switch
              checked={record.authorized}
              checkedChildren="Authorized"
              unCheckedChildren="Not&nbsp;Authorized"
              onChange={authorized =>
                handleAuthorizedChange(record.id, authorized)
              }
            />
          )
        },
      },
      ...(isAdmin ? filesColumns : []),
      {
        title: 'Actions',
        key: 'actions',
        render(text, preAuthorization) {
          return (
            <Link
              to={toPath.preAuthorizations.preAuthorization({
                id: preAuthorization.id,
              })}
            >
              <Button type="link" icon="file-search" />
            </Link>
          )
        },
      },
    ]
  }, [classes, handleAuthorizedChange, isAdmin])

  const [exportingExcel, exportExcel] = useExportExcel(
    `cycles/${cycleId}/pre-authorizations.xlsx`,
  )

  return (
    <>
      {error && <ListError description={error.message} onClose={clearError} />}
      <Table
        size="middle"
        columns={columns}
        dataSource={preAuthorizations}
        rowKey="id"
        loading={pending}
        rowSelection={rowSelection}
        pagination={{
          current: page,
          pageSize,
          onChange: onPageChange,
          total,
        }}
      />
      {Boolean(preAuthorizations.length) && (
        <div>
          <Button
            loading={exportingExcel}
            onClick={exportExcel}
            icon="file-excel"
          >
            Export this list to Excel
          </Button>
        </div>
      )}
    </>
  )
}

PreAuthorizations.propTypes = {
  cycleId: PropTypes.string.isRequired,
  onPageChange: PropTypes.func.isRequired,
  rowSelection: PropTypes.shape({}),
  page: PropTypes.number,
  filters: PropTypes.shape({ q: PropTypes.string }),
}

PreAuthorizations.defaultProps = {
  page: 1,
  filters: {},
  rowSelection: null,
}
