import { Button, Icon, Table, Tooltip } from 'antd'
import moment from 'moment-timezone'
import React, { useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { useAsync } from '../../hooks'
import useQueryParams from '../../hooks/useQueryParams'
import {
  useAlerts,
  useAlertsPagination,
  useFetchAlerts,
} from '../../redux/slices/alerts'
import { toPath } from '../../routes'
import { ListError, PageHeader } from '../Common'
import AddNewItemButton from '../Common/AddNewItemButton'
import DiseasesTags from '../Common/DiseasesTags'
import Filters, { FIELDS_OPTIONS } from '../Forms/Filters'
import { useIsAdmin, useIsPractitioner } from '../../redux/slices/session'
import { formatDOB } from '../../lib/datetime'

const useStyles = createUseStyles({
  active: {
    color: 'green',
  },
  inactive: {
    color: 'red',
  },
  criticalIcon: {
    color: 'red',
  },
  divider: {
    margin: '0 4px',
  },
  block: {
    display: 'block',
  },
})

const DEFAULT_FILTERS = {
  acknowledged: FIELDS_OPTIONS.acknowledged[0].id,
  query: '',
}
const QUERY_PARAMS_SCHEMA = { page: Number }

export default function AlertsList() {
  const classes = useStyles()
  const isAdmin = useIsAdmin()
  const isPractitioner = useIsPractitioner()
  const [conditions, setConditions] = useQueryParams({
    initialValues: DEFAULT_FILTERS,
    schema: QUERY_PARAMS_SCHEMA,
  })
  const fromPracticeParam = useMemo(
    () => (conditions.practiceId ? '?fromPractice=true' : ''),
    [conditions],
  )

  const columns = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'critical',
        key: 'critical',
        align: 'center',
        render(_text, { critical }) {
          return (
            critical && (
              <Tooltip title="Requires Attention">
                <Icon
                  type="exclamation-circle"
                  className={classes.criticalIcon}
                />
              </Tooltip>
            )
          )
        },
      },
      {
        title: 'Full Name',
        key: 'fullName',
        render(_text, alert) {
          return (
            <Link to={toPath.patients.patient({ id: alert.patientSummary.id })}>
              {alert.patientSummary.lastName}, {alert.patientSummary.firstName}
            </Link>
          )
        },
      },
      {
        title: 'DOB',
        dataIndex: 'patientSummary.birthDate',
        key: 'dob',
        render: formatDOB,
      },
      {
        title: 'Diseases',
        key: 'diseases',
        render(text, alert) {
          const { patientSummary } = alert
          return <DiseasesTags patientSummary={patientSummary} />
        },
      },
      {
        title: 'Device Type',
        dataIndex: 'device.deviceModel.deviceType.name',
        key: 'deviceType',
      },
      {
        title: 'Created At',
        key: 'createdAt',
        dataIndex: 'createdAt',
        render(text) {
          return moment(text).calendar(null, { sameElse: 'lll' })
        },
      },
      {
        align: 'center',
        title: 'Acknowledged',
        key: 'acknowledged',
        dataIndex: 'acknowledgedAt',
        render: acknowledgedAtText => (
          <Icon
            type={`${acknowledgedAtText ? 'check' : 'close'}-circle`}
            className={classes[acknowledgedAtText ? 'active' : 'inactive']}
          />
        ),
      },
      {
        title: 'Actions',
        key: 'actions',
        render(text, alert) {
          return (
            <span>
              <Link
                to={`${toPath.alerts.alert({
                  id: alert.id,
                })}${fromPracticeParam}`}
              >
                <Button type="link" icon="file-search" />
              </Link>
              <Link
                to={toPath.alerts.alert.edit({ id: alert.id })}
                className={classes.block}
              >
                <Button type="link" icon="edit" />
              </Link>
            </span>
          )
        },
      },
    ],
    [classes, fromPracticeParam],
  )
  const { pending, error, clearError } = useAsync(useFetchAlerts(conditions))

  const handlePageChange = useCallback(
    page => setConditions({ ...conditions, page }),
    [conditions, setConditions],
  )

  const handleSubmit = useCallback(
    ({ practiceId, acknowledged, medicalProviderId, deviceTypeId }) =>
      setConditions({
        page: 1,
        practiceId,
        acknowledged,
        medicalProviderId,
        deviceTypeId,
      }),
    [setConditions],
  )

  const { pageSize, total } = useAlertsPagination()
  const alerts = useAlerts()

  return (
    <div>
      {error && <ListError description={error.message} onClose={clearError} />}
      <PageHeader
        title="Alerts"
        extra={
          isAdmin && (
            <AddNewItemButton
              itemName="alert"
              path={toPath.alerts.new()}
              icon="alert"
            />
          )
        }
      />
      {(isAdmin || isPractitioner) && (
        <Filters
          currentValues={conditions}
          onSubmit={handleSubmit}
          showPractice={isAdmin}
          showAcknowledged
          showDeviceType
          showMedicalProvider
        />
      )}
      <Table
        columns={columns}
        dataSource={alerts}
        loading={pending}
        pagination={{
          current: conditions.page || 1,
          pageSize,
          onChange: handlePageChange,
          total,
        }}
        rowKey="id"
      />
    </div>
  )
}
