import { denormalize, normalize } from 'normalizr'
import { useSelector } from 'react-redux'
import api from '../../api'
import { buildResolveOnlyLastPromise } from '../../lib/promises'
import { patientsSchema } from '../normalizr'
import { getPaginationHeaders, useAsyncAction } from '../utils'
import createSlice from '../create-slice'
import { getEntities } from './entities'

const patientsInitialState = {
  patientsIds: [],
  pageSize: 0,
  total: 0,
}

const patientsSlice = createSlice({
  name: 'patients',
  initialState: patientsInitialState,
  reducers: {
    setPatients: (state, { payload: { result, pageSize, total } }) => {
      state.patientsIds = result
      state.pageSize = pageSize
      state.total = total
    },
  },
})

const { actions } = patientsSlice

const selectState = state => state[patientsSlice.name]

export function usePatients() {
  const { patientsIds } = useSelector(selectState)
  const entities = useSelector(getEntities)
  const patients = denormalize(patientsIds, [patientsSchema], entities)
  return patients
}

export function usePatientsPagination() {
  const { pageSize, total } = useSelector(selectState)
  return { pageSize, total }
}

const onlyKeepLastPatientList = buildResolveOnlyLastPromise(
  api.patients.getPatients,
)

export function fetchPatients(
  page,
  practiceId,
  approved,
  showInactive,
  q,
  deviceTypeId,
  deviceModelManufacturer,
  medicalProviderId,
  enrolled,
  paired,
  monitoring,
  useOnlyLastResponse,
  birthDate,
) {
  return async dispatch => {
    const reqFn = useOnlyLastResponse
      ? onlyKeepLastPatientList
      : api.patients.getPatients
    const { body, headers } = await reqFn({
      page,
      approved,
      practiceId,
      showInactive,
      q,
      deviceTypeId,
      monitoring: monitoring !== 'all' ? monitoring : undefined,
      deviceModelManufacturer:
        deviceModelManufacturer !== 'all' ? deviceModelManufacturer : undefined,
      medicalProviderId,
      enrolled,
      paired,
      birthDate,
    })
    dispatch(
      actions.setPatients({
        ...normalize(body, [patientsSchema]),
        ...getPaginationHeaders(headers),
      }),
    )
  }
}

export function useFetchPatients(
  {
    page,
    approved,
    practiceId,
    showInactive,
    query,
    deviceTypeId,
    deviceModelManufacturer,
    medicalProviderId,
    enrolled,
    paired,
    monitoring,
    birthDate,
  } = {},
  useOnlyLastResponse = false,
) {
  return useAsyncAction(
    fetchPatients,
    page,
    practiceId,
    approved,
    showInactive,
    query,
    deviceTypeId,
    deviceModelManufacturer,
    medicalProviderId,
    enrolled,
    paired,
    monitoring,
    useOnlyLastResponse,
    birthDate,
  )
}

export default patientsSlice
