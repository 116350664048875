import queryString from 'query-string'
import apiClient from './api-client'

function getPractices(page, q, showInactive) {
  const pageQuery = page === 'all' ? { all: true } : { page }
  return apiClient.get(
    queryString.stringifyUrl({
      url: 'practices',
      query: {
        ...pageQuery,
        // avoid sending when false (string false is also truthy)
        show_inactive: showInactive || undefined,
        q,
      },
    }),
    { withHeaders: true },
  )
}

function createPractice(practice) {
  return apiClient.post('practices', practice)
}

function updatePractice(id, practiceData) {
  return apiClient.patch(`practices/${id}`, practiceData)
}

function getPractice(id) {
  return apiClient.get(`practices/${id}`)
}

function deletePractice(id) {
  return apiClient.destroy(`practices/${id}`)
}

function getCycles(id) {
  return apiClient.get(
    queryString.stringifyUrl({
      url: `practices/${id}/cycles`,
      query: { all: true },
    }),
  )
}

function getProviders(id, headers = false) {
  return apiClient.get(
    queryString.stringifyUrl({
      url: `practices/${id}/providers`,
      query: { all: true },
    }),

    { withHeaders: headers },
  )
}

function getMedicalProviders(id, headers = false) {
  return apiClient.get(
    queryString.stringifyUrl({
      url: `practices/${id}/medical-providers`,
      query: { all: true },
    }),

    { withHeaders: headers },
  )
}

function setProvider(id, userId) {
  return apiClient.post(`practices/${id}/providers`, { userId })
}

function unsetProvider(id, userId) {
  return apiClient.destroy(`practices/${id}/providers/${userId}`)
}

export default {
  deletePractice,
  getCycles,
  getPractices,
  getPractice,
  createPractice,
  updatePractice,
  getProviders,
  setProvider,
  unsetProvider,
  getMedicalProviders,
}
