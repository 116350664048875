import React from 'react'
import Select from './Select'

export const TYPES = [
  { id: 'sms', name: 'SMS' },
  { id: 'voice', name: 'Voice' },
]

export default function ReminderTypeSelect(props) {
  return <Select {...props} options={TYPES} allowClear />
}
