import { Button, Divider, message, Result } from 'antd'
import pick from 'lodash/pick'
import React, { useCallback, useState } from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'
import api from '../../api'
import { useAsync } from '../../hooks'
import useQueryParams from '../../hooks/useQueryParams'
import { formatDateTime } from '../../lib/datetime'
import { useUnacknowledgedAlertsCount } from '../../redux/slices/alerts'
import { useIsAdmin, useCurrentUserRole } from '../../redux/slices/session'
import { toPath } from '../../routes'
import Comments from '../Comments'
import { ListError, PageHeader } from '../Common'
import ActionButtons from '../Common/ActionButtons'
import NavigationButtons from '../Common/NavigationButtons'
import PatientSummary from '../Common/PatientSummary'
import PDFPreview from '../Common/PDFPreview'
import Loading from '../Loading'
import DownloadButtons from '../Common/DownloadButtons'

export default function Show() {
  const { id } = useParams()
  const history = useHistory()
  const isAdmin = useIsAdmin()
  const isClinician = useCurrentUserRole() === 'clinician'

  const [conditions] = useQueryParams({})
  const { fromPractice } = conditions
  const fromPracticeParam = fromPractice ? `?fromPractice=${fromPractice}` : ''

  const { reset: setAlertsCount } = useUnacknowledgedAlertsCount()
  const [alert, setAlert] = useState({})

  const getAlert = useCallback(async () => {
    setAlert(await api.alerts.get(id, pick(conditions, 'fromPractice')))
  }, [conditions, id])

  const { pending: isAckPending, execute: ack } = useAsync(async () => {
    try {
      await api.alerts.acknowledge(id, pick(conditions, 'fromPractice'))
      await getAlert()

      setAlertsCount()

      message.success('Alert successfully acknowledged')
    } catch (err) {
      message.error(
        `There was a problem acknowledging this alert: ${err.message}`,
      )
    }
  }, false)

  const { pending: isUnackPending, execute: unack } = useAsync(async () => {
    try {
      await api.alerts.unacknowledge(id, pick(conditions, 'fromPractice'))
      await getAlert()

      setAlertsCount()

      message.success('Alert successfully unacknowledged')
    } catch (err) {
      message.error(
        `There was a problem unacknowledging this alert: ${err.message}`,
      )
    }
  }, false)

  const { error, pending } = useAsync(getAlert)

  const alertPath = useCallback(
    alertId =>
      alertId && `${toPath.alerts.alert({ id: alertId })}${fromPracticeParam}`,
    [fromPracticeParam],
  )

  const handleDelete = useCallback(async () => {
    try {
      await api.alerts.destroy(id)
      message.success('Alert successfully removed')
      history.goBack()
    } catch (e) {
      message.error(`There was an error removing this alert: ${e.message}`)
    }
  }, [history, id])

  if (pending) {
    return <Loading large />
  }

  if (error) {
    return (
      <ListError
        message="There was an error loading this alert"
        description={error.message}
      />
    )
  }

  const { patient, nextAlertId, previousAlertId } = alert

  return (
    <div>
      <NavigationButtons
        nextPath={alertPath(nextAlertId)}
        prevPath={alertPath(previousAlertId)}
      />
      <PageHeader
        title={`${patient.firstName} ${patient.lastName}`}
        subTitle={
          alert.acknowledgedAt &&
          `Acknowledged ${formatDateTime(alert.acknowledgedAt)}`
        }
        onBack={history.goBack}
        extra={
          <>
            {alert.acknowledgedAt && (
              <Button loading={isUnackPending} onClick={unack}>
                Unacknowledge
              </Button>
            )}
            {!isClinician && (
              <Link
                key="edit-patient"
                to={toPath.patients.patient.edit({ id: patient.id })}
              >
                <Button>Edit Patient</Button>
              </Link>
            )}
            <Link key="edit-alert" to={toPath.alerts.alert.edit({ id })}>
              <Button>Edit Alert</Button>
            </Link>
            {!isClinician && <DownloadButtons resource={alert} />}
          </>
        }
      />
      <PatientSummary patient={patient} />
      <Divider />
      {alert.acknowledgedAt || isAdmin ? (
        <>
          {alert.filePath && <PDFPreview path={alert.filePath} />}
          <ActionButtons onDelete={handleDelete} />
          <Comments collectionName="alerts" id={alert.id} />
        </>
      ) : (
        <Result
          title="You need to acknowledge this alert before you can see its contents"
          extra={
            <Button type="primary" loading={isAckPending} onClick={ack}>
              Acknowledge
            </Button>
          }
        />
      )}
    </div>
  )
}
