import { Form } from 'antd'
import { Formik } from 'formik'
import mapValues from 'lodash/mapValues'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { formElementPropsGenerator } from '../../lib/forms'
import { Yup } from '../../lib/validations'
import { Input, SubmitButton } from '../Forms'
import CODES from './codes'

const DEVICE_TYPES_LABELS = CODES.reduce(
  (labels, { name, attribute }) => ({
    ...labels,
    [attribute]: name,
  }),
  {},
)

const INITIAL_VALUES = mapValues(DEVICE_TYPES_LABELS, () => undefined)

const propsFor = formElementPropsGenerator({
  formName: 'device-types-form',
  labels: DEVICE_TYPES_LABELS,
})

const labelCol = {
  md: { span: 4, offset: 2 },
}

const wrapperCol = {
  md: { span: 16 },
}

const actionsWrapperCol = {
  md: { span: 4, offset: labelCol.md.span + labelCol.md.offset },
}

function buildValidationSchema(cycleLength) {
  const schema = {}

  if (cycleLength.includes('30')) {
    schema.cptCodes30 = Yup.string()
      .required()
      .nullable()
    schema.icd10Code = Yup.string()
      .required()
      .nullable()
  }

  if (cycleLength.includes('90')) {
    schema.cptCodes90Order2 = Yup.string()
      .required()
      .nullable()
    schema.icd10Code90Order2 = Yup.string()
      .required()
      .nullable()
  }

  if (cycleLength.includes('30_and_90')) {
    schema.cptCodes90Order1 = Yup.string()
      .required()
      .nullable()
  }

  return Yup.object().shape(schema)
}

export default function DeviceTypesForm({ deviceType, onSubmit }) {
  const validationSchema = useMemo(
    () => buildValidationSchema(deviceType.cycleLength),
    [deviceType.cycleLength],
  )
  return (
    <div>
      <Formik
        initialValues={deviceType || INITIAL_VALUES}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, isValid, submitCount }) => (
          <Form
            onSubmit={handleSubmit}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
          >
            {CODES.map(({ attribute }) => (
              <Input key={attribute} {...propsFor(attribute)} />
            ))}
            <SubmitButton
              isSubmitting={isSubmitting}
              isValid={isValid}
              title="Save CPT Codes"
              submitCount={submitCount}
              wrapperCol={actionsWrapperCol}
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}

DeviceTypesForm.propTypes = {
  deviceType: PropTypes.shape({
    ...CODES.reduce(({ attribute }) => ({ [attribute]: PropTypes.string })),
    id: PropTypes.string,
    cycleLength: PropTypes.string.isRequired,
  }),
  onSubmit: PropTypes.func.isRequired,
}

DeviceTypesForm.defaultProps = {
  deviceType: undefined,
}
