import queryString from 'query-string'
import apiClient from './api-client'

function list(page, from, to, reminderStatus, reminderType) {
  return apiClient.get(
    queryString.stringifyUrl({
      url: 'reminders',
      query: {
        page,
        from,
        to,
        status: reminderStatus,
        message_type: reminderType,
      },
    }),
    { withHeaders: true },
  )
}

export default {
  list,
}
