import { denormalize, normalize } from 'normalizr'
import { useSelector } from 'react-redux'
import api from '../../api'
import { reportsSchema } from '../normalizr'
import { getPaginationHeaders, useAsyncAction } from '../utils'
import createSlice from '../create-slice'
import { getEntities } from './entities'

const unreviewedReportsInitialState = {
  reports: [],
  pageSize: 0,
  total: 0,
}

const unreviewedReportsSlice = createSlice({
  name: 'unreviewedReports',
  initialState: unreviewedReportsInitialState,
  reducers: {
    setReports: (state, { payload: { result, page, pageSize, total } }) => {
      state.reports = result
      state.currentPage = page
      state.pageSize = pageSize
      state.total = total
    },
  },
})

const { actions } = unreviewedReportsSlice

const selectState = state => {
  return state[unreviewedReportsSlice.name]
}

export function useUnreviewedReports() {
  const result = useSelector(selectState)
  const { reports } = result
  const entities = useSelector(getEntities)
  return denormalize(reports, [reportsSchema], entities)
}

export function useUnreviewedReportsPagination() {
  const { currentPage, pageSize, total } = useSelector(selectState)
  return { currentPage, pageSize, total }
}

export function fetchUnreviewedReports(page, practiceId, q) {
  return async dispatch => {
    const { body, headers } = await api.reports.getUnreviewed({
      page,
      practiceId,
      q,
    })
    dispatch(
      actions.setReports({
        ...normalize(body, [reportsSchema]),
        ...getPaginationHeaders(headers),
      }),
    )
  }
}

export function useFetchUnreviewedReports({ page, practiceId, query } = {}) {
  return useAsyncAction(fetchUnreviewedReports, page, practiceId, query)
}

export function updateReportPriority(reportId, priority) {
  const type = 'UPDATE_REPORT_PRIORITY' // Just to update entities slice
  return async dispatch => {
    const body = await api.reports.updatePriority(reportId, priority)
    return dispatch({
      type,
      payload: normalize(body, reportsSchema),
    })
  }
}

export function unassignReport(reportId) {
  const type = 'UNASSIGN_REPORT' // Just to update entities slice
  return async dispatch => {
    const body = await api.reports.unassign(reportId)
    return dispatch({
      type,
      payload: normalize(body, reportsSchema),
    })
  }
}

export default unreviewedReportsSlice
