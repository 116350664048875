import { Button } from 'antd'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { toPath } from '../routes'
import SessionLayout from './Session/SessionLayout'

const useStyles = createUseStyles({
  centeredContent: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0',
  },
  titleCenter: {
    textAlign: 'center',
    color: '#2F2F2F',
  },
})

export default function LoggedOut() {
  const history = useHistory()
  const classes = useStyles()

  return (
    <SessionLayout>
      <h2 className={classes.titleCenter}>You are now logged out</h2>

      <h4 className={classes.titleCenter}>
        Click the button below to log in again
      </h4>

      <div className={classes.centeredContent}>
        <Button type="primary" onClick={() => history.push(toPath.login())}>
          Log in
        </Button>
      </div>
    </SessionLayout>
  )
}
