import { denormalize, normalize } from 'normalizr'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import api from '../../api'
import { officeAuthorizationsSchema } from '../normalizr'
import { getPaginationHeaders, useAsyncAction } from '../utils'
import createSlice from '../create-slice'
import { getEntities } from './entities'

const officeAuthorizationsInitialState = {
  officeAuths: {},
}

const officeAuthorizationsSlice = createSlice({
  name: 'inOfficeAuthorizations',
  initialState: officeAuthorizationsInitialState,
  reducers: {
    setAuths: (state, { payload: { checkDate, result, pageSize, total } }) => {
      state.officeAuths[checkDate] = state.officeAuths[checkDate] || {}
      state.officeAuths[checkDate].ids = result
      state.officeAuths[checkDate].pageSize = pageSize
      state.officeAuths[checkDate].total = total
    },
  },
})

const { actions } = officeAuthorizationsSlice

const selectState = state => state[officeAuthorizationsSlice.name]

export function useOfficeAuthorizations(checkDate) {
  const { officeAuths } = useSelector(selectState)
  const entities = useSelector(getEntities)
  return denormalize(
    get(officeAuths, [checkDate, 'ids'], []),
    [officeAuthorizationsSchema],
    entities,
  )
}

export function useOfficeAuthorizationsPagination(checkDate) {
  const { officeAuths } = useSelector(selectState)
  const { pageSize, total } = get(officeAuths, [checkDate], {})
  return { pageSize, total }
}

function fetchOfficeAuthorizations(practiceId, page, filters) {
  return async dispatch => {
    const { body, headers } = await api.inOfficeAuthorizations.list(
      practiceId,
      page,
      filters,
    )
    dispatch(
      actions.setAuths({
        checkDate: filters.checkDate,
        ...normalize(body, [officeAuthorizationsSchema]),
        ...getPaginationHeaders(headers),
      }),
    )
  }
}

export function useFetchOfficeAuthorizations(practiceId, page, filters) {
  return useAsyncAction(fetchOfficeAuthorizations, practiceId, page, filters)
}

export function updateOfficeAuthorization(id, payload) {
  const type = 'UPDATE_OFFICE_PRE_AUTHORIZATION'
  return async dispatch => {
    const body = await api.inOfficeAuthorizations.update(id, payload)
    return dispatch({
      type,
      payload: normalize(body, officeAuthorizationsSchema),
    })
  }
}

export default officeAuthorizationsSlice
