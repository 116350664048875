import { useSelector } from 'react-redux'
import { createSlice } from '@reduxjs/toolkit'

const preAuthorizationDownloadsInitialState = {
  open: false,
  cycles: {},
}

const preAuthorizationDownloadsSlice = createSlice({
  name: 'preAuthorizationDownloads',
  initialState: preAuthorizationDownloadsInitialState,
  reducers: {
    start: (state, { payload: { cycleId } }) => {
      state.open = true
      state.cycles[cycleId] = state.cycles[cycleId] || {}
      state.cycles[cycleId].progress = 0
    },
    addProgress: (state, { payload: { cycleId, newProgress } }) => {
      state.cycles[cycleId].progress = newProgress
    },
    finish: (state, { payload: { cycleId } }) => {
      delete state.cycles[cycleId]
      if (Object.keys(state.cycles).length === 0) {
        state.open = false
      }
    },
  },
})

const { actions } = preAuthorizationDownloadsSlice

const selectDownloadState = state => state[preAuthorizationDownloadsSlice.name]

export function useDownloads() {
  const { cycles } = useSelector(selectDownloadState)
  return cycles
}

export function useOpenDownloadsUi() {
  const { open } = useSelector(selectDownloadState)
  return open
}

export function initDownload(cycleId) {
  return dispatch => {
    dispatch(actions.start({ cycleId }))
  }
}

export function addProgress(cycleId, newProgress) {
  return dispatch => {
    dispatch(actions.addProgress({ cycleId, newProgress }))
  }
}

export function finish(cycleId) {
  return dispatch => {
    dispatch(actions.finish({ cycleId }))
  }
}

export default preAuthorizationDownloadsSlice
