import apiClient from './api-client'
import alerts from './alerts'
import billingOrders from './billing-orders'
import comments from './comments'
import cycles from './cycles'
import dashboard from './dashboard'
import deviceModels from './device-models'
import deviceTypes from './device-types'
import downloads from './downloads'
import insuranceAuthorization from './insurance-authorization'
import insuranceProviders from './insurance-providers'
import missedTransmissions from './missed-transmissions'
import passwordReset from './password-reset'
import patients from './patients'
import practices from './practices'
import preAuthorizations from './pre-authorizations'
import inOfficeAuthorizations from './in-office-authorizations'
import reports from './reports'
import roles from './roles'
import users from './users'
import teams from './teams'
import icdCodes from './icd-codes'
import reminders from './reminders'
import letterPhoneNumbers from './letter-phone-numbers'
import diseases from './diseases'
import officeChecks from './office-checks'
import inOfficeBillingOrders from './in-office-billing-orders'

export default {
  apiClient,
  alerts,
  billingOrders,
  comments,
  cycles,
  dashboard,
  deviceModels,
  deviceTypes,
  downloads,
  insuranceAuthorization,
  insuranceProviders,
  missedTransmissions,
  officeChecks,
  passwordReset,
  patients,
  practices,
  preAuthorizations,
  reports,
  roles,
  users,
  teams,
  icdCodes,
  reminders,
  letterPhoneNumbers,
  diseases,
  inOfficeAuthorizations,
  inOfficeBillingOrders,
}
