import { Typography } from 'antd'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useHistory, Redirect } from 'react-router-dom'
import Filters from '../Forms/Filters'
import {
  useCurrentUserPracticeId,
  useIsAdmin,
} from '../../redux/slices/session'

const { Title } = Typography

export default function PracticeSelectView({ routeMethod }) {
  const history = useHistory()
  const navigate = useCallback(
    ({ practiceId }) => {
      if (practiceId) {
        history.push(routeMethod({ practiceId }))
      }
    },
    [routeMethod, history],
  )
  const isAdmin = useIsAdmin()
  const currentPracticeId = useCurrentUserPracticeId()

  if (isAdmin) {
    return (
      <div>
        <Title level={4}>Please select a practice:</Title>
        <Filters showInactiveCheck showPractice onSubmit={navigate} />
      </div>
    )
  }

  return <Redirect to={routeMethod({ practiceId: currentPracticeId })} />
}

PracticeSelectView.propTypes = {
  routeMethod: PropTypes.func.isRequired,
}
