import { denormalize, normalize } from 'normalizr'
import { useSelector } from 'react-redux'
import api from '../../api'
import { buildResolveOnlyLastPromise } from '../../lib/promises'
import { remindersSchema } from '../normalizr'
import { getPaginationHeaders, useAsyncAction } from '../utils'
import createSlice from '../create-slice'
import { getEntities } from './entities'

const remindersInitialState = {
  remindersIds: [],
  pageSize: 0,
  total: 0,
}

const remindersSlice = createSlice({
  name: 'reminders',
  initialState: remindersInitialState,
  reducers: {
    setReminders: (state, { payload: { result, pageSize, total } }) => {
      state.remindersIds = result
      state.pageSize = pageSize
      state.total = total
    },
  },
})

const { actions } = remindersSlice

const selectState = state => state[remindersSlice.name]

export function useReminders() {
  const { remindersIds } = useSelector(selectState)
  const entities = useSelector(getEntities)
  const reminders = denormalize(remindersIds, [remindersSchema], entities)
  return reminders
}

export function useRemindersPagination() {
  const { pageSize, total } = useSelector(selectState)
  return { pageSize, total }
}

const onlyKeepLastReminderList = buildResolveOnlyLastPromise(api.reminders.list)

export function fetchReminders(
  page,
  from,
  to,
  reminderStatus,
  reminderType,
  useOnlyLastResponse,
) {
  return async dispatch => {
    const reqFn = useOnlyLastResponse
      ? onlyKeepLastReminderList
      : api.reminders.list
    const { body, headers } = await reqFn(
      page,
      from,
      to,
      reminderStatus,
      reminderType,
    )
    dispatch(
      actions.setReminders({
        ...normalize(body, [remindersSchema]),
        ...getPaginationHeaders(headers),
      }),
    )
  }
}

export function useFetchReminders(
  { page, from, to, reminderStatus, reminderType },
  useOnlyLastResponse = false,
) {
  return useAsyncAction(
    fetchReminders,
    page,
    from,
    to,
    reminderStatus,
    reminderType,
    useOnlyLastResponse,
  )
}

export default remindersSlice
