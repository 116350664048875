import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import api from '../../api'
import createSlice from '../create-slice'

export const profileInitialState = {
  firstName: '',
  lastName: '',
  email: '',
}

const profileSlice = createSlice({
  name: 'profile',
  initialState: profileInitialState,
  reducers: {
    setProfile: (
      state,
      {
        payload: {
          id,
          firstName,
          lastName,
          email,
          practice,
          position,
          positionLabel,
          role,
        },
      },
    ) => {
      state.firstName = firstName
      state.lastName = lastName || ''
      state.email = email
      state.practice = practice
      state.position = position
      state.positionLabel = positionLabel
      state.role = role.name
    },
  },
})

const { actions } = profileSlice

const selectState = state => state[profileSlice.name]

export function useProfile() {
  const profile = useSelector(selectState)
  return profile
}

function fetchProfile() {
  return async dispatch => {
    const body = await api.users.me()
    dispatch(actions.setProfile(body))
  }
}

export function useFetchProfile() {
  const dispatch = useDispatch()
  const callback = useCallback(() => dispatch(fetchProfile()), [dispatch])
  return callback
}

export default profileSlice
