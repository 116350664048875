import React from 'react'
import PropTypes from 'prop-types'
import { List, Button } from 'antd'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles({
  center: {
    textAlign: 'center',
  },
})

export default function AlertsRecipients({ onManage, providers }) {
  const classes = useStyles()

  return (
    <List
      bordered
      dataSource={providers}
      footer={
        <div className={classes.center}>
          <Button type="link" onClick={onManage}>
            Manage
          </Button>
        </div>
      }
      renderItem={item => (
        <List.Item>
          {item.firstName} {item.lastName} &lt;{item.email}&gt;
        </List.Item>
      )}
    />
  )
}

AlertsRecipients.defaultProps = {
  providers: [],
}

AlertsRecipients.propTypes = {
  providers: PropTypes.arrayOf({}),
  onManage: PropTypes.func.isRequired,
}
