import { Button, Modal, message, Tag } from 'antd'
import moment from 'moment'
import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { processCycle, useCycle } from '../../redux/slices/practices'
import Loading from '../Loading'

export default function GenerateBillingOrdersButton({ cycleId }) {
  const cycle = useCycle(cycleId)
  const hasBeenCalled = !!cycle.preAuthProcessedAt
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const handleGenerate = useCallback(async () => {
    try {
      setLoading(true)
      await dispatch(processCycle(cycleId))
      message.success('Missed transmissions have been generated')
    } catch (e) {
      message.error(
        `There was an error generating missed transmissions: ${e.message}`,
      )
    }
    setLoading(false)
  }, [cycleId, dispatch])
  return (
    <>
      <Button
        onClick={handleGenerate}
        type={hasBeenCalled ? 'default' : 'primary'}
      >
        Generate missed transmissions
      </Button>
      {hasBeenCalled && (
        <span>
          Last time called:&nbsp;
          <Tag color="cyan">{moment(cycle.preAuthProcessedAt).fromNow()}</Tag>
        </span>
      )}
      <Modal
        maskClosable={false}
        footer={null}
        closable={false}
        title="Generating missing transmissions..."
        visible={loading}
      >
        <Loading large />
      </Modal>
    </>
  )
}

GenerateBillingOrdersButton.propTypes = {
  cycleId: PropTypes.string.isRequired,
}
