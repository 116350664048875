import React from 'react'
import moment from 'moment'
import Select from './Select'

export default function MonthSelect(props) {
  const nextYear = moment().add(1, 'year')
  const prevYear = moment().subtract(1, 'year')

  const generateMonthYearOptions = (start, end) => {
    const monthsYears = []
    const current = moment(start).startOf('month')

    while (current.isBefore(end)) {
      const date = current.format('YYYY-MM')
      monthsYears.push({ id: date, name: moment(date).format('MMMM, YYYY') })
      current.add(1, 'month')
    }

    return monthsYears
  }
  const options = generateMonthYearOptions(prevYear, nextYear)

  return <Select options={options} {...props} />
}
