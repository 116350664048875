import moment from 'moment-timezone'

// eslint-disable-next-line import/prefer-default-export
export function isCurrentCycle(cycle) {
  const current = moment()
  return (
    current.isSameOrAfter(cycle.startsAt, 'day') &&
    current.isSameOrBefore(cycle.endsAt, 'day')
  )
}
