import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import SplitPane from 'react-split-pane'

import useQueryParams from '../../hooks/useQueryParams'
import { ListError } from '../Common'
import PDFPreview from '../Common/PDFPreview'
import api from '../../api'
import { useAsync } from '../../hooks'
import Loading from '../Loading'
import { toPath } from '../../routes'
import ReportPatientHeader from './ReportPatientHeader'
import Form from './Form'

const useStyles = createUseStyles({
  app: { display: 'flex', alignItems: 'stretch', flex: '1 1 auto' },
  pdfBox: {
    maxWidth: 'calc(100% - 456px)',
    flexGrow: '1',
    minWidth: '100%',
    position: 'relative',
    height: 'calc(100vh - 96px - 68px)',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '& canvas': {
      maxWidth: '100%',
    },
  },
  formBox: {
    position: 'relative',
    overflowY: 'scroll',
    height: 'calc(100vh - 96px - 68px)',
    padding: '0 16px',
    '& > form': {
      marginTop: '-16px',
    },
  },
})

export default function EditReport() {
  const { id } = useParams()
  const getReport = useCallback(() => api.reports.getReportForm(id), [id])
  const { error, pending: loading, value: reportForm } = useAsync(getReport)
  const classes = useStyles()
  const [query, , getPathWithQuery] = useQueryParams({})

  if (loading) {
    return <Loading large />
  }

  if (error) {
    return (
      <ListError
        message="There was an error loading this report"
        description={error.message}
      />
    )
  }

  return (
    <div className={classes.app}>
      <SplitPane split="vertical">
        <div initialSize="65%" minSize="10%" className={classes.pdfBox}>
          <ReportPatientHeader
            report={reportForm}
            backPath={getPathWithQuery(
              toPath.reports.report({ id: reportForm.id }),
              query,
            )}
          />
          {reportForm.rawFilePath && (
            <PDFPreview path={reportForm.rawFilePath} perPage={10} />
          )}
        </div>
        <div className={classes.formBox}>
          <Form report={reportForm} />
        </div>
      </SplitPane>
    </div>
  )
}
