import { useSelector } from 'react-redux'
import { createSlice } from '@reduxjs/toolkit'

const officeCheckDownloadsInitialState = {
  open: false,
  practices: {},
}

const officeCheckDownloadsSlice = createSlice({
  name: 'officeCheckDownloads',
  initialState: officeCheckDownloadsInitialState,
  reducers: {
    start: (state, { payload: { practiceId, checkDate } }) => {
      state.open = true
      state.practices[practiceId] = state.practices[practiceId] || {}
      state.practices[practiceId][checkDate] =
        state.practices[practiceId][checkDate] || {}
      state.practices[practiceId][checkDate].progress = 0
    },
    addProgress: (
      state,
      { payload: { practiceId, checkDate, newProgress } },
    ) => {
      state.practices[practiceId][checkDate].progress = newProgress
    },
    finish: (state, { payload: { practiceId, checkDate } }) => {
      delete state.practices[practiceId][checkDate]

      if (Object.keys(state.practices[practiceId]).length === 0) {
        delete state.practices[practiceId]
        state.open = false
      }
    },
  },
})

const { actions } = officeCheckDownloadsSlice
const selectDownloadState = state => state[officeCheckDownloadsSlice.name]

export function useOfficeCheckDownloads() {
  const { practices } = useSelector(selectDownloadState)
  return practices
}

export function initDownload(practiceId, checkDate) {
  return dispatch => {
    dispatch(actions.start({ practiceId, checkDate }))
  }
}

export function addProgress(practiceId, checkDate, newProgress) {
  return dispatch => {
    dispatch(actions.addProgress({ practiceId, checkDate, newProgress }))
  }
}

export function finish(practiceId, checkDate) {
  return dispatch => {
    dispatch(actions.finish({ practiceId, checkDate }))
  }
}

export default officeCheckDownloadsSlice
