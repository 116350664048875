import { Descriptions } from 'antd'
import React from 'react'
import { formatPhoneNumber } from 'react-phone-number-input'
import { formatDOB } from '../../lib/datetime'
import { patientShape } from '../prop-types'

export default function PatientSummary({ patient }) {
  return (
    <Descriptions column={3} size="small">
      <Descriptions.Item label="First Name">
        {patient.firstName}
      </Descriptions.Item>
      <Descriptions.Item label="Last Name">
        {patient.lastName}
      </Descriptions.Item>
      <Descriptions.Item label="Date of birth">
        {formatDOB(patient.birthDate)}
      </Descriptions.Item>
      <Descriptions.Item label="E-mail">{patient.email}</Descriptions.Item>
      <Descriptions.Item label="Mobile phone">
        {formatPhoneNumber(patient.mobilePhone)}
      </Descriptions.Item>
      <Descriptions.Item label="Home phone">
        {formatPhoneNumber(patient.homePhone)}
      </Descriptions.Item>
      <Descriptions.Item label="Atrial Fibrillation / Flutter Disease">
        {patient.atrialFibrilationFlutterDisease ? 'Yes' : 'No'}
      </Descriptions.Item>
      <Descriptions.Item label="Anticoagulated Disease">
        {patient.anticoagulatedDisease ? 'Yes' : 'No'}
      </Descriptions.Item>
    </Descriptions>
  )
}

PatientSummary.propTypes = {
  patient: patientShape.isRequired,
}
