import PropTypes from 'prop-types'
import React, { useContext, useEffect } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import { SetRouteContext } from './RouterContext'

function MatchUpdater() {
  const match = useRouteMatch()
  const setMatch = useContext(SetRouteContext)
  useEffect(() => setMatch(match), [match, setMatch])
  return null
}

export default function MainRoute({ children, ...props }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Route {...props}>
      <MatchUpdater />
      {children}
    </Route>
  )
}

MainRoute.propTypes = {
  children: PropTypes.node,
}

MainRoute.defaultProps = {
  children: undefined,
}
