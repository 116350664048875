import queryString from 'query-string'
import humps from 'humps'
import apiClient from './api-client'

function list({
  page,
  practiceId,
  acknowledged,
  medicalProviderId,
  deviceTypeId,
}) {
  return apiClient.get(
    queryString.stringifyUrl({
      url: 'alerts',
      query: humps.decamelizeKeys({
        page,
        practiceId,
        acknowledged,
        medicalProviderId,
        deviceTypeId,
      }),
    }),
    { withHeaders: true },
  )
}

function create(alertFormData) {
  return apiClient.post('alerts', alertFormData)
}

function get(id, { fromPractice, includeForm } = {}) {
  return apiClient.get(
    queryString.stringifyUrl({
      url: `alerts/${id}`,
      query: {
        from_practice: fromPractice,
        include_form: includeForm,
      },
    }),
  )
}

function destroy(id) {
  return apiClient.destroy(`alerts/${id}`)
}

function update(id, data) {
  return apiClient.patch(`alerts/${id}`, data)
}

function acknowledge(id, { fromPractice } = {}) {
  return apiClient.put(
    queryString.stringifyUrl({
      url: `alerts/${id}/acknowledge`,
      query: { acknowledge: true, from_practice: fromPractice },
    }),
  )
}

function unacknowledge(id, { fromPractice } = {}) {
  return apiClient.put(
    queryString.stringifyUrl({
      url: `alerts/${id}/acknowledge`,
      query: { acknowledge: false, from_practice: fromPractice },
    }),
  )
}

function getUnacknowledgedCount() {
  return apiClient.get('alerts/unacknowledged/count')
}

function getForm(deviceId) {
  return apiClient.get(`/devices/${deviceId}/alerts/form`)
}

export default {
  acknowledge,
  unacknowledge,
  create,
  destroy,
  get,
  update,
  getForm,
  getUnacknowledgedCount,
  list,
}
