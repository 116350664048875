import { Tag } from 'antd'
import React from 'react'
import { patientSummaryShape } from '../prop-types'

export default function DiseasesTags({ patientSummary }) {
  return (
    <>
      {patientSummary.atrialFibrilationFlutterDisease && (
        <div>
          <Tag color="orange">Atrial Fib./Flutter</Tag>
        </div>
      )}
      {patientSummary.anticoagulatedDisease && (
        <div>
          <Tag color="magenta">Anticoagulated</Tag>
        </div>
      )}
    </>
  )
}

DiseasesTags.propTypes = {
  patientSummary: patientSummaryShape.isRequired,
}
