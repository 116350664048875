// eslint-disable-next-line import/prefer-default-export
export const dropZone = {
  dropZone: {
    marginBottom: 30,
    minHeight: 200,
    background: '#eaeaea',
    border: '1px dashed #888',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#888',
    transition: '0.5s ease-in-out',
    '& > *': {
      display: 'block',
    },
    '& i': {
      fontSize: 60,
    },
  },
  activeDropZone: {
    background: '#ffff9757',
    color: '#441',
    '& i': {
      color: '#441',
    },
  },
}
