import React, { useCallback } from 'react'
import mapValues from 'lodash/mapValues'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import { toPath } from '../../../routes'
import { PageHeader } from '../../Common'
import api from '../../../api'
import Form from './form'

export default function NewPhoneNumber() {
  const history = useHistory()
  const goToList = useCallback(
    () => history.push(toPath.welcomeLetters.phoneNumbers()),
    [history],
  )

  const onSubmit = useCallback(
    async (letterPhoneNumberData, { setSubmitting, setErrors }) => {
      try {
        await api.letterPhoneNumbers.createLetterPhoneNumber(
          letterPhoneNumberData,
        )

        message.success('Phone number successfully created')
        goToList()
      } catch (e) {
        if (e.response && e.response.errors) {
          setErrors(mapValues(e.response.errors, error => error[0]))
        } else {
          message.error('Oops, we had a problem. Please try submitting again')
        }
      }
    },
    [goToList],
  )

  return (
    <div>
      <PageHeader title="New Phone Number" onBack={history.goBack} />
      <Form onSubmit={onSubmit} />
    </div>
  )
}
