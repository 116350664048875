import { Button, Descriptions, Divider, message, Popconfirm } from 'antd'
import React, { useCallback } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import api from '../../api'
import { useAsync } from '../../hooks'
import { PageHeader, ListError } from '../Common'
import Loading from '../Loading'
import Comments from '../Comments'
import { toPath } from '../../routes'
import { useIsAdmin } from '../../redux/slices/session'
import { formatDOB } from '../../lib/datetime'

const useStyles = createUseStyles({
  actions: {
    marginTop: 30,
    textAlign: 'center',
  },
})

export default function Details() {
  const classes = useStyles()
  const history = useHistory()
  const { id } = useParams()
  const isAdmin = useIsAdmin()
  const getMissedTransmission = useCallback(
    () => api.missedTransmissions.get(id),
    [id],
  )
  const { pending, value: missedTransmission, error, clearError } = useAsync(
    getMissedTransmission,
  )
  const handleDelete = useCallback(async () => {
    try {
      await api.missedTransmissions.destroy(id)
      message.success('Missed Transmission successfully removed')
      history.goBack()
    } catch (e) {
      message.error(
        `There was an error removing this missed transmission: ${e.message}`,
      )
    }
  }, [history, id])

  if (pending) return <Loading large />
  if (error) {
    return <ListError description={error.message} onClose={clearError} />
  }
  const { device, patient } = missedTransmission

  return (
    <div>
      <PageHeader
        title="Missed Transmission"
        subTitle={`${patient.firstName} ${patient.lastName}`}
        onBack={history.goBack}
      />
      <Divider orientation="left">Personal Information</Divider>
      <Descriptions column={2} size="small">
        <Descriptions.Item label="First Name">
          {patient.firstName}
        </Descriptions.Item>
        <Descriptions.Item label="Last Name">
          {patient.lastName}
        </Descriptions.Item>
        <Descriptions.Item label="Date of birth" span={2}>
          {formatDOB(patient.birthDate)}
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">Cardiac Device</Divider>
      <Descriptions column={2} size="small">
        <Descriptions.Item label="Company">
          {device.deviceModel.manufacturer}
        </Descriptions.Item>
        <Descriptions.Item label="Type">
          {device.deviceModel.deviceType.name}
        </Descriptions.Item>
        <Descriptions.Item label="Serial Number">
          {device.serialNumber}
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">More</Divider>
      <Descriptions column={2} size="small">
        <Descriptions.Item label="Reason">
          {missedTransmission.comment}
        </Descriptions.Item>
      </Descriptions>
      {/* TODO: use ActionButtons */}
      {isAdmin && (
        <div className={classes.actions}>
          <Link
            to={toPath.missedTransmissions.missedTransmission.edit({ id })}
            className="edit-button"
          >
            <Button icon="edit" type="primary" ghost>
              Edit
            </Button>
          </Link>
          <Divider type="vertical" />
          <Popconfirm
            title="Are you sure？"
            okText="Yes"
            cancelText="No"
            onConfirm={handleDelete}
          >
            <Button
              type="danger"
              ghost
              icon="close-circle"
              className="hard-delete-button"
            >
              Delete
            </Button>
          </Popconfirm>
        </div>
      )}
      <Comments
        collectionName="missed-transmissions"
        id={missedTransmission.id}
      />
    </div>
  )
}
