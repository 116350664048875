import { denormalize, normalize } from 'normalizr'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import api from '../../api'
import { officeChecksSchema } from '../normalizr'
import { getPaginationHeaders, useAsyncAction } from '../utils'
import createSlice from '../create-slice'
import { getEntities } from './entities'

const officeChecksInitialState = {
  officeChecks: {},
  practices: {},
}

const officeChecksSlice = createSlice({
  name: 'officeChecks',
  initialState: officeChecksInitialState,
  reducers: {
    setOfficeChecks: (
      state,
      { payload: { checkDate, result, pageSize, total } },
    ) => {
      state.officeChecks[checkDate] = state.officeChecks[checkDate] || {}
      state.officeChecks[checkDate].ids = result
      state.officeChecks[checkDate].pageSize = pageSize
      state.officeChecks[checkDate].total = total
    },
    download: (state, { payload: { practiceId, checkDate } }) => {
      state.open = true
      state.practices[practiceId] = state.practices[practiceId] || {}
      state.practices[practiceId][checkDate] =
        state.practices[practiceId][checkDate] || {}
      state.practices[practiceId][checkDate].progress = 0
    },
  },
})

const { actions } = officeChecksSlice

const selectState = state => state[officeChecksSlice.name]

export function useOfficeChecks(checkDate) {
  const { officeChecks } = useSelector(selectState)
  const entities = useSelector(getEntities)
  return denormalize(
    get(officeChecks, [checkDate, 'ids'], []),
    [officeChecksSchema],
    entities,
  )
}

export function useOfficeChecksPagination(checkDate) {
  const { officeChecks } = useSelector(selectState)
  const { pageSize, total } = get(officeChecks, [checkDate], {})
  return { pageSize, total }
}

function fetchOfficeChecks(practiceId, checkDate, page, filters) {
  return async dispatch => {
    const { body, headers } = await api.officeChecks.byPracticeId({
      practiceId,
      checkDate,
      page,
      filters,
    })
    dispatch(
      actions.setOfficeChecks({
        checkDate,
        ...normalize(body, [officeChecksSchema]),
        ...getPaginationHeaders(headers),
      }),
    )
  }
}

export function useFetchOfficeChecks(practiceId, checkDate, page, filters) {
  return useAsyncAction(fetchOfficeChecks, practiceId, checkDate, page, filters)
}

export function initDownload(practiceId, checkDate) {
  return dispatch => {
    dispatch(actions.download({ practiceId, checkDate }))
  }
}

export default officeChecksSlice
