import React from 'react'
import PropTypes from 'prop-types'
import { Form, Alert } from 'antd'
import { Formik } from 'formik'
import { createUseStyles } from 'react-jss'
import { formElementPropsGenerator } from '../../lib/forms'
import { Yup } from '../../lib/validations'
import { SubmitButton } from '../Forms'
import RadioButton from '../Forms/RadioButton'

const useStyles = createUseStyles({
  mt6: {
    marginTop: '16px',
  },
})

const VALIDATION_SCHEMA = Yup.object().shape({
  question: Yup.string().required('You need to answer this question'),
})

const options = [
  { label: 'Yes', value: 'yes' },
  { label: 'No', value: 'no' },
]

export default function QuestionForm({ additionalQuestion, handleQuestion }) {
  const classes = useStyles()
  const propsFor = formElementPropsGenerator({
    formName: 'report-edit-form',
    labels: { question: additionalQuestion },
  })

  return (
    <Formik
      onSubmit={handleQuestion}
      initialValues={{
        question: undefined,
      }}
      validationSchema={VALIDATION_SCHEMA}
    >
      {({ handleSubmit, isSubmitting, isValid, submitCount }) => (
        <div>
          <Alert
            message="Before completing the form, please answer this question"
            type="warning"
            showIcon
          />
          <Form
            onSubmit={handleSubmit}
            layout="vertical"
            className={classes.mt6}
          >
            <RadioButton options={options} {...propsFor('question')} />

            <SubmitButton
              isSubmitting={isSubmitting}
              isValid={isValid}
              submitCount={submitCount}
              title="Continue"
            />
          </Form>
        </div>
      )}
    </Formik>
  )
}

QuestionForm.propTypes = {
  additionalQuestion: PropTypes.string.isRequired,
  handleQuestion: PropTypes.func.isRequired,
}
