import React from 'react'
import { useFetchPractices, usePractices } from '../../redux/slices/practices'
import AsyncSelect from './AsyncSelect'

export default function PracticeSelect(props) {
  const fetchOptions = useFetchPractices('all')
  const practices = usePractices()

  return (
    <AsyncSelect
      fetchOptions={fetchOptions}
      options={practices}
      showSearch
      allowClear
      optionFilterProp="children"
      placeholder="Select practice"
      {...props}
    />
  )
}
