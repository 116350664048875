import React from 'react'
import Select from './Select'

export const STATUSES = [
  { id: 'all', name: 'Signing: all' },
  { id: 'true', name: 'Signed' },
  { id: 'false', name: 'Not signed' },
]

export default function SigningStatusSelect(props) {
  return <Select {...props} options={STATUSES} />
}
