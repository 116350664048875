import { createSlice } from '@reduxjs/toolkit'
import merge from 'lodash/merge'
import sessionSlice from './slices/session'

export default function createSliceWithLogout(options) {
  options.extraReducers = merge(options.extraReducers || {}, {
    [sessionSlice.actions.logout]: () => options.initialState,
  })
  return createSlice(options)
}
