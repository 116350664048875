import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { Form } from 'antd'
import React from 'react'
import { createUseStyles } from 'react-jss'
import CycleSelect from '../Forms/CycleSelect'
import { formElementPropsGenerator } from '../../lib/forms'
import SubmitOnChange from '../Forms/SubmitOnChange'

const useStyles = createUseStyles({
  filtersContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 20,
  },
  filterItem: {
    flex: 1,
    minWidth: 150,
  },
})

const wrapperCol = { span: 24 }

const propsFor = formElementPropsGenerator({
  formName: 'cycles-filter-form',
  placeholders: {
    cycleId: 'Cycle',
  },
})

export default function CyclesFilter({ currentCycleId, practiceId, onSubmit }) {
  const classes = useStyles()
  return (
    <Formik onSubmit={onSubmit} initialValues={{ cycleId: currentCycleId }}>
      {({ handleSubmit }) => (
        <Form
          layout="inline"
          wrapperCol={wrapperCol}
          className={classes.filtersContainer}
          onSubmit={handleSubmit}
        >
          <SubmitOnChange debounced />
          <CycleSelect
            {...propsFor('cycleId')}
            practiceId={practiceId}
            containerClassName={classes.filterItem}
          />
        </Form>
      )}
    </Formik>
  )
}

CyclesFilter.propTypes = {
  currentCycleId: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  practiceId: PropTypes.string.isRequired,
}
