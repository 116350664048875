import React, { useCallback } from 'react'
import moment from 'moment'
import { useParams } from 'react-router-dom'
import { ListError, PageHeader } from '../../Common'
import { useIsAdmin, useFeatureGuard } from '../../../redux/slices/session'
import api from '../../../api'
import { useAsync } from '../../../hooks'
import MonthFilter from '../../Common/MonthFilter'
import useFilters from '../../../hooks/useFilters'
import BillingOrders from './BillingOrders'

const DEFAULT_FILTERS = {
  checkDate: moment()
    .startOf('month')
    .format('YYYY-MM'),
}

export default function OfficeBillingOrders() {
  const { practiceId } = useParams()
  const isAdmin = useIsAdmin()
  const getPractice = useCallback(() => api.practices.getPractice(practiceId), [
    practiceId,
  ])
  const { pending, value: practice, error, clearError } = useAsync(getPractice)

  const { handleFilterChange, handlePageChange, conditions } = useFilters(
    DEFAULT_FILTERS,
  )

  const { page, checkDate } = conditions
  useFeatureGuard('in_office_checks')

  if (checkDate && !pending) {
    return (
      <div>
        <PageHeader
          title="Office Billing Orders"
          subTitle={isAdmin && practice.name}
        />

        <MonthFilter onSubmit={handleFilterChange} currentValue={checkDate} />

        <BillingOrders
          checkDate={checkDate}
          onPageChange={handlePageChange}
          page={page}
          practiceId={practiceId}
        />
      </div>
    )
  }

  return (
    <div>
      {error && <ListError description={error.message} onClose={clearError} />}
    </div>
  )
}
