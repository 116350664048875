import PropTypes from 'prop-types'
import React from 'react'
import { Redirect } from 'react-router-dom'
import { useIsAdmin } from '../../redux/slices/session'
import MainRoute from './MainRoute'

export default function AdminRoute({ path, children, ...props }) {
  const isAdmin = useIsAdmin()

  if (!isAdmin) {
    return <Redirect from={path} to="/" />
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <MainRoute path={path} {...props}>
      {children}
    </MainRoute>
  )
}

AdminRoute.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.node,
}

AdminRoute.defaultProps = {
  children: undefined,
}
