import React, { useCallback } from 'react'
import api from '../../api'
import AsyncSelect from './AsyncSelect'

export default function DeviceModelSelect(props) {
  const fetchOptions = useCallback(async () => {
    const devices = await api.deviceModels.list()
    return devices.map(device => ({
      id: device.id,
      name: `${device.name} - ${device.manufacturer}`,
    }))
  }, [])

  return (
    <AsyncSelect
      fetchOptions={fetchOptions}
      showSearch
      optionFilterProp="children"
      placeholder="Select device"
      {...props}
    />
  )
}
