import humps from 'humps'
import queryString from 'query-string'
import apiClient from './api-client'

function create(practiceId, data) {
  return apiClient.post(`/practices/${practiceId}/in-office-authorizations`, {
    pre_authorization: {
      patientId: data.patientId,
      billingDate: data.dateOfCheck,
      cptCode: data.cptCode,
      icd_10Code: data.icd10Code,
      authorized: data.authorized,
      officeCheckId: data.officeCheckId,
    },
  })
}

function list(practiceId, page, filters) {
  const options = { withHeaders: true }
  const pageFilter = page === 'all' ? { all: true } : { page }
  const query = humps.decamelizeKeys({ ...filters, ...pageFilter })
  const fullUrl = queryString.stringifyUrl({
    url: `/practices/${practiceId}/in-office-authorizations`,
    query,
  })
  return apiClient.get(fullUrl, options)
}

function update(id, preAuthorization) {
  return apiClient.patch(`pre-authorizations/${id}`, { preAuthorization })
}

export default {
  create,
  list,
  update,
}
