import { createSelector } from '@reduxjs/toolkit'
import { denormalize, normalize } from 'normalizr'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import api from '../../api'
import { missedTransmissionsSchema } from '../normalizr'
import { getPaginationHeaders, useAsyncAction } from '../utils'
import createSlice from '../create-slice'
import { getEntities } from './entities'

const missedTransmissionsInitialState = {
  cycles: {},
}

const missedTransmissionsSlice = createSlice({
  name: 'missedTransmissions',
  initialState: missedTransmissionsInitialState,
  reducers: {
    setMissedTransmissions: (
      state,
      { payload: { cycleId, result, pageSize, total } },
    ) => {
      state.cycles[cycleId] = state.cycles[cycleId] || {}
      state.cycles[cycleId].ids = result
      state.cycles[cycleId].pageSize = pageSize
      state.cycles[cycleId].total = total
    },
  },
})

const { actions } = missedTransmissionsSlice

const selectState = state => state[missedTransmissionsSlice.name]

const selectMissedTransmissionsInCycle = createSelector(
  selectState,
  getEntities,
  (state, cycleId) => cycleId,
  (state, entities, cycleId) =>
    denormalize(
      get(state.cycles, [cycleId, 'ids'], []),
      [missedTransmissionsSchema],
      entities,
    ),
)

export function useMissedTransmissions(cycleId) {
  return useSelector(state => selectMissedTransmissionsInCycle(state, cycleId))
}

export function useMissedTransmissionsPagination(cycleId) {
  const { cycles } = useSelector(selectState)
  const { pageSize, total } = get(cycles, [cycleId], {})
  return { pageSize, total }
}

function fetchMissedTransmissions(practiceId, cycleId, page) {
  return async dispatch => {
    const { body, headers } = await api.missedTransmissions.list(
      practiceId,
      cycleId,
      page,
    )
    dispatch(
      actions.setMissedTransmissions({
        cycleId,
        ...normalize(body, [missedTransmissionsSchema]),
        ...getPaginationHeaders(headers),
      }),
    )
  }
}

export function useFetchMissedTransmissions(practiceId, cycleId, page) {
  return useAsyncAction(fetchMissedTransmissions, practiceId, cycleId, page)
}

export default missedTransmissionsSlice
