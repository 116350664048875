import { useSelector } from 'react-redux'
import api from '../../api'
import { useAsyncAction } from '../utils'
import createSlice from '../create-slice'

const icdCodesInitialState = {
  icdCodes: [],
  pageSize: 0,
  total: 0,
}

const icdCodesSlice = createSlice({
  name: 'icdCodes',
  initialState: icdCodesInitialState,
  reducers: {
    setIcdCodes: (state, { payload: { result } }) => {
      state.icdCodes = result
    },
  },
})

const { actions } = icdCodesSlice

const selectState = state => state[icdCodesSlice.name]

export function useIcdCodes() {
  const st = useSelector(selectState)

  return st.icdCodes
}

export function fetchIcdCodes() {
  return async dispatch => {
    const body = await api.icdCodes.getIcdCodes()

    dispatch(actions.setIcdCodes({ result: body }))
  }
}

export function useFetchIcdCodes() {
  return useAsyncAction(fetchIcdCodes)
}

export default icdCodesSlice
