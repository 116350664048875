import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import routes from '../../routes'

const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID
const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE
const REDIRECT_URI = `${window.location.origin}${routes.authenticated()}`

const Auth0ProviderWithHistory = ({ children }) => {
  const history = useHistory()

  const onRedirectCallback = appState => {
    history.push(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      audience={AUTH0_AUDIENCE}
      redirectUri={REDIRECT_URI}
      onRedirectCallback={onRedirectCallback}
      allowRememberBrowser={false}
    >
      {children}
    </Auth0Provider>
  )
}

Auth0ProviderWithHistory.propTypes = {
  children: PropTypes.node,
}

Auth0ProviderWithHistory.defaultProps = {
  children: undefined,
}

export default Auth0ProviderWithHistory
