import React from 'react'
import PropTypes from 'prop-types'
import { Menu } from 'antd'
import { canAccess } from '../../lib/authorization'
import { useCurrentUserRole } from '../../redux/slices/session'

const { SubMenu: AntSubMenu } = Menu

export default function SubMenu({ item, children, ...rest }) {
  const userRole = useCurrentUserRole()

  if (!canAccess(userRole, item)) return null

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <AntSubMenu key={item} className="white-menu" theme="dark" {...rest}>
      {children}
    </AntSubMenu>
  )
}

SubMenu.propTypes = {
  item: PropTypes.string.isRequired,
  children: PropTypes.node,
}

SubMenu.defaultProps = {
  children: undefined,
}
