import { List } from 'antd'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { useAsync } from '../../hooks'
import { getPaginationHeaders } from '../../redux/utils'
import { ListError } from '../Common'

export default function AlertsReportsList({
  fetchFn,
  renderItem,
  patientId,
  header,
}) {
  const [currentPage, setCurrentPage] = useState(1)
  const fetch = useCallback(() => fetchFn(patientId, { page: currentPage }), [
    currentPage,
    fetchFn,
    patientId,
  ])
  const { pending, value, error, clearError } = useAsync(fetch)

  if (error) {
    return <ListError description={error.message} onClose={clearError} />
  }

  const list = value && value.body
  const { total, pageSize, page } =
    (value && getPaginationHeaders(value.headers)) || {}

  return (
    <List
      header={header}
      loading={pending}
      dataSource={list}
      renderItem={renderItem}
      pagination={
        total > pageSize && {
          current: page,
          pageSize,
          onChange: setCurrentPage,
          total,
        }
      }
    />
  )
}

AlertsReportsList.propTypes = {
  fetchFn: PropTypes.func.isRequired,
  renderItem: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
}
