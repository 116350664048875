import React from 'react'
import { toPath } from '../../routes'
import { PageHeader } from '../Common'
import PracticeSelectView from '../Practices/PracticeSelectView'

export default function OfficeChecks() {
  return (
    <div>
      <PageHeader title="Office Checks" />
      <PracticeSelectView routeMethod={toPath.practices.officeChecks} />
    </div>
  )
}
