import { Button, Descriptions, Divider, message, Popconfirm, Icon } from 'antd'
import React, { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import api from '../../../api'
import { useAsync } from '../../../hooks'
import { PageHeader, ListError } from '../../Common'
import DownloadLink from '../../Common/DownloadLink'
import Loading from '../../Loading'
import Comments from '../../Comments'
import { useIsAdmin } from '../../../redux/slices/session'
import { formatDateTime, formatDOB } from '../../../lib/datetime'

const useStyles = createUseStyles({
  actions: {
    marginTop: 30,
    textAlign: 'center',
  },
})

export default function Details() {
  const history = useHistory()
  const { id } = useParams()
  const isAdmin = useIsAdmin()
  const classes = useStyles()
  const getBillingOrder = useCallback(() => api.billingOrders.get(id), [id])
  const { pending, value: billingOrder, error, clearError } = useAsync(
    getBillingOrder,
  )

  const handleDelete = useCallback(async () => {
    try {
      await api.billingOrders.destroy(id)
      message.success('Billing Order successfully removed')
      history.goBack()
    } catch (e) {
      message.error(
        `There was an error removing this billing order: ${e.message}`,
      )
    }
  }, [history, id])

  if (pending) return <Loading large />
  if (error) {
    return <ListError description={error.message} onClose={clearError} />
  }
  const { device, patient, preAuthorization } = billingOrder

  const medicalProviderName = () => {
    if (!billingOrder.medicalProvider.firstName) return ''
    const { firstName, lastName } = billingOrder.medicalProvider
    return `${firstName} ${lastName}`
  }
  return (
    <div>
      <PageHeader
        title="Billing Order"
        subTitle={`${patient.firstName} ${patient.lastName}`}
        onBack={history.goBack}
      />
      <Divider orientation="left">Personal Information</Divider>
      <Descriptions column={2} size="small">
        <Descriptions.Item label="First Name">
          {patient.firstName}
        </Descriptions.Item>
        <Descriptions.Item label="Last Name">
          {patient.lastName}
        </Descriptions.Item>
        <Descriptions.Item label="Date of birth" span={2}>
          {formatDOB(patient.birthDate)}
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">Cardiac Device</Divider>
      <Descriptions column={2} size="small">
        <Descriptions.Item label="Company">
          {device.deviceModel.manufacturer}
        </Descriptions.Item>
        <Descriptions.Item label="Type">
          {device.deviceModel.deviceType.name}
        </Descriptions.Item>
        <Descriptions.Item label="Serial Number">
          {device.serialNumber}
        </Descriptions.Item>
        <Descriptions.Item label="CPT Code">
          {billingOrder.cptCode}
        </Descriptions.Item>
        <Descriptions.Item label="ICD-10 Code">
          {billingOrder.icd10Code}
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">Insurance Information</Divider>
      <Descriptions column={2} size="small">
        <Descriptions.Item label="Insurance Provider">
          {patient.insuranceProvider ? patient.insuranceProvider.name : ''}
        </Descriptions.Item>
        <Descriptions.Item label="Insurance Policy #">
          {patient.insurancePolicyNumber}
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">Insurance Authorization:</Divider>
      <Descriptions column={2} size="small">
        <Descriptions.Item label="Insurance Auth file">
          {preAuthorization.insuranceAuthorizationFilePath && (
            <DownloadLink
              path={preAuthorization.insuranceAuthorizationFilePath}
            >
              <Icon
                type="file-pdf"
                className={classes.pdfIcon}
                aria-label="insurance authorization file"
              />
            </DownloadLink>
          )}
        </Descriptions.Item>
        <Descriptions.Item label="Insurance Auth #">
          {preAuthorization.insuranceAuthorizationNumber}
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">Medical Provider:</Divider>
      <Descriptions column={2} size="small">
        <Descriptions.Item label="Name">
          {medicalProviderName()}
        </Descriptions.Item>
        <Descriptions.Item label="NPI">
          {billingOrder.medicalProvider?.nationalProviderId}
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">Data</Divider>
      <Descriptions column={2} size="small">
        <Descriptions.Item label="Billing Date">
          {billingOrder.billingDate
            ? formatDateTime(billingOrder.billingDate)
            : '-'}
        </Descriptions.Item>
        <Descriptions.Item label="Report Signature Date">
          {billingOrder.reportSignatureDate
            ? formatDateTime(billingOrder.reportSignatureDate)
            : '-'}
        </Descriptions.Item>
      </Descriptions>
      {/* TODO: use ActionButtons */}
      {isAdmin && (
        <div className={classes.actions}>
          <Popconfirm
            title="Are you sure？"
            okText="Yes"
            cancelText="No"
            onConfirm={handleDelete}
          >
            <Button
              type="danger"
              ghost
              icon="close-circle"
              className="hard-delete-button"
            >
              Delete
            </Button>
          </Popconfirm>
        </div>
      )}
      <Comments collectionName="billing-orders" id={billingOrder.id} />
    </div>
  )
}
