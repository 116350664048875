import React from 'react'
import { useFetchIcdCodes, useIcdCodes } from '../../redux/slices/icd-codes'
import AsyncSelect from './AsyncSelect'

export default function IcdCodesSelect(props) {
  const fetchOptions = useFetchIcdCodes()
  const icdCodes = useIcdCodes().map(code => ({
    ...code,
    label: `${code.code} - ${code.description}`,
  }))

  return (
    <AsyncSelect
      fetchOptions={fetchOptions}
      options={icdCodes}
      showSearch
      allowClear
      optionFilterProp="children"
      placeholder="Select ICD Codes"
      labelPropName="label"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 20 }}
      {...props}
    />
  )
}
