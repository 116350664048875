import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import api from '../../api'
import AsyncSelect from './AsyncSelect'

export const formatMedicalProvider = provider => {
  const fullName = `${provider?.firstName || ''} ${provider?.lastName ||
    ''}`.trim()
  const label = provider?.nationalProviderId || provider?.email || ''
  return `${fullName}${label ? ` (${label})` : ''}`.trim()
}

export default function MedicalProviderSelect(props) {
  const { practiceId, alertRecipients } = props
  const placeholder = props?.placeholder || 'Select a medical provider'
  const { values, setFieldValue } = useFormikContext()

  useEffect(() => {
    async function fetchData() {
      if (values.patientId) {
        const patient = await api.patients.get(values.patientId)
        setFieldValue('medicalProviderId', patient.medicalProvider.id)
      }
    }
    fetchData()
  }, [setFieldValue, values.patientId])

  const fetchOptions = useCallback(async () => {
    const providers = alertRecipients
      ? await api.practices.getProviders(practiceId)
      : await api.practices.getMedicalProviders(practiceId)
    return providers.map(p => ({
      id: p.id,
      name: formatMedicalProvider(p),
    }))
  }, [alertRecipients, practiceId])

  return (
    <AsyncSelect
      fetchOptions={fetchOptions}
      showSearch
      allowClear
      optionFilterProp="children"
      {...props}
      placeholder={placeholder}
    />
  )
}

MedicalProviderSelect.propTypes = {
  practiceId: PropTypes.string.isRequired,
  displayNPI: PropTypes.bool,
  placeholder: PropTypes.string,
  alertRecipients: PropTypes.bool,
}

MedicalProviderSelect.defaultProps = {
  displayNPI: false,
  placeholder: '',
  alertRecipients: false,
}
