import { useIdleTimer } from 'react-idle-timer'
import { useLogout } from '../redux/slices/session'

const INACTIVE_USER_TIME_THRESHOLD = 1000 * 60 * 30 // thirty minutes

function AutoLogout() {
  const logout = useLogout('/logged-out')

  useIdleTimer({
    timeout: INACTIVE_USER_TIME_THRESHOLD,
    onIdle: logout,
    debounce: 500,
    crossTab: true,
  })

  return null
}

export default AutoLogout
