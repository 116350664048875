import React, { useMemo } from 'react'
import PieChart from 'react-minimal-pie-chart'
import PropTypes from 'prop-types'
import { Row, Col, Typography } from 'antd'
import { createUseStyles } from 'react-jss'
import { COMPANIES_TRANSLATIONS } from '../../lib/companies'
import PieChartLabel from './PieChartLabel'

const { Title, Paragraph } = Typography

const useStyles = createUseStyles({
  title: {
    textAlign: 'center',
  },
  subtitle: {
    textAlign: 'center',
    marginBottom: 5,
  },
  centeredRow: {
    textAlign: 'center',
    marginTop: 30,
  },
  pieContainer: {
    maxWidth: 250,
    margin: '0 auto',
  },
  legendItem: {
    display: 'inline-block',
    margin: '0 30px 15px 30px',
  },
  legendColor: {
    display: 'inline-block',
    height: 20,
    width: 20,
    border: '1px solid black',
    marginRight: 10,
    verticalAlign: 'top',
  },
})

const COLORS = {
  Medtronic: '#3695dc',
  Abbott: '#5abf3f',
  Boston: '#Fcaa58',
  Biotronik: '#Ff3d4a',
}

function translateCompany(input) {
  return COMPANIES_TRANSLATIONS[input] || input
}

export default function DevicesSummary({ devicesStats }) {
  const classes = useStyles()
  const piesData = useMemo(
    () =>
      devicesStats.map(deviceTypeData => ({
        title: deviceTypeData.name,
        total: deviceTypeData.count,
        chartData: deviceTypeData.manufacturers.map(data => ({
          value: data.count,
          color: COLORS[translateCompany(data.name)],
          title: translateCompany(data.name),
          key: translateCompany(data.name),
        })),
      })),
    [devicesStats],
  )
  return (
    <>
      <Row type="flex" gutter={[50, 50]}>
        {piesData.map(pieData => (
          <Col span={24 / piesData.length} key={pieData.title}>
            <Title level={4} className={classes.title}>
              {pieData.title}
            </Title>
            <Paragraph type="primary" className={classes.subtitle}>
              {`${pieData.total} device${pieData.total !== 1 ? 's' : ''}`}
            </Paragraph>
            <div className={classes.pieContainer}>
              <PieChart
                data={pieData.chartData}
                label={PieChartLabel}
                animate
                labelStyle={{ fill: 'white' }}
                segmentsStyle={{ transition: 'stroke .3s' }}
                segmentsShift={() => 7}
                radius={50}
                lineWidth={25}
                labelPosition={60}
              />
            </div>
          </Col>
        ))}
      </Row>
      <Row type="flex" className={classes.centeredRow}>
        <Col span={24}>
          {Object.keys(COLORS).map(company => (
            <div className={classes.legendItem} key={company}>
              <span
                className={classes.legendColor}
                style={{ background: COLORS[company] }}
              />
              {company}
            </div>
          ))}
        </Col>
      </Row>
    </>
  )
}

DevicesSummary.propTypes = {
  devicesStats: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}
