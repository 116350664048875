import React, { useCallback, useEffect, useState } from 'react'
import { Alert, Divider, message, Typography } from 'antd'
import mapValues from 'lodash/mapValues'
import { Link, useHistory, useParams } from 'react-router-dom'
import jwtDecode from 'jwt-decode'
import { createUseStyles } from 'react-jss'
import ChangePasswordForm from '../../Profile/ChangePasswordForm'
import api from '../../../api'
import SessionLayout from '../SessionLayout'
import { toPath } from '../../../routes'

const { Paragraph } = Typography

const useStyles = createUseStyles({
  errorContainer: {
    marginBottom: '20px',
  },
})

export default function PasswordReset() {
  const { token } = useParams()
  const history = useHistory()
  const classes = useStyles()
  const [expired, setExpired] = useState()

  useEffect(() => {
    try {
      const { exp } = jwtDecode(token)
      if (new Date(exp * 1000) < new Date()) {
        setExpired(true)
      }
    } catch (e) {
      // We treat any problem as expired
      setExpired(true)
    }
  }, [token])

  const resetPassword = useCallback(
    async ({ password, passwordConfirmation }, { setErrors }) => {
      try {
        await api.passwordReset.update(token, {
          password,
          passwordConfirmation,
        })
        message.success('Password successfully updated')
        history.replace('/')
      } catch (e) {
        if (e.response && e.response.errors) {
          setErrors(mapValues(e.response.errors, fieldErrors => fieldErrors[0]))
        } else {
          message.error('There was an error updating your password')
        }
      }
    },
    [history, token],
  )

  if (expired) {
    return (
      <SessionLayout>
        <Alert
          className={classes.errorContainer}
          type="error"
          showIcon
          message="This link has expired"
        />
        <Link to={toPath.password.reset()}>Ask for a new link</Link>
        <Divider type="vertical" />
        <Link to="/">Login</Link>
      </SessionLayout>
    )
  }

  return (
    <SessionLayout>
      <Paragraph>Create your new password</Paragraph>
      <ChangePasswordForm onSubmit={resetPassword} hideCurrentPassword />
      <Link to="/">Login</Link>
    </SessionLayout>
  )
}
