import { Button, Form } from 'antd'
import { Formik } from 'formik'
import pick from 'lodash/pick'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { formElementPropsGenerator } from '../../lib/forms'
import { Yup } from '../../lib/validations'
import { SubmitButton } from '../Forms'
import TextArea from '../Forms/TextArea'
import { commentShape } from '../prop-types'

const useStyles = createUseStyles({
  submitButton: {
    marginRight: 10,
  },
  container: {
    '& .ant-form-item': {
      marginBottom: 5,
    },
  },
})

const INITIAL_VALUES = {
  message: '',
}

const validationSchema = Yup.object().shape({
  message: Yup.string().required(),
})

const propsFor = formElementPropsGenerator({
  formName: 'comments-form',
  placeholders: { message: 'Write your comment here...' },
})

const TEXT_AREA_SIZE = { minRows: 2, maxRows: 8 }

export default function CommentForm({ comment, onSubmit, onCancel }) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Formik
        initialValues={
          comment ? pick(comment, 'id', 'message') : INITIAL_VALUES
        }
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid, submitCount }) => (
          <Form onSubmit={handleSubmit}>
            <TextArea {...propsFor('message')} autoSize={TEXT_AREA_SIZE} />
            <Form.Item>
              <SubmitButton
                isSubmitting={isSubmitting}
                isValid={isValid}
                title={comment ? 'Save' : 'Post'}
                submitCount={submitCount}
                className={classes.submitButton}
                noWrapper
              />
              {onCancel && (
                <Button onClick={onCancel} type="secondary">
                  Cancel
                </Button>
              )}
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  )
}

CommentForm.propTypes = {
  comment: commentShape,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
}

CommentForm.defaultProps = {
  comment: undefined,
  onCancel: undefined,
}
