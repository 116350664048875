import { denormalize, normalize } from 'normalizr'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import api from '../../api'
import { billingOrdersSchema } from '../normalizr'
import { getPaginationHeaders, useAsyncAction } from '../utils'
import createSlice from '../create-slice'
import { getEntities } from './entities'

const billingOrdersInitialState = {
  cycles: {},
}

const billingOrdersSlice = createSlice({
  name: 'billingOrders',
  initialState: billingOrdersInitialState,
  reducers: {
    setBillingOrders: (
      state,
      { payload: { cycleId, result, pageSize, total } },
    ) => {
      state.cycles[cycleId] = state.cycles[cycleId] || {}
      state.cycles[cycleId].ids = result
      state.cycles[cycleId].pageSize = pageSize
      state.cycles[cycleId].total = total
    },
  },
})

const { actions } = billingOrdersSlice

const selectState = state => state[billingOrdersSlice.name]

export function useBillingOrders(cycleId) {
  const { cycles } = useSelector(selectState)
  const entities = useSelector(getEntities)
  return denormalize(
    get(cycles, [cycleId, 'ids'], []),
    [billingOrdersSchema],
    entities,
  )
}

export function useBillingOrdersPagination(cycleId) {
  const { cycles } = useSelector(selectState)
  const { pageSize, total } = get(cycles, [cycleId], {})
  return { pageSize, total }
}

function fetchBillingOrders(cycleId, page) {
  return async dispatch => {
    const { body, headers } = await api.billingOrders.list(cycleId, page)
    dispatch(
      actions.setBillingOrders({
        cycleId,
        ...normalize(body, [billingOrdersSchema]),
        ...getPaginationHeaders(headers),
      }),
    )
  }
}

export function useFetchBillingOrders(cycleId, page) {
  return useAsyncAction(fetchBillingOrders, cycleId, page)
}

export function markBillingOrderAsBilled(id, billed) {
  const type = 'MARK_AS_BILLED' // Just to update entities slice
  return async dispatch => {
    const body = await api.billingOrders.markAsBilled(id, { billed })
    return dispatch({
      type,
      payload: normalize(body, billingOrdersSchema),
    })
  }
}

export default billingOrdersSlice
