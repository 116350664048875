import { useEffect, useState } from 'react'
import api from '../api'

const useDiseases = () => {
  const [diseases, setDiseases] = useState([])

  useEffect(() => {
    const fetchDiseases = async () => {
      try {
        const response = await api.diseases.getAllDiseases()
        setDiseases(response)
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching diseases:', error)
      }
    }

    fetchDiseases()
  }, [])

  return diseases
}

export default useDiseases
