import React from 'react'
import { PageHeader } from '../../Common'
import PracticeSelectView from '../../Practices/PracticeSelectView'
import { toPath } from '../../../routes'

export default function BillingOrders() {
  return (
    <div>
      <PageHeader title="Remote Billing Orders" />
      <PracticeSelectView routeMethod={toPath.practices.billingOrders} />
    </div>
  )
}
