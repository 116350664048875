import { Button, Table, Icon, Tooltip } from 'antd'
import moment from 'moment-timezone'
import React, { useCallback } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { Link, useParams } from 'react-router-dom'
import { useAsync } from '../../hooks'
import useFilters from '../../hooks/useFilters'
import {
  useOfficeChecks,
  useOfficeChecksPagination,
  useFetchOfficeChecks,
} from '../../redux/slices/office-checks'
import { USER_ROLES } from '../../lib/constants'
import { usePractice } from '../../redux/slices/practices'
import {
  useCanSign,
  useCurrentUser,
  useFeatureGuard,
} from '../../redux/slices/session'
import { toPath } from '../../routes'
import { ListError, PageHeader } from '../Common'
import DiseasesTags from '../Common/DiseasesTags'
import MonthFilter from '../Common/MonthFilter'
import Filters from '../Forms/Filters'
import { formatDOB } from '../../lib/datetime'
import useExportExcel from '../../hooks/useExportExcel'
import BatchDownloadButton from './BatchDownloadButton'

const useStyles = createUseStyles({
  active: {
    color: 'green',
  },
  inactive: {
    color: 'red',
  },
  criticalIcon: {
    color: 'red',
  },
  notPresent: {
    color: 'orange',
  },
  pageHeader: {
    display: 'grid',
    width: '100%',
    gridTemplateColumns: 'auto auto 1fr',
  },
  total: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: 20,
  },
  totalResults: {
    background: ({ theme }) => theme.lightGray,
    color: '#707070',
    padding: '0 24px',
    fontWeight: 'bold',
    display: 'flex',
    alignContent: 'center',
    flexWrap: 'wrap',
  },
  officeChecksTable: {
    '& tr td:last-child': {
      textAlign: 'right',
      borderLeft: '1px solid rgb(232, 232, 232)',
      backgroundColor: '#f1f2fc',
    },
    '& tr th:last-child': {
      textAlign: 'center',
      borderLeft: '1px solid rgb(232, 232, 232)',
      backgroundColor: '#f1f2fc',
    },
    '& tr td:last-child button': {
      border: '1px solid #5175b3',
    },
  },
  actions: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '.5rem',
    justifyContent: 'center',
  },
})

const DEFAULT_FILTERS = {
  filters: 'all',
  checkDate: moment()
    .startOf('month')
    .format('YYYY-MM'),
}

export default function OfficeChecksReports() {
  const { practiceId } = useParams()
  const practice = usePractice(practiceId)

  const user = useCurrentUser()
  const userRole = user.role
  const isPractitioner = userRole === USER_ROLES.PRACTITIONER
  const isAdmin = userRole === USER_ROLES.ADMIN

  const canSign = useCanSign()

  const theme = useTheme()
  const classes = useStyles({ theme })
  const {
    handleFilterChange,
    handlePageChange,
    conditions,
    setConditions,
  } = useFilters(DEFAULT_FILTERS)
  const handleStatusFilter = useCallback(
    ({ filters }) => {
      setConditions({ ...conditions, filters, page: 1 })
    },
    [conditions, setConditions],
  )
  const { page, checkDate, filters } = conditions
  const officeChecks = useOfficeChecks(checkDate)

  const toOfficeCheck = id => {
    const path = toPath.practices.officeChecks.officeCheck({
      id,
      practiceId,
    })

    return `${path}?check_date=${checkDate}`
  }

  const toEditOfficeCheck = id => {
    const path = toPath.practices.officeChecks.officeCheck.edit({
      id,
      practiceId,
    })

    return `${path}?check_date=${checkDate}`
  }

  const columns = [
    {
      title: 'Full Name',
      key: 'id',
      render: (_, row) => {
        return (
          <>
            <Link
              to={toPath.patients.patient({ id: row.patient.id })}
              style={{ whiteSpace: 'nowrap', marginRight: 5 }}
            >
              <Tooltip title="View Patient">
                {`${row.patient.lastName}, ${row.patient.firstName}`}
              </Tooltip>
            </Link>
            <Link
              to={toPath.patients.patient.edit({ id: row.patient.id })}
              title="Edit Patient"
            >
              <Tooltip title="Edit Patient">
                <Icon type="edit" />
              </Tooltip>
            </Link>
          </>
        )
      },
    },
    {
      title: 'DOB',
      dataIndex: 'patient.birthDate',
      key: 'dob',
      render: formatDOB,
    },
    {
      title: 'Relevant History',
      key: 'diseases',
      render: (_, row) => <DiseasesTags patientSummary={row.patient} />,
    },
    {
      title: 'Device Type',
      dataIndex: 'patient.currentDevice.modelType',
      key: 'deviceType',
    },
    {
      title: 'CPT Code',
      dataIndex: 'patient.currentDevice.inOfficeCptCode',
      key: 'cptCode',
    },
    {
      title: 'ICD-10 Code',
      dataIndex: 'patient.currentDevice.inOfficeIcdCode',
      key: 'icdCode',
    },
    {
      title: 'Report Day',
      key: 'checkDate',
      dataIndex: 'checkDate',
      render: formatDOB,
    },
    {
      title: 'Device Report',
      dataIndex: 'hasDeviceReport',
      key: 'hasDeviceReport',
      align: 'center',
      render(_text, { hasDeviceReport }) {
        return (
          <Icon
            type={`${hasDeviceReport ? 'check' : 'close'}-circle`}
            className={classes[hasDeviceReport ? 'active' : 'notPresent']}
          />
        )
      },
    },
    {
      title: 'Completed',
      dataIndex: 'hasBiobridgeReport',
      key: 'hasBiobridgeReport',
      align: 'center',
      render(_text, { hasBiobridgeReport }) {
        return (
          <Icon
            type={`${hasBiobridgeReport ? 'check' : 'close'}-circle`}
            className={classes[hasBiobridgeReport ? 'active' : 'notPresent']}
          />
        )
      },
    },
    {
      title: 'Signed',
      dataIndex: 'signed',
      key: 'signedAt',
      align: 'center',
      render(_text, { signedAt }) {
        return (
          <Icon
            type={`${signedAt ? 'check' : 'close'}-circle`}
            className={classes[signedAt ? 'active' : 'notPresent']}
          />
        )
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render(_text, report) {
        return (
          <div className={classes.actions}>
            {!report.hasDeviceReport && (
              <Link to={toEditOfficeCheck(report.id)}>
                <Tooltip title="Add Device Report">
                  <Button type="link" icon="plus" size="small" />
                </Tooltip>
              </Link>
            )}
            {shouldSign(report) && !report.signedAt && (
              <Link to={toOfficeCheck(report.id)}>
                <Tooltip title="Sign Report">
                  <Button type="link" icon="edit" size="small" />
                </Tooltip>
              </Link>
            )}
            {report.hasDeviceReport && (
              <>
                <Link to={toOfficeCheck(report.id)}>
                  <Tooltip title="View Report">
                    <Button type="link" icon="file-search" size="small" />
                  </Tooltip>
                </Link>
                <Link to={toEditOfficeCheck(report.id)}>
                  <Tooltip title="Edit Report">
                    <Button type="link" icon="edit" size="small" />
                  </Tooltip>
                </Link>
              </>
            )}
          </div>
        )
      },
    },
  ]

  const shouldSign = row => {
    return row.hasBiobridgeReport && canSign && !row.signedAt
  }

  const res = useAsync(
    useFetchOfficeChecks(practiceId, checkDate, page, filters),
  )
  const { pending, error, clearError } = res
  const { pageSize, total } = useOfficeChecksPagination(checkDate)

  const [exportingExcel, exportExcel] = useExportExcel(
    `practices/${practiceId}/office-checks.xlsx?check_date=${checkDate}`,
  )

  useFeatureGuard('in_office_checks')

  return (
    <div>
      {error && <ListError description={error.message} onClose={clearError} />}
      <PageHeader
        title="Office Checks"
        subTitle={practice?.name}
        extra={[
          (isPractitioner || isAdmin) && (
            <BatchDownloadButton
              key="download"
              practiceId={practiceId}
              checkDate={checkDate}
            />
          ),
        ]}
      />
      <div className={classes.pageHeader}>
        <MonthFilter onSubmit={handleFilterChange} currentValue={checkDate} />
        <Filters
          showOfficeCheckFilters
          defaultValues={DEFAULT_FILTERS}
          currentValues={conditions}
          placeholders={{ officeCheckFilters: 'All' }}
          onSubmit={handleStatusFilter}
        />
        <div className={classes.total}>
          <div className={classes.totalResults}>
            {`${total || 0} result${total !== 1 ? 's' : ''}`}
          </div>
        </div>
      </div>
      <Table
        columns={columns}
        dataSource={officeChecks || []}
        loading={pending}
        className={classes.officeChecksTable}
        pagination={{
          current: conditions.page || 1,
          pageSize,
          onChange: handlePageChange,
          total,
        }}
        rowKey="id"
      />
      {Boolean(officeChecks.length > 0) && (
        <div>
          <Button
            loading={exportingExcel}
            onClick={exportExcel}
            icon="file-excel"
          >
            Export this list to Excel
          </Button>
        </div>
      )}
    </div>
  )
}
