import { Button, Popconfirm } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { useCurrentUser } from '../../redux/slices/session'

const useStyles = createUseStyles({
  actions: {
    marginTop: 30,
    textAlign: 'center',
  },
})

export default function ActionButtons({ onDelete, onlyAdmin }) {
  const classes = useStyles()
  const user = useCurrentUser()
  const userRole = user.role

  const isAdmin = userRole === 'admin'
  const isPractitioner = userRole === 'practitioner'
  const shouldShow = isAdmin || isPractitioner

  if (!shouldShow || (onlyAdmin && !isAdmin)) return null

  return (
    <div className={classes.actions}>
      {/* TODO: add Link component with edit button using new editPath prop */}
      <Popconfirm
        title="Are you sure？"
        okText="Yes"
        cancelText="No"
        onConfirm={onDelete}
      >
        <Button
          type="danger"
          ghost
          icon="close-circle"
          className="hard-delete-button"
        >
          Delete
        </Button>
      </Popconfirm>
    </div>
  )
}

ActionButtons.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onlyAdmin: PropTypes.bool,
}

ActionButtons.defaultProps = {
  onlyAdmin: true,
}
