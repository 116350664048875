import { Button, Icon, Table } from 'antd'
import queryString from 'query-string'
import React, { useCallback, useMemo } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useAsync } from '../../hooks'
import useExportExcel from '../../hooks/useExportExcel'
import useQueryParams from '../../hooks/useQueryParams'
import { formatDOB } from '../../lib/datetime'
import {
  useFetchPatients,
  usePatients,
  usePatientsPagination,
} from '../../redux/slices/patients'
import {
  useIsAdmin,
  useCurrentUserPracticeId,
} from '../../redux/slices/session'
import { toPath } from '../../routes'
import { ListError, PageHeader } from '../Common'
import AddNewItemButton from '../Common/AddNewItemButton'
import DiseasesTags from '../Common/DiseasesTags'
import Filters, { FIELDS_OPTIONS } from '../Forms/Filters'
import UnapprovedCount from './UnapprovedCount'

const useStyles = createUseStyles({
  ok: {
    color: 'green',
  },
  notOk: {
    color: 'red',
  },
  exportContainer: {
    textAlign: 'center',
  },
  disabledRow: {
    background: '#fbfbfb',
    opacity: 0.5,
  },
})

const QUERY_PARAMS_SCHEMA = {
  page: Number,
  showInactive: Boolean,
}

const DEFAULT_FILTERS = {
  approved: FIELDS_OPTIONS.approved[1].id,
  enrolled: FIELDS_OPTIONS.approved[0].id,
  page: 1,
  paired: FIELDS_OPTIONS.approved[0].id,
  deviceModelManufacturer: FIELDS_OPTIONS.deviceModelManufacturer[0].id,
  monitoring: FIELDS_OPTIONS.monitoringTypes[0].id,
}

export default function PatientsList() {
  const theme = useTheme()
  const classes = useStyles({ theme })
  const isAdmin = useIsAdmin()
  const [conditions, setConditions] = useQueryParams({
    initialValues: DEFAULT_FILTERS,
    schema: QUERY_PARAMS_SCHEMA,
  })
  const patients = usePatients()
  const userPracticeId = useCurrentUserPracticeId()
  const { pageSize, total } = usePatientsPagination()
  const { pending: loading, error, clearError } = useAsync(
    useFetchPatients(conditions, true),
    true,
  )
  const handlePageChange = useCallback(
    page => setConditions({ ...conditions, page }),
    [conditions, setConditions],
  )

  const excelExportUrl = useMemo(
    () =>
      queryString.stringifyUrl({
        url: `${toPath.patients()}.xlsx`,
        query: {
          approved: conditions.approved,
          practice_id: conditions.practiceId,
          device_type_id: conditions.deviceTypeId,
          medical_provider_id: conditions.medicalProviderId,
          device_model_manufacturer: conditions.deviceModelManufacturer,
          enrolled: conditions.enrolled,
          paired: conditions.paired,
          show_inactive: conditions.showInactive || undefined,
          monitoring: conditions.monitoring,
          birth_date: conditions.birthDate,
          q: conditions.query,
        },
      }),
    [
      conditions.approved,
      conditions.deviceModelManufacturer,
      conditions.deviceTypeId,
      conditions.medicalProviderId,
      conditions.enrolled,
      conditions.paired,
      conditions.practiceId,
      conditions.query,
      conditions.showInactive,
      conditions.monitoring,
      conditions.birthDate,
    ],
  )

  const formatBirthDate = bd =>
    bd ? moment(bd).format('YYYY-MM-DD') : undefined

  const handleSubmit = useCallback(
    ({
      approved,
      deviceTypeId,
      deviceModelManufacturer,
      medicalProviderId,
      enrolled,
      paired,
      practiceId,
      showInactive,
      birthDate,
      query,
      monitoring,
    }) => {
      setConditions({
        approved,
        deviceTypeId,
        deviceModelManufacturer,
        medicalProviderId,
        enrolled,
        birthDate: formatBirthDate(birthDate),
        page: 1,
        paired,
        practiceId,
        showInactive: showInactive || undefined,
        query,
        monitoring,
      })
    },
    [setConditions],
  )

  const columns = useMemo(
    () => [
      {
        title: 'Patient',
        dataIndex: 'patient.lastName',
        render(text, patient) {
          return (
            <Link to={toPath.patients.patient({ id: patient.id })}>
              {patient.lastName}
              {', '}
              {patient.firstName}
            </Link>
          )
        },
      },
      {
        title: 'DOB',
        dataIndex: 'birthDate',
        key: 'birthDate',
        render: formatDOB,
      },
      {
        title: 'Diseases',
        key: 'diseases',
        render(text, patient) {
          const patientSummary = {
            firstName: patient.firstName,
            lastName: patient.lastName,
            atrialFibrilationFlutterDisease:
              patient.atrialFibrilationFlutterDisease,
            anticoagulatedDisease: patient.anticoagulatedDisease,
          }
          return <DiseasesTags patientSummary={patientSummary} />
        },
      },
      {
        title: 'Device Type',
        dataIndex: 'currentDevice.modelType',
        key: 'modelType',
      },
      {
        title: 'Device Company',
        dataIndex: 'currentDevice.modelManufacturer',
        key: 'modelManufacturer',
      },
      {
        title: 'Serial Number',
        dataIndex: 'currentDevice.serialNumber',
        key: 'serialNumber',
      },
      {
        title: 'Enrolled',
        dataIndex: 'enrolled',
        key: 'enrolled',
        render(text, patient) {
          return (
            <Icon
              type={`${patient.enrolled ? 'check' : 'close'}-circle`}
              className={classes[patient.enrolled ? 'ok' : 'notOk']}
            />
          )
        },
      },
      {
        title: 'Paired',
        dataIndex: 'paired',
        key: 'paired',
        render(text, patient) {
          return (
            <Icon
              type={`${patient.paired ? 'check' : 'close'}-circle`}
              className={classes[patient.paired ? 'ok' : 'notOk']}
            />
          )
        },
      },
    ],
    [classes],
  )

  const rowClassName = useCallback(
    patient => (patient.active ? undefined : classes.disabledRow),
    [classes.disabledRow],
  )

  const [exportingExcel, exportExcel] = useExportExcel(excelExportUrl)

  const filterConditions = () => {
    if (userPracticeId) {
      return {
        practiceId: userPracticeId,
        ...conditions,
      }
    }
    return conditions
  }

  return (
    <div>
      {error && <ListError description={error.message} onClose={clearError} />}
      <PageHeader
        title="Patients"
        extra={
          <AddNewItemButton
            itemName="patient"
            path={toPath.patients.new()}
            icon="user-add"
          />
        }
      />
      {isAdmin && conditions.approved !== 'false' && <UnapprovedCount />}
      <Filters
        currentValues={filterConditions()}
        defaultValues={DEFAULT_FILTERS}
        showApprovalStatus
        showDeviceType
        showMedicalProvider
        showDeviceManufacturer
        showEnrollment
        showInactiveCheck
        showPairing
        showPractice={isAdmin}
        showMonitoringType
        showBirthDate
        showSearch
        onSubmit={handleSubmit}
        placeholders={{ query: 'Search by name or serial number' }}
      />
      <Table
        size="middle"
        dataSource={patients}
        columns={columns}
        rowKey="id"
        loading={loading}
        pagination={{
          current: conditions.page || 1,
          pageSize,
          onChange: handlePageChange,
          total,
        }}
        rowClassName={rowClassName}
      />
      {Boolean(patients.length) && (
        <div className={classes.exportContainer}>
          <Button
            loading={exportingExcel}
            onClick={exportExcel}
            icon="file-excel"
          >
            Export this list to Excel
          </Button>
        </div>
      )}
    </div>
  )
}
