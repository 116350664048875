import { useState, useCallback, useMemo } from 'react'
import { message } from 'antd'
import fromPairs from 'lodash/fromPairs'
import omit from 'lodash/omit'
import flatten from 'lodash/flatten'
import partition from 'lodash/partition'
import { useDropzone } from 'react-dropzone'
import unzip from 'unzip-js'
import api from '../../api'
import { useAsync } from '../../hooks'

const SUPPORTED_TYPES = {
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  zip: [
    'application/zip',
    'multipart/x-zip',
    'application/x-zip-compressed',
    'application/x-compressed',
  ],
}

const SUPPORTED_SINGLE_TYPES = omit(SUPPORTED_TYPES, 'zip')
const SUPPORTED_TYPES_LIST = flatten(Object.values(SUPPORTED_TYPES))
const SUPPORTED_SINGLE_TYPES_LIST = Object.values(SUPPORTED_SINGLE_TYPES)
const SUPPORTED_EXTENSIONS = Object.keys(SUPPORTED_SINGLE_TYPES)

function toInputName(filename) {
  return filename.replace(/\./g, ' ')
}

function filterByExtension({ name }) {
  return (
    !name.startsWith('_') &&
    SUPPORTED_EXTENSIONS.includes(name.split('.').pop())
  )
}

export default function useUpload(cycleId, practiceId) {
  const [result, setResult] = useState(null)

  const checkFilenamesCb = useCallback(
    filenames => api.reports.checkFilenames(practiceId, cycleId, filenames),
    [practiceId, cycleId],
  )
  const {
    pending: checkingFilenames,
    execute: checkFilenames,
    value: filenamesCheck,
  } = useAsync(checkFilenamesCb, false)

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => {
      if (rejectedFiles.length) {
        return message.error(
          `We can't process these files: ${rejectedFiles
            .map(file => file.name)
            .join(', ')}`,
          10,
        )
      }
      if (acceptedFiles.length > 1) {
        return message.error('Please select just 1 file')
      }
      const [file] = acceptedFiles
      if (!file) return undefined
      if (SUPPORTED_SINGLE_TYPES_LIST.includes(file.type)) {
        checkFilenames([file.name])
      } else if (SUPPORTED_TYPES.zip.includes(file.type)) {
        unzip(file, (err, zipFile) => {
          if (err) {
            return message.error(
              `The file could not be unzipped correctly: ${err.message}`,
            )
          }
          zipFile.readEntries((readEntriesError, entries) => {
            if (readEntriesError) {
              return message.error(
                `Files inside this zip could not be read: ${err.message}`,
              )
            }
            checkFilenames(
              entries.filter(filterByExtension).map(({ name }) => name),
            )
            return undefined
          })
          return undefined
        })
      }
      return undefined
    },
    [checkFilenames],
  )

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
  } = useDropzone({
    accept: SUPPORTED_TYPES_LIST,
    multiple: false,
    onDrop,
    disabled: checkingFilenames,
  })

  const [file] = acceptedFiles

  const [unmatchedReports, matchedReports, initialValues] = useMemo(() => {
    if (!filenamesCheck) return []
    const [unmatched, matched] = partition(
      filenamesCheck.map(check => ({
        ...check,
        inputName: toInputName(check.filename),
      })),
      check => !check.patient,
    )
    return [
      unmatched,
      matched,
      unmatched &&
        fromPairs(unmatched.map(check => [check.inputName, undefined])),
    ]
  }, [filenamesCheck])

  const submitFileWithMetadata = useCallback(
    async data => {
      const metadata = filenamesCheck
        .map(check => {
          if (check.patient) {
            return {
              filename: check.filename,
              type: check.type,
              patient_id: check.patient.id,
            }
          }
          const selectedPatientId = data[toInputName(check.filename)]
          if (!selectedPatientId) return undefined
          return {
            filename: check.filename,
            type: check.type,
            patient_id: selectedPatientId,
          }
        })
        .filter(Boolean)
      const fd = new FormData()
      fd.append('reports', file)
      fd.append('filenames', JSON.stringify(metadata))
      try {
        const response = await api.reports.upload(practiceId, cycleId, fd)
        setResult(response)
      } catch (err) {
        if (err.response.errors?.filenames.includes('not_present')) {
          message.error(
            `There was an error uploading the report(s): Please assign the report(s) to a patient`,
          )
        } else {
          message.error(`Something went wrong: ${err.message}`)
        }
      }
    },
    [cycleId, file, filenamesCheck, practiceId],
  )
  const { pending: submittingFile, execute: submitFileForm } = useAsync(
    submitFileWithMetadata,
    false,
  )

  return {
    file,
    getRootProps,
    getInputProps,
    isDragActive,
    checkingFilenames,
    unmatchedReports,
    matchedReports,
    initialValues,
    submitFileForm,
    submittingFile,
    result,
  }
}
