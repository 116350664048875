import { Skeleton, Typography } from 'antd'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { useCurrentUser } from '../../redux/slices/session'
import routes from '../../routes'

const { Text } = Typography

const useStyles = createUseStyles({
  userName: {
    margin: '0 10px',
  },
  skeleton: {
    width: '80px',
    marginRight: '10px',
  },
})

export default function ProfileLink() {
  const classes = useStyles()
  const { firstName, lastName, practice } = useCurrentUser()

  return (
    <div>
      <Skeleton
        active
        className={classes.skeleton}
        loading={false}
        paragraph={false}
      >
        <Link to={routes.profile()}>
          <Text className={classes.userName}>{`${firstName} ${lastName}`}</Text>
          {practice?.name && (
            <>
              {' '}
              -{' '}
              <Text type="secondary" className={classes.userName}>
                {practice.name}
              </Text>
            </>
          )}
        </Link>
      </Skeleton>
    </div>
  )
}
