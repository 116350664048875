import React from 'react'
import mapValues from 'lodash/mapValues'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import { Formik } from 'formik'
import { formElementPropsGenerator } from '../../../lib/forms'
import { Yup } from '../../../lib/validations'
import { Input, SubmitButton } from '../../Forms'

const PHONE_NUMBER_LABELS = {
  areaCode: 'Area Code',
  phoneNumber: 'Phone Number',
}

const PHONE_NUMBER_PLACEHOLDERS = {
  areaCode: 'Area Code',
  phoneNumber: 'Phone number',
}

const DEFAULT_VALUES = mapValues(PHONE_NUMBER_LABELS, '')

const labelCol = {
  sm: { span: 4, offset: 2 },
}

const wrapperCol = {
  sm: { span: 16 },
}

const actionsWrapperCol = {
  sm: { span: 4, offset: labelCol.sm.span + labelCol.sm.offset },
}

const propsFor = formElementPropsGenerator({
  formName: 'practice-form',
  labels: PHONE_NUMBER_LABELS,
  placeholders: PHONE_NUMBER_PLACEHOLDERS,
})

const validationSchema = Yup.object().shape({
  areaCode: Yup.number()
    .integer()
    .positive()
    .required(),
  phoneNumber: Yup.string().required(),
})

export default function PhoneNumberForm({ phoneNumberData, onSubmit }) {
  return (
    <div>
      <Formik
        initialValues={phoneNumberData || DEFAULT_VALUES}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, handleSubmit, isValid, submitCount }) => (
          <Form
            onSubmit={handleSubmit}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
          >
            <Input {...propsFor('areaCode')} />
            <Input {...propsFor('phoneNumber')} />
            <SubmitButton
              isSubmitting={isSubmitting}
              isValid={isValid}
              title={
                phoneNumberData ? 'Save Phone Number' : 'Create Phone Number'
              }
              submitCount={submitCount}
              wrapperCol={actionsWrapperCol}
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}

PhoneNumberForm.propTypes = {
  phoneNumberData: PropTypes.shape({
    areaCode: PropTypes.string,
    phoneNumber: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
}

PhoneNumberForm.defaultProps = {
  phoneNumberData: undefined,
}
