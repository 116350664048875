/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Form, Icon } from 'antd'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useField } from 'formik'
import { createUseStyles } from 'react-jss'
import { useDropzone } from 'react-dropzone'
import isString from 'lodash/isString'
import { dropZone as dropZoneStyles } from '../../theme/shared-styles'

const useStyles = createUseStyles({
  ...{
    ...dropZoneStyles,
    dropZone: {
      ...dropZoneStyles.dropZone,
      minHeight: '120px',
      marginBottom: '4px',
      padding: '4px',
    },
  },
})

const FIELD_STATUSES = {
  empty: 'empty',
  initial: 'initialValue',
  dragging: 'dragging',
  filled: 'filled',
}

function fileStatus(field, isDragActive) {
  const value = field && field.value
  const hasFile = Array.isArray(value) && value.length > 0

  if (isString(value)) return FIELD_STATUSES.initial
  if (isDragActive) return FIELD_STATUSES.dragging
  if (!isDragActive && !hasFile) return FIELD_STATUSES.empty

  return FIELD_STATUSES.filled
}

function formatInitialFile(value) {
  const filename = (value || '').split('/').pop()
  return filename.replaceAll('%20', ' ')
}

function UploadFormikFile({ name, label, supportFiles }) {
  const [field, meta, { setValue }] = useField({ name })
  const classes = useStyles()
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: supportFiles,
    onDrop: acceptedFiles => {
      setValue(acceptedFiles)
    },
  })
  const hasError = Boolean(meta.touched && meta.error)
  const status = fileStatus(field, isDragActive)

  return (
    <div>
      <Form.Item
        label={label}
        help={hasError && meta.error}
        validateStatus={hasError ? 'error' : ''}
      >
        <div
          {...getRootProps({
            className: classnames(
              classes.dropZone,
              classes.dropZoneOverride,
              isDragActive && classes.activeDropZone,
            ),
          })}
        >
          <input {...getInputProps()} name={label} />
          {FIELD_STATUSES.initial === status && (
            <div>{formatInitialFile(field.value)}</div>
          )}
          {FIELD_STATUSES.empty === status && (
            <p>Drag and drop some files here, or click to select files</p>
          )}
          {FIELD_STATUSES.dragging === status && (
            <>
              <p>Drop the files here ...</p>
              <Icon type="file-add" theme="filled" />
            </>
          )}
          {FIELD_STATUSES.filled === status && (
            <div>
              {field.value.map(file => (
                <div key={file}>{file.name}</div>
              ))}
            </div>
          )}
        </div>
      </Form.Item>
    </div>
  )
}

UploadFormikFile.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  supportFiles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default UploadFormikFile
