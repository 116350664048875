import apiClient from './api-client'

function create(email) {
  return apiClient.post('users/password-reset', { user: { email } })
}

function update(token, payload) {
  return apiClient.put('users/password-reset', {
    user: payload,
    auth: { token },
  })
}

export default {
  create,
  update,
}
