/* eslint-disable camelcase */
import React, { useState, useMemo, useEffect } from 'react'
import {
  Divider,
  Descriptions,
  Button,
  Modal,
  Alert,
  Icon,
  notification,
} from 'antd'
import PropTypes from 'prop-types'
import { createUseStyles, useTheme } from 'react-jss'

import api from '../../api'
import { buildUrl } from '../../api/api-client'
import WelcomeLetterStatus from './WelcomeLetterStatus'

const useStyles = createUseStyles({
  preview: {
    maxWidth: '100%',
  },
  modalZoom: {
    width: 612,
  },
  '@media (max-width: 600px)': {
    modalZoom: {
      width: '90%',
    },
  },
  previewContainer: {
    position: 'relative',
  },
  zoomButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    cursor: 'pointer',
    zIndex: 1,
  },
  notification: {
    color: 'black',
  },
})

const useStylesLetter = createUseStyles({
  letter: {
    maxWidth: '100%',
  },
  letterWrapper: {
    position: 'relative',
  },
  logo: {
    position: 'absolute',
    top: '5%',
    left: '30%',
    width: '40%',
    objectFit: 'contain',
    objectPosition: 'center',
  },
})

// eslint-disable-next-line react/prop-types
const Letter = ({ letter, logo }) => {
  const classes = useStylesLetter()
  return (
    <div className={classes.letterWrapper}>
      {logo && (
        <img
          className={classes.logo}
          src={buildUrl(logo)}
          alt="Practice logo"
        />
      )}
      <img className={classes.letter} src={letter} alt="Letter preview" />
    </div>
  )
}

const WelcomeLetter = ({ patient, practice, showWelcomeLetterButton }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isZoomed, setIsZoomed] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const { letterStatus, welcomeLetterTemplate } = patient
  const letterPreview = `/images/welcome_letters/${welcomeLetterTemplate}.jpg`
  const classes = useStyles()
  const { brandSuccess, brandDanger } = useTheme()

  useEffect(() => {
    if (!isModalVisible && isZoomed) setTimeout(() => setIsZoomed(false), 500)
  }, [isModalVisible, isZoomed])

  const maxWidth = useMemo(() => {
    return window.innerWidth >= 650 ? 612 : '90%'
  }, [])

  const sendWelcomeLetter = async () => {
    try {
      setIsSending(true)
      await api.patients.sendWelcomeLetter(patient.id)
      setIsSending(false)
      setIsModalVisible(false)
      notification.open({
        message: 'Welcome letter queued for sending',
        description:
          'The letter will be sent shortly and the status will be updated.',
        className: classes.notification,
        icon: <Icon type="check-circle" style={{ color: brandSuccess }} />,
      })
    } catch (e) {
      notification.open({
        message: 'Error sending the welcome letter',
        description: e.message,
        className: classes.notification,
        icon: <Icon type="exclamation-circle" style={{ color: brandDanger }} />,
      })
      setIsSending(false)
    }
  }

  return (
    <div className="letter-prompt">
      <Divider orientation="left">Welcome Letter</Divider>
      <Descriptions column={2} size="small">
        <Descriptions.Item label="Status">
          <WelcomeLetterStatus status={letterStatus} />
        </Descriptions.Item>
        <Descriptions.Item label="Send letter">
          <Button
            type="secondary"
            onClick={() => setIsModalVisible(true)}
            disabled={!showWelcomeLetterButton}
          >
            Preview & send
          </Button>
        </Descriptions.Item>
      </Descriptions>
      <Modal
        title="Preview welcome letter"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => sendWelcomeLetter()}
        okText="Send welcome letter"
        okType="primary"
        width={isZoomed ? maxWidth : 400}
        confirmLoading={isSending}
      >
        <div className={classes.previewContainer}>
          <Button
            icon={`zoom-${isZoomed ? 'out' : 'in'}`}
            className={classes.zoomButton}
            onClick={() => setIsZoomed(!isZoomed)}
          />
          <Letter letter={letterPreview} logo={practice?.logoPath} />
        </div>

        <Alert
          message="Sending a welcome letter has a charge."
          type="warning"
          showIcon
        />
      </Modal>
    </div>
  )
}

WelcomeLetter.propTypes = {
  patient: PropTypes.shape(),
  practice: PropTypes.shape(),
  showWelcomeLetterButton: PropTypes.bool,
}

WelcomeLetter.defaultProps = {
  patient: {
    id: '',
    letterStatus: '',
    welcomeLetterTemplate: '',
  },
  practice: {
    id: '',
    logoPath: '',
  },
  showWelcomeLetterButton: false,
}

export default WelcomeLetter
