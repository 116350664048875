import { Typography, Button, Table, Switch, message } from 'antd'
import React, { useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { createUseStyles } from 'react-jss'
import {
  useUnreviewedReportsPagination,
  useUnreviewedReports,
  useFetchUnreviewedReports,
  updateReportPriority,
  unassignReport,
} from '../../redux/slices/unreviewed-reports'
import Filters from '../Forms/Filters'
import { toPath } from '../../routes'
import { formatDOB } from '../../lib/datetime'
import { useCurrentUserRole } from '../../redux/slices/session'
import { useAsync } from '../../hooks'
import { assignUnreviewed } from '../../api/reports'
import { PageHeader } from '../Common'
import useQueryParams from '../../hooks/useQueryParams'
import AssignedReportDetails from './AssignedReportDetails'

const { Text } = Typography

const useStyles = createUseStyles({
  center: {
    textAlign: 'center',
  },
})

const QUERY_PARAMS_SCHEMA = {
  page: Number,
}

const COLUMNS = [
  {
    title: 'Patient',
    dataIndex: 'patient.lastName',
    render(text, report) {
      return (
        <Link to={toPath.patients.patient({ id: report.patient.id })}>
          {report.patient.firstName} {report.patient.lastName}
        </Link>
      )
    },
  },
  {
    title: 'DOB',
    dataIndex: 'patient.birthDate',
    render: formatDOB,
  },
  {
    title: 'Device Type',
    dataIndex: 'device.deviceModel.deviceType.name',
  },
  {
    title: 'Cycle',
    dataIndex: 'cycleEndDate',
    render: formatDOB,
  },
  {
    title: 'Practice',
    dataIndex: 'patient.practiceName',
  },
  {
    title: 'Assignee',
    dataIndex: 'report.assignee.id',
    render(text, report) {
      return (
        report.assignee && (
          <Text>
            {report.assignee.firstName} {report.assignee.lastName}
          </Text>
        )
      )
    },
  },
]

function AdminView() {
  const classes = useStyles()

  const [conditions, setConditions] = useQueryParams({
    initialValues: { page: 1 },
    schema: QUERY_PARAMS_SCHEMA,
  })
  const { pending: loading } = useAsync(
    useFetchUnreviewedReports(conditions),
    true,
  )
  const reports = useUnreviewedReports()

  const dispatch = useDispatch()
  const { currentPage, pageSize, total } = useUnreviewedReportsPagination()

  const handlePageChange = useCallback(
    page => setConditions({ ...conditions, page }),
    [conditions, setConditions],
  )

  const handlePriorityChange = useCallback(
    async (id, priority) => {
      try {
        await dispatch(updateReportPriority(id, { priority }))
        message.success('Report successfully updated')
      } catch (e) {
        message.error()
      }
    },
    [dispatch],
  )
  const handleSubmit = useCallback(
    ({ practiceId, query, serialNumber }) => {
      setConditions({
        page: 1,
        practiceId,
        query,
        serialNumber,
      })
    },
    [setConditions],
  )

  const freeReport = useCallback(
    async reportId => {
      try {
        await dispatch(unassignReport(reportId))
        message.success('Report successfully unassigned')
      } catch (e) {
        message.error('There was an error unassigning the report')
      }
    },
    [dispatch],
  )

  const columns = [
    ...COLUMNS,
    {
      title: 'Priority Report?',
      dataIndex: 'priority',
      render(_text, record) {
        return (
          <Switch
            checked={record.priority}
            checkedChildren="Priority"
            unCheckedChildren="Not&nbsp;Priority"
            onChange={priority => handlePriorityChange(record.id, priority)}
          />
        )
      },
    },
    {
      title: 'View',
      key: 'view',
      render(_text, record) {
        return (
          <span>
            <Link
              to={{
                pathname: toPath.reports.report({
                  id: record.id,
                }),
                search: 'unreviewed=true',
              }}
            >
              <Button type="link" icon="file-search" />
            </Link>
          </span>
        )
      },
    },
    {
      title: 'Unassign',
      key: 'unassign',
      render(_text, record) {
        return (
          record.assignee && (
            <div className={classes.center}>
              <Button
                icon="stop"
                type="link"
                onClick={() => freeReport(record.id)}
              />
            </div>
          )
        )
      },
    },
  ]

  return (
    <div>
      <Filters
        showSearch
        showPractice
        onSubmit={handleSubmit}
        currentValues={conditions}
        placeholders={{ query: 'Search by name or device serial number' }}
      />
      <Table
        columns={columns}
        loading={loading}
        pagination={{
          onChange: handlePageChange,
          current: currentPage,
          pageSize,
          total,
        }}
        dataSource={reports}
        rowKey="id"
      />
    </div>
  )
}

function ClinicianView() {
  const { total } = useUnreviewedReportsPagination()
  const { pending: loading } = useAsync(useFetchUnreviewedReports(), true)
  const history = useHistory()

  const reports = useUnreviewedReports()
  const report = reports.length > 0 ? reports[0] : null

  const assignReport = async () => {
    try {
      const response = await assignUnreviewed()
      history.push(`/reports/${response.id}/edit`)
    } catch (e) {
      message.error('There was an error assigning a report for review')
    }
  }

  if (reports.length === 0) {
    return (
      <div>
        <Typography.Title level={4}>
          There are {total} unreviewed reports
        </Typography.Title>
        <Button
          type="primary"
          disabled={loading || total === 0}
          onClick={assignReport}
        >
          Take one to review
        </Button>
      </div>
    )
  }

  return <AssignedReportDetails report={report} />
}

export default function UnreviewedReports() {
  const userRole = useCurrentUserRole()
  const isClinician = userRole === 'clinician'
  const isAdmin = userRole === 'admin'

  return (
    <>
      <PageHeader title="Unreviewed Reports" />
      {isAdmin && <AdminView />}
      {isClinician && <ClinicianView />}
    </>
  )
}
