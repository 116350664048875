import { message, Typography } from 'antd'
import { Formik } from 'formik'
import moment from 'moment-timezone'
import React, { useCallback, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import api from '../../api'
import { useAsync } from '../../hooks'
import { Yup } from '../../lib/validations'
import { useIsAdmin } from '../../redux/slices/session'
import { getPaginationHeaders } from '../../redux/utils'
import { ListError, PageHeader } from '../Common'
import Loading from '../Loading'
import CyclesCreation from './CyclesCreation'
import PracticeCyclesList from './PracticeCyclesList'

const VALIDATION_SCHEMA = Yup.object().shape({
  startsAt: Yup.date()
    .nullable()
    .required(),
  endsAt: Yup.date()
    .nullable()
    .required(),
})

export default function PracticeCycles() {
  const [page, setPage] = useState()
  const [editingCycleId, setEditingCycleId] = useState()
  const { practiceId } = useParams()
  const history = useHistory()
  const isAdmin = useIsAdmin()
  const fetchPractice = useCallback(
    () => api.practices.getPractice(practiceId),
    [practiceId],
  )
  const { pending: loadingPractice, value: practice } = useAsync(fetchPractice)
  const fetchCycles = useCallback(() => api.cycles.list({ practiceId, page }), [
    page,
    practiceId,
  ])
  const { pending, value, execute, error } = useAsync(fetchCycles)
  const cycles = value && value.body

  const initialValues = useMemo(() => {
    const editingCycle =
      editingCycleId && cycles.find(cycle => cycle.id === editingCycleId)
    return {
      cycleId: editingCycleId,
      startsAt: editingCycle && moment(editingCycle.startsAt),
      endsAt: editingCycle && moment(editingCycle.endsAt),
    }
  }, [cycles, editingCycleId])

  const handleCyclesUpdate = useCallback(
    async ({ startsAt, endsAt, cycleId }) => {
      try {
        await api.cycles.updateCycles({
          practiceId,
          cycleId,
          startsAt: startsAt && startsAt.format(moment.HTML5_FMT.DATE),
          endsAt: endsAt.format(moment.HTML5_FMT.DATE),
        })
        setEditingCycleId(null)
        await execute()
        message.success('Cycles updated successfully')
      } catch (e) {
        message.error(`There was an error updating the cycles: ${e.message}`)
      }
    },
    [execute, practiceId],
  )

  if (loadingPractice || pending) return <Loading large />

  let content = null
  if (error) {
    content = <ListError description={error.message} />
  } else if (!pending && (!cycles || !cycles.length)) {
    if (isAdmin) {
      content = (
        <CyclesCreation
          practiceId={practiceId}
          afterCreationCallback={execute}
        />
      )
    } else {
      content = (
        <Typography.Paragraph>There is no schedule yet.</Typography.Paragraph>
      )
    }
  } else {
    const headers = value && value.headers
    const paginationData =
      value && value.headers ? getPaginationHeaders(headers) : {}
    const pagination = {
      current: paginationData.page,
      pageSize: paginationData.pageSize,
      onChange: setPage,
      total: paginationData.total,
    }
    content = (
      <Formik
        initialValues={initialValues}
        onSubmit={handleCyclesUpdate}
        validationSchema={VALIDATION_SCHEMA}
        enableReinitialize
      >
        {({ handleSubmit }) => (
          <PracticeCyclesList
            editableCycle={editingCycleId}
            onEditCycle={setEditingCycleId}
            pagination={pagination}
            loading={pending}
            dataSource={cycles}
            onSubmit={isAdmin ? handleSubmit : undefined}
          />
        )}
      </Formik>
    )
  }

  return (
    <div>
      <PageHeader
        title="Remote Schedule"
        subTitle={isAdmin && practice.name}
        onBack={isAdmin && history.goBack}
      />
      {content}
    </div>
  )
}
