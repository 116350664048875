import queryString from 'query-string'
import apiClient from './api-client'

function list(practiceId, checkDate, page) {
  const options = { withHeaders: true }
  const query = page === 'all' ? { all: true } : { page }

  if (checkDate) query.date = checkDate

  return apiClient.get(
    queryString.stringifyUrl({
      url: `/practices/${practiceId}/in-office-billing-orders`,
      query,
    }),
    options,
  )
}

export default {
  list,
}
