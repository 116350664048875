import { message } from 'antd'
import mapValues from 'lodash/mapValues'
import isNil from 'lodash/isNil'
import React, { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import humps from 'humps'
import api from '../../api'
import { useAsync } from '../../hooks'
import { PageHeader } from '../Common'
import Loading from '../Loading'
import PracticeForm, { NON_FILE_PRACTICE_FIELDS } from './PracticeForm'

export default function EditPractice() {
  const history = useHistory()
  const { id } = useParams()
  const getPractice = useCallback(
    () =>
      api.practices.getPractice(id).then(practice => {
        practice.logo = practice.logoPath
        practice.ownerEmails = practice.ownerEmails.join('\n')
        practice.bpAlertRecipients = practice.bpAlertRecipients.join('\n')
        return practice
      }),
    [id],
  )
  const { pending, value } = useAsync(getPractice)

  const onSubmit = useCallback(
    async (practiceValues, { setSubmitting, setErrors }) => {
      try {
        const fd = new FormData()

        NON_FILE_PRACTICE_FIELDS.forEach(field => {
          if (!isNil(practiceValues[field])) {
            fd.append(humps.decamelize(field), practiceValues[field])
          }
        })

        if (Array.isArray(practiceValues.logo)) {
          fd.append('logo', practiceValues.logo[0])
        }

        await api.practices.updatePractice(id, fd)
        message.success('Practice successfully updated')
        history.goBack()
      } catch (e) {
        if (e.response && e.response.errors) {
          setErrors(mapValues(e.response.errors, fieldErrors => fieldErrors[0]))
        } else {
          message.error('Oops, we had a problem. Please try submitting again')
        }
      }
    },
    [history, id],
  )

  if (pending) return <Loading large />

  return (
    <div>
      <PageHeader title="Edit Practice" onBack={history.goBack} />
      <PracticeForm practiceData={value} onSubmit={onSubmit} />
    </div>
  )
}
