import apiClient from './api-client'

const API_BASE_PATH = '/welcome-letters/phone-numbers'

function getLetterPhoneNumbers() {
  return apiClient.get(API_BASE_PATH)
}

function getLetterPhoneNumber(id) {
  return apiClient.get(`${API_BASE_PATH}/${id}`)
}

function createLetterPhoneNumber(data) {
  return apiClient.post(API_BASE_PATH, data)
}

function updateLetterPhoneNumber(id, data) {
  return apiClient.patch(`${API_BASE_PATH}/${id}`, data)
}

function deleteLetterPhoneNumber(id) {
  return apiClient.destroy(`${API_BASE_PATH}/${id}`)
}

export default {
  getLetterPhoneNumbers,
  getLetterPhoneNumber,
  createLetterPhoneNumber,
  updateLetterPhoneNumber,
  deleteLetterPhoneNumber,
}
