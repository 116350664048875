import { message } from 'antd'
import mapValues from 'lodash/mapValues'
import moment from 'moment-timezone'
import React, { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import api from '../../api'
import { useAsync } from '../../hooks'
import { PageHeader } from '../Common'
import Loading from '../Loading'
import Form from './Form'

export default function Edit() {
  const history = useHistory()
  const { id } = useParams()

  const getPatient = useCallback(
    () =>
      api.patients.get(id).then(patient => {
        patient.birthDate = moment(patient.birthDate)
        return patient
      }),
    [id],
  )
  const { pending, value } = useAsync(getPatient)

  const onSubmit = useCallback(
    async (
      {
        firstName,
        lastName,
        birthDate,
        email,
        mobilePhone,
        homePhone,
        addressStreet,
        addressApt,
        addressCity,
        addressState,
        addressCountry,
        addressZipCode,
        atrialFibrilationFlutterDisease,
        anticoagulatedDisease,
        icdCodeIds,
        deviceIcdCodeId,
        enrolled,
        paired,
        homeMonitorSerialNumber,
        insuranceProviderId,
        insurancePolicyNumber,
        provider,
        pcpName,
        pcpPhoneNumber,
        pcpFaxNumber,
        alertRecipientIds,
        remoteMonitoring,
        practiceMrn,
        medicalProviderId,
      },
      { setErrors },
    ) => {
      try {
        await api.patients.update(id, {
          firstName,
          lastName,
          birthDate: birthDate.toISOString(),
          email: email || null,
          mobilePhone: mobilePhone || null,
          homePhone: homePhone || null,
          addressStreet,
          addressApt: addressApt || null,
          addressCity,
          addressState: addressState || null,
          addressCountry,
          addressZipCode,
          atrialFibrilationFlutterDisease,
          anticoagulatedDisease,
          icdCodeIds,
          deviceIcdCodeId: deviceIcdCodeId || null,
          enrolled,
          paired,
          homeMonitorSerialNumber,
          insuranceProviderId,
          insurancePolicyNumber,
          provider,
          pcpName,
          pcpPhoneNumber,
          pcpFaxNumber,
          alertRecipientIds,
          remoteMonitoring,
          practiceMrn,
          medicalProviderId: medicalProviderId || null,
        })
        message.success('Patient successfully updated')
        history.goBack()
      } catch (e) {
        if (e.response && e.response.errors) {
          setErrors(mapValues(e.response.errors, error => error[0]))
        } else {
          message.error('Oops, we had a problem. Please try submitting again')
        }
      }
    },
    [history, id],
  )

  if (pending) return <Loading large />

  return (
    <div>
      <PageHeader title="Update Patient" onBack={history.goBack} />
      <Form patientData={value} onSubmit={onSubmit} />
    </div>
  )
}
