import React from 'react'
import Select from './Select'

export const TYPES = [
  { id: 'all', name: 'Monitoring: All' },
  { id: 'in_office', name: 'In Office' },
  { id: 'remote', name: 'Remote' },
]

export default function MonitoringTypeSelect(props) {
  return <Select {...props} options={TYPES} />
}
