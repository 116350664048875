import PropTypes from 'prop-types'
import { Button, Divider, List, message, Modal } from 'antd'
import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import api from '../../api'
import { useAsync } from '../../hooks'

const useStyles = createUseStyles({
  actions: {
    marginTop: 30,
    textAlign: 'center',
  },
  tag: {
    verticalAlign: 'middle',
    marginBottom: 5,
  },
  dangerZoneButton: {
    marginLeft: 'auto',
  },
  modalButtons: {
    textAlign: 'right',
  },
  comments: {
    marginTop: 30,
  },
})

export default function RemindersModal({ patientIds, onCancel, visible }) {
  const classes = useStyles()
  const remindCall = useCallback(async () => {
    try {
      await api.patients.remind(patientIds, 'voice')
      onCancel()
      message.success('Reminder call sent')
    } catch (e) {
      message.error(`Could not send reminder: ${e.message}`)
    }
  }, [onCancel, patientIds])

  const remindSms = useCallback(async () => {
    try {
      await api.patients.remind(patientIds, 'sms')
      onCancel()
      message.success('Reminder SMS sent')
    } catch (e) {
      message.error(`Could not send reminder: ${e.message}`)
    }
  }, [onCancel, patientIds])

  const { pending: makingReminderCall, execute: makeReminderCall } = useAsync(
    remindCall,
    false,
  )

  const { pending: sendingReminderSms, execute: sendReminderSms } = useAsync(
    remindSms,
    false,
  )

  return (
    <Modal
      title="Patient reminders"
      visible={visible}
      onCancel={onCancel}
      cancelText={null}
      okType="secondary"
      footer={null}
    >
      <List title="Reminders">
        <List.Item>
          <div>
            <strong>Make a reminder phone call</strong>
            <p>
              A reminder call will be made to the patient&apos;s phone number.
            </p>
          </div>
          <Button
            type="primary"
            ghost
            icon="phone"
            className={`delete-button ${classes.dangerZoneButton}`}
            onClick={makeReminderCall}
            loading={makingReminderCall}
          >
            Make reminder call
          </Button>
        </List.Item>
        <List.Item>
          <div>
            <strong>Send an SMS reminder</strong>
            <p>
              An SMS message will be sent to the patient&apos;s mobile phone
              number.
            </p>
          </div>
          <Button
            type="primary"
            ghost
            icon="message"
            className={`hard-delete-button ${classes.dangerZoneButton}`}
            onClick={sendReminderSms}
            loading={sendingReminderSms}
          >
            Send reminder SMS
          </Button>
        </List.Item>
      </List>
      <div className={classes.modalButtons}>
        <Divider />
        <Button type="secondary" onClick={onCancel}>
          Close
        </Button>
      </div>
    </Modal>
  )
}

RemindersModal.propTypes = {
  patientIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool,
}

RemindersModal.defaultProps = {
  visible: true,
}
