import React, { useCallback, useState } from 'react'
import { Button, message, Typography } from 'antd'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { acknowledgeReports } from '../../redux/slices/reports'

const { Paragraph } = Typography

export default function Acknowledge({ practiceId, cycleId }) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const handleAcknowledge = useCallback(async () => {
    try {
      setLoading(true)
      await dispatch(acknowledgeReports(practiceId, cycleId))
      message.success('Reports has been acknowledged')
    } catch (e) {
      message.error(`There was an error acknowledging reports: ${e.message}`)
    }
    setLoading(false)
  }, [cycleId, dispatch, practiceId])
  return (
    <div>
      <Paragraph>
        The reports are ready. By pressing “Acknowledge” you recognize the
        practice is aware of this cycle’s reports.
      </Paragraph>
      <Button loading={loading} onClick={handleAcknowledge} type="primary">
        Acknowledge
      </Button>
    </div>
  )
}

Acknowledge.propTypes = {
  practiceId: PropTypes.string.isRequired,
  cycleId: PropTypes.string.isRequired,
}
