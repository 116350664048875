import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'
import {
  Typography,
  Table,
  Collapse,
  Tag,
  Row,
  Col,
  DatePicker,
  Card,
  Form,
} from 'antd'
import moment from 'moment'
import { useAsync } from '../../hooks'
import { PageHeader } from '../Common'
import Filters from '../Forms/Filters'
import {
  useFetchReviewedSummary,
  useReviewedSummary,
} from '../../redux/slices/reviewed-summary'
import Loading from '../Loading'
import { useIsAdmin } from '../../redux/slices/session'
import useQueryParams from '../../hooks/useQueryParams'

const { Title } = Typography
const { Panel } = Collapse
const { RangePicker } = DatePicker
const { Item } = Form

const useStyles = createUseStyles({
  criticalIcon: {
    color: 'red',
    paddingTop: '0.3em',
  },
  verticalSpaced: {
    marginBottom: '1em',
    '& .ant-table-wrapper': {
      marginBottom: '1em',
    },
  },
  totalReports: {
    color: '#000000d9',
    fontSize: '2em',
    width: '100%',
    textAlign: 'center',
    display: 'block',
  },
  filterContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '20px',
  },
  spacedGrid: {
    ':first-child': {
      marginRight: '2em',
    },
  },
  filterItem: {
    flex: 1,
    minWidth: 150,
  },
})

const totalReports = summary => {
  let count = 0
  let amount = 0

  for (const [, details] of Object.entries(summary)) {
    count += details.count
    amount += details.count * details.price
  }

  return { reportsCount: count, moneyCount: amount }
}

const DEVICE_COLUMNS = [
  {
    title: 'Device Type',
    dataIndex: 'name',
  },
  {
    title: 'Count',
    align: 'center',
    dataIndex: 'count',
  },
]

const DEFAULT_FILTERS = {
  fromDate: moment()
    .startOf('month')
    .format('YYYY-MM-DD'),
  toDate: moment()
    .endOf('month')
    .format('YYYY-MM-DD'),
}

const QUERY_PARAMS_SCHEMA = {
  assigneeId: String,
  deviceTypeId: String,
  fromDate: String,
  toDate: String,
}

export function SummaryTable({ summary }) {
  if (!summary) return <div />

  return (
    <Table
      dataSource={summary.deviceTypes}
      columns={DEVICE_COLUMNS}
      showHeader={false}
      rowKey="name"
      pagination={{ position: ['none', 'none'] }}
    />
  )
}

SummaryTable.propTypes = {
  summary: PropTypes.shape({ deviceTypes: PropTypes.array }),
}

SummaryTable.defaultProps = {
  summary: { deviceTypes: [] },
}

const countFor = dtSummary => (
  <div>
    <Tag color="blue">{(dtSummary && dtSummary.count) || 0}</Tag>
    <Tag color="orange">
      ${(dtSummary && dtSummary.count * dtSummary.price) || 0}
    </Tag>
  </div>
)

export default function ReviewedReportsSummary() {
  const [conditions, setConditions] = useQueryParams({
    initialValues: DEFAULT_FILTERS,
    schema: QUERY_PARAMS_SCHEMA,
  })

  const classes = useStyles()
  const isAdmin = useIsAdmin()

  const { pending: summaryLoading, execute } = useAsync(
    useFetchReviewedSummary(conditions),
    true,
  )
  const summary = useReviewedSummary()

  const handleDateChange = useCallback(
    (_, rangeString) => {
      if (rangeString[0] === '' || rangeString[1] === '') return

      setConditions({
        assigneeId: conditions.assigneeId,
        fromDate: rangeString[0],
        toDate: rangeString[1],
      })
      execute({ ...conditions })
    },
    [setConditions, conditions, execute],
  )

  const handleSubmit = useCallback(
    ({ assigneeId }) => {
      setConditions({
        assigneeId,
        fromDate: conditions.fromDate,
        toDate: conditions.toDate,
      })
    },
    [setConditions, conditions.fromDate, conditions.toDate],
  )

  const { reportsCount, moneyCount } = totalReports(summary)

  const rangeFilter = () => {
    const startDate = conditions.fromDate
      ? moment(conditions.fromDate)
      : moment().startOf('month')
    const endDate = conditions.toDate
      ? moment(conditions.toDate)
      : moment().endOf('month')

    return (
      <Item className={classes.filterItem}>
        <RangePicker
          defaultValue={[startDate, endDate]}
          ranges={{
            'This Month': [moment().startOf('month'), moment().endOf('month')],
          }}
          onChange={handleDateChange}
        />
      </Item>
    )
  }

  if (summaryLoading) return <Loading large />

  return (
    <>
      <PageHeader title="Reviewed Reports" />

      <div className={classes.verticalSpaced}>
        <Filters
          showClinician={isAdmin}
          onSubmit={handleSubmit}
          currentValues={conditions}
          placeholders={{ assigneeId: 'Filter by clinician' }}
          extraFilter={rangeFilter()}
        />
        <Title level={4}>Device Types Summary</Title>
        <Row gutter={16}>
          <Col span={12}>
            <Collapse>
              <Panel
                header="Pacemaker Check"
                key="1"
                extra={countFor(summary.pm)}
              >
                <SummaryTable summary={summary.pm} />
              </Panel>
              <Panel header="ICD Check" key="3" extra={countFor(summary.icd)}>
                <SummaryTable summary={summary.icd} />
              </Panel>
              <Panel header="ICM Check" key="5" extra={countFor(summary.icm)}>
                <SummaryTable summary={summary.icm} />
              </Panel>
              <Panel header="ILR Check" key="6" extra={countFor(summary.ilr)}>
                <SummaryTable summary={summary.ilr} />
              </Panel>
              <Panel
                header="CardioMEMS Check"
                key="7"
                extra={countFor(summary.hf)}
              >
                <SummaryTable summary={summary.hf} />
              </Panel>
            </Collapse>
          </Col>
          <Col span={12}>
            <Row className={classes.verticalSpaced}>
              <Col span={12}>
                <Card title="Total Reviewed Reports">
                  <span className={classes.totalReports}>{reportsCount}</span>
                </Card>
              </Col>
              <Col span={12}>
                <Card title="Total Payment">
                  <span className={classes.totalReports}>$ {moneyCount}</span>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  )
}

ReviewedReportsSummary.propTypes = {
  summary: PropTypes.shape({}),
}

ReviewedReportsSummary.defaultProps = {
  summary: {},
}
