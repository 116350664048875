import { message } from 'antd'
import mapValues from 'lodash/mapValues'
import React, { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import api from '../../api'
import { ListError, PageHeader } from '../Common'
import { toPath } from '../../routes'
import { useAsync } from '../../hooks'
import Loading from '../Loading'
import DeviceModelForm from './Form'

export default function EditDeviceModel() {
  const history = useHistory()
  const { id } = useParams()
  const getDeviceModel = useCallback(() => api.deviceModels.get(id), [id])
  const { pending, value, error, clearError } = useAsync(getDeviceModel)
  const goToList = useCallback(() => history.push(toPath.deviceModels()), [
    history,
  ])

  const onSubmit = useCallback(
    async ({ manufacturer, name, deviceTypeId }, { setErrors }) => {
      try {
        await api.deviceModels.update(id, {
          manufacturer,
          name,
          deviceTypeId,
        })
        message.success('Device Model successfully updated')
        goToList()
      } catch (e) {
        if (e.response && e.response.errors) {
          setErrors(mapValues(e.response.errors, fieldErrors => fieldErrors[0]))
        } else {
          message.error(e.message)
        }
      }
    },
    [goToList, id],
  )

  if (pending) return <Loading large />

  return (
    <div>
      <PageHeader title="Edit Device Model" onBack={history.goBack} />
      {error && <ListError description={error.message} onClose={clearError} />}
      <DeviceModelForm deviceModel={value} onSubmit={onSubmit} />
    </div>
  )
}
