import moment from 'moment'
import React from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import PropTypes from 'prop-types'

const useStyles = createUseStyles({
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100px',
    textAlign: 'center',
  },
  underlined: {
    textDecoration: 'underline',
  },
})

export default function EstimatedLongevity({ estimatedBatteryLongevity }) {
  const theme = useTheme()
  const classes = useStyles({ theme })
  const { lastEstimation, lastEstimationAt, estimatedLongevity } =
    estimatedBatteryLongevity || {}
  let formattedDate
  try {
    formattedDate = moment(lastEstimationAt).format('L')
  } catch (e) {
    formattedDate = 'N/A'
  }

  return (
    <div className={classes.content}>
      {lastEstimation && (
        <div>
          <div>Most Recent Longevity:</div>
          <div>
            <span className={classes.underlined}>{lastEstimation}</span> on{' '}
            <span className={classes.underlined}>{formattedDate}</span>
          </div>
        </div>
      )}
      <div>
        <div>Estimated Current Longevity:</div>
        <div className={classes.underlined}>{estimatedLongevity || 'N/A'}</div>
      </div>
    </div>
  )
}

EstimatedLongevity.propTypes = {
  estimatedBatteryLongevity: PropTypes.shape({
    lastEstimation: PropTypes.string,
    lastEstimationAt: PropTypes.string,
    estimatedLongevity: PropTypes.string,
  }),
}

EstimatedLongevity.defaultProps = {
  estimatedBatteryLongevity: {},
}
