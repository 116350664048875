import React from 'react'
import PropTypes from 'prop-types'
import { Menu } from 'antd'
import { canAccess } from '../../lib/authorization'
import { useCurrentUserRole } from '../../redux/slices/session'

export default function MenuItem({ item, children, ...rest }) {
  const userRole = useCurrentUserRole()

  if (!canAccess(userRole, item)) return null

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Menu.Item key={item} {...rest}>
      {children}
    </Menu.Item>
  )
}

MenuItem.propTypes = {
  item: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.object,
}

MenuItem.defaultProps = {
  children: undefined,
}
