import queryString from 'query-string'
import apiClient from './api-client'

function getAll({ q }) {
  return apiClient.get(
    queryString.stringifyUrl({
      url: 'insurance-providers',
      query: {
        q,
      },
    }),
    { withHeaders: true },
  )
}

export default {
  getAll,
}
