import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { Form } from 'antd'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { formElementPropsGenerator } from '../../lib/forms'
import SubmitOnChange from '../Forms/SubmitOnChange'
import MonthSelect from '../Forms/MonthSelect'

const useStyles = createUseStyles({
  filtersContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 20,
  },
  filterItem: {
    flex: 1,
    minWidth: 150,
  },
})

const wrapperCol = { span: 24 }

const propsFor = formElementPropsGenerator({
  formName: 'month-filter-form',
  placeholders: {
    month: 'Month',
  },
  label: 'Month',
})

export default function MonthFilter({ onSubmit, currentValue }) {
  const classes = useStyles()

  return (
    <Formik onSubmit={onSubmit} initialValues={{ month: currentValue }}>
      {({ handleSubmit }) => (
        <Form
          layout="inline"
          wrapperCol={wrapperCol}
          className={classes.filtersContainer}
          onSubmit={handleSubmit}
        >
          <SubmitOnChange debounced />
          <MonthSelect
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...propsFor('month')}
            defaultValue={currentValue}
            containerClassName={classes.filterItem}
          />
        </Form>
      )}
    </Formik>
  )
}

MonthFilter.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  currentValue: PropTypes.string.isRequired,
}
