import { message } from 'antd'
import mapValues from 'lodash/mapValues'
import isNil from 'lodash/isNil'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import humps from 'humps'
import api from '../../api'
import { toPath } from '../../routes'
import { PageHeader } from '../Common'
import PracticeForm, { NON_FILE_PRACTICE_FIELDS } from './PracticeForm'

export default function NewPractice() {
  const history = useHistory()
  const goToList = useCallback(() => history.push(toPath.practices()), [
    history,
  ])

  const onSubmit = useCallback(
    async (practiceValues, { setSubmitting, setErrors }) => {
      try {
        const fd = new FormData()

        NON_FILE_PRACTICE_FIELDS.forEach(field => {
          if (!isNil(practiceValues[field])) {
            fd.append(humps.decamelize(field), practiceValues[field])
          }
        })

        if (Array.isArray(practiceValues.logo)) {
          fd.append('logo', practiceValues.logo[0])
        }

        await api.practices.createPractice(fd)
        message.success('Practice successfully created')
        goToList()
      } catch (e) {
        if (e.response && e.response.errors) {
          setErrors(mapValues(e.response.errors, error => error[0]))
        } else {
          message.error('Oops, we had a problem. Please try submitting again')
        }
      }
    },
    [goToList],
  )

  return (
    <div>
      <PageHeader title="New Practice" onBack={history.goBack} />
      <PracticeForm onSubmit={onSubmit} />
    </div>
  )
}
