import { Row, Col, Form, Tooltip, Icon } from 'antd'
import { Formik } from 'formik'
import mapValues from 'lodash/mapValues'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { formElementPropsGenerator } from '../../lib/forms'
import { Yup } from '../../lib/validations'
import { Input, SubmitButton } from '../Forms'
import PracticeSelect from '../Forms/PracticeSelect'
import RoleSelect from '../Forms/RoleSelect'
import Switch from '../Forms/Switch'
import { userShape } from '../prop-types'
import PositionSelect from '../Forms/PositionSelect'

import { USERS_PLACEHOLDERS, USERS_LABELS } from '../../lib/constants'

const INITIAL_VALUES = mapValues(USERS_LABELS, field => '')
INITIAL_VALUES.active = true
INITIAL_VALUES.roleId = undefined
INITIAL_VALUES.position = undefined

const propsFor = formElementPropsGenerator({
  formName: 'user-form',
  labels: USERS_LABELS,
  placeholders: USERS_PLACEHOLDERS,
})

function needsPractice(roleValue) {
  return roleValue && roleValue.label && roleValue.label === 'practitioner'
}

function buildValidationSchema(isNew) {
  const validations = {
    firstName: Yup.string()
      .min(2)
      .required(),
    lastName: Yup.string()
      .min(2)
      .required(),
    email: Yup.string()
      .email()
      .required(),
    position: Yup.object(),
    practiceId: Yup.string().when('roleId', {
      is: needsPractice,
      then: Yup.string()
        .nullable()
        .required(),
      otherwise: Yup.string().nullable(),
    }),
    roleId: Yup.object().required(),
    nationalProviderId: Yup.string()
      .nullable()
      .npi(),
  }
  return Yup.object().shape(validations)
}

const labelCol = {
  sm: { span: 4, offset: 2 },
}

const wrapperCol = {
  sm: { span: 12 },
}

const actionsWrapperCol = {
  sm: { span: 12, offset: labelCol.sm.span },
}

export default function UserForm({ userData, onSubmit }) {
  const initialValues = userData
    ? {
        ...userData,
        roleId: { key: userData.role.id, label: userData.role.name },
        position: userData.position
          ? { key: userData.position, label: userData.positionLabel }
          : undefined,
        nationalProviderId:
          userData.role.name === 'practitioner' &&
          userData.position === 'provider'
            ? userData.nationalProviderId
            : undefined,
      }
    : INITIAL_VALUES
  const validationSchema = useMemo(() => buildValidationSchema(!userData), [
    userData,
  ])

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid, values, submitCount }) => {
          return (
            <Form
              onSubmit={handleSubmit}
              labelCol={labelCol}
              wrapperCol={wrapperCol}
            >
              <Row>
                <Form.Item
                  labelCol={{ span: 5 }}
                  label={USERS_LABELS.firstName}
                >
                  <Input {...propsFor('firstName')} label="" />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item labelCol={{ span: 5 }} label={USERS_LABELS.lastName}>
                  <Input {...propsFor('lastName')} label="" />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item labelCol={{ span: 5 }} label={USERS_LABELS.email}>
                  <Input {...propsFor('email')} type="email" label="" />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item labelCol={{ span: 5 }} label={USERS_LABELS.active}>
                  <Switch {...propsFor('active')} label="" />
                </Form.Item>
              </Row>
              <Row>
                <Form.Item labelCol={{ span: 5 }} label={USERS_LABELS.password}>
                  An invitation email will be sent to the user from which they
                  will be able to set their own password
                </Form.Item>
              </Row>
              <Row>
                <Form.Item labelCol={{ span: 5 }} label={USERS_LABELS.roleId}>
                  <RoleSelect {...propsFor('roleId')} label="" />
                </Form.Item>
              </Row>
              {needsPractice(values.roleId) && (
                <>
                  <Row>
                    <Form.Item
                      labelCol={{ span: 5 }}
                      label={USERS_LABELS.position}
                    >
                      <PositionSelect {...propsFor('position')} label="" />
                    </Form.Item>
                  </Row>
                  <Row>
                    <Form.Item
                      labelCol={{ span: 5 }}
                      label={USERS_LABELS.practiceId}
                    >
                      <PracticeSelect
                        {...propsFor('practiceId', { hasCustomEvents: true })}
                        clearWhenUnmount
                        label=""
                      />
                    </Form.Item>
                  </Row>
                  {values.position?.key === 'provider' && (
                    <Row>
                      <Form.Item
                        labelCol={{ span: 5 }}
                        label={
                          <span>
                            NPI{' '}
                            <Tooltip title={USERS_LABELS.nationalProviderId}>
                              <Icon type="question-circle" />
                            </Tooltip>
                          </span>
                        }
                      >
                        <Input {...propsFor('nationalProviderId')} label="" />
                      </Form.Item>
                    </Row>
                  )}
                </>
              )}
              <Row>
                <Col span={12} offset={8}>
                  <SubmitButton
                    isSubmitting={isSubmitting}
                    isValid={isValid}
                    submitCount={submitCount}
                    title={userData ? 'Save User' : 'Create User'}
                    wrapperCol={actionsWrapperCol}
                  />
                </Col>
              </Row>
            </Form>
          )
        }}
      </Formik>
      <style>
        {`
        .ant-form-item {
          margin-bottom: 12px;
        }
        `}
      </style>
    </div>
  )
}

UserForm.propTypes = {
  userData: userShape,
  onSubmit: PropTypes.func.isRequired,
}

UserForm.defaultProps = {
  userData: undefined,
}
