import { denormalize } from 'normalizr'
import { useSelector } from 'react-redux'
import createSlice from '../create-slice'
import api from '../../api'
import { reportsSummarySchema } from '../normalizr'
import { useAsyncAction } from '../utils'
import { getEntities } from './entities'

const summaryInitialState = {
  summary: {},
}

export const reviewedSummarySlice = createSlice({
  name: 'reviewedSummary',
  initialState: summaryInitialState,
  reducers: {
    setSummary: (state, { payload: { result } }) => {
      state.summary = result
    },
  },
})

const { actions } = reviewedSummarySlice

const selectState = state => {
  return state[reviewedSummarySlice.name]
}

export function useReviewedSummary() {
  const result = useSelector(selectState)
  const { summary } = result
  const entities = useSelector(getEntities)
  return denormalize(summary, [reportsSummarySchema], entities)
}

export function fetchReviewedSummary({
  assigneeId,
  deviceTypeId,
  fromDate,
  toDate,
}) {
  return async dispatch => {
    const { body } = await api.reports.getReviewedSummary({
      assigneeId,
      deviceTypeId,
      fromDate,
      toDate,
    })
    dispatch(actions.setSummary({ result: body }))
  }
}

export function useFetchReviewedSummary(query) {
  return useAsyncAction(fetchReviewedSummary, query)
}

export default reviewedSummarySlice
