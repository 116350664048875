import queryString from 'query-string'
import apiClient from './api-client'

function getPatients({
  page,
  approved,
  practiceId,
  showInactive,
  q,
  deviceTypeId,
  deviceModelManufacturer,
  medicalProviderId,
  enrolled,
  monitoring,
  paired,
  birthDate,
}) {
  return apiClient.get(
    queryString.stringifyUrl({
      url: 'patients',
      query: {
        page,
        approved,
        practice_id: practiceId,
        device_type_id: deviceTypeId,
        device_model_manufacturer: deviceModelManufacturer,
        medical_provider_id: medicalProviderId,
        enrolled,
        paired,
        monitoring,
        // avoid sending when false (string false is also truthy)
        show_inactive: showInactive || undefined,
        birth_date: birthDate,
        q,
      },
    }),
    { withHeaders: true },
  )
}

function get(id) {
  return apiClient.get(`patients/${id}`)
}

function getAlerts(id, query) {
  return apiClient.get(
    queryString.stringifyUrl({
      url: `patients/${id}/alerts`,
      query,
    }),
    { withHeaders: true },
  )
}

function getReports(id, query) {
  return apiClient.get(
    queryString.stringifyUrl({
      url: `patients/${id}/reports`,
      query,
    }),
    { withHeaders: true },
  )
}

function getOfficeChecks(practiceId, id) {
  return apiClient.get(
    queryString.stringifyUrl({
      url: `practices/${practiceId}/office-checks`,
      query: {
        patient_id: id,
      },
    }),
    { withHeaders: true },
  )
}

function getMissedTransmissions(id) {
  return apiClient.get(
    queryString.stringifyUrl({
      url: `missed-transmissions`,
      query: {
        patient_id: id,
      },
    }),
    { withHeaders: true },
  )
}

function getUnapprovedCount() {
  return apiClient.get('patients/unapproved/count')
}

function create(patient) {
  return apiClient.post('patients', patient)
}

function update(id, patientData) {
  return apiClient.patch(`patients/${id}`, patientData)
}

function destroy(id) {
  return apiClient.destroy(`patients/${id}`)
}

function createDevice(patientId, deviceData) {
  return apiClient.post(`patients/${patientId}/devices`, deviceData)
}

function fullRemoval(id) {
  return apiClient.destroy(`patients/${id}/destroy`)
}

function remind(ids, type) {
  let reminderType = type

  if (type !== 'voice' && type !== 'sms') {
    reminderType = 'sms'
  }

  return apiClient.post('/reminders', { patient_ids: ids, type: reminderType })
}

function savePreferences(id, preferences) {
  return apiClient.post(`/patients/${id}/preferences`, preferences)
}
function sendWelcomeLetter(id) {
  return apiClient.post(`/patients/${id}/welcome_letter`)
}

export default {
  create,
  createDevice,
  destroy,
  fullRemoval,
  get,
  getAlerts,
  getPatients,
  getReports,
  getOfficeChecks,
  getMissedTransmissions,
  getUnapprovedCount,
  update,
  remind,
  savePreferences,
  sendWelcomeLetter,
}
