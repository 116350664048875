import queryString from 'query-string'
import apiClient from './api-client'

function getIcdCodes(page, q) {
  const pageQuery = page === 'all' ? { all: true } : { page }

  return apiClient.get(
    queryString.stringifyUrl({
      url: 'icd/v10',
      query: {
        ...pageQuery,
        ...q,
      },
    }),
  )
}

export default {
  getIcdCodes,
}
