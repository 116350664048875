import apiClient from './api-client'

function get(id) {
  return apiClient.get(`device-types/${id}`)
}

function list() {
  return apiClient.get('device-types')
}

function update(id, payload) {
  return apiClient.patch(`device-types/${id}`, payload)
}

export default {
  get,
  list,
  update,
}
