import { Table, Typography, DatePicker, Form } from 'antd'
import { Link } from 'react-router-dom'
import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import capitalize from 'lodash/capitalize'
import moment from 'moment'
import { useAsync } from '../../hooks'
import useQueryParams from '../../hooks/useQueryParams'
import {
  useFetchReminders,
  useReminders,
  useRemindersPagination,
} from '../../redux/slices/reminders'
import { toPath } from '../../routes'
import { ListError, PageHeader } from '../Common'
import { standardDateTime } from '../../lib/datetime'
import Filters from '../Forms/Filters'

const { Text } = Typography
const { RangePicker } = DatePicker
const { Item } = Form

const QUERY_PARAMS_SCHEMA = {
  page: Number,
}

const useStyles = createUseStyles({
  filterItem: {
    flex: 1,
    minWidth: 150,
  },
})

export default function Reminders() {
  const classes = useStyles()

  const [conditions, setConditions] = useQueryParams({
    initialValues: { page: 1 },
    schema: QUERY_PARAMS_SCHEMA,
  })

  const { pending, error, clearError, execute } = useAsync(
    useFetchReminders(conditions, true),
    true,
  )

  const handlePageChange = useCallback(
    page => setConditions({ ...conditions, page }),
    [conditions, setConditions],
  )

  const handleSubmit = useCallback(
    ({ reminderStatus, reminderType }) => {
      setConditions({
        page: 1,
        reminderStatus,
        reminderType,
      })
    },
    [setConditions],
  )

  const handleDateChange = useCallback(
    (_, rangeString) => {
      if (rangeString[0] === '' || rangeString[1] === '') return

      setConditions({
        from: rangeString[0],
        to: rangeString[1],
      })
      execute({ ...conditions })
    },
    [setConditions, conditions, execute],
  )

  const rangeFilter = () => {
    const startDate = conditions.fromDate
      ? moment(conditions.fromDate)
      : moment().startOf('month')
    const endDate = conditions.toDate
      ? moment(conditions.toDate)
      : moment().endOf('month')

    return (
      <Item className={classes.filterItem}>
        <RangePicker
          defaultValue={[startDate, endDate]}
          ranges={{
            'This Month': [moment().startOf('month'), moment().endOf('month')],
          }}
          onChange={handleDateChange}
        />
      </Item>
    )
  }

  const columns = [
    {
      title: 'Patient',
      dataIndex: 'patient.id',
      key: 'patientId',
      render(text, reminder) {
        if (!reminder.patient) return '-'
        return (
          <Link to={toPath.patients.patient({ id: reminder.patient.id })}>
            {reminder.patient.lastName}
            {', '}
            {reminder.patient.firstName}
          </Link>
        )
      },
    },
    {
      title: 'Type',
      dataIndex: 'messageType',
      key: 'messageType',
      render(text, reminder) {
        if (text === 'sms') {
          return text.toUpperCase()
        }
        return capitalize(text)
      },
    },
    {
      title: 'To',
      dataIndex: 'to',
      key: 'phoneTo',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render(text, reminder) {
        let cssType

        switch (text) {
          case 'completed':
          case 'delivered':
            // Antd docs mention type="success" but it does not work
            // might be a bug in the version we're using.
            // Use "copy-success" instead
            cssType = 'copy-success'
            break
          case 'queued':
            cssType = 'warning'
            break
          default:
            cssType = 'info'
        }

        return <Text type={cssType}>{text}</Text>
      },
    },
    {
      title: 'Sent on',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render(text, reminder) {
        if (!reminder.createdAt) return ''
        return standardDateTime(reminder.createdAt)
      },
    },
    {
      title: 'Updated on',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render(text, reminder) {
        if (!reminder.updatedAt) return ''
        return standardDateTime(reminder.updatedAt)
      },
    },
    {
      title: 'Practice',
      dataIndex: 'patient.practice.name',
      key: 'practiceName',
    },
    {
      title: 'Error Message',
      dataIndex: 'errorMessage',
      key: 'errorMessage',
      render(text, reminder) {
        if (!reminder.errorMessage) return '-'

        return <Text type="danger">{text}</Text>
      },
    },
    {
      title: 'Error Code',
      dataIndex: 'errorCode',
      key: 'errorCode',
      render(text, reminder) {
        if (!reminder.errorCode) return '-'

        return <Text type="danger">{text}</Text>
      },
    },
  ]

  const { pageSize, total } = useRemindersPagination()
  const reminders = useReminders()

  return (
    <div>
      {error && <ListError description={error.message} onClose={clearError} />}
      <PageHeader title="Reminders" />
      <Filters
        currentValues={conditions}
        onSubmit={handleSubmit}
        extraFilter={rangeFilter()}
        showReminders
      />
      <Table
        columns={columns}
        dataSource={reminders}
        loading={pending}
        pagination={{
          current: conditions.page || 1,
          pageSize,
          onChange: handlePageChange,
          total,
        }}
        rowKey="id"
      />
    </div>
  )
}
