import React from 'react'
import Select from './Select'

export const STATUSES = [
  { id: 'all', name: 'Pairing: all' },
  { id: 'true', name: 'Paired' },
  { id: 'false', name: 'Not paired' },
]

export default function PairingStatusSelect(props) {
  return <Select {...props} options={STATUSES} />
}
