import React, { useCallback } from 'react'
import mapValues from 'lodash/mapValues'
import PropTypes from 'prop-types'
import noop from 'lodash/noop'
import { Modal, Form, message } from 'antd'
import { Formik } from 'formik'
import { formElementPropsGenerator } from '../../lib/forms'
import { Yup } from '../../lib/validations'
import DeviceModelSelect from '../Forms/DeviceModelSelect'
import { Input } from '../Forms'
import api from '../../api'

const LABELS = {
  deviceModelId: 'Model',
  serialNumber: 'Serial number',
}
const PLACEHOLDERS = {
  deviceModelId: 'Select a model',
  serialNumber: 'e.g. 123-456-789',
}

const INITIAL_VALUES = mapValues(LABELS, () => undefined)

const VALIDATION_SCHEMA = Yup.object().shape({
  deviceModelId: Yup.string().required(),
  serialNumber: Yup.string().required(),
})

const propsFor = formElementPropsGenerator({
  formName: 'device-form',
  labels: LABELS,
  placeholders: PLACEHOLDERS,
})

export default function ChangeDeviceModal({
  patientId,
  onDeviceChanged,
  visible,
}) {
  const handleSave = useCallback(
    async ({ deviceModelId, serialNumber }) => {
      try {
        await api.patients.createDevice(patientId, {
          deviceModelId,
          serialNumber,
        })
        message.success('New device created successfully.')
        onDeviceChanged(true)
      } catch (e) {
        message.error(`There was an error adding the device: ${e.message}`)
      }
    },
    [onDeviceChanged, patientId],
  )
  const handleCancel = useCallback(() => onDeviceChanged(false), [
    onDeviceChanged,
  ])
  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={handleSave}
      validationSchema={VALIDATION_SCHEMA}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Modal
          title="Change Device"
          visible={visible}
          onOk={handleSubmit}
          onCancel={handleCancel}
          okText="Save new device"
          confirmLoading={isSubmitting}
          centered
          destroyOnClose
        >
          <Form onSubmit={handleSubmit}>
            <DeviceModelSelect {...propsFor('deviceModelId')} autoFocus />
            <Input {...propsFor('serialNumber')} />
          </Form>
        </Modal>
      )}
    </Formik>
  )
}

ChangeDeviceModal.propTypes = {
  patientId: PropTypes.string.isRequired,
  onDeviceChanged: PropTypes.func,
  visible: PropTypes.bool,
}

ChangeDeviceModal.defaultProps = {
  onDeviceChanged: noop,
  visible: false,
}
