import {
  Button,
  Comment as AntDComment,
  Divider,
  Popconfirm,
  Tooltip,
} from 'antd'
import classnames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { useToggle } from '../../hooks'
import { useCurrentUserId, useIsAdmin } from '../../redux/slices/session'
import { commentShape } from '../prop-types'
import CommentForm from './CommentForm'

const useStyles = createUseStyles({
  editedTimestamp: {
    fontSize: 12,
    display: 'block',
    marginTop: 5,
  },
  container: {
    '& .ant-btn-link': {
      height: 18,
      padding: 0,
      fontSize: 11,
      color: '#bbb',
    },
    '& .ant-btn span': {
      marginLeft: 3,
    },
    '& .ant-comment-actions': {
      marginTop: 5,
    },
  },
})

export default function Comment({ comment, onUpdate, onDelete }) {
  const classes = useStyles()
  const [editMode, toggleEditMode] = useToggle(false)
  const { user: author } = comment
  const isAdmin = useIsAdmin()
  const userId = useCurrentUserId()
  const handleUpdate = useCallback(
    async (...args) => {
      await onUpdate(...args)
      toggleEditMode(false)
    },
    [onUpdate, toggleEditMode],
  )
  const actions = useMemo(() => {
    if (editMode || (!isAdmin && author.id !== userId)) return []
    return [
      <Button
        size="small"
        type="link"
        onClick={() => toggleEditMode()}
        icon="edit"
      >
        Edit
      </Button>,
      <Divider type="vertical" />,
      <Popconfirm
        title="Are you sure？"
        okText="Yes"
        cancelText="No"
        onConfirm={() => onDelete(comment.id)}
      >
        <Button size="small" type="link" icon="delete">
          Delete
        </Button>
      </Popconfirm>,
    ]
  }, [
    editMode,
    isAdmin,
    author.id,
    userId,
    toggleEditMode,
    onDelete,
    comment.id,
  ])
  return (
    <AntDComment
      className={classes.container}
      content={
        editMode ? (
          <CommentForm
            comment={comment}
            onSubmit={handleUpdate}
            onCancel={() => toggleEditMode(false)}
          />
        ) : (
          <div>
            {comment.message}
            {comment.updatedAt !== comment.createdAt && (
              <span
                className={classnames(
                  classes.editedTimestamp,
                  'ant-comment-content-author-time',
                )}
              >
                {`(Edited ${moment(comment.updatedAt).calendar()})`}
              </span>
            )}
          </div>
        )
      }
      datetime={
        <Tooltip title={moment(comment.createdAt).format('LLLL')}>
          {moment(comment.createdAt).calendar()}
        </Tooltip>
      }
      author={
        <Tooltip title={author.email}>
          {author.firstName} {author.lastName}
        </Tooltip>
      }
      actions={actions}
    />
  )
}

Comment.propTypes = {
  comment: commentShape.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}
