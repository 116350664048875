import { useDispatch } from 'react-redux'
import { useCallback } from 'react'

export function getPaginationHeaders(headers) {
  return {
    page: Number(headers.get('X-Page')),
    pageSize: Number(headers.get('X-Per-Page')),
    total: Number(headers.get('X-Total')),
  }
}

export function useAsyncAction(action, ...initialArgs) {
  const dispatch = useDispatch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    (...args) => {
      const finalArgs = args.length ? args : initialArgs
      return dispatch(action(...finalArgs))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [action, dispatch, ...initialArgs],
  )
}
