/* eslint-disable react/jsx-props-no-spreading */
import { Form, Row } from 'antd'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import * as Yup from 'yup'
import { formElementPropsGenerator } from '../../lib/forms'
import { DatePicker, Input, SubmitButton } from '../Forms'
import Switch from '../Forms/Switch'
import PatientSelect from '../Forms/PatientSelect'
import { useIsAdmin } from '../../redux/slices/session'
import MedicalProviderSelect from '../Forms/MedicalProviderSelect'

const PRE_AUTHORIZATION_LABELS = {
  cptCode: 'CPT Code',
  icd10Code: 'ICD-10 Code',
  patientId: 'Patient',
  billingDate: 'Billing Date',
  authorized: 'Authorized',
  medicalProviderId: 'Medical Provider',
}

const propsFor = formElementPropsGenerator({
  formName: 'pre-authorization-form',
  labels: PRE_AUTHORIZATION_LABELS,
})

const labelCol = {
  sm: { span: 4, offset: 2 },
}

const wrapperCol = {
  sm: { span: 16 },
}

const actionsWrapperCol = {
  sm: { span: 4, offset: labelCol.sm.span + labelCol.sm.offset },
}

function buildValidationSchema(isNew = false, isAdmin = false) {
  const shape = {
    authorized: Yup.bool().required(),
    medicalProviderId: Yup.string().nullable(),
  }

  if (isNew) {
    shape.patientId = Yup.string().required()
  }

  if (isAdmin) {
    shape.cptCode = Yup.string().required()
    shape.icd10Code = Yup.string().required()
    shape.billingDate = Yup.date().required()
  }

  return Yup.object().shape(shape)
}

export default function PreAuthorizationForm({
  practiceId,
  preAuthorization,
  onSubmit,
}) {
  const isAdmin = useIsAdmin()
  const isNew = !preAuthorization.id
  const validationSchema = useMemo(
    () => buildValidationSchema(isNew, isAdmin),
    [isAdmin, isNew],
  )
  const initialPracticeId = practiceId || preAuthorization.patient.practiceId
  const initialValues = { ...preAuthorization }
  const selectLabelCol = { sm: 3, offset: 3 }
  const selectWrapperCol = { sm: 12, offset: 0 }
  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnMount={isNew}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, submitCount }) => (
          <Form
            onSubmit={handleSubmit}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
          >
            {isNew && (
              <PatientSelect
                {...propsFor('patientId')}
                practiceId={practiceId}
              />
            )}
            {isAdmin && (
              <>
                <Input {...propsFor('cptCode')} />
                <Input {...propsFor('icd10Code')} />
                <DatePicker {...propsFor('billingDate')} allowClear={false} />
              </>
            )}
            <Switch
              {...propsFor('authorized')}
              checkedChildren="Authorized"
              unCheckedChildren="Not&nbsp;authorized"
            />

            <MedicalProviderSelect
              {...propsFor('medicalProviderId')}
              practiceId={initialPracticeId}
              displayNPI
              labelCol={selectLabelCol}
              wrapperCol={selectWrapperCol}
            />

            <Row>
              <SubmitButton
                isSubmitting={isSubmitting}
                title={
                  isNew
                    ? 'Create Remote Authorization'
                    : 'Save Remote Authorization'
                }
                isValid
                submitCount={submitCount}
                wrapperCol={actionsWrapperCol}
              />
            </Row>
          </Form>
        )}
      </Formik>
    </div>
  )
}

PreAuthorizationForm.propTypes = {
  practiceId: PropTypes.string,
  preAuthorization: PropTypes.shape({
    comment: PropTypes.string,
    id: PropTypes.string,
    patient: PropTypes.shape({
      practiceId: PropTypes.string,
    }),
  }),
  onSubmit: PropTypes.func.isRequired,
  patient: PropTypes.shape({
    practiceId: PropTypes.string,
  }),
}

PreAuthorizationForm.defaultProps = {
  practiceId: undefined,
  preAuthorization: {
    authorized: false,
    patient: {
      practiceId: undefined,
    },
  },
  patient: {
    practiceId: undefined,
  },
}
