import React, { useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Button, Checkbox } from 'antd'
import { useIsAdmin } from '../../redux/slices/session'
import { ListError, PageHeader } from '../Common'
import CyclesFilter from '../Common/CyclesFilter'
import usePracticeCycles from '../Common/usePracticeCycles'
import useFilters from '../Cycles/useFilters'
import AddNewItemButton from '../Common/AddNewItemButton'
import { toPath } from '../../routes'
import Filters, { FIELDS_OPTIONS } from '../Forms/Filters'
import { usePreAuthorizations } from '../../redux/slices/pre-authorizations'
import RemindersModal from '../Common/RemindersModal'
import PreAuthorizations from './PreAuthorizations'
import GenerateBillingOrdersButton from './GenerateBillingOrdersButton'
import BatchDownloadButton from './BatchDownloadButton'

const DEFAULT_FILTERS = {
  query: '',
  noFullReports: false,
  authorized: FIELDS_OPTIONS.authorized[0].id,
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    '& > form': {
      flex: '1',
      minWidth: '135px',
    },
  },
  filterContainer: {
    marginBottom: '25px',
  },
})

export default function PracticePreAuthorizations() {
  const isAdmin = useIsAdmin()
  const classes = useStyles()
  const [
    { error, pendingCycles, clearError },
    { practice, pending, practiceId },
  ] = usePracticeCycles()
  const {
    handleFilterChange,
    handlePageChange,
    conditions,
    setConditions,
  } = useFilters(DEFAULT_FILTERS)
  const preAuthFilters = useMemo(() => {
    return {
      q: conditions.query,
      noFullReports: conditions.noFullReports,
      authorized: conditions.authorized,
    }
  }, [conditions])
  const { cycleId, page } = conditions

  const preAuthorizations = usePreAuthorizations(cycleId)
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedPatients, setSelectedPatients] = useState([])
  const hasSelected = selectedRowKeys.length > 0

  const onSelectChange = rowsIdSelection => {
    setSelectedRowKeys(rowsIdSelection)

    const preAuths = preAuthorizations.filter(pa =>
      rowsIdSelection.includes(pa.id),
    )

    setSelectedPatients(preAuths.map(pa => pa.patient.id))
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const [remindersOptionsVisible, setRemindersOptionsVisible] = useState(false)
  const hideRemindersOptions = useCallback(
    () => setRemindersOptionsVisible(false),
    [],
  )

  const showRemindersOptions = useCallback(() => {
    setRemindersOptionsVisible(true)
  }, [])

  const showNoFullReports = e => {
    setConditions({ ...conditions, noFullReports: e.target.checked })
  }

  if (cycleId && !pending && !pendingCycles) {
    return (
      <div>
        <PageHeader
          title="Remote Authorizations"
          subTitle={isAdmin && practice.name}
          extra={
            isAdmin && [
              <BatchDownloadButton key="download" cycleId={cycleId} />,
              <GenerateBillingOrdersButton key="1" cycleId={cycleId} />,
              <AddNewItemButton
                key="2"
                itemName="remote authorization"
                path={toPath.cycles.cycle.preAuthorizations.new({
                  id: cycleId,
                })}
                icon="plus-circle"
              />,
            ]
          }
        />
        <div className={classes.container}>
          <CyclesFilter
            practiceId={practiceId}
            onSubmit={handleFilterChange}
            currentCycleId={cycleId}
          />
          <Filters
            defaultValues={DEFAULT_FILTERS}
            currentValues={conditions}
            placeholders={{
              query: 'Search by name or device serial number',
              authorized: 'Authorized status',
            }}
            onSubmit={({ query, authorized }) =>
              setConditions({ cycleId, query, authorized, page: 1 })
            }
            showSearch
            showAuthorizedStatus
          />
          <div className={classes.filterContainer}>
            <Checkbox
              onChange={showNoFullReports}
              value={conditions.noFullReports}
            >
              Without full reports
            </Checkbox>
          </div>
          <div className={classes.filterContainer}>
            <Button
              icon="phone"
              disabled={!hasSelected}
              onClick={showRemindersOptions}
            >
              Send reminder
            </Button>
          </div>
        </div>
        <PreAuthorizations
          cycleId={cycleId}
          onPageChange={handlePageChange}
          page={page}
          filters={preAuthFilters}
          rowSelection={rowSelection}
        />
        <RemindersModal
          patientIds={selectedPatients}
          visible={remindersOptionsVisible}
          onCancel={hideRemindersOptions}
        />
      </div>
    )
  }

  return (
    <div>
      {error && <ListError description={error.message} onClose={clearError} />}
    </div>
  )
}
