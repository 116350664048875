import { message } from 'antd'
import set from 'lodash/set'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import api from '../../api'
import { useIsAdmin } from '../../redux/slices/session'
import { toPath } from '../../routes'
import { PageHeader } from '../Common'
import Form from './Form'

export default function New() {
  const history = useHistory()
  const isAdmin = useIsAdmin()
  const onSubmit = useCallback(
    async (
      {
        practiceId,
        firstName,
        lastName,
        birthDate,
        email,
        mobilePhone,
        homePhone,
        addressStreet,
        addressApt,
        addressCity,
        addressState,
        addressCountry,
        addressZipCode,
        atrialFibrilationFlutterDisease,
        anticoagulatedDisease,
        enrolled,
        paired,
        icdCodeIds,
        deviceIcdCodeId,
        homeMonitorSerialNumber,
        currentDeviceAttributes,
        insuranceProviderId,
        insurancePolicyNumber,
        provider,
        pcpName,
        pcpPhoneNumber,
        pcpFaxNumber,
        remoteMonitoring,
        practiceMrn,
        diseaseIds,
        medicalProviderId,
      },
      { setSubmitting, setErrors },
    ) => {
      try {
        const patient = await api.patients.create({
          practiceId,
          firstName,
          lastName,
          birthDate: birthDate && birthDate.toISOString(),
          email,
          mobilePhone,
          homePhone,
          addressStreet,
          addressApt,
          addressCity,
          addressState,
          addressCountry,
          addressZipCode,
          atrialFibrilationFlutterDisease,
          anticoagulatedDisease,
          enrolled,
          paired,
          icdCodeIds,
          deviceIcdCodeId,
          homeMonitorSerialNumber,
          currentDeviceAttributes,
          insuranceProviderId,
          insurancePolicyNumber,
          provider,
          pcpName,
          pcpPhoneNumber,
          pcpFaxNumber,
          remoteMonitoring,
          practiceMrn,
          diseaseIds,
          medicalProviderId: medicalProviderId || null,
          ...(isAdmin && { approved: true }),
        })
        message.success('Patient successfully created')
        history.replace(toPath.patients.patient({ id: patient.id }))
      } catch (e) {
        if (e.response && e.response.errors) {
          const { errors } = e.response
          Object.keys(errors).forEach(key => {
            if (key.startsWith('currentDevice.')) {
              set(
                errors,
                key.replace('currentDevice.', 'currentDeviceAttributes.'),
                errors[key][0],
              )
              delete errors[key]
            }
          })
          setErrors(errors)
        } else {
          message.error('Oops, we had a problem. Please try submitting again')
        }
      }
    },
    [history, isAdmin],
  )

  return (
    <div>
      <PageHeader title="New Patient" onBack={history.goBack} />
      <Form onSubmit={onSubmit} />
    </div>
  )
}
