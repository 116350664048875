import queryString from 'query-string'
import apiClient from './api-client'

function urlFor(collectionName, id, commentId) {
  const base = `${collectionName}/${id}/comments`
  return commentId ? `${base}/${commentId}` : base
}

function list(collectionName, id, page) {
  return apiClient.get(
    queryString.stringifyUrl({
      url: urlFor(collectionName, id),
      query: { page },
    }),
    { withHeaders: true },
  )
}

function create(collectionName, id, comment) {
  return apiClient.post(urlFor(collectionName, id), { comment })
}

function update(collectionName, id, comment) {
  return apiClient.patch(urlFor(collectionName, id, comment.id), { comment })
}

function destroy(collectionName, id, commentId) {
  return apiClient.destroy(urlFor(collectionName, id, commentId))
}

export default {
  create,
  destroy,
  list,
  update,
}
