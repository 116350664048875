import React from 'react'
import { createUseStyles } from 'react-jss'
import { PageHeader as AntDPageHeader } from 'antd'

const useStyles = createUseStyles({
  pageHeader: {
    border: '1px solid rgb(235, 237, 240)',
    marginBottom: '23px',
    '& .ant-page-header-heading-title': {
      fontSize: 25,
    },
  },
})

/**
 * For information about the props for this component head over to ant design
 *
 * https://ant.design/components/page-header/
 */
export default function PageHeader(props) {
  const classes = useStyles()

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <AntDPageHeader {...props} className={classes.pageHeader} />
}
