import { Form } from 'antd'
import { Formik } from 'formik'
import mapValues from 'lodash/mapValues'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { formElementPropsGenerator } from '../../lib/forms'
import { Yup } from '../../lib/validations'
import { Input, UploadFormikFile, SubmitButton } from '../Forms'
import TextArea from '../Forms/TextArea'
import Switch from '../Forms/Switch'
import Item from '../Forms/Item'
import { practiceShape } from '../prop-types'
import AccountManagerSelect from '../Forms/AccountManagerSelect'
import AlertsRecipients from './AlertsRecipients'

const MAX_SIZE_LOGO = 300 * 1024

const PRACTICES_LABELS = {
  name: 'Name',
  address: 'Address',
  phoneNumber: 'Phone Number',
  faxNumber: 'Fax Number',
  active: 'Status',
  logo: 'Logo',
  ownerEmails: 'Default alerts recipients',
  bpAlertRecipients: 'Blood Pressure recipients',
  accountManagerId: 'Account Manager',
}

export const NON_FILE_PRACTICE_FIELDS = Object.keys(PRACTICES_LABELS).filter(
  field => field !== 'logo',
)

const PRACTICES_PLACEHOLDERS = {
  name: 'Doe Healthcare',
  address: 'Address',
  phoneNumber: 'Phone number',
  faxNumber: 'Fax number',
  ownerEmails: 'owner@gobiobridge.com',
  bpAlertRecipients: 'alert@gobiobridge.com',
  accountManagerId: 'Account Manager',
}

const DEFAULT_VALUES = mapValues(PRACTICES_LABELS, '')
DEFAULT_VALUES.active = true

const labelCol = {
  sm: { span: 4, offset: 2 },
}

const wrapperCol = {
  sm: { span: 16 },
}

const actionsWrapperCol = {
  sm: { span: 4, offset: labelCol.sm.span + labelCol.sm.offset },
}

const propsFor = formElementPropsGenerator({
  formName: 'practice-form',
  labels: PRACTICES_LABELS,
  placeholders: PRACTICES_PLACEHOLDERS,
})

const validationSchema = Yup.object().shape({
  name: Yup.string().required(),
  address: Yup.string().required(),
  phoneNumber: Yup.string().required(),
  ownerEmails: Yup.string(),
  bpAlertRecipients: Yup.string(),
  accountManagerId: Yup.string().nullable(),
  logo: Yup.mixed()
    .required('You need to provide a file')
    .test('fileSize', 'The file is too large', value => {
      if (typeof value === 'string') return true

      return value && value[0].size <= MAX_SIZE_LOGO
    }),
})

export default function PracticeForm({ practiceData, onSubmit }) {
  const history = useHistory()
  const browseProviders = useCallback(() => {
    history.replace(`/practices/${practiceData.id}/providers`)
  }, [history, practiceData])
  return (
    <div>
      <Formik
        initialValues={practiceData || DEFAULT_VALUES}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, handleSubmit, isValid, submitCount }) => (
          <Form
            onSubmit={handleSubmit}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
          >
            <Input {...propsFor('name')} />
            <Input {...propsFor('address')} />
            <Input {...propsFor('phoneNumber')} />
            <Input {...propsFor('faxNumber')} />
            {practiceData?.medicalProviders && (
              <Item label="Alerts Recipients">
                <AlertsRecipients
                  providers={practiceData?.medicalProviders || [{}]}
                  onManage={browseProviders}
                />
              </Item>
            )}
            <TextArea {...propsFor('ownerEmails')} />
            <TextArea {...propsFor('bpAlertRecipients')} />
            <UploadFormikFile
              name="logo"
              label={PRACTICES_LABELS.logo}
              supportFiles={['image/*']}
            />
            <Switch {...propsFor('active')} />

            <AccountManagerSelect
              {...propsFor('accountManagerId')}
              label={PRACTICES_LABELS.accountManagerId}
              allowClear
              labelCol={labelCol}
              wrapperCol={wrapperCol}
              labelAlign="right"
            />

            <SubmitButton
              isSubmitting={isSubmitting}
              isValid={isValid}
              title={practiceData ? 'Save Practice' : 'Create Practice'}
              submitCount={submitCount}
              wrapperCol={actionsWrapperCol}
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}

PracticeForm.propTypes = {
  practiceData: practiceShape,
  onSubmit: PropTypes.func.isRequired,
}

PracticeForm.defaultProps = {
  practiceData: undefined,
}
