import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Progress, message } from 'antd'
import { useDispatch } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { addProgress, finish } from '../../redux/slices/downloads'
import { formatDateTime } from '../../lib/datetime'
import { useCycle } from '../../redux/slices/practices'
import useInterval from '../../hooks/useInterval'
import api from '../../api'
import useQueryParams from '../../hooks/useQueryParams'
import { downloadZip } from '../../lib/download'
import sleep from '../../lib/sleep'

const REFETCHING_TIME = 5000

function xhrAsync(url, handleProgress) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    xhr.open('GET', url)

    xhr.onload = () => {
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(xhr.response)
      } else {
        reject(new Error(`${xhr.statusText} - ${xhr.status}`))
      }
    }

    xhr.addEventListener('progress', handleProgress)

    xhr.onerror = () => reject(new Error(`${xhr.statusText} - ${xhr.status}`))
    xhr.send()
  })
}

const useStyles = createUseStyles(theme => ({
  label: {
    lineHeight: '0.75',
    color: 'white',
    fontSize: '13px',
  },
  container: {
    marginTop: '12px',
    '&:first-child': {
      marginTop: 0,
    },
  },
}))

const getZipFile = async (downloadId, filters = {}) => {
  const { downloadUrl, filename, error } = await api.downloads.zipFile(
    downloadId,
    filters,
  )

  if (error) {
    throw new Error('Error building zip file')
  }

  if (downloadUrl) return { downloadUrl, filename }

  await sleep(5000) // wait 5 seconds to make the request again

  return getZipFile(downloadId, filters)
}

const downloadStatusMap = {
  finished: 'success',
  loading: 'active',
  error: 'exception',
}

function Download({ downloadId, progress }) {
  const dispatch = useDispatch()
  const [buildingZip, setBuildingZip] = useState('loading')
  const [downloadStatus, setDownloadStatus] = useState('idle')
  const classes = useStyles()
  const cycle = useCycle(downloadId)
  const cycleName = formatDateTime(cycle.endsAt)
  const [queryParams] = useQueryParams({})

  const getFileProgressZip = async () => {
    try {
      const { totalFiles, addedFiles, finishedAt } = await api.downloads.get(
        downloadId,
        queryParams,
      )
      const zipProgress = parseInt((addedFiles * 50) / totalFiles, 0)
      dispatch(addProgress(downloadId, zipProgress))

      if (finishedAt && zipProgress >= 50) {
        setBuildingZip('finished')
      }
    } catch (err) {
      setBuildingZip('error')
      dispatch(finish(downloadId))
      message.error(`Something went wrong: ${err.message}`)
    }
  }

  const processDownload = useCallback(async () => {
    const { downloadUrl, filename } = await getZipFile(downloadId, queryParams)

    if (!downloadUrl) {
      throw new Error('Zip url is not present!')
    }

    const response = await xhrAsync(downloadUrl, event => {
      const downloadProgress = parseInt((event.loaded * 50) / event.total, 0)
      dispatch(addProgress(downloadId, 50 + downloadProgress))
    })
    await downloadZip(response, filename)
  }, [dispatch, downloadId, queryParams])

  const downloadReportsZip = useCallback(async () => {
    try {
      setDownloadStatus('loading')
      await processDownload()
      setDownloadStatus('finished')

      setTimeout(() => {
        dispatch(finish(downloadId))
      }, 3000)
    } catch (e) {
      message.error(`There was a problem downloading the reports: ${e.message}`)
      setDownloadStatus('error')
      dispatch(finish(downloadId))
    }
  }, [dispatch, downloadId, processDownload])

  useInterval(
    getFileProgressZip,
    buildingZip === 'loading' ? REFETCHING_TIME : null,
  )

  React.useEffect(() => {
    if (buildingZip === 'finished') {
      downloadReportsZip()
    }
  }, [downloadReportsZip, buildingZip])

  return (
    <div className={classes.container}>
      <div className={classes.label}>
        {buildingZip === 'loading' && (
          <>Generating zip file for cycle {cycleName}</>
        )}
        {downloadStatus === 'loading' && 'Downloading the zip file'}
        {downloadStatus === 'finished' && 'The download has been completed'}
        {downloadStatus === 'error' &&
          'There was an error downloading the zip file'}
      </div>
      <Progress percent={progress} status={downloadStatusMap[downloadStatus]} />
    </div>
  )
}

Download.propTypes = {
  downloadId: PropTypes.string,
  progress: PropTypes.number,
}

Download.defaultProps = {
  downloadId: undefined,
  progress: 0,
}

export default Download
