import { Card, message, Typography, Button, Divider } from 'antd'
import React, { useCallback, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useAsync } from '../../hooks'
import { useFetchProfile, useProfile } from '../../redux/slices/profile'
import { useIsAdmin } from '../../redux/slices/session'
import { ListError } from '../Common'
import api from '../../api'

const { Text } = Typography
const { Meta } = Card

const useStyles = createUseStyles({
  card: {
    height: '300px',
    padding: '10px 30px',
    marginBottom: '10px',
  },
})

export default function Profile() {
  const { firstName, lastName, email, practice } = useProfile()
  const isAdmin = useIsAdmin()
  const { pending: loading, error, clearError } = useAsync(useFetchProfile())
  const classes = useStyles()
  const [success, setSuccess] = useState(false)

  const startPasswordReset = useCallback(async () => {
    try {
      const response = await api.users.startPasswordChange(email)

      setSuccess(response.success)

      message.success(`Password reset email sent to ${email}`)
    } catch (e) {
      message.error(`Error: ${e.message}`)
    }
  }, [setSuccess, email])

  return (
    <div>
      {error && <ListError description={error.message} onClose={clearError} />}
      <Card
        className={classes.card}
        loading={loading}
        title={`${firstName} ${lastName}`}
      >
        <Meta
          title={isAdmin ? 'Administrator' : practice && practice.name}
          description={email}
        />
      </Card>

      <Card loading={loading} title="Change Password">
        <Text type="primary">
          By clicking the button below, you&apos;re initiating the password
          reset process. You will receive an email in the email address on your
          profile with a link to the reset password page.
        </Text>
        <br />
        <Text type="primary">
          Your password won&apos;t be changed until complete the process from
          the email you will receive.
        </Text>
        <p />
        <Button type="primary" onClick={startPasswordReset}>
          Reset my password
        </Button>
        {success && (
          <>
            <Divider />
            <Text type="success">
              We&apos;ve sent you an email to start your password reset. Please
              complete the process from there.
            </Text>
          </>
        )}
      </Card>
    </div>
  )
}
