import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'
import LinkButton from './LinkButton'

const useStyles = createUseStyles({
  container: {
    textAlign: 'right',
  },
})

export default function AddNewItemButton({ itemName, path, icon }) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <LinkButton text={`Add new ${itemName}`} to={path} icon={icon} />
    </div>
  )
}

AddNewItemButton.propTypes = {
  itemName: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
}
