import PropTypes from 'prop-types'
import { Button, message } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { initDownload } from '../../redux/slices/office-check-downloads'
import api from '../../api'

export default function BatchDownloadButton({ practiceId, checkDate }) {
  const dispatch = useDispatch()
  const downloadBatch = async () => {
    try {
      await api.officeChecks.createBatch(practiceId, checkDate)
      dispatch(initDownload(practiceId, checkDate))
    } catch (err) {
      message.error(`Something went wrong: ${err.message}`)
    }
  }

  return (
    <Button icon="download" onClick={downloadBatch}>
      Download All Reports
    </Button>
  )
}

BatchDownloadButton.propTypes = {
  practiceId: PropTypes.string.isRequired,
  checkDate: PropTypes.string.isRequired,
}
