import PropTypes from 'prop-types'
import { Button, message } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { initDownload } from '../../redux/slices/downloads'
import useQueryParams from '../../hooks/useQueryParams'
import api from '../../api'

export default function BatchDownloadButton({ cycleId }) {
  const dispatch = useDispatch()
  const [queryParams] = useQueryParams({})
  const { page, perPage, cycleId: cycle, ...searchParams } = queryParams

  const downloadBatch = async () => {
    try {
      await api.downloads.create(cycleId, searchParams)

      dispatch(initDownload(cycleId))
    } catch (err) {
      message.error(`Something went wrong: ${err.message}`)
    }
  }

  const getButtonTitle = () => {
    const { ...filters } = searchParams

    // FIXME: These query params shouldn't be added if they have the default value
    if (filters.query === '') delete filters.query
    if (filters.signed === 'all') delete filters.signed

    if (Object.keys(filters).length > 0) {
      return 'Download Filtered Reports'
    }

    return 'Download All Reports'
  }

  return (
    <Button icon="download" onClick={downloadBatch}>
      {getButtonTitle()}
    </Button>
  )
}

BatchDownloadButton.propTypes = {
  cycleId: PropTypes.string.isRequired,
}
