import { buildRoutes, buildToPath } from '@negebauer/routes-helper'

const routes = {
  alerts: {
    alert: {
      __pathName: ':id',
      edit: '',
    },
    new: '',
    redirectToMergedFile: ':alertId/redirect-to-merged-file',
  },
  authenticated: '',
  billing: {
    officeAuthorizations: {
      __pathName: 'office-authorizations',
      officeAuthorization: {
        __pathName: ':id',
        edit: '',
      },
    },
    officeBillingOrders: {
      __pathName: 'office-billing-orders',
      officeBillingOrder: {
        __pathName: ':id',
      },
    },
    remoteBillingOrders: {
      __pathName: 'remote-billing-orders',
      remoteBillingOrder: {
        __pathName: ':id',
      },
    },
  },
  cycles: {
    cycle: {
      __pathName: ':id',
      missedTransmissions: {
        __pathName: 'missed-transmissions',
        new: '',
      },
      preAuthorizations: {
        __pathName: 'pre-authorizations',
        new: '',
      },
      reports: {
        upload: '',
      },
    },
  },
  deviceModels: {
    __pathName: 'device-models',
    deviceModel: {
      __pathName: ':id',
      edit: '',
    },
    new: '',
  },
  deviceTypes: {
    __pathName: 'device-types',
    deviceType: {
      __pathName: ':id',
      edit: '',
    },
  },
  error404: { __pathName: '404' },
  healthProviders: ':practiceId/providers',
  home: { __pathName: '' },
  login: '',
  logout: '',
  loggedOut: {
    __pathName: 'logged-out',
  },
  missedTransmissions: {
    __pathName: 'missed-transmissions',
    missedTransmission: {
      __pathName: ':id',
      edit: '',
    },
  },
  officeChecks: {
    __pathName: 'office-checks',
    new: '',
    officeCheck: {
      __pathName: ':id',
      edit: '',
    },
  },
  password: {
    reset: {
      token: {
        __pathName: ':token',
      },
    },
  },
  patients: {
    new: '',
    patient: {
      __pathName: ':id',
      edit: '',
    },
  },
  pendingReports: {
    __pathName: 'pending-reports',
  },
  practices: {
    billingOrders: ':practiceId/billing-orders',
    cycles: ':practiceId/cycles',
    healthProviders: ':practiceId/providers',
    missedTransmissions: ':practiceId/missed-transmissions',
    new: '',
    officeAuthorizations: {
      __pathName: ':practiceId/office-authorizations',
      new: '',
    },
    officeBillingOrders: ':practiceId/office-billing-orders',
    officeChecks: {
      __pathName: ':practiceId/office-checks',
      new: '',
      officeCheck: {
        __pathName: ':id',
        edit: '',
      },
    },
    preAuthorizations: ':practiceId/pre-authorizations',
    practice: {
      __pathName: ':id',
      edit: '',
    },
    reports: ':practiceId/reports',
  },
  preAuthorizations: {
    __pathName: 'pre-authorizations',
    preAuthorization: {
      __pathName: ':id',
      edit: '',
    },
  },
  profile: '',
  reminders: '',
  reports: {
    report: {
      __pathName: ':id',
      edit: '',
    },
  },
  reviewedSummary: {
    __pathName: 'reviewed-summary',
  },
  resources: '',
  teams: '',
  unreviewedReports: {
    __pathName: 'unreviewed-reports',
  },
  users: {
    new: '',
    user: {
      __pathName: ':id',
      edit: '',
    },
  },
  welcomeLetters: {
    __pathName: 'welcome-letters',
    phoneNumbers: {
      __pathName: 'phone-numbers',
      new: '',
      phoneNumber: {
        __pathName: ':id',
        edit: '',
      },
    },
  },
}

export const toPath = buildToPath(buildRoutes(routes))

export default buildRoutes(routes)
