import React, { useCallback } from 'react'
import { Descriptions, Form, Icon, List, Typography, Tag, Row, Col } from 'antd'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { createUseStyles } from 'react-jss'
import { useHistory, useParams } from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import partition from 'lodash/partition'
import { cycleShape } from '../prop-types'
import api from '../../api'
import { useAsync } from '../../hooks'
import { formElementPropsGenerator } from '../../lib/forms'
import { dropZone as dropZoneStyles } from '../../theme/shared-styles'
import { PageHeader } from '../Common'
import { SubmitButton } from '../Forms'
import PatientSelect from '../Forms/PatientSelect'
import Loading from '../Loading'
import ReportTag, { reportTypeColor } from './ReportTag'
import useUpload from './useUpload'

const REPORT_STATUSES = {
  success: 'success',
  failed: 'failed',
}

const REPORT_TITLES = {
  success: 'Successful reports',
  failed: 'Failed reports',
}

const useStyles = createUseStyles(theme => ({
  ...dropZoneStyles,
  form: {
    '& .ant-form-item-label': {
      textOverflow: 'ellipsis',
    },
  },
  listReport: {
    '&.ant-list-item': {
      border: 'none',
    },
  },
  patient: {
    color: theme.grayPrimary,
    textTransform: 'uppercase',
    fontSize: '12px',
  },
  errorMessage: {
    color: theme.brandDanger,
    marginLeft: '4px',
  },
  errorIcon: {
    color: theme.brandDanger,
    marginRight: '8px',
  },
}))

const labelCol = {
  sm: { span: 11 },
}

const wrapperCol = {
  sm: { span: 12 },
}

const propsFor = formElementPropsGenerator({
  formName: 'upload-check-form',
})

function ListReport({ reports, status }) {
  const classes = useStyles()

  if (reports.length === 0) return null

  return (
    <Col md={{ span: 24 }} lg={{ span: 12 }}>
      <Typography.Title level={4} type="secondary">
        {status === REPORT_STATUSES.success ? (
          <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />
        ) : (
          <Icon type="close-circle" className={classes.errorIcon} />
        )}{' '}
        {REPORT_TITLES[status]}
      </Typography.Title>
      <List
        itemLayout="horizontal"
        dataSource={sortBy(reports, item => item.patient.id)}
        renderItem={item => {
          const { patient, message } = item
          const fullName = `${patient.firstName} ${patient.lastName}`

          return (
            <List.Item className={classes.listReport}>
              <List.Item.Meta
                avatar={
                  <div style={{ width: '60px' }}>
                    <Tag color={reportTypeColor[item.reportType]}>
                      {item.reportType}
                    </Tag>
                  </div>
                }
                title={<Typography.Text>{item.filename}</Typography.Text>}
                description={
                  <>
                    <Typography.Text className={classes.patient} strong>
                      {fullName}
                    </Typography.Text>
                    {message && (
                      <span className={classes.errorMessage}>({message})</span>
                    )}
                  </>
                }
              />
            </List.Item>
          )
        }}
      />
    </Col>
  )
}

ListReport.propTypes = {
  reports: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  status: PropTypes.oneOf(Object.keys(REPORT_STATUSES)).isRequired,
}

function UploadContent({ cycle }) {
  const classes = useStyles()
  const {
    file,
    getRootProps,
    getInputProps,
    isDragActive,
    checkingFilenames,
    unmatchedReports,
    matchedReports,
    initialValues,
    submitFileForm,
    submittingFile,
    result,
  } = useUpload(cycle.id, cycle && cycle.practiceId)

  if (result) {
    const [failedReports, successfulReports] = partition(
      result,
      item => item.error,
    )
    return (
      <div style={{ marginTop: '1em' }}>
        <Row gutter={[60, 32]}>
          <ListReport
            reports={successfulReports}
            status={REPORT_STATUSES.success}
          />
          <ListReport reports={failedReports} status={REPORT_STATUSES.failed} />
        </Row>
      </div>
    )
  }

  if (!file) {
    return (
      <>
        <Typography.Paragraph>
          Please drop or select either <strong>a single ZIP file</strong>{' '}
          containing all the reports you want to upload for this practice&apos;s
          cycle, or <strong>a single PDF or Word file</strong> with just one of
          the reports you want to upload.
        </Typography.Paragraph>
        <Typography.Paragraph type="secondary">
          <ul>
            <li>
              We will try to automatically match each report&apos;s filename
              with a patient&apos;s <b>serial number device</b> and then ask you
              to fill in only the ones we couldn&apos;t match.
            </li>
            <li>
              For the automatic matching to work, the name of each report file
              inside the ZIP file must include the patient&apos;s{' '}
              <strong>serial number device</strong>.
            </li>
            <li>
              We will try to match which type of report the filename corresponds
              to (report, editable or full). In order to do that, both reports
              (regular and editable) must end with <strong>BB report</strong>{' '}
              just before the extension.
            </li>
            <li>
              If the filename includes &apos;ICM&apos; then we will try to match
              it with a 30-cycle preauthorization. If not, then to a 90-cycle
              preauthorization.
            </li>
          </ul>
        </Typography.Paragraph>
        <Typography.Paragraph type="secondary">
          For example:
          <ul>
            <li>
              Report:{' '}
              <strong>
                Doe John 11-25-1980 ICM 03-2021 PFZ243040H BB report.pdf
              </strong>
            </li>
            <li>
              Editable report:{' '}
              <strong>
                Doe John 11-25-1980 03-2021 PFZ243040H BB report.docx
              </strong>
            </li>
            <li>
              Full report:{' '}
              <strong>
                Doe_John_19500413_NA_20210322022504_CPR_155854752_PFZ243040H.pdf
              </strong>
            </li>
          </ul>
        </Typography.Paragraph>
        <div
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...getRootProps({
            className: classnames(
              classes.dropZone,
              isDragActive && classes.activeDropZone,
            ),
          })}
        >
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <input {...getInputProps()} />
          {isDragActive ? (
            <>
              <p>Drop the file here ...</p>
              <Icon type="file-add" theme="filled" />
            </>
          ) : (
            <>
              <p>
                Drag and drop a ZIP, PDF or Word file here, or click to select
                the files from your file browser
              </p>
              <Icon type="file-zip" />
            </>
          )}
        </div>
      </>
    )
  }

  return (
    <div>
      {checkingFilenames && <div>Checking reports...</div>}
      {initialValues && (
        <Formik
          initialValues={initialValues}
          onSubmit={submitFileForm}
          enableReinitialize
        >
          {({ handleSubmit, isValid, submitCount }) => (
            <Form
              onSubmit={handleSubmit}
              layout="horizontal"
              labelCol={labelCol}
              wrapperCol={wrapperCol}
              className={classes.form}
            >
              <Typography.Paragraph>
                Check the matches we did in case something seems off.{' '}
                {Boolean(unmatchedReports.length) &&
                  "Fill out the ones we couldn't find a match for if you want to include those reports. "}
                When ready, submit this upload and we&apos;ll process all the
                reports.
              </Typography.Paragraph>
              {Boolean(unmatchedReports.length) && (
                <>
                  <Typography.Title level={4}>
                    Unmatched reports
                  </Typography.Title>
                  {unmatchedReports.map(check => (
                    <PatientSelect
                      practiceId={cycle.practiceId}
                      key={check.inputName}
                      {...propsFor(check.inputName)}
                      label={<ReportTag reportCheck={check} />}
                      allowClear
                    />
                  ))}
                </>
              )}
              <Typography.Title level={4}>Matched reports</Typography.Title>
              {!matchedReports.length && <p>No report was matched :(</p>}
              <Descriptions column={1}>
                {matchedReports.map(check => (
                  <Descriptions.Item
                    label={<ReportTag reportCheck={check} />}
                    key={check.filename}
                  >
                    {check.patient.firstName} {check.patient.lastName} -{' '}
                    {check.patient.birthDate}
                  </Descriptions.Item>
                ))}
              </Descriptions>
              <SubmitButton
                isSubmitting={submittingFile}
                isValid={isValid}
                submitCount={submitCount}
                title="Upload Reports"
              />
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
}

UploadContent.propTypes = {
  cycle: cycleShape.isRequired,
}

export default function Upload() {
  const history = useHistory()
  const { id: cycleId } = useParams()
  const fetchCycle = useCallback(() => api.cycles.get(cycleId), [cycleId])
  const { value: cycle, pending: pendingCycle } = useAsync(fetchCycle)
  const fetchPractice = useCallback(
    async () => cycle && api.practices.getPractice(cycle.practiceId),
    [cycle],
  )
  const { value: practice, pending: pendingPractice } = useAsync(fetchPractice)

  if (pendingCycle || pendingPractice) return <Loading large />

  return (
    <PageHeader
      title={`Upload reports - ${practice.name} - ${cycle.endsAt}`}
      onBack={history.goBack}
    >
      <UploadContent cycle={cycle} />
    </PageHeader>
  )
}
