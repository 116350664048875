import { createSelector } from '@reduxjs/toolkit'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import { denormalize, normalize } from 'normalizr'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import api from '../../api'
import { buildResolveOnlyLastPromise } from '../../lib/promises'
import { cyclesSchema, practicesSchema, usersSchema } from '../normalizr'
import { getPaginationHeaders, useAsyncAction } from '../utils'
import createSlice from '../create-slice'
import { getEntities } from './entities'

const practicesInitialState = {
  cyclesIds: {},
  practicesIds: [],
  pageSize: 0,
  total: 0,
}

const practicesSlice = createSlice({
  name: 'practices',
  initialState: practicesInitialState,
  reducers: {
    setPractices: (state, { payload: { result, pageSize, total } }) => {
      state.practicesIds = result
      state.pageSize = pageSize
      state.total = total
    },
    setCycles: (state, { payload: { result, practiceId } }) => {
      state.cyclesIds[practiceId] = result
    },
  },
})

const { actions } = practicesSlice

const selectState = state => state[practicesSlice.name]

export function usePractices() {
  const { practicesIds } = useSelector(selectState)
  const entities = useSelector(getEntities)
  const practices = denormalize(practicesIds, [practicesSchema], entities)
  return practices
}

export function usePractice(practiceId) {
  const entities = useSelector(getEntities)
  return denormalize(practiceId, practicesSchema, entities)
}

export function usePracticesPagination() {
  const { pageSize, total } = useSelector(selectState)
  return { pageSize, total }
}

const onlyKeepLastPracticeList = buildResolveOnlyLastPromise(
  api.practices.getPractices,
)

export function fetchPractices(page, query, showInactive, useOnlyLastResponse) {
  return async dispatch => {
    const reqFn = useOnlyLastResponse
      ? onlyKeepLastPracticeList
      : api.practices.getPractices
    const { body, headers } = await reqFn(page, query, showInactive)
    dispatch(
      actions.setPractices({
        ...normalize(body, [practicesSchema]),
        ...getPaginationHeaders(headers),
      }),
    )
  }
}

export function useFetchPractices(
  { page, query, showInactive },
  useOnlyLastResponse = false,
) {
  return useAsyncAction(
    fetchPractices,
    page,
    query,
    showInactive,
    useOnlyLastResponse,
  )
}

const selectCycles = createSelector(
  selectState,
  getEntities,
  (state, practiceId) => practiceId,
  (state, entities, practiceId) => {
    const { cyclesIds } = state
    const cycles = denormalize(
      cyclesIds[practiceId] || [],
      [cyclesSchema],
      entities,
    )
    return sortBy(cycles, 'startsAt')
  },
)

const selectCurrentCycle = createSelector(selectCycles, cycles => {
  const currentMoment = moment()
  return cycles.find(({ startsAt, endsAt }) =>
    currentMoment.isBetween(startsAt, endsAt, 'day', '[]'),
  )
})

export function getPrevCycle(cycles) {
  const prevMonth = moment().subtract(1, 'months')

  return cycles.find(({ startsAt, endsAt }) =>
    prevMonth.isBetween(startsAt, endsAt, 'day', '[]'),
  )
}

export function fetchCycles(practiceId) {
  return async dispatch => {
    const response = await api.practices.getCycles(practiceId)
    return dispatch(
      actions.setCycles({
        ...normalize(response, [cyclesSchema]),
        practiceId,
      }),
    )
  }
}

export function useFetchCycles(practiceId) {
  const dispatch = useDispatch()
  return useCallback(() => dispatch(fetchCycles(practiceId)), [
    dispatch,
    practiceId,
  ])
}

export function useCurrentCycle(practiceId) {
  return useSelector(state => selectCurrentCycle(state, practiceId))
}

export function useCycles(practiceId) {
  return useSelector(state => selectCycles(state, practiceId))
}

const selectCycleEntities = createSelector(
  getEntities,
  entities => entities.cycles,
)

const selectCycle = createSelector(
  selectCycleEntities,
  (state, cycleId) => cycleId,
  (cycles, cycleId) => cycles[cycleId],
)

export function useCycle(cycleId) {
  return useSelector(state => selectCycle(state, cycleId))
}

export function processCycle(cycleId) {
  const type = 'PROCESS_CYCLE' // Just to update entities slice
  return async dispatch => {
    const body = await api.cycles.process(cycleId)
    return dispatch({
      type,
      payload: normalize(body, cyclesSchema),
    })
  }
}

export function updateProvider(userId, practiceId, { enabled }) {
  const type = 'UPDATE_USER' // Just to update entities slice

  const reqFn = enabled
    ? api.practices.setProvider
    : api.practices.unsetProvider

  return async dispatch => {
    const body = await reqFn(practiceId, userId)
    return dispatch({
      type,
      payload: normalize(body, usersSchema),
    })
  }
}

export default practicesSlice
