import React from 'react'
import { useIsAdmin } from '../../redux/slices/session'
import { ListError, PageHeader } from '../Common'
import CyclesFilter from '../Common/CyclesFilter'
import usePracticeCycles from '../Common/usePracticeCycles'
import useFilters from '../Cycles/useFilters'
import AddNewItemButton from '../Common/AddNewItemButton'
import { toPath } from '../../routes'
import MissedTransmissions from './MissedTransmissions'

export default function PracticeMissedTransmissions() {
  const isAdmin = useIsAdmin()
  const [
    { error, clearError },
    { practice, pending, practiceId },
  ] = usePracticeCycles()
  const { handleFilterChange, handlePageChange, conditions } = useFilters()
  const { cycleId, page } = conditions

  if (cycleId && !pending) {
    return (
      <div>
        <PageHeader
          title="Missed Transmissions"
          subTitle={isAdmin && practice.name}
          extra={
            isAdmin && (
              <AddNewItemButton
                itemName="missed-transmission"
                path={toPath.cycles.cycle.missedTransmissions.new({
                  id: cycleId,
                })}
                icon="plus-circle"
              />
            )
          }
        />
        <CyclesFilter
          practiceId={practiceId}
          onSubmit={handleFilterChange}
          currentCycleId={cycleId}
        />
        <MissedTransmissions
          practiceId={practiceId}
          cycleId={cycleId}
          onPageChange={handlePageChange}
          page={page}
        />
      </div>
    )
  }

  return (
    <div>
      {error && <ListError description={error.message} onClose={clearError} />}
    </div>
  )
}
