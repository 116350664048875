import React from 'react'
import { Typography, Divider, Descriptions } from 'antd'
import PropTypes from 'prop-types'
import { formatDOB } from '../../lib/datetime'

export default function AssignedReportDetails({ report }) {
  return (
    <div>
      <Typography.Title level={4}>
        You have the following report assigned for review
      </Typography.Title>

      <Divider orientation="left">Report Information</Divider>
      <Descriptions column={2} size="small">
        <Descriptions.Item label="Title">{report.title}</Descriptions.Item>
        <Descriptions.Item label="Report Date">
          {formatDOB(report.reportDate)}
        </Descriptions.Item>
        <Descriptions.Item label="Last Update">
          {formatDOB(report.updatedAt)}
        </Descriptions.Item>
        <Descriptions.Item label="Priority Report">
          {report.priority ? 'Yes' : 'No'}
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">Patient Information</Divider>
      <Descriptions column={2} size="small">
        <Descriptions.Item label="Anticoagulated Desease">
          {report.patient.anticoagulatedDisease ? 'Yes' : 'No'}
        </Descriptions.Item>
        <Descriptions.Item label="Atrial Fibrillation Desease">
          {report.patient.atrialFibrilationFlutterDisease ? 'Yes' : 'No'}
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation="left">Device Information</Divider>
      <Descriptions column={2} size="small">
        <Descriptions.Item label="Model">
          {report.device.deviceModel.deviceType.name}
        </Descriptions.Item>
        <Descriptions.Item label="Manufacturer">
          {report.device.deviceModel.manufacturer}
        </Descriptions.Item>
        <Descriptions.Item label="CPT Code">{report.cptCode}</Descriptions.Item>
        <Descriptions.Item label="Serial Number">
          {report.device.serialNumber}
        </Descriptions.Item>
      </Descriptions>
      <Divider orientation="left" />
      <Descriptions column={1} size="small">
        <Descriptions.Item label="">
          <a className="ant-btn" href={`/reports/${report.id}/edit`}>
            Review Now
          </a>
        </Descriptions.Item>
      </Descriptions>
    </div>
  )
}

AssignedReportDetails.propTypes = {
  report: PropTypes.shape().isRequired,
}
