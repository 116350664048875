import React, { useCallback } from 'react'
import { useHistory, useParams, Redirect } from 'react-router-dom'
import { message } from 'antd'
import mapValues from 'lodash/mapValues'
import moment from 'moment'
import { ListError, PageHeader } from '../Common'
import api from '../../api'
import { toPath } from '../../routes'
import { useAsync } from '../../hooks'
import PreAuthorizationsForm from './Form'

export default function NewPreAuthorization() {
  const history = useHistory()
  const { id: cycleId } = useParams()
  const fetchCycle = useCallback(() => api.cycles.get(cycleId), [cycleId])
  const {
    value: cycle,
    error: cycleError,
    clearError: clearCycleError,
  } = useAsync(fetchCycle)

  const onSubmit = useCallback(
    async (
      {
        cptCode,
        icd10Code,
        patientId,
        billingDate,
        authorized,
        medicalProviderId,
      },
      { setErrors },
    ) => {
      try {
        const preAuthorization = await api.preAuthorizations.create(cycleId, {
          cptCode,
          icd_10_code: icd10Code,
          patientId,
          billingDate: billingDate.format(moment.HTML5_FMT.DATE),
          authorized,
          cycle,
          medicalProviderId,
        })
        message.success('Remote authorization successfully created')
        history.replace(
          toPath.preAuthorizations.preAuthorization({
            id: preAuthorization.id,
          }),
        )
      } catch (e) {
        if (e.response && e.response.errors) {
          setErrors(mapValues(e.response.errors, fieldErrors => fieldErrors[0]))
        } else {
          message.error(e.message)
        }
      }
    },
    [cycle, cycleId, history],
  )

  if (cycleError) {
    message.error(cycleError.message)
    return <Redirect to={toPath.preAuthorizations()} />
  }

  return (
    <div>
      <PageHeader title="New Remote Authorization" onBack={history.goBack} />
      {cycleError && (
        <ListError description={cycleError.message} onClose={clearCycleError} />
      )}
      {cycle && (
        <PreAuthorizationsForm
          onSubmit={onSubmit}
          practiceId={cycle.practiceId}
        />
      )}
    </div>
  )
}
