import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import SplitPane from 'react-split-pane'
import { message, Button } from 'antd'
import cn from 'classnames'
import { ListError } from '../Common'
import PDFPreview from '../Common/PDFPreview'
import api from '../../api'
import { useAsync } from '../../hooks'
import Loading from '../Loading'
import ReportPatientHeader from '../Reports/ReportPatientHeader'
import { toPath } from '../../routes'
import useQueryParams from '../../hooks/useQueryParams'
import ReportForm from './ReportForm'
import ReportUpload from './ReportUpload'

const useStyles = createUseStyles({
  app: { display: 'flex', alignItems: 'stretch', flex: '1 1 auto' },
  pdfBox: {
    maxWidth: 'calc(100% - 456px)',
    flexGrow: '1',
    minWidth: '100%',
    position: 'relative',
    height: 'calc(100vh - 96px - 68px)',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '& canvas': {
      maxWidth: '100%',
    },
  },
  formBox: {
    position: 'relative',
    overflowY: 'scroll',
    height: 'calc(100vh - 96px - 68px)',
    padding: '0 16px',
    '& > form': {
      marginTop: '-16px',
    },
  },
  reuploadContainer: {
    marginBottom: '1em',
    padding: '0 24px',
  },
  toggle: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

export default function EditOfficeCheck() {
  const { id, practiceId } = useParams()
  const [isUploaderVisible, setIsUploaderVisible] = useState(false)
  const [query] = useQueryParams({})

  const getReportForm = useCallback(
    () => api.officeChecks.getReportForm(id, practiceId),
    [id, practiceId],
  )

  const { error, pending: loading, value: reportForm } = useAsync(getReportForm)
  const manufacturer = reportForm?.device?.deviceModel?.manufacturer

  const backPath = () => {
    const path = toPath.practices.officeChecks.officeCheck({
      id: reportForm.id,
      practiceId,
    })

    const queryString = new URLSearchParams(query).toString()

    return `${path}?${queryString}`
  }

  useEffect(() => {
    setIsUploaderVisible(!reportForm?.deviceReportPath)
  }, [reportForm])

  const classes = useStyles()

  const onUpload = useCallback(
    async files => {
      const fd = new FormData()

      files.forEach(f => fd.append('attachment[]', f))

      try {
        await api.officeChecks
          .processDeviceReport(id, practiceId, fd)
          .then(() => window.location.reload(false))
      } catch (e) {
        message.error(`We got an error trying to process this file.`)
      }
    },
    [id, practiceId],
  )

  if (loading) {
    return <Loading large />
  }

  if (error) {
    return (
      <ListError
        message="There was an error loading this office check report"
        description={error.message}
      />
    )
  }

  return (
    <div className={classes.app}>
      <SplitPane split="vertical">
        <div initialSize="65%" minSize="10%" className={classes.pdfBox}>
          <ReportPatientHeader report={reportForm} backPath={backPath()} />
          {reportForm.deviceReportPath && (
            <div className={cn([classes.reuploadContainer, classes.toggle])}>
              {!isUploaderVisible && (
                <Button onClick={() => setIsUploaderVisible(true)}>
                  Upload new file
                </Button>
              )}
              {isUploaderVisible && (
                <Button onClick={() => setIsUploaderVisible(false)}>
                  Cancel
                </Button>
              )}
            </div>
          )}
          {isUploaderVisible && (
            <div className={classes.reuploadContainer}>
              <ReportUpload onUpload={onUpload} manufacturer={manufacturer} />
            </div>
          )}
          {reportForm.deviceReportPath && (
            <PDFPreview path={reportForm.deviceReportPath} perPage={10} />
          )}
        </div>
        <div className={classes.formBox}>
          <ReportForm
            report={reportForm}
            practiceId={practiceId}
            // ENG-304: disable validation on form fields
            noValidation
          />
        </div>
      </SplitPane>
    </div>
  )
}
