import React from 'react'
import { Typography } from 'antd'
import { createUseStyles } from 'react-jss'
import { useAuth0 } from '@auth0/auth0-react'
import SessionLayout from './SessionLayout'

const { Paragraph } = Typography

const useStyles = createUseStyles({
  redirectNotice: {
    marginBottom: '10px',
    color: '#5175b3',
  },
})

export default function Auth0LoginRedirect() {
  const classes = useStyles()
  const { loginWithRedirect } = useAuth0()

  loginWithRedirect()

  return (
    <SessionLayout>
      <Paragraph className={classes.redirectNotice}>
        Redirecting to login. Please wait...
      </Paragraph>
    </SessionLayout>
  )
}
