import React, { useCallback } from 'react'
import api from '../../api'
import AsyncSelect from './AsyncSelect'

export default function DeviceTypeSelect(props) {
  const fetchOptions = useCallback(() => api.deviceTypes.list(), [])
  return (
    <AsyncSelect
      fetchOptions={fetchOptions}
      showSearch
      optionFilterProp="children"
      placeholder="Select device"
      {...props}
    />
  )
}
