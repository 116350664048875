import React from 'react'
import { ThemeProvider } from 'react-jss'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import App from './App'
import theme from './theme/bioBridge'
import store from './redux/store'
import Auth0ProviderWithHistory from './components/Auth/Auth0ProviderWithHistory'

function Root() {
  return (
    <Provider store={store}>
      <Router>
        <ThemeProvider theme={theme}>
          <Auth0ProviderWithHistory>
            <App />
          </Auth0ProviderWithHistory>
          ,
        </ThemeProvider>
      </Router>
    </Provider>
  )
}

export default Root
