import React, { useState, useCallback } from 'react'
import { Button, Descriptions, Divider, Icon, message, Popconfirm } from 'antd'
import { createUseStyles } from 'react-jss'
import { Link, useHistory, useParams } from 'react-router-dom'
import api from '../../api'
import { useAsync } from '../../hooks'
import { formatDateTime, formatDOB } from '../../lib/datetime'
import { useIsAdmin } from '../../redux/slices/session'
import { toPath } from '../../routes'
import Comments from '../Comments'
import { ListError, PageHeader } from '../Common'
import Loading from '../Loading'
import DownloadLink from '../Common/DownloadLink'
import { formatMedicalProvider } from '../Forms/MedicalProviderSelect'
import InsuranceAuthorizationModal from './InsuranceAuthorizationModal'

const useStyles = createUseStyles(theme => ({
  actions: {
    marginTop: 30,
    textAlign: 'center',
  },
  pdfIcon: {
    fontSize: '32px',
    color: theme.grayPrimary,
  },
}))

export default function Details() {
  const [insuranceAuthVisible, setInsuranceAuthVisible] = useState(false)
  const history = useHistory()
  const { id } = useParams()
  const isAdmin = useIsAdmin()
  const classes = useStyles()
  const getPreAuthorization = useCallback(() => api.preAuthorizations.get(id), [
    id,
  ])
  const {
    pending,
    value: preAuthorization,
    error,
    clearError,
    execute: revalidatePreAuth,
  } = useAsync(getPreAuthorization)

  const insuranceAuth = preAuthorization && {
    insuranceAuthorizationNumber: preAuthorization.insuranceAuthorizationNumber,
    insuranceAuthorizationFilePath:
      preAuthorization.insuranceAuthorizationFilePath,
  }

  const handleDelete = useCallback(async () => {
    try {
      const {
        patient: { practiceId },
      } = await getPreAuthorization()

      await api.preAuthorizations.destroy(id)

      message.success('Remote authorization successfully removed')
      history.push(toPath.practices.preAuthorizations({ practiceId }))
    } catch (e) {
      message.error(
        `There was an error removing this remote authorization: ${e.message}`,
      )
    }
  }, [getPreAuthorization, history, id])

  const onFinishInsuranceAuthModal = useCallback(
    (insuranceAuthChanged = false) => {
      setInsuranceAuthVisible(false)
      if (insuranceAuthChanged) {
        revalidatePreAuth()
      }
    },
    [revalidatePreAuth],
  )

  if (pending) return <Loading large />
  if (error) {
    return <ListError description={error.message} onClose={clearError} />
  }
  const { device, patient, assignee } = preAuthorization

  return (
    <div>
      <PageHeader
        title="Remote Authorization"
        subTitle={`${patient.firstName} ${patient.lastName}`}
        onBack={history.goBack}
      />
      <Divider orientation="left">Personal Information</Divider>
      <Descriptions column={2} size="small">
        <Descriptions.Item label="First Name">
          {patient.firstName}
        </Descriptions.Item>
        <Descriptions.Item label="Last Name">
          {patient.lastName}
        </Descriptions.Item>
        <Descriptions.Item label="Date of birth" span={2}>
          {formatDOB(patient.birthDate)}
        </Descriptions.Item>
      </Descriptions>
      <div>
        <Divider orientation="left">Insurance Information</Divider>
        <Descriptions column={2} size="small">
          <Descriptions.Item label="Insurance Provider">
            {patient.insuranceProvider ? patient.insuranceProvider.name : ''}
          </Descriptions.Item>
          <Descriptions.Item label="Insurance Policy #">
            {patient.insurancePolicyNumber}
          </Descriptions.Item>
        </Descriptions>
        <Divider orientation="left">Insurance Authorization:</Divider>
        <Descriptions column={2} size="small">
          <Descriptions.Item label="Insurance Auth file">
            {preAuthorization.insuranceAuthorizationFilePath && (
              <DownloadLink
                path={preAuthorization.insuranceAuthorizationFilePath}
              >
                <Icon
                  type="file-pdf"
                  className={classes.pdfIcon}
                  aria-label="insurance authorization file"
                />
              </DownloadLink>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Insurance Auth #">
            {preAuthorization.insuranceAuthorizationNumber}
          </Descriptions.Item>
        </Descriptions>
        <Divider orientation="left">Medical Providers</Divider>
        <Descriptions column={2} size="small">
          <Descriptions.Item label="Provider">
            {formatMedicalProvider(preAuthorization?.medicalProvider)}
          </Descriptions.Item>
          <Descriptions.Item label="PCP name">
            {patient.pcpName}
          </Descriptions.Item>
          <Descriptions.Item label="PCP phone number">
            {patient.pcpPhoneNumber}
          </Descriptions.Item>
          <Descriptions.Item label="PCP fax number">
            {patient.pcpFaxNumber}
          </Descriptions.Item>
        </Descriptions>
        <Divider orientation="left">Cardiac Device</Divider>
        <Descriptions column={2} size="small">
          <Descriptions.Item label="Company">
            {device.deviceModel.manufacturer}
          </Descriptions.Item>
          <Descriptions.Item label="Type">
            {device.deviceModel.deviceType.name}
          </Descriptions.Item>
          <Descriptions.Item label="Serial Number">
            {device.serialNumber}
          </Descriptions.Item>
          <Descriptions.Item label="CPT Code">
            {preAuthorization.cptCode}
          </Descriptions.Item>
          <Descriptions.Item label="ICD-10 Code">
            {preAuthorization.icd10Code}
          </Descriptions.Item>
        </Descriptions>
        <Divider orientation="left">More</Divider>
        <Descriptions column={2} size="small">
          <Descriptions.Item label="Authorized">
            <Icon
              type={`${preAuthorization.authorized ? 'check' : 'close'}-circle`}
            />
            &nbsp;
            {preAuthorization.authorized ? 'Yes' : 'No'}
          </Descriptions.Item>
          <Descriptions.Item label="Billing Date">
            {preAuthorization.billingDate
              ? formatDateTime(preAuthorization.billingDate)
              : '-'}
          </Descriptions.Item>
        </Descriptions>
        {assignee && (
          <>
            <Divider orientation="left">Clinician</Divider>
            <Descriptions column={2} size="small">
              <Descriptions.Item label="Name">
                {assignee.lastName}, {assignee.firstName}
              </Descriptions.Item>
              <Descriptions.Item label="Email">
                {assignee.email}
              </Descriptions.Item>
            </Descriptions>
          </>
        )}
      </div>

      {/* TODO: use ActionButtons */}
      <div className={classes.actions}>
        <Button icon="file-pdf" onClick={() => setInsuranceAuthVisible(true)}>
          {preAuthorization.insuranceAuthorizationFilePath ? 'Edit' : 'Add'}{' '}
          Authorization
        </Button>
        {isAdmin && (
          <>
            <Divider type="vertical" />
            <Link
              to={toPath.preAuthorizations.preAuthorization.edit({ id })}
              className="edit-button"
            >
              <Button icon="edit" type="primary" ghost>
                Edit
              </Button>
            </Link>
            <Divider type="vertical" />
            <Popconfirm
              title="Are you sure？"
              okText="Yes"
              cancelText="No"
              onConfirm={handleDelete}
            >
              <Button
                type="danger"
                ghost
                icon="close-circle"
                className="hard-delete-button"
              >
                Delete
              </Button>
            </Popconfirm>
          </>
        )}
      </div>
      <InsuranceAuthorizationModal
        preAuthorizationId={id}
        visible={insuranceAuthVisible}
        onFinish={onFinishInsuranceAuthModal}
        insuranceAuth={insuranceAuth}
      />
      <Comments collectionName="pre-authorizations" id={preAuthorization.id} />
    </div>
  )
}
