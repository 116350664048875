import React, { useCallback } from 'react'
import api from '../../api'
import AsyncSelect from './AsyncSelect'

export default function PositionSelect(props) {
  const fetchOptions = useCallback(() => api.users.positions(), [])
  return (
    <AsyncSelect
      fetchOptions={fetchOptions}
      optionFilterProp="children"
      labelPropName="label"
      valuePropName="value"
      labelInValue
      placeholder="Select position"
      {...props}
    />
  )
}
