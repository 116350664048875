import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Table, Select, message } from 'antd'
import { useFetchPractices, usePractices } from '../../redux/slices/practices'
import { useAsync } from '../../hooks'
import api from '../../api'
import { teamShape, practiceShape } from '../prop-types'

function SelectTeam({ practice, teams }) {
  const [teamIds, setTeamIds] = useState(practice.teamIds)

  return (
    <Select
      mode="multiple"
      optionFilterProp="children"
      placeholder="Select teams"
      value={teamIds}
      onChange={async newTeamIds => {
        try {
          const result = await api.teams.updatePracticeTeams(
            practice.id,
            newTeamIds,
          )
          setTeamIds(result)
        } catch (error) {
          message.error(
            `There was an error updating the teams: ${error.message}`,
          )
        }
      }}
      style={{ width: '90%' }}
    >
      {teams.map(team => (
        <Select.Option value={team.id}>{team.name}</Select.Option>
      ))}
    </Select>
  )
}

SelectTeam.propTypes = {
  teams: PropTypes.arrayOf(teamShape).isRequired,
  practice: PropTypes.shape(practiceShape).isRequired,
}

function Practices({ teams }) {
  const { pending } = useAsync(useFetchPractices('all', true))
  const practices = usePractices()

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '50%',
      editable: true,
    },
    {
      title: 'Teams',
      dataIndex: 'teams',
      render: (_text, record) => <SelectTeam teams={teams} practice={record} />,
    },
  ]

  return (
    <Table
      size="middle"
      rowKey="id"
      columns={columns}
      dataSource={practices}
      loading={pending}
    />
  )
}

Practices.propTypes = {
  teams: PropTypes.arrayOf(teamShape).isRequired,
}

export default Practices
