import { message } from 'antd'
import mapValues from 'lodash/mapValues'
import React, { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import api from '../../api'
import { useAsync } from '../../hooks'
import { PageHeader } from '../Common'
import Loading from '../Loading'
import UserForm from './UserForm'

function adaptUserData(userData) {
  return {
    ...userData,
    roleId: userData.role.id,
    practiceId: userData.practice && userData.practice.id,
  }
}

export default function EditUser() {
  const history = useHistory()
  const { id } = useParams()
  const getUser = useCallback(() => api.users.get(id).then(adaptUserData), [id])
  const { pending, value } = useAsync(getUser)

  const onSubmit = useCallback(
    async (
      {
        email,
        firstName,
        lastName,
        password,
        active,
        practiceId,
        roleId,
        position,
        nationalProviderId,
      },
      { setErrors },
    ) => {
      try {
        await api.users.update(id, {
          email,
          firstName,
          lastName,
          password,
          active,
          practiceId,
          nationalProviderId,
          roleId: roleId.key,
          position: position && position.key,
        })
        message.success('User successfully updated')
        history.goBack()
      } catch (e) {
        if (e.response && e.response.errors) {
          setErrors(mapValues(e.response.errors, fieldErrors => fieldErrors[0]))
        } else {
          message.error('Oops, we had a problem. Please try submitting again')
        }
      }
    },
    [history, id],
  )

  if (pending) return <Loading large />

  return (
    <div>
      <PageHeader title="Edit User" onBack={history.goBack} />
      <UserForm userData={value} onSubmit={onSubmit} />
    </div>
  )
}
