import PropTypes from 'prop-types'
import { Button, message } from 'antd'
import React from 'react'
import { useDispatch } from 'react-redux'
import { initDownload } from '../../redux/slices/pre-authorization-downloads'
import api from '../../api'

export default function BatchDownloadButton({ cycleId }) {
  const dispatch = useDispatch()
  const downloadBatch = async () => {
    try {
      await api.preAuthorizations.createBatch(cycleId)
      dispatch(initDownload(cycleId))
    } catch (err) {
      message.error(`Something went wrong: ${err.message}`)
    }
  }

  return (
    <Button icon="download" onClick={downloadBatch}>
      Download All Authorizations
    </Button>
  )
}

BatchDownloadButton.propTypes = {
  cycleId: PropTypes.string.isRequired,
}
