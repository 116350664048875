import { schema } from 'normalizr'

export const alertsSchema = new schema.Entity('alerts')
export const billingOrdersSchema = new schema.Entity('billingOrders')
export const inOfficebillingOrdersSchema = new schema.Entity(
  'inOfficebillingOrders',
)
export const cyclesSchema = new schema.Entity('cycles')
export const missedTransmissionsSchema = new schema.Entity(
  'missedTransmissions',
)
export const practicesSchema = new schema.Entity('practices')
export const patientsSchema = new schema.Entity('patients')
export const preAuthorizationsSchema = new schema.Entity('preAuthorizations')
export const officeAuthorizationsSchema = new schema.Entity(
  'officeAuthorizations',
)
export const reportsSchema = new schema.Entity('reports')
export const usersSchema = new schema.Entity('users')
export const providersSchema = new schema.Entity('providers')
export const reportsSummarySchema = new schema.Entity('reportsSummary')
export const reviewedReportSchema = new schema.Entity('reviewedReportSummary')
export const cliniciansSchema = new schema.Entity('clinicians')
export const remindersSchema = new schema.Entity('reminders')
export const officeChecksSchema = new schema.Entity('officeChecks')
