import PropTypes from 'prop-types'
import { Form, message, Modal, Typography } from 'antd'
import React, { useCallback } from 'react'
import { Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import { Input } from '../Forms'
import { formElementPropsGenerator } from '../../lib/forms'
import api from '../../api'
import { toPath } from '../../routes'
import { Yup } from '../../lib/validations'

const { Paragraph } = Typography

const INITIAL_VALUES = { confirm: '' }

const propsFor = formElementPropsGenerator({
  formName: 'hard-delete-patient-form',
})

const VALIDATION_SCHEMA = Yup.object().shape({
  confirm: Yup.string()
    .matches(/^DELETE$/, { message: 'Should be DELETE' })
    .required(),
})

export default function FullRemovalModal({ visible, patientId, onCancel }) {
  const history = useHistory()
  const remove = useCallback(async () => {
    try {
      await api.patients.fullRemoval(patientId)
      message.success('Patient successfully deleted')
      history.replace(toPath.patients())
    } catch (e) {
      message.error(`Could not delete patient: ${e.message}`)
    }
  }, [history, patientId])

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={remove}
      validateOnMount
      validationSchema={VALIDATION_SCHEMA}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <Modal
          title="Are you absolutely sure?"
          visible={visible}
          onOk={handleSubmit}
          onCancel={onCancel}
          okText="Delete this patient"
          okButtonProps={{ disabled: !isValid }}
          confirmLoading={isSubmitting}
          centered
          destroyOnClose
        >
          <Form onSubmit={handleSubmit}>
            <Paragraph>
              This action cannot be undone. This will permanently delete this
              patient alongside their devices information, alerts and comments.
            </Paragraph>
            <Paragraph>
              Please type <strong>DELETE</strong> to confirm:
            </Paragraph>
            <Input {...propsFor('confirm')} />
          </Form>
        </Modal>
      )}
    </Formik>
  )
}

FullRemovalModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  patientId: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
}
