import { Alert, Col, Icon, Row, Statistic, Typography } from 'antd'
import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import api from '../../api'
import { useAsync } from '../../hooks'
import { useUnacknowledgedAlertsCount } from '../../redux/slices/alerts'
import { useIsAdmin } from '../../redux/slices/session'
import { toPath } from '../../routes'
import { ListError } from '../Common'
import Loading from '../Loading'
import ConnectivityRates from './ConnectivityRates'
import DevicesSummary from './DevicesSummary'

const useStyles = createUseStyles({
  title: {
    textAlign: 'center',
  },
  centeredRow: {
    textAlign: 'center',
  },
  alerts: {
    marginBottom: 10,
  },
})

const { Title } = Typography

export default function Dashboard() {
  const classes = useStyles()
  const isAdmin = useIsAdmin()
  const { count: unacknowledgedAlertsCount } = useUnacknowledgedAlertsCount()
  const fetchData = useCallback(() => api.dashboard.get(), [])
  const { value: stats, pending, error } = useAsync(fetchData)

  if (pending) return <Loading large />
  if (error) {
    return (
      <ListError
        description={error.message}
        message="There was an error loading dashboard data"
      />
    )
  }

  return (
    <div>
      {Boolean(unacknowledgedAlertsCount) && (
        <Link to={toPath.alerts()}>
          <Alert
            message={`You have ${unacknowledgedAlertsCount} alert${
              unacknowledgedAlertsCount > 1 ? 's' : ''
            } not yet acknowledged. Click here to see them.`}
            type="warning"
            showIcon
            closable
            className={classes.alerts}
          />
        </Link>
      )}
      <Title level={3} className={classes.title}>
        General Stats
      </Title>
      <Row gutter={[0, 26]} className={classes.centeredRow}>
        <Col span={isAdmin ? 12 : 24}>
          <Statistic
            title="Total patients"
            value={stats.totalPatients}
            prefix={<Icon type="idcard" />}
          />
        </Col>
        {isAdmin && (
          <Col span={12}>
            <Statistic
              title="Total practices"
              value={stats.totalPractices}
              prefix={<Icon type="medicine-box" />}
            />
          </Col>
        )}
      </Row>
      {!isAdmin && stats?.connectivityRates && (
        <>
          <Row>
            <Col span={24}>
              <Title level={3} className={classes.title}>
                Connectivity rates
              </Title>
            </Col>
          </Row>
          <ConnectivityRates rates={stats.connectivityRates} />
        </>
      )}
      <Row>
        <Col span={24}>
          <Title level={3} className={classes.title}>
            Devices summary
          </Title>
        </Col>
      </Row>
      {stats && <DevicesSummary devicesStats={stats.devices} />}
    </div>
  )
}
