import { Row, Col, Icon } from 'antd'
import React from 'react'
import { useTheme } from 'react-jss'
import PropTypes from 'prop-types'
import { useFeatureEnabled } from '../../redux/slices/session'

export default function TopContent({ remoteMonitoring }) {
  const { brandSuccess } = useTheme()
  const IconTrue = () => (
    <Icon type="check-circle" theme="twoTone" twoToneColor={brandSuccess} />
  )

  return (
    <>
      {useFeatureEnabled('in_office_checks') && (
        <Row>
          <Col span={4} offset={remoteMonitoring ? 4 : 10}>
            <IconTrue /> Office checks
          </Col>
          {remoteMonitoring && (
            <Col span={4}>
              <IconTrue /> Remote Monitoring
            </Col>
          )}
        </Row>
      )}
    </>
  )
}

TopContent.propTypes = {
  remoteMonitoring: PropTypes.bool.isRequired,
}
