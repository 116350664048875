import queryString from 'query-string'
import humps from 'humps'

import { PAGE_SIZE } from '../lib/constants'
import apiClient from './api-client'

function list(practiceId, cycleId, page, filters, perPage) {
  const options = { withHeaders: true }
  const pageFilter = page === 'all' ? { all: true } : { page }
  const query = humps.decamelizeKeys({
    ...filters,
    ...pageFilter,
    perPage: perPage || PAGE_SIZE,
  })

  return apiClient.get(
    queryString.stringifyUrl({
      url: `/practices/${practiceId}/cycles/${cycleId}/reports`,
      query,
    }),
    options,
  )
}

function create(cycleId, reportData) {
  return apiClient.post(`/cycles/${cycleId}/reports`, reportData)
}

function update(id, reportData) {
  return apiClient.patch(`reports/${id}`, reportData)
}

function get(id, query) {
  return apiClient.get(
    queryString.stringifyUrl({
      url: `reports/${id}`,
      query,
    }),
  )
}

function destroy(id) {
  return apiClient.destroy(`reports/${id}`)
}

function acknowledge(practiceId, cycleId) {
  return apiClient.put(
    `/practices/${practiceId}/cycles/${cycleId}/reports/acknowledge`,
    { acknowledge: true },
  )
}

function checkFilenames(practiceId, cycleId, filenames) {
  return apiClient.post(
    `practices/${practiceId}/cycles/${cycleId}/reports/upload/check`,
    { filenames },
  )
}

function upload(practiceId, cycleId, formData) {
  return apiClient.put(
    `practices/${practiceId}/cycles/${cycleId}/reports/upload`,
    formData,
  )
}

function batchDownload(practiceId, cycleId) {
  return apiClient.get(
    `practices/${practiceId}/cycles/${cycleId}/reports/download`,
    {
      headers: {
        Accept: 'application/pdf',
      },
    },
  )
}

function getPendings({ page, practiceId, q }) {
  return apiClient.get(
    queryString.stringifyUrl({
      url: 'reports/pendings',
      query: {
        page,
        practice_id: practiceId,
        q,
      },
    }),
    { withHeaders: true },
  )
}

function approveReports({ practiceId, q }) {
  return apiClient.post('reports/pendings', { practiceId, q })
}

function rejectReport(id) {
  return apiClient.destroy(`reports/pendings/${id}`)
}

function getReportForm(id) {
  return apiClient.get(`reports/${id}/form`)
}

function updateReportForm(id, reportFormData) {
  return apiClient.patch(`reports/${id}/form`, reportFormData)
}

function signReport(id) {
  return apiClient.put(`reports/${id}/signature`)
}

function getUnreviewed({ page, practiceId, q }) {
  return apiClient.get(
    queryString.stringifyUrl({
      url: 'reports/unreviewed',
      query: {
        page,
        practice_id: practiceId,
        q,
      },
    }),
    { withHeaders: true },
  )
}

function getReviewedSummary({
  assigneeId,
  deviceTypeId,
  fromDate,
  toDate,
} = {}) {
  const query = {
    assignee_id: assigneeId,
    device_type_id: deviceTypeId,
    from_date: fromDate,
    to_date: toDate,
  }

  return apiClient.get(
    queryString.stringifyUrl({
      url: 'reports/reviewed/summary',
      query,
    }),
    { withHeaders: true },
  )
}

function getReviewed({ assigneeId, deviceTypeId, fromDate, toDate, page }) {
  return apiClient.get(
    queryString.stringifyUrl({
      url: 'reports/reviewed',
      query: {
        assignee_id: assigneeId,
        device_type_id: deviceTypeId,
        from_date: fromDate,
        to_date: toDate,
        page,
      },
    }),
    { withHeaders: true },
  )
}

export function assignUnreviewed() {
  return apiClient.post('reports/assignments', { withHeaders: true })
}

function updatePriority(id, priority) {
  return apiClient.put(`reports/${id}/priority`, { priority })
}

function unassign(id) {
  return apiClient.destroy(`reports/${id}/assignment`)
}

function turnOff(id, resetAssigned = false) {
  return apiClient.put(`reports/${id}/reject`, { unassign: resetAssigned })
}

export function signReports(practiceId, reports) {
  return apiClient.post(`practices/${practiceId}/reports/signatures`, {
    reports,
  })
}

export default {
  acknowledge,
  batchDownload,
  checkFilenames,
  destroy,
  list,
  get,
  create,
  update,
  upload,
  getPendings,
  rejectReport,
  signReport,
  approveReports,
  getReportForm,
  updateReportForm,
  getUnreviewed,
  getReviewed,
  getReviewedSummary,
  updatePriority,
  unassign,
  turnOff,
  signReports,
}
