import { denormalize, normalize } from 'normalizr'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import api from '../../api'
import { preAuthorizationsSchema } from '../normalizr'
import { getPaginationHeaders, useAsyncAction } from '../utils'
import createSlice from '../create-slice'
import { getEntities } from './entities'

const preAuthorizationsInitialState = {
  cycles: {},
}

const preAuthorizationsSlice = createSlice({
  name: 'preAuthorizations',
  initialState: preAuthorizationsInitialState,
  reducers: {
    setPreAuthorizations: (
      state,
      { payload: { cycleId, result, pageSize, total } },
    ) => {
      state.cycles[cycleId] = state.cycles[cycleId] || {}
      state.cycles[cycleId].ids = result
      state.cycles[cycleId].pageSize = pageSize
      state.cycles[cycleId].total = total
    },
  },
})

const { actions } = preAuthorizationsSlice

const selectState = state => state[preAuthorizationsSlice.name]

export function usePreAuthorizations(cycleId) {
  const { cycles } = useSelector(selectState)
  const entities = useSelector(getEntities)
  return denormalize(
    get(cycles, [cycleId, 'ids'], []),
    [preAuthorizationsSchema],
    entities,
  )
}

export function usePreAuthorizationsPagination(cycleId) {
  const { cycles } = useSelector(selectState)
  const { pageSize, total } = get(cycles, [cycleId], {})
  return { pageSize, total }
}

function fetchPreAuthorizations(cycleId, page, filters) {
  return async dispatch => {
    const { body, headers } = await api.preAuthorizations.list(
      cycleId,
      page,
      filters,
    )
    dispatch(
      actions.setPreAuthorizations({
        cycleId,
        ...normalize(body, [preAuthorizationsSchema]),
        ...getPaginationHeaders(headers),
      }),
    )
  }
}

export function useFetchPreAuthorizations(cycleId, page, filters) {
  return useAsyncAction(fetchPreAuthorizations, cycleId, page, filters)
}

export function updatePreAuthorization(id, payload) {
  const type = 'UPDATE_PRE_AUTHORIZATION' // Just to update entities slice
  return async dispatch => {
    const body = await api.preAuthorizations.update(id, payload)
    return dispatch({
      type,
      payload: normalize(body, preAuthorizationsSchema),
    })
  }
}

export function updatePreAuthorizationOfficeCheck(practiceId, id, payload) {
  const type = 'UPDATE_PRE_AUTHORIZATION_OFFICE_CHECK' // Just to update entities slice
  return async dispatch => {
    const body = await api.preAuthorizations.updateInOffice(
      practiceId,
      id,
      payload,
    )
    return dispatch({
      type,
      payload: normalize(body, preAuthorizationsSchema),
    })
  }
}

export default preAuthorizationsSlice
