import apiClient from './api-client'

function deleteDeviceModel(id) {
  return apiClient.destroy(`device-models/${id}`)
}

function list() {
  return apiClient.get('device-models')
}

function create(payload) {
  return apiClient.post('device-models', payload)
}

function get(id) {
  return apiClient.get(`device-models/${id}`)
}

function update(id, payload) {
  return apiClient.patch(`device-models/${id}`, payload)
}

export default {
  create,
  delete: deleteDeviceModel,
  get,
  list,
  update,
}
