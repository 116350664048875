import { message, Table } from 'antd'
import React, { useCallback, useState } from 'react'
import api from '../../api'
import { useAsync } from '../../hooks'
import useQueryParams from '../../hooks/useQueryParams'
import {
  useFetchUsers,
  useUsers,
  useUsersPagination,
} from '../../redux/slices/users'
import { toPath } from '../../routes'
import { ListError, PageHeader } from '../Common'
import AddNewItemButton from '../Common/AddNewItemButton'
import Filters from '../Forms/Filters'
import useColumns from './useColumns'

const QUERY_PARAMS_SCHEMA = {
  page: Number,
  showInactive: Boolean,
}

export default function UsersList() {
  const [conditions, setConditions] = useQueryParams({
    initialValues: { page: 1 },
    schema: QUERY_PARAMS_SCHEMA,
  })
  const [columnsConfig, setColumnsConfig] = useState({
    practice: true,
    active: conditions.showInactive,
  })
  const { pending, error, clearError, execute: fetchUsers } = useAsync(
    useFetchUsers(conditions, true),
  )

  const handlePageChange = useCallback(
    page => setConditions({ ...conditions, page }),
    [conditions, setConditions],
  )

  const handleSubmit = useCallback(
    ({ practiceId, query, showInactive }) => {
      setColumnsConfig({
        practice: !practiceId,
        active: showInactive,
      })
      setConditions({
        page: 1,
        practiceId,
        query,
        showInactive: showInactive || undefined,
      })
    },
    [setConditions],
  )

  const { pending: deleting, execute: handleDelete } = useAsync(async id => {
    try {
      await api.users.delete(id)
      message.success('User successfully deactivated')
    } catch (e) {
      message.error(`There was an error deactivating this user: ${e.message}`)
    }
    fetchUsers()
  }, false)

  const handlePasswordReset = useCallback(async email => {
    try {
      await api.users.startPasswordChange(email)

      message.success(`Password reset email sent to ${email}`)
    } catch (e) {
      message.error(`Error: ${e.message}`)
    }
  }, [])

  const columns = useColumns(columnsConfig, handleDelete, handlePasswordReset)

  const { pageSize, total } = useUsersPagination()
  const users = useUsers()

  return (
    <div>
      {error && <ListError description={error.message} onClose={clearError} />}
      <PageHeader
        title="Users"
        extra={
          <AddNewItemButton
            itemName="user"
            path={toPath.users.new()}
            icon="user-add"
          />
        }
      />
      <Filters
        currentValues={conditions}
        onSubmit={handleSubmit}
        showInactiveCheck
        showPractice
        showSearch
      />
      <Table
        columns={columns}
        dataSource={users}
        loading={pending || deleting}
        pagination={{
          current: conditions.page || 1,
          pageSize,
          onChange: handlePageChange,
          total,
        }}
        rowKey="id"
      />
    </div>
  )
}
