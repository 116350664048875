import React, { useCallback, useMemo } from 'react'
import { message, Table, Button, Divider, Popconfirm, Icon } from 'antd'
import { createUseStyles } from 'react-jss'
import { useHistory } from 'react-router-dom'
import api from '../../api'
import { useAsync } from '../../hooks'
import useQueryParams from '../../hooks/useQueryParams'
import {
  useFetchPractices,
  usePractices,
  usePracticesPagination,
} from '../../redux/slices/practices'
import { toPath } from '../../routes'
import { ListError, PageHeader } from '../Common'
import AddNewItemButton from '../Common/AddNewItemButton'
import { buildUrl } from '../../api/api-client'

import Filters from '../Forms/Filters'

const useStyles = createUseStyles({
  active: {
    color: 'green',
  },
  inactive: {
    color: 'red',
  },
})

const QUERY_PARAMS_SCHEMA = {
  page: Number,
  showInactive: Boolean,
}

const COLUMNS = [
  {
    title: 'Logo',
    dataIndex: 'logoPath',
    key: 'logoPath',
    render: (_text, record) =>
      record.logoPath && (
        <img
          width="80px"
          src={buildUrl(record.logoPath)}
          alt={`logo-${record.name}`}
        />
      ),
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Phone Number',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
  },
  {
    title: 'Fax Number',
    dataIndex: 'faxNumber',
    key: 'faxNumber',
  },
]

export default function Practices() {
  const classes = useStyles()
  const [conditions, setConditions] = useQueryParams({
    initialValues: { page: 1 },
    schema: QUERY_PARAMS_SCHEMA,
  })
  const history = useHistory()
  const goToEdit = useCallback(
    id => history.push(toPath.practices.practice.edit({ id })),
    [history],
  )
  const { pending, error, clearError, execute: fetchPractices } = useAsync(
    useFetchPractices(conditions, true),
  )
  const handlePageChange = useCallback(
    page => setConditions({ ...conditions, page }),
    [conditions, setConditions],
  )
  const handleSubmit = useCallback(
    ({ query, showInactive }) => {
      setConditions({ page: 1, query, showInactive: showInactive || undefined })
    },
    [setConditions],
  )
  const { pending: deleting, execute: handleDelete } = useAsync(async id => {
    try {
      await api.practices.deletePractice(id)
      message.success('Practice successfully removed')
    } catch (e) {
      message.error(`There was an error removing this practice: ${e.message}`)
    }
    fetchPractices()
  }, false)
  const { pageSize, total } = usePracticesPagination()
  const practices = usePractices()

  const columns = useMemo(
    () => [
      ...COLUMNS,
      {
        align: 'center',
        title: 'Active?',
        dataIndex: 'active',
        key: 'active',
        render(_text, record) {
          return (
            <Icon
              type={`${record.active ? 'check' : 'close'}-circle`}
              className={classes[record.active ? 'active' : 'inactive']}
            />
          )
        },
      },
      {
        align: 'center',
        title: 'Actions',
        key: 'actions',
        width: '100px',
        render: (_text, record) => {
          return (
            <span>
              <Button
                onClick={() => goToEdit(record.id)}
                type="link"
                icon="edit"
              />
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure you want to delete this practice?"
                onConfirm={() => handleDelete(record.id)}
                okText="Yes"
                cancelText="No"
                placement="topRight"
              >
                <Button type="link" icon="delete" className="delete-button" />
              </Popconfirm>
            </span>
          )
        },
      },
    ],
    [classes, goToEdit, handleDelete],
  )

  return (
    <div>
      {error && <ListError description={error.message} onClose={clearError} />}
      <PageHeader
        title="Practices"
        extra={
          <AddNewItemButton
            itemName="practice"
            path={toPath.practices.new()}
            icon="folder-add"
          />
        }
      />
      <Filters
        showSearch
        showInactiveCheck
        onSubmit={handleSubmit}
        currentValues={conditions}
      />

      <Table
        size="middle"
        rowKey="id"
        loading={pending || deleting}
        dataSource={practices}
        columns={columns}
        pagination={{
          current: conditions.page || 1,
          pageSize,
          onChange: handlePageChange,
          total,
        }}
      />
    </div>
  )
}
