/* eslint-disable react/prop-types */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Form, Card } from 'antd'
import { Formik } from 'formik'
import startCase from 'lodash/startCase'
import { createUseStyles } from 'react-jss'
import humps from 'humps'
import { formElementPropsGenerator } from '../../lib/forms'
import { SubmitButton } from '../Forms'
import Checkbox from '../Forms/Checkbox'
import FormItem from '../Reports/FormItem'
import {
  formatInitialValues,
  formatSavedValues,
  getLabels,
  getPlaceholders,
  buildValidationSchema,
} from '../Reports/form-utils'

const useStyles = createUseStyles({
  sectionCard: {
    marginTop: '16px',
    marginBottom: '16px',
  },
})

export default function AlertForm({ form, onSubmit }) {
  const { sections, values: fieldValues, critical = false } = form
  const classes = useStyles()
  const validationSchema = buildValidationSchema(sections)
  const handleCreate = useCallback(
    ({ critical: criticalValue, ...values }) => {
      const alertValues = formatSavedValues(values, sections)
      onSubmit({
        values: humps.decamelizeKeys(alertValues),
        critical: criticalValue,
      })
    },
    [sections, onSubmit],
  )

  const formType = 'default'
  const propsFor = formElementPropsGenerator({
    formName: 'report-edit-form',
    labels: { ...getLabels(sections), critical: 'Requires Attention' },
    placeholders: getPlaceholders(sections),
  })

  return (
    <Formik
      onSubmit={handleCreate}
      initialValues={{
        critical,
        ...formatInitialValues(fieldValues, sections, formType),
      }}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting, isValid, submitCount }) => (
        <Form onSubmit={handleSubmit}>
          {sections.map(section => (
            <Card
              type="inner"
              title={startCase(section.name)}
              key={section.name}
              className={classes.sectionCard}
            >
              {section.fields.map(field => (
                <FormItem
                  propsFor={propsFor}
                  key={field.itemId}
                  field={field}
                  formType={formType}
                />
              ))}
            </Card>
          ))}
          <Card
            type="inner"
            title="Requires Attention"
            className={classes.sectionCard}
          >
            <Checkbox {...propsFor('critical')} />
          </Card>
          <SubmitButton
            isSubmitting={isSubmitting}
            isValid={isValid}
            submitCount={submitCount}
            title="Save Alert"
          />
        </Form>
      )}
    </Formik>
  )
}

AlertForm.propTypes = {
  form: PropTypes.shape({}).isRequired,
  onSubmit: PropTypes.func.isRequired,
}
