import React from 'react'
import { createUseStyles } from 'react-jss'
import { useOfficeCheckDownloads } from '../../redux/slices/office-check-downloads'
import OfficeCheckDownload from './OfficeCheckDownload'

const useStyles = createUseStyles({
  container: {
    position: 'fixed',
    bottom: '4px',
    left: '0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  downloadBox: {
    background: '#1C1C1C',
    padding: '20px 40px',
    width: '400px',
    borderRadius: '4px',
    boxShadow: '0 2px 8px rgb(28 28 28 / 75%)',
  },
})

function OfficeCheckDownloads() {
  const classes = useStyles()
  const officeCheckDownloads = useOfficeCheckDownloads()
  const practiceKeys = Object.keys(officeCheckDownloads)

  if (practiceKeys.length === 0) return null

  return (
    <div className={classes.container}>
      <div className={classes.downloadBox}>
        {practiceKeys.map(practice => {
          const checkDates = Object.keys(officeCheckDownloads[practice])

          return checkDates.map(checkDate => {
            const { progress } = officeCheckDownloads[practice][checkDate]
            return (
              <OfficeCheckDownload
                key={practice + checkDate}
                progress={progress}
                practiceId={practice}
                checkDate={checkDate}
              />
            )
          })
        })}
      </div>
    </div>
  )
}

export default OfficeCheckDownloads
