import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'
import logo from '../../assets/logo.png'

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundColor: '#5175B3',
  },
  form: {
    minWidth: '300px',
    backgroundColor: '#fff',
    padding: '25px',
    borderRadius: '10px',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '25px',
  },
  logo: {
    height: '86px',
    width: '241px',
  },
})

export default function SessionLayout({ children }) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <div className={classes.form}>
        <div className={classes.logoContainer}>
          <img className={classes.logo} src={logo} alt="logo" />
        </div>
        {children}
      </div>
    </div>
  )
}

SessionLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
}
