import moment from 'moment'
import { Form, message, Button } from 'antd'
import { Formik, useFormikContext } from 'formik'
import React, { useCallback, useState, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory, useLocation } from 'react-router-dom'
import SplitPane from 'react-split-pane'
import api from '../../api'
import { formElementPropsGenerator } from '../../lib/forms'
import { Yup } from '../../lib/validations'
import { ListError, PageHeader } from '../Common'
import { SubmitButton, UploadFormikFile } from '../Forms'
import PatientSelect from '../Forms/PatientSelect'
import PracticeSelect from '../Forms/PracticeSelect'
import PDFPreview from '../Common/PDFPreview'
import { useAsync } from '../../hooks'
import AlertForm from './AlertForm'

const propsFor = formElementPropsGenerator({
  formName: 'alerts-form',
  labels: { practice: 'Practice', patient: 'Patient' },
})

const validationSchema = Yup.object().shape({
  practice: Yup.object().required(),
  patient: Yup.object().required(),
  fileToUpload: Yup.mixed().required('You need to provide a full report'),
})

const useStyles = createUseStyles({
  app: { display: 'flex', alignItems: 'stretch', flex: '1 1 auto' },
  pdfBox: {
    maxWidth: 'calc(100% - 456px)',
    flexGrow: '1',
    minWidth: '100%',
    position: 'relative',
    height: 'calc(100vh - 96px - 68px)',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '& canvas': {
      maxWidth: '100%',
    },
  },
  formBox: {
    position: 'relative',
    overflowY: 'scroll',
    height: 'calc(100vh - 96px - 68px)',
    padding: '0 16px',
    '& > form': {
      marginTop: '-16px',
    },
  },
})

const labelCol = {
  sm: { span: 4, offset: 2 },
}

const wrapperCol = {
  sm: { span: 12 },
}

const actionsWrapperCol = {
  sm: { span: 4, offset: labelCol.sm.span + labelCol.sm.offset },
}

// eslint-disable-next-line react/prop-types
function AlertFormView({ patientId, fileToUpload }) {
  const history = useHistory()
  const getForm = useCallback(async () => {
    try {
      const patient = await api.patients.get(patientId)
      const form = await api.alerts.getForm(patient.currentDevice.id)
      return form
    } catch (err) {
      message.error(`There was a problem: ${err.message}`)
      return undefined
    }
  }, [patientId])
  const { error, pending, value: form } = useAsync(getForm)

  const handleCreateAlert = useCallback(
    async ({ values, critical }) => {
      const fd = new FormData()
      fd.append('patient_id', patientId)
      fd.append('critical', critical)
      fd.append('values', JSON.stringify(values))
      fd.append('raw_file', fileToUpload[0])

      try {
        await api.alerts.create(fd)
        message.success('Alert successfully created')
        history.goBack()
      } catch (err) {
        message.error(
          'Oops! There was an error submitting your alert. Please try again',
        )
      }
    },
    [fileToUpload, history, patientId],
  )

  const classes = useStyles()

  if (error) {
    return (
      <ListError
        message="There was an error loading this alert"
        description={error.message}
      />
    )
  }

  if (pending) return <div>Loading...</div>

  return (
    <div className={classes.formBox}>
      <AlertForm form={form} onSubmit={handleCreateAlert} />
    </div>
  )
}

// eslint-disable-next-line react/prop-types
function ResetPatientField({ practice }) {
  const { values, setFieldValue } = useFormikContext()
  const practiceId = values.practice && values.practice.key
  const prevPracticeKey = practice && practice.key

  useEffect(() => {
    if (prevPracticeKey !== practiceId) {
      setFieldValue('patient', undefined)
    }
  }, [practiceId, prevPracticeKey, setFieldValue])

  return null
}

export default function New() {
  const classes = useStyles()
  const [formShow, setFormShow] = useState(false)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const patientId = searchParams.get('patientId')
  const [practice, setPractice] = useState()
  const getPatient = useCallback(async () => {
    const queryPatient = await api.patients.get(patientId)
    return queryPatient
  }, [patientId])
  const { value: queryPatient } = useAsync(getPatient)
  const practiceId = queryPatient ? queryPatient.practiceId : ''
  const patientLabel = queryPatient
    ? `${queryPatient.firstName} ${queryPatient.lastName} - ${moment(
        queryPatient.birthDate,
      ).format('MM-DD-YYYY')}`
    : ''
  const [patient, setPatient] = useState()
  const [fileToUpload, setFileToUpload] = useState(null)
  const history = useHistory()
  const handleFirstForm = values => {
    setPractice(values.practice)
    setPatient(values.patient)
    setFileToUpload(values.fileToUpload)
    setFormShow(true)
  }
  const selectLabelCol = { sm: 6, offset: 0 }
  const selectWrapperCol = { sm: 12, offset: 0 }

  if (formShow) {
    return (
      <div className={classes.app}>
        <SplitPane split="vertical">
          <div initialSize="65%" minSize="10%" className={classes.pdfBox}>
            <PageHeader
              title="New Alert"
              onBack={history.goBack}
              extra={
                <Button onClick={() => setFormShow(false)}>
                  Change full report
                </Button>
              }
            />
            {fileToUpload && (
              <PDFPreview
                url={URL.createObjectURL(fileToUpload[0])}
                perPage={10}
              />
            )}
          </div>
          <AlertFormView patientId={patient.key} fileToUpload={fileToUpload} />
        </SplitPane>
      </div>
    )
  }

  return (
    <div>
      <PageHeader title="New Alert" onBack={history.goBack} />
      <Formik
        initialValues={{ practice, patient, fileToUpload }}
        onSubmit={handleFirstForm}
        validationSchema={validationSchema}
      >
        {({ isSubmitting, handleSubmit, isValid, submitCount, values }) => (
          <Form
            onSubmit={handleSubmit}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
          >
            <PracticeSelect
              {...propsFor('practice')}
              labelInValue
              labelAlign="right"
              value={practiceId ? { key: practiceId } : undefined}
              allowClear={false}
              labelCol={selectLabelCol}
              wrapperCol={selectWrapperCol}
            />
            <ResetPatientField practice={practice} />
            <PatientSelect
              {...propsFor('patient')}
              practiceId={
                (values.practice && values.practice.key) || practiceId
              }
              options={
                queryPatient
                  ? [{ id: queryPatient.id, name: patientLabel }]
                  : undefined
              }
              value={queryPatient ? { key: queryPatient.id } : undefined}
              labelInValue
              labelAlign="right"
              allowClear={false}
              labelCol={selectLabelCol}
              wrapperCol={selectWrapperCol}
              disabled={queryPatient ? false : !values.practice}
            />
            <UploadFormikFile
              name="fileToUpload"
              label="Full Report"
              supportFiles={['application/pdf']}
            />
            <SubmitButton
              title="Next"
              isValid={isValid}
              submitCount={submitCount}
              isSubmitting={isSubmitting}
              wrapperCol={actionsWrapperCol}
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}
