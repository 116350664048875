import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles, useTheme } from 'react-jss'
import { Layout } from 'antd'
import { useToggle } from '../../hooks'

import Header from './Header'
import SideBar from './SideBar'

const { Content } = Layout

const useStyles = createUseStyles({
  outer: {
    minHeight: '100vh',
  },
  content: {
    margin: '24px 16px',
    padding: 24,
    background: ({ theme }) => theme.brandBackground,
    minHeight: 280,
  },
})

export default function AppLayout({ children }) {
  const theme = useTheme()
  const classes = useStyles({ theme })

  const [collapsed, toggleCollapsed] = useToggle(false)

  return (
    <Layout className={classes.outer}>
      <SideBar collapsed={collapsed} />
      <Layout>
        <Header collapsed={collapsed} handleToggleCollapsed={toggleCollapsed} />
        <Content className={classes.content}>{children}</Content>
      </Layout>
    </Layout>
  )
}

AppLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
}
