import queryString from 'query-string'
import humps from 'humps'
import apiClient from './api-client'

function get(cycleId, filters = {}) {
  const { page, ...queryFilters } = filters

  return apiClient.get(
    queryString.stringifyUrl({
      url: `/reports/cycles/${cycleId}/batch`,
      query: humps.decamelizeKeys({ ...queryFilters }),
    }),
  )
}

function create(cycleId, filters = {}) {
  const { page, ...queryFilters } = filters

  return apiClient.post(`/reports/cycles/${cycleId}/batch`, queryFilters)
}

function zipFile(cycleId, filters = {}) {
  const { page, ...queryFilters } = filters

  return apiClient.get(
    queryString.stringifyUrl({
      url: `reports/cycles/${cycleId}/file`,
      query: humps.decamelizeKeys({ ...queryFilters }),
    }),
  )
}

export default {
  get,
  create,
  zipFile,
}
