import api from '../../../../api'

class Result {
  constructor(object, err) {
    this.object = object
    this.err = err
  }

  isSuccess() {
    return !!this.object && !this.err
  }

  getErrors() {
    if (!this.err) return []

    if (this.err.response && this.err.response.errors) {
      const { errors } = this.err.response

      return errors
    }

    return this.err.message
  }
}

export default async (patientId, practiceId, attributes) => {
  const {
    cptCode,
    icd10Code,
    dateOfCheck,
    authorized,
    officeCheckId,
  } = attributes

  try {
    if (!patientId)
      return new Result(
        undefined,
        new Error('Something went wrong! Patient is not present.'),
      )

    return new Result(
      await api.inOfficeAuthorizations.create(practiceId, {
        patientId,
        cptCode,
        icd10Code,
        dateOfCheck: dateOfCheck && dateOfCheck.toISOString(),
        authorized,
        officeCheckId,
      }),
      undefined,
    )
  } catch (e) {
    return new Result(undefined, e)
  }
}
