import { Button, message, Switch, Table } from 'antd'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { useAsync } from '../../../hooks'
import useExportExcel from '../../../hooks/useExportExcel'
import { formatDOB } from '../../../lib/datetime'
import {
  markBillingOrderAsBilled,
  useBillingOrders,
  useBillingOrdersPagination,
  useFetchBillingOrders,
} from '../../../redux/slices/billing-orders'
import DownloadLink from '../../Common/DownloadLink'
import { toPath } from '../../../routes'
import { ListError } from '../../Common'

const COLUMNS = [
  {
    title: 'Last Name',
    dataIndex: 'patient.lastName',
    key: 'lastName',
  },
  {
    title: 'First Name',
    dataIndex: 'patient.firstName',
    key: 'firstName',
  },
  {
    title: 'DOB',
    key: 'dob',
    dataIndex: 'patient.birthDate',
    render: formatDOB,
  },
  {
    title: 'Device Type',
    dataIndex: 'device.deviceModel.deviceType.name',
    key: 'deviceType',
  },
  {
    title: 'CPT Code',
    dataIndex: 'cptCode',
    key: 'cptCode',
  },
  {
    title: 'ICD-10 Code',
    dataIndex: 'icd10Code',
    key: 'icd10Code',
  },
  {
    title: 'Tech DOS',
    key: 'billingDate',
    render(text, billingOrder) {
      if (!billingOrder.billingDate) return '-'
      return formatDOB(billingOrder.billingDate)
    },
  },
  {
    title: 'Pro DOS',
    key: 'reportSignatureDate',
    render(text, billingOrder) {
      if (!billingOrder.reportSignatureDate) return '-'
      return formatDOB(billingOrder.reportSignatureDate)
    },
  },
  {
    title: 'Auth #',
    key: 'auth',
    render(_text, billingOrder) {
      if (!billingOrder.preAuthorization) return null

      const {
        insuranceAuthorizationFilePath,
        insuranceAuthorizationNumber,
      } = billingOrder.preAuthorization
      if (!insuranceAuthorizationFilePath) return insuranceAuthorizationNumber

      return (
        <DownloadLink path={insuranceAuthorizationFilePath}>
          <span>{insuranceAuthorizationNumber}</span>
        </DownloadLink>
      )
    },
  },
  {
    title: 'Actions',
    key: 'actions',
    render(text, billingOrder) {
      return (
        <Link
          to={toPath.billing.remoteBillingOrders.remoteBillingOrder({
            id: billingOrder.id,
          })}
        >
          <Button type="link" icon="file-search">
            Comments / More
          </Button>
        </Link>
      )
    },
  },
]

export default function BillingOrders({ cycleId, onPageChange, page }) {
  const dispatch = useDispatch()
  const billingOrders = useBillingOrders(cycleId)
  const { pageSize, total } = useBillingOrdersPagination(cycleId)
  const { pending, error, clearError } = useAsync(
    useFetchBillingOrders(cycleId, page),
  )

  const handleBilledChange = useCallback(
    async (id, billed) => {
      try {
        await dispatch(markBillingOrderAsBilled(id, billed))
        message.success('Billing Order has been updated')
      } catch (e) {
        message.error(
          get(
            e,
            'response.errors.billed',
            'Oops, we had a problem. Please try submitting again',
          ),
        )
      }
    },
    [dispatch],
  )

  const columns = useMemo(() => {
    const copy = [...COLUMNS]
    copy.splice(copy.length - 1, 0, {
      title: 'Billed',
      key: 'billed',
      render: (text, record) => {
        return (
          <Switch
            checked={record.billed}
            checkedChildren="Billed"
            unCheckedChildren="Not&nbsp;Billed"
            onChange={billed => handleBilledChange(record.id, billed)}
          />
        )
      },
    })
    return copy
  }, [handleBilledChange])

  const [exportingExcel, exportExcel] = useExportExcel(
    `cycles/${cycleId}/billing-orders.xlsx`,
  )

  return (
    <>
      {error && <ListError description={error.message} onClose={clearError} />}
      <Table
        size="middle"
        columns={columns}
        dataSource={billingOrders}
        rowKey="id"
        loading={pending}
        pagination={{
          current: page,
          pageSize,
          onChange: onPageChange,
          total,
        }}
      />
      <div>
        <Button
          loading={exportingExcel}
          onClick={exportExcel}
          icon="file-excel"
          disabled={billingOrders.length === 0}
        >
          Export this list to Excel
        </Button>
      </div>
    </>
  )
}

BillingOrders.propTypes = {
  cycleId: PropTypes.string.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number,
}

BillingOrders.defaultProps = {
  page: 1,
}
