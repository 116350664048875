import { message } from 'antd'
import mapValues from 'lodash/mapValues'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import api from '../../api'
import { PageHeader } from '../Common'
import { toPath } from '../../routes'
import DeviceModelForm from './Form'

export default function NewDeviceModel() {
  const history = useHistory()
  const goToList = useCallback(() => history.push(toPath.deviceModels()), [
    history,
  ])

  const onSubmit = useCallback(
    async ({ manufacturer, name, deviceTypeId }, { setErrors }) => {
      try {
        await api.deviceModels.create({
          manufacturer,
          name,
          deviceTypeId,
        })
        message.success('Device Model successfully created')
        goToList()
      } catch (e) {
        if (e.response && e.response.errors) {
          setErrors(mapValues(e.response.errors, fieldErrors => fieldErrors[0]))
        } else {
          message.error(e.message)
        }
      }
    },
    [goToList],
  )

  return (
    <div>
      <PageHeader title="New Device Model" onBack={history.goBack} />
      <DeviceModelForm onSubmit={onSubmit} />
    </div>
  )
}
