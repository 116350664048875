import React, { useCallback, useMemo, useState } from 'react'
import { Button, Table } from 'antd'
import { Link } from 'react-router-dom'
import { ListError, PageHeader } from '../Common'
import { useAsync } from '../../hooks'
import api from '../../api'
import Filters from '../Forms/Filters'
import { toPath } from '../../routes'
import codes from './codes'

const COLUMNS = [
  {
    title: 'Device Type',
    dataIndex: 'name',
    key: 'name',
  },
  ...codes.map(({ name, attribute }) => ({
    title: name,
    dataIndex: attribute,
    key: attribute,
  })),
  {
    title: 'Actions',
    key: 'actions',
    render: (text, record) => {
      return (
        <span>
          <Link
            to={toPath.deviceTypes.deviceType.edit({ id: record.id })}
            className="edit-button"
          >
            <Button type="link" icon="edit" />
          </Link>
        </span>
      )
    },
  },
]

// All except the last column
const SEARCHABLE = COLUMNS.slice(0, COLUMNS.length - 1)

function filter(value, search) {
  return (value || '').toLowerCase().includes(search.toLowerCase())
}

function filterDeviceType(deviceType, search) {
  return !!SEARCHABLE.find(({ dataIndex }) =>
    filter(deviceType[dataIndex], search),
  )
}

export default function DeviceTypes() {
  const fetchDeviceTypes = useCallback(() => api.deviceTypes.list(), [])
  const { pending, error, clearError, value: deviceTypes } = useAsync(
    fetchDeviceTypes,
  )
  const [queryFilter, setQueryFilter] = useState('')
  const onSubmit = useCallback(({ query }) => {
    setQueryFilter(query)
  }, [])

  const deviceTypesFiltered = useMemo(() => {
    return (deviceTypes || []).filter(deviceType =>
      filterDeviceType(deviceType, queryFilter),
    )
  }, [deviceTypes, queryFilter])

  return (
    <div>
      <PageHeader title="CPT Codes" />
      {error && <ListError description={error.message} onClose={clearError} />}
      <Filters onSubmit={onSubmit} showSearch />
      <Table
        columns={COLUMNS}
        dataSource={deviceTypesFiltered}
        loading={pending}
        rowKey="id"
      />
    </div>
  )
}
