import { message } from 'antd'
import mapValues from 'lodash/mapValues'
import React, { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import api from '../../api'
import { toPath } from '../../routes'
import { PageHeader } from '../Common'
import MissedTransmissionsForm from './Form'

export default function NewMissedTransmission() {
  const history = useHistory()
  const { id: cycleId } = useParams()

  const onSubmit = useCallback(
    async ({ comment, preAuthorizationId }, { setErrors }) => {
      try {
        const missedTransmission = await api.missedTransmissions.create(
          preAuthorizationId,
          { comment },
        )
        message.success('Missed Transmission successfully created')
        history.replace(
          toPath.missedTransmissions.missedTransmission({
            id: missedTransmission.id,
          }),
        )
      } catch (e) {
        if (e.response && e.response.errors) {
          setErrors(mapValues(e.response.errors, fieldErrors => fieldErrors[0]))
        } else {
          message.error(e.message)
        }
      }
    },
    [history],
  )

  return (
    <div>
      <PageHeader title="New Missed Transmission" onBack={history.goBack} />
      <MissedTransmissionsForm onSubmit={onSubmit} cycleId={cycleId} />
    </div>
  )
}
