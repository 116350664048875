import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { Form, Input as AntDInput } from 'antd'
import PhoneInput from 'react-phone-number-input/input'
import { useField } from 'formik'

const { Item } = Form

const PhoneInputComponent = React.forwardRef(({ onChange, ...rest }, ref) => {
  const handleChange = useCallback(e => onChange(e.target.value), [onChange])
  const htmlInputRef = useCallback(
    comp => {
      if (!comp) return
      ref.current = comp.input
    },
    [ref],
  )
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <AntDInput ref={htmlInputRef} {...rest} onChange={handleChange} />
})

PhoneInputComponent.propTypes = {
  onChange: PropTypes.func.isRequired,
}

export default function PhoneNumber({ id, label, ...rest }) {
  const [field, meta, { setValue }] = useField(rest)
  const hasError = Boolean(meta.touched && meta.error)
  return (
    <Item
      label={label}
      htmlFor={id}
      hasFeedback={hasError}
      validateStatus={hasError ? 'error' : ''}
      help={hasError && meta.error}
    >
      <PhoneInput
        defaultCountry="US"
        {...field} // eslint-disable-line react/jsx-props-no-spreading
        onChange={setValue}
        inputComponent={PhoneInputComponent}
        {...rest} // eslint-disable-line react/jsx-props-no-spreading
      />
    </Item>
  )
}

PhoneNumber.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
}

PhoneNumber.defaultProps = {
  label: undefined,
  id: undefined,
  type: undefined,
}
