import React from 'react'
import { PageHeader } from '../Common'
import PracticeSelectView from '../Practices/PracticeSelectView'
import { toPath } from '../../routes'

export default function AddOfficeCheck() {
  return (
    <div>
      <PageHeader title="Add Office Check" />
      <PracticeSelectView routeMethod={toPath.practices.officeChecks.new} />
    </div>
  )
}
