import { useCallback, useEffect } from 'react'
import useQueryParams from '../../hooks/useQueryParams'
import {
  useCurrentCycle,
  useCycles,
  getPrevCycle,
} from '../../redux/slices/practices'
import usePracticeCycles from '../Common/usePracticeCycles'
import { PAGE_SIZE } from '../../lib/constants'

const QUERY_PARAMS_SCHEMA = { page: Number, perPage: Number }

export default function useFilters(initialValues = {}) {
  // eslint-disable-next-line no-unused-vars
  const [_, { practice, practiceId }] = usePracticeCycles()
  const [conditions, setConditions] = useQueryParams({
    initialValues,
    schema: QUERY_PARAMS_SCHEMA,
  })
  const cycles = useCycles(practiceId)
  const currentCycle = useCurrentCycle(practiceId)
  const { cycleId } = conditions

  useEffect(() => {
    if (practice && cycles.length && currentCycle && !cycleId) {
      const newCycleId = (
        (practice.hospitalSystemWorkflow && getPrevCycle(cycles)) ||
        currentCycle
      ).id
      setConditions({ cycleId: newCycleId, page: 1, perPage: PAGE_SIZE })
    }
  }, [cycleId, currentCycle, cycles, practice, setConditions])

  const handleFilterChange = useCallback(
    args =>
      setConditions({
        ...conditions,
        cycleId: args.cycleId,
        page: 1,
        perPage: PAGE_SIZE,
      }),
    [conditions, setConditions],
  )

  const handlePageChange = useCallback(
    page => setConditions({ ...conditions, page }),
    [conditions, setConditions],
  )

  return {
    conditions,
    setConditions,
    handlePageChange,
    handleFilterChange,
  }
}
