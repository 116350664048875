import { useCallback, useState } from 'react'
import { message } from 'antd'
import { downloadExcel } from '../lib/download'

export default function useExportExcel(url) {
  const [exportingExcel, setExportingExcel] = useState(false)
  const exportExcel = useCallback(async () => {
    setExportingExcel(true)
    try {
      await downloadExcel(url)
    } catch (e) {
      message.error(
        'Oops, we had a problem exporting to excel. Please try again',
      )
    }
    setExportingExcel(false)
  }, [url])

  return [exportingExcel, exportExcel]
}
