import apiClient from './api-client'

function update(id, formData) {
  return apiClient.put(
    `/pre-authorizations/${id}/insurance-authorization`,
    formData,
  )
}

function destroy(id) {
  return apiClient.destroy(`/pre-authorizations/${id}/insurance-authorization`)
}

export default {
  update,
  destroy,
}
