import { Checkbox as AntDCheckbox, Form } from 'antd'
import { useField } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'

const { Item } = Form

export default function Checkbox({ containerClassName, label, ...rest }) {
  const [field] = useField({ ...rest, type: 'checkbox' })
  return (
    <Item className={containerClassName}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <AntDCheckbox {...field} {...rest} value={field?.value || ''}>
        {label}
      </AntDCheckbox>
    </Item>
  )
}

Checkbox.propTypes = {
  containerClassName: PropTypes.string,
  label: PropTypes.string,
}

Checkbox.defaultProps = {
  containerClassName: undefined,
  label: '',
}
