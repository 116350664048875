import PropTypes from 'prop-types'

export const listItemPropTypes = PropTypes.shape({
  id: PropTypes.string.isRequired,
})

export const practiceShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
})

export const userShape = PropTypes.shape({
  email: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  active: PropTypes.bool,
  practiceId: PropTypes.string,
  roleId: PropTypes.string.isRequired,
})

export const patientShape = PropTypes.shape({
  practiceId: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  birthDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  mobilePhone: PropTypes.string,
  homePhone: PropTypes.string,
  addressStreet: PropTypes.string.isRequired,
  addressApt: PropTypes.string,
  addressCity: PropTypes.string.isRequired,
  addressState: PropTypes.string,
  addressCountry: PropTypes.string.isRequired,
  addressZipCode: PropTypes.string.isRequired,
  currentDeviceAttributes: PropTypes.shape({
    deviceModelId: PropTypes.string.isRequired,
    serialNumber: PropTypes.string.isRequired,
  }),
  atrialFibrilationFlutterDisease: PropTypes.bool,
  anticoagulatedDisease: PropTypes.bool,
})

export const patientSummaryShape = PropTypes.shape({
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  birthDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  atrialFibrilationFlutterDisease: PropTypes.bool,
  anticoagulatedDisease: PropTypes.bool,
})

export const commentShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  updatedAt: PropTypes.string.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
  }),
})

export const cycleShape = PropTypes.shape({
  id: PropTypes.string.isRequired,
  startsAt: PropTypes.string.isRequired,
  endsAt: PropTypes.string.isRequired,
  preAuthorizationDate: PropTypes.string.isRequired,
  reportDate: PropTypes.string.isRequired,
  practiceId: PropTypes.string.isRequired,
})

export const insuranceAuthorizationShape = PropTypes.shape({
  insuranceAuthorizationNumber: PropTypes.string,
  insuranceAuthorizationFilePath: PropTypes.string,
})

export const teamShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
})

export const clinicianShape = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
})

export const phoneNumberShape = PropTypes.shape({
  id: PropTypes.string,
  area_code: PropTypes.number.isRequired,
  phone_number: PropTypes.string.isRequired,
})
