import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Form, message, Card } from 'antd'
import { Formik } from 'formik'
import humps from 'humps'
import { useHistory, useParams } from 'react-router-dom'
import startCase from 'lodash/startCase'
import get from 'lodash/get'
import { createUseStyles } from 'react-jss'

import { formElementPropsGenerator } from '../../lib/forms'
import { SubmitButton } from '../Forms'
import api from '../../api'
import Checkbox from '../Forms/Checkbox'
import useQueryParams from '../../hooks/useQueryParams'
import QuestionForm from './QuestionForm'
import FormItem from './FormItem'

import {
  formatInitialValues,
  formatSavedValues,
  getLabels,
  getPlaceholders,
  buildValidationSchema,
} from './form-utils'

function filterCycle30Fields(formSections) {
  return formSections.filter(section => section.restrictedTo !== 'cycle_30')
}

const useStyles = createUseStyles({
  sectionCard: {
    marginTop: '16px',
    marginBottom: '16px',
  },
})

export default function ReportForm({ report }) {
  const history = useHistory()
  const { id } = useParams()
  const classes = useStyles()
  const [answeredQuestion, setAnsweredQuestion] = useState(false)
  const [filterFields, setFilterFields] = useState(report.filteredFields)
  const [query, , getPathWithQuery] = useQueryParams({})

  const {
    formSections: initialFormSections,
    fieldValues,
    critical,
    device,
    additionalQuestion,
    hasFile,
    hasLongCycleAndDeviceWithFullLength,
  } = report
  const showQuestionForm = !hasFile && !answeredQuestion && additionalQuestion
  const formSections = filterFields
    ? filterCycle30Fields(initialFormSections)
    : initialFormSections
  const handleEdit = useCallback(
    async ({ critical: criticalField, ...values }) => {
      const reportValues = formatSavedValues(values, formSections)
      try {
        if (filterFields) {
          await api.reports.turnOff(id)
        }

        await api.reports.updateReportForm(id, {
          reportValues,
          critical: criticalField,
        })
        history.push(getPathWithQuery(`/reports/${id}`, query))
        message.success('BioBridge report has been edited')
      } catch (e) {
        message.error(
          `There was an error editing a BioBridge report: ${e.message}`,
        )
      }
    },
    [filterFields, formSections, getPathWithQuery, history, id, query],
  )
  const handleQuestion = useCallback(
    async ({ question }) => {
      try {
        if (question === 'yes') {
          setAnsweredQuestion(true)
          return
        }

        if (question === 'no' && hasLongCycleAndDeviceWithFullLength) {
          setFilterFields(true)
          setAnsweredQuestion(true)
          return
        }

        await api.reports.turnOff(id, true)
        message.success('BioBridge report has been rejected')
        history.push('/unreviewed-reports')
      } catch (e) {
        message.error(
          `There was an error rejected a BioBridge report: ${e.message}`,
        )
      }
    },
    [hasLongCycleAndDeviceWithFullLength, history, id],
  )

  if (showQuestionForm) {
    return (
      <QuestionForm
        additionalQuestion={additionalQuestion}
        handleQuestion={handleQuestion}
      />
    )
  }

  const validationSchema = buildValidationSchema(formSections)
  const formType = humps.camelize(
    get(device, 'deviceModel.deviceType.formType'),
  )
  const propsFor = formElementPropsGenerator({
    formName: 'report-edit-form',
    labels: { ...getLabels(formSections), critical: 'Requires Attention' },
    placeholders: getPlaceholders(formSections),
  })

  return (
    <Formik
      onSubmit={handleEdit}
      initialValues={{
        ...formatInitialValues(fieldValues, formSections, formType),
        critical,
      }}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting, isValid, submitCount }) => (
        <Form onSubmit={handleSubmit}>
          {formSections.map(section => (
            <Card
              type="inner"
              title={startCase(section.name)}
              key={section.name}
              className={classes.sectionCard}
            >
              <div>
                {section.fields.map(field => (
                  <FormItem
                    propsFor={propsFor}
                    key={field.itemId}
                    field={field}
                    formType={formType}
                  />
                ))}
              </div>
            </Card>
          ))}
          <Card
            type="inner"
            title="Requires Attention"
            className={classes.sectionCard}
          >
            <Checkbox {...propsFor('critical')} />
          </Card>
          <SubmitButton
            isSubmitting={isSubmitting}
            isValid={isValid}
            submitCount={submitCount}
            title="Save Report"
          />
        </Form>
      )}
    </Formik>
  )
}

ReportForm.propTypes = {
  report: PropTypes.shape().isRequired,
}
