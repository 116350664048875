import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Button, Drawer, Descriptions } from 'antd'
import { useHistory } from 'react-router-dom'
import AlertsReports from '../Patients/AlertsReports'
import { PageHeader } from '../Common'
import { useCurrentUserRole } from '../../redux/slices/session'

export default function ReportPatientHeader({ report, backPath }) {
  const [visiblePatient, setVisiblePatient] = useState(false)
  const [visiblePast, setVisiblePast] = useState(false)
  const history = useHistory()
  const goToReport = useCallback(() => history.push(backPath), [
    backPath,
    history,
  ])

  const userRole = useCurrentUserRole()
  const isClinician = userRole === 'clinician'

  const title = `${isClinician ? 'Generate' : 'Update'} BioBridge Report`

  return (
    <>
      <PageHeader
        title={title}
        onBack={!isClinician && backPath && goToReport}
        extra={
          <>
            <Button onClick={() => setVisiblePatient(true)}>Patient</Button>
            <Button onClick={() => setVisiblePast(true)}>
              Previous Alerts and Reports
            </Button>
          </>
        }
      />
      <Drawer
        title="Patient Information"
        width={360}
        placement="right"
        onClose={() => setVisiblePatient(false)}
        visible={visiblePatient}
      >
        <Descriptions column={1} size="small" layout="vertical">
          <Descriptions.Item label="Device Type">
            {report.device.deviceModel.deviceType.name}
          </Descriptions.Item>
          <Descriptions.Item label="Anticoagulated Disease">
            {report.patient.anticoagulatedDisease ? 'Yes' : 'No'}
          </Descriptions.Item>
          <Descriptions.Item label="Atrial Fibrillation / Flutter Disease">
            {report.patient.atrialFibrilationFlutterDisease ? 'Yes' : 'No'}
          </Descriptions.Item>
          <Descriptions.Item label="Device Serial Number">
            {report.device.serialNumber}
          </Descriptions.Item>
        </Descriptions>
      </Drawer>
      <Drawer
        width={500}
        title="Previous alerts and reports"
        placement="right"
        onClose={() => setVisiblePast(false)}
        visible={visiblePast}
      >
        <AlertsReports
          patientId={report.patient.id}
          layout="vertical"
          targetBlank
        />
      </Drawer>
    </>
  )
}

ReportPatientHeader.propTypes = {
  report: PropTypes.shape().isRequired,
  backPath: PropTypes.string,
}

ReportPatientHeader.defaultProps = {
  backPath: '',
}
