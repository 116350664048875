import PropTypes from 'prop-types'
import { Formik } from 'formik'
import { Icon } from 'antd'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { formElementPropsGenerator } from '../../../lib/forms'
import { Input, SubmitButton } from '../../Forms'
import { Yup } from '../../../lib/validations'

const useStyles = createUseStyles({
  submitButton: {
    marginBottom: '10px',
  },
  errorMessage: {
    maxWidth: '300px',
    color: 'red',
  },
})

const DEFAULT_VALUES = { email: '' }

const propsFor = formElementPropsGenerator({
  placeholders: {
    email: 'e-mail',
  },
})

const validationSchema = Yup.object().shape({
  email: Yup.string().required(),
})

export default function SendInstructionsForm({ onSubmit }) {
  const classes = useStyles()
  return (
    <Formik
      initialValues={DEFAULT_VALUES}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit, isSubmitting, isValid }) => (
        <form onSubmit={handleSubmit} className={classes.form}>
          <Input
            {...propsFor('email')}
            prefix={<Icon type="user" />}
            autoComplete="username"
          />
          <SubmitButton
            block
            className={classes.submitButton}
            isSubmitting={isSubmitting}
            isValid={isValid}
            title="Get Reset Link"
          />
        </form>
      )}
    </Formik>
  )
}

SendInstructionsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}
