import EventEmitter from 'eventemitter3'

export const TOKEN_STORAGE_OBSERVER = new EventEmitter()
export const TOKEN_REMOVED_EVENT = 'token-removed'

export const LOCAL_STORAGE_KEY = 'token'
export const CURRENT_USER_KEY = 'current-user'

function geToken() {
  return localStorage.getItem(LOCAL_STORAGE_KEY)
}

function setToken(token) {
  localStorage.setItem(LOCAL_STORAGE_KEY, token)
}

function removeToken(preventEvent = false) {
  const token = geToken()

  if (token) {
    localStorage.removeItem(LOCAL_STORAGE_KEY)

    if (!preventEvent) TOKEN_STORAGE_OBSERVER.emit(TOKEN_REMOVED_EVENT)
  }
}

function setCurrentUser(user) {
  localStorage.setItem(CURRENT_USER_KEY, JSON.stringify(user))
}

function getCurrentUser() {
  return JSON.parse(localStorage.getItem(CURRENT_USER_KEY))
}

function removeCurrentUser(preventEvent = false) {
  const user = getCurrentUser()

  if (user) {
    localStorage.removeItem(CURRENT_USER_KEY)

    if (!preventEvent) TOKEN_STORAGE_OBSERVER.emit(TOKEN_REMOVED_EVENT)
  }
}

export default {
  geToken,
  setToken,
  removeToken,
  getCurrentUser,
  setCurrentUser,
  removeCurrentUser,
}
