import React from 'react'
import Select from './Select'

export const STATUSES = [
  { id: 'all', name: 'Authorized: all' },
  { id: 'true', name: 'Authorized' },
  { id: 'false', name: 'Not authorized' },
]

export default function AuthorizedStatusSelect(props) {
  return <Select {...props} options={STATUSES} />
}
