import { Form } from 'antd'
import { Formik } from 'formik'
import mapValues from 'lodash/mapValues'
import PropTypes from 'prop-types'
import React from 'react'
import { formElementPropsGenerator } from '../../lib/forms'
import { Yup } from '../../lib/validations'
import { Input, SubmitButton } from '../Forms'
import DeviceTypeSelect from '../Forms/DeviceTypeSelect'
import ManufacturerSelect from '../Forms/ManufacturerSelect'

const DEVICE_MODEL_LABELS = {
  manufacturer: 'Manufacturer',
  name: 'Name',
  deviceTypeId: 'Device Type',
}

const INITIAL_VALUES = mapValues(DEVICE_MODEL_LABELS, () => undefined)

const propsFor = formElementPropsGenerator({
  formName: 'device-models-form',
  labels: DEVICE_MODEL_LABELS,
})

const labelCol = {
  sm: { span: 4, offset: 2 },
}

const wrapperCol = {
  sm: { span: 16 },
}

const actionsWrapperCol = {
  sm: { span: 4, offset: labelCol.sm.span + labelCol.sm.offset },
}

const validationSchema = Yup.object().shape({
  manufacturer: Yup.string().required(),
  deviceTypeId: Yup.string().required(),
  name: Yup.string().required(),
})

export default function DeviceModelsForm({ deviceModel, onSubmit }) {
  const isNew = !deviceModel
  return (
    <div>
      <Formik
        initialValues={deviceModel || INITIAL_VALUES}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, isValid, submitCount }) => (
          <Form
            onSubmit={handleSubmit}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
          >
            <Input {...propsFor('name')} autoFocus={isNew} />
            <ManufacturerSelect {...propsFor('manufacturer')} />
            <DeviceTypeSelect {...propsFor('deviceTypeId')} clearWhenUnmount />
            <SubmitButton
              isSubmitting={isSubmitting}
              isValid={isValid}
              title={isNew ? 'Create Device Model' : 'Save Device Model'}
              submitCount={submitCount}
              wrapperCol={actionsWrapperCol}
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}

DeviceModelsForm.propTypes = {
  deviceModel: PropTypes.shape({
    manufacturer: PropTypes.string,
    id: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
}

DeviceModelsForm.defaultProps = {
  deviceModel: undefined,
}
