import queryString from 'query-string'
import apiClient from './api-client'

function byPracticeId({ practiceId, patientId, checkDate, page, filters }) {
  const options = { withHeaders: true }
  let query = {}
  if (patientId) query.patient_id = patientId
  if (checkDate) query.check_date = checkDate
  if (page) query.page = page
  if (filters) query = { ...query, filters }
  return apiClient.get(
    queryString.stringifyUrl({
      url: `/practices/${practiceId}/office-checks`,
      query,
    }),
    options,
  )
}

function get(practiceId, id, query) {
  return apiClient.get(
    queryString.stringifyUrl({
      url: `/practices/${practiceId}/office-checks/${id}`,
      query,
    }),
  )
}

function create(practiceId, data) {
  return apiClient.post(`/practices/${practiceId}/office-checks`, {
    office_check: {
      patientId: data.patientId,
      checkDate: data.dateOfCheck,
    },
  })
}

async function getReportForm(id, practiceId) {
  return apiClient.get(
    `/practices/${practiceId}/office-checks/${id}/report/form`,
  )
}

async function saveReportForm(id, practiceId, reportValues) {
  return apiClient.put(
    `/practices/${practiceId}/office-checks/${id}/report/form`,
    reportValues,
  )
}

async function processDeviceReport(id, practiceId, formData) {
  return apiClient.patch(
    `/practices/${practiceId}/office-checks/${id}/report/process`,
    formData,
  )
}

function signReport(id, practiceId) {
  return apiClient.put(`/practices/${practiceId}/office-checks/${id}/signature`)
}

function destroy(id, practiceId) {
  return apiClient.destroy(`/practices/${practiceId}/office-checks/${id}`)
}

function getBatch(practiceId, checkDate) {
  return apiClient.get(
    `/practices/${practiceId}/office-checks/reports/batch?check_date=${checkDate}`,
  )
}

function createBatch(practiceId, checkDate) {
  return apiClient.post(
    `/practices/${practiceId}/office-checks/reports/batch?check_date=${checkDate}`,
  )
}

function downloadFile(practiceId, checkDate) {
  return apiClient.get(
    `/practices/${practiceId}/office-checks/reports/file?check_date=${checkDate}`,
  )
}

export default {
  byPracticeId,
  get,
  create,
  getReportForm,
  processDeviceReport,
  saveReportForm,
  signReport,
  destroy,
  getBatch,
  createBatch,
  downloadFile,
}
