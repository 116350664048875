import React, { useMemo } from 'react'
import { Icon, Tag } from 'antd'
import PropTypes from 'prop-types'
import { useTheme } from 'react-jss'

import { USERS_LETTER_STATUS } from '../../lib/constants'

const WelcomeLetterStatus = ({ status }) => {
  const { brandSuccess, brandDanger, brandInfo } = useTheme()
  const tag = useMemo(() => {
    switch (status) {
      case 1:
        return (
          <Tag color={brandInfo}>
            <Icon type="sync" spin /> {USERS_LETTER_STATUS['1']}
          </Tag>
        )
      case 2:
        return (
          <Tag color={brandSuccess}>
            <Icon type="check-circle" /> {USERS_LETTER_STATUS['2']}
          </Tag>
        )
      case 3:
        return (
          <Tag color={brandDanger}>
            <Icon type="close-circle" /> {USERS_LETTER_STATUS['3']}
          </Tag>
        )
      default:
        return (
          <Tag color="gold">
            <Icon type="exclamation-circle" /> {USERS_LETTER_STATUS['0']}
          </Tag>
        )
    }
  }, [brandDanger, brandInfo, brandSuccess, status])

  return tag
}

WelcomeLetterStatus.propTypes = {
  status: PropTypes.number,
}

WelcomeLetterStatus.defaultProps = {
  status: 0,
}

export default WelcomeLetterStatus
