import get from 'lodash/get'
import nanoid from 'nanoid'

// eslint-disable-next-line import/prefer-default-export
export function formElementPropsGenerator({
  formName = `form-${nanoid()}`,
  labels = {},
  placeholders = {},
  ...rest
} = {}) {
  return name => {
    const props = {
      ...rest,
      id: `${formName}-${name}`,
      name,
    }
    const label = get(labels, name)
    if (label !== undefined) props.label = label
    const placeholder = get(placeholders, name)
    if (placeholder !== undefined) props.placeholder = placeholder
    return props
  }
}
