import React from 'react'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '6px 0',
  },
  large: {
    height: '100%',
    marginTop: '0',
  },
})

function Loading({ large }) {
  const classes = useStyles()
  const className = classnames([classes.container, large && classes.large])

  return (
    <div className={className}>
      <Spin size={large ? 'large' : 'default'} />
    </div>
  )
}

Loading.propTypes = {
  large: PropTypes.bool,
}

Loading.defaultProps = {
  large: false,
}

export default Loading
