import { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useFetchCycles } from '../../redux/slices/practices'
import { useAsync } from '../../hooks'
import api from '../../api'

export default function usePracticeCycles() {
  const { practiceId } = useParams()
  const { error, pending: pendingCycles, clearError } = useAsync(
    useFetchCycles(practiceId),
  )
  const getPractice = useCallback(() => api.practices.getPractice(practiceId), [
    practiceId,
  ])
  const { pending, value: practice } = useAsync(getPractice)

  const cycleData = {
    error,
    clearError,
    pendingCycles,
  }

  const practiceData = {
    practice,
    pending,
    practiceId,
  }

  return [cycleData, practiceData]
}
