import React from 'react'
import { Link } from 'react-router-dom'
import { Typography, Button } from 'antd'
import { useTheme } from 'react-jss'

import { toPath } from '../routes'

const { Title } = Typography

const Error404 = () => {
  const theme = useTheme()
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        background: theme.brandPrimary,
        color: 'white',
        textAlign: 'center',
        padding: '24px',
      }}
    >
      <div>
        <Title style={{ fontSize: '30vh', color: 'white' }}>404</Title>

        <Title level={2} style={{ opacity: 0.4, color: 'white' }}>
          Oops. Nothing here...
        </Title>

        <Button type="secondary" style={{ marginTop: '40px' }}>
          <Link to={toPath.home()} style={{ color: theme.brandPrimary }}>
            Go Home
          </Link>
        </Button>
      </div>
    </div>
  )
}

export default Error404
