import { configureStore } from '@reduxjs/toolkit'
import reducer from './slices'

function configure({ initialState = {} } = {}) {
  const store = configureStore({
    reducer,
    preloadedState: initialState,
  })
  return store
}

export default configure
