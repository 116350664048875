import { Form, Select } from 'antd'
import { useField } from 'formik'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

const STATES = [
  ['Alabama', 'AL'],
  ['Alaska', 'AK'],
  ['American Samoa', 'AS'],
  ['Arizona', 'AZ'],
  ['Arkansas', 'AR'],
  ['California', 'CA'],
  ['Colorado', 'CO'],
  ['Connecticut', 'CT'],
  ['Delaware', 'DE'],
  ['District Of Columbia', 'DC'],
  ['Federated States Of Micronesia', 'FM'],
  ['Florida', 'FL'],
  ['Georgia', 'GA'],
  ['Guam', 'GU'],
  ['Hawaii', 'HI'],
  ['Idaho', 'ID'],
  ['Illinois', 'IL'],
  ['Indiana', 'IN'],
  ['Iowa', 'IA'],
  ['Kansas', 'KS'],
  ['Kentucky', 'KY'],
  ['Louisiana', 'LA'],
  ['Maine', 'ME'],
  ['Marshall Islands', 'MH'],
  ['Maryland', 'MD'],
  ['Massachusetts', 'MA'],
  ['Michigan', 'MI'],
  ['Minnesota', 'MN'],
  ['Mississippi', 'MS'],
  ['Missouri', 'MO'],
  ['Montana', 'MT'],
  ['Nebraska', 'NE'],
  ['Nevada', 'NV'],
  ['New Hampshire', 'NH'],
  ['New Jersey', 'NJ'],
  ['New Mexico', 'NM'],
  ['New York', 'NY'],
  ['North Carolina', 'NC'],
  ['North Dakota', 'ND'],
  ['Northern Mariana Islands', 'MP'],
  ['Ohio', 'OH'],
  ['Oklahoma', 'OK'],
  ['Oregon', 'OR'],
  ['Palau', 'PW'],
  ['Pennsylvania', 'PA'],
  ['Puerto Rico', 'PR'],
  ['Rhode Island', 'RI'],
  ['South Carolina', 'SC'],
  ['South Dakota', 'SD'],
  ['Tennessee', 'TN'],
  ['Texas', 'TX'],
  ['Utah', 'UT'],
  ['Vermont', 'VT'],
  ['Virgin Islands', 'VI'],
  ['Virginia', 'VA'],
  ['Washington', 'WA'],
  ['West Virginia', 'WV'],
  ['Wisconsin', 'WI'],
  ['Wyoming', 'WY'],
]

const { Item } = Form
const { Option } = Select

export default function USStateSelect({
  containerClassName,
  id,
  label,
  ...rest
}) {
  const [field, { error, touched }, { setValue, setTouched }] = useField(rest)
  const handleBlur = useCallback(() => setTouched(true), [setTouched])
  const hasError = Boolean(touched && error)
  return (
    <Item
      className={containerClassName}
      label={label}
      htmlFor={id}
      hasFeedback={hasError}
      validateStatus={hasError ? 'error' : ''}
      help={hasError && error}
    >
      <Select
        allowClear
        showSearch
        optionFilterProp="children"
        id={id}
        {...field} // eslint-disable-line react/jsx-props-no-spreading
        onChange={setValue}
        onBlur={handleBlur}
        placeholder="Select state"
        {...rest} // eslint-disable-line react/jsx-props-no-spreading
      >
        {STATES.map(state => (
          <Option key={state[1]}>{state[0]}</Option>
        ))}
      </Select>
    </Item>
  )
}

USStateSelect.propTypes = {
  containerClassName: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
}

USStateSelect.defaultProps = {
  containerClassName: undefined,
  id: undefined,
  label: undefined,
  type: undefined,
}
