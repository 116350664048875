import { useSelector } from 'react-redux'
import api from '../../api'
import { useAsyncAction } from '../utils'
import createSlice from '../create-slice'

const cliniciansInitialState = {
  result: [],
  pageSize: 0,
  total: 0,
}

const cliniciansSlice = createSlice({
  name: 'clinicians',
  initialState: cliniciansInitialState,
  reducers: {
    setClinicians: (state, { payload: { result } }) => {
      state.clinicians = result
    },
  },
})

const { actions } = cliniciansSlice

const selectState = state => state[cliniciansSlice.name]

export function useClinicians() {
  const { clinicians } = useSelector(selectState)
  return clinicians
}

export function fetchClinicians() {
  return async dispatch => {
    const body = await api.users.getClinicians()

    dispatch(actions.setClinicians({ result: body }))
  }
}

export function useFetchClinicians(useOnlyLastResponse = false) {
  return useAsyncAction(fetchClinicians)
}

export default cliniciansSlice
