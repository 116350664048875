import {
  TOKEN_STORAGE_OBSERVER,
  TOKEN_REMOVED_EVENT,
} from '../lib/token-storage'
import sessionSlice from './slices/session'
import configureStore from './index'

const store = configureStore()

TOKEN_STORAGE_OBSERVER.on(TOKEN_REMOVED_EVENT, () => {
  store.dispatch(sessionSlice.actions.logout())
})

export default store
