import { Alert } from 'antd'
import noop from 'lodash/noop'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../../api'
import { toPath } from '../../routes'

const UNAPPROVED_URL = queryString.stringifyUrl({
  url: toPath.patients(),
  query: { approved: 'false' },
})

export default function UnapprovedCount() {
  const [unapprovedCount, setUnapprovedCount] = useState()
  useEffect(() => {
    api.patients
      .getUnapprovedCount()
      .then(({ count }) => count && setUnapprovedCount(count))
      .catch(noop)
  }, [])
  if (!unapprovedCount) return null
  return (
    <Link to={UNAPPROVED_URL}>
      <Alert
        message={`You have ${unapprovedCount} patient${
          unapprovedCount > 1 ? 's' : ''
        } needing your approval. Click here.`}
        type="warning"
        showIcon
        closable
      />
    </Link>
  )
}
