import PropTypes from 'prop-types'
import { Form, Modal, Typography } from 'antd'
import React from 'react'
import { Formik } from 'formik'

const { Paragraph } = Typography

const INITIAL_VALUES = { confirm: '' }

export default function ReactivateModal({ visible, onSubmit, onCancel }) {
  return (
    <Formik initialValues={INITIAL_VALUES} onSubmit={onSubmit} validateOnMount>
      {({ handleSubmit, isSubmitting, isValid }) => (
        <Modal
          title="Do yo you want to reactivate this patient?"
          visible={visible}
          onOk={handleSubmit}
          onCancel={onCancel}
          okText="Reactivate this patient"
          okButtonProps={{ disabled: !isValid }}
          confirmLoading={isSubmitting}
          centered
          destroyOnClose
        >
          <Form onSubmit={handleSubmit}>
            <Paragraph>
              This patient has been deactivated. Would you like to reactivate
              them for IOC?
            </Paragraph>
          </Form>
        </Modal>
      )}
    </Formik>
  )
}

ReactivateModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
