import React from 'react'
import PropTypes from 'prop-types'

import { Table } from 'antd'

const getTextOrDefaultToDash = text => text || '-'

const COLUMNS = [
  {
    dataIndex: 'name',
    key: 'name',
    width: 120,
  },
  {
    title: 'Company',
    dataIndex: 'manufacturer',
    key: 'manufacturer',
    align: 'center',
    render: getTextOrDefaultToDash,
  },
  {
    title: 'Model #',
    dataIndex: 'modelNumber',
    key: 'modelNumber',
    align: 'center',
    render: getTextOrDefaultToDash,
  },
]

const LEAD_PROP_TYPES = {
  modelNumber: PropTypes.string,
  manufacturer: PropTypes.string,
}

export default function LeadInfo({ currentLead }) {
  const { rightAtrium, rightVentricle, leftVentricle } = currentLead || {}

  const data = [
    { name: 'Right Atrium', ...rightAtrium },
    { name: 'Right Ventricle', ...rightVentricle },
    { name: 'Left Ventricle', ...leftVentricle },
  ]
  return (
    <Table
      bordered
      columns={COLUMNS}
      dataSource={data}
      pagination={false}
      size="small"
      rowKey="name"
    />
  )
}

LeadInfo.propTypes = {
  currentLead: PropTypes.oneOf([
    PropTypes.shape({
      rightAtrium: LEAD_PROP_TYPES,
      rightVentricle: LEAD_PROP_TYPES,
      leftVentricle: LEAD_PROP_TYPES,
    }),
    {},
  ]),
}

LeadInfo.defaultProps = {
  currentLead: {},
}
