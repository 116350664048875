import moment from 'moment-timezone'
import fromPairs from 'lodash/fromPairs'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { Table, Col, Row } from 'antd'

export default function ConnectivityRates({ rates }) {
  const columns = useMemo(
    () =>
      rates.map(rate => ({
        key: rate.cycle.id,
        title: moment(rate.cycle.endsAt).format('LL'),
        dataIndex: rate.cycle.id,
        render(text) {
          return `${(Number(text) * 100).toFixed(0)}%`
        },
      })),
    [rates],
  )
  const data = useMemo(
    () => [
      {
        ...fromPairs(rates.map(rate => [rate.cycle.id, rate.conectivityRate])),
        ...fromPairs(rates.map(rate => ['key', rate.cycle.id])),
      },
    ],
    [rates],
  )
  return (
    <Row gutter={[0, 50]}>
      <Col span={24}>
        <Table
          columns={columns}
          dataSource={data}
          size="small"
          scroll={{ x: 'max-content' }}
          pagination={false}
        />
      </Col>
    </Row>
  )
}

ConnectivityRates.propTypes = {
  rates: PropTypes.arrayOf(
    PropTypes.shape({
      cycle: PropTypes.shape({
        id: PropTypes.string.isRequired,
        endsAt: PropTypes.string.isRequired,
      }).isRequired,
      conectivityRate: PropTypes.number.isRequired,
    }),
  ).isRequired,
}
