/* eslint-disable react/jsx-props-no-spreading */
import { Button, Form } from 'antd'
import classnames from 'classnames'
import { Formik } from 'formik'
import moment from 'moment'
import KeyMirror from 'keymirror'
import pick from 'lodash/pick'
import PropTypes from 'prop-types'
import React, { useMemo, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { formElementPropsGenerator } from '../../lib/forms'
import ApprovalStatusSelect, {
  STATUSES as APPROVED_STATUSES,
} from './ApprovalStatusSelect'
import SigningStatusSelect, {
  STATUSES as SIGNED_STATUSES,
} from './SigningStatusSelect'
import AuthorizedStatusSelect, {
  STATUSES as AUTHORIZED_STATUSES,
} from './AuthorizedStatusSelect'
import AcknowledgedSelect, {
  STATUSES as ACKNOWLEDGED_STATUSES,
} from './AcknowledgedSelect'
import OfficeCheckFilterSelect, {
  STATUSES as OFC_STATUSES,
} from './OfficeCheckFilterSelect'
import Checkbox from './Checkbox'
import DeviceTypeSelect from './DeviceTypeSelect'
import MedicalProviderSelect from './MedicalProviderSelect'
import EnrollmentStatusSelect, {
  STATUSES as ENROLLMENT_STATUSES,
} from './EnrollmentStatusSelect'
import ManufacturerSelect, { MANUFACTURERS } from './ManufacturerSelect'
import PairingStatusSelect, {
  STATUSES as PAIRING_STATUSES,
} from './PairingStatusSelect'
import PracticeSelect from './PracticeSelect'
import ClinicianSelect from './ClinicianSelect'
import SearchBar from './SearchBar'
import DatePicker from './DatePicker'
import SubmitOnChange from './SubmitOnChange'
import ReminderStatusSelect, {
  STATUSES as REMINDER_STATUS,
} from './ReminderStatusSelect'
import ReminderTypeSelect, {
  TYPES as REMINDER_TYPES,
} from './ReminderTypeSelect'
import MonitoringTypeSelect, {
  TYPES as MONITORING_TYPES,
} from './MonitoringTypeSelect'

const useStyles = createUseStyles({
  filtersContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 20,
  },
  small: {},
  filterItem: {
    flex: 1,
    minWidth: 150,
    '&$small': {
      minWidth: 130,
      flex: 0,
    },
  },
})

const wrapperCol = { span: 24 }

const FIELD_NAMES = KeyMirror({
  approved: null,
  deviceTypeId: null,
  medicalProviderId: null,
  deviceModelManufacturer: null,
  enrolled: null,
  paired: null,
  practiceId: null,
  query: null,
  showInactive: null,
  assigneeId: null,
  signed: null,
  acknowledged: null,
  reminderStatus: null,
  reminderType: null,
  monitoring: null,
  birthDate: null,
  filters: null,
})

const LABELS = {
  [FIELD_NAMES.showInactive]: 'Show inactive',
}

const PLACEHOLDERS = {
  [FIELD_NAMES.deviceModelManufacturer]: 'Filter by device company',
  [FIELD_NAMES.deviceTypeId]: 'Filter by device type',
  [FIELD_NAMES.practiceId]: 'Filter by practice',
  [FIELD_NAMES.query]: 'Search',
  [FIELD_NAMES.assigneeId]: 'Filter by clinician',
  [FIELD_NAMES.reminderStatus]: 'Status',
  [FIELD_NAMES.reminderType]: 'Message Type',
  [FIELD_NAMES.monitoring]: 'Monitoring',
  [FIELD_NAMES.birthDate]: 'Filter by date of birth',
}

function buildInitialValues(values) {
  const initialValues = pick(values, Object.keys(FIELD_NAMES))
  initialValues.showInactive = Boolean(initialValues.showInactive)
  initialValues.approved = String(initialValues.approved)
  initialValues.enrolled = String(initialValues.enrolled)
  initialValues.paired = String(initialValues.paired)
  initialValues.monitoring = String(initialValues.monitoring)
  initialValues.deviceModelManufacturer = String(
    initialValues.deviceModelManufacturer,
  )
  return initialValues
}

export const FIELDS_OPTIONS = {
  approved: APPROVED_STATUSES,
  enrolled: ENROLLMENT_STATUSES,
  paired: PAIRING_STATUSES,
  signed: SIGNED_STATUSES,
  authorized: AUTHORIZED_STATUSES,
  acknowledged: ACKNOWLEDGED_STATUSES,
  filters: OFC_STATUSES,
  reminderStatus: REMINDER_STATUS,
  reminderTypes: REMINDER_TYPES,
  deviceModelManufacturer: MANUFACTURERS,
  monitoringTypes: MONITORING_TYPES,
}

export default function Filters({
  defaultValues,
  currentValues,
  placeholders,
  onSubmit,
  showApprovalStatus,
  showDeviceType,
  showMedicalProvider,
  showDeviceManufacturer,
  showEnrollment,
  showInactiveCheck,
  showPairing,
  showPractice,
  showClinician,
  showSearch,
  extraFilter,
  showSigningStatus,
  showAuthorizedStatus,
  showOfficeCheckFilters,
  showAcknowledged,
  showReminders,
  showMonitoringType,
  showBirthDate,
}) {
  const propsFor = useCallback(
    formElementPropsGenerator({
      formName: 'filters-form',
      labels: LABELS,
      placeholders: { ...PLACEHOLDERS, ...placeholders },
      fullWidth: true,
    }),
    [placeholders],
  )
  const classes = useStyles()
  const processedInitialValues = useMemo(
    () => buildInitialValues(currentValues),
    [currentValues],
  )
  const filtersQuantity = useMemo(
    () =>
      [
        showApprovalStatus,
        showDeviceType,
        showMedicalProvider,
        showDeviceManufacturer,
        showEnrollment,
        showInactiveCheck,
        showPairing,
        showPractice,
        showClinician,
        showSearch,
        extraFilter,
        showSigningStatus,
        showAuthorizedStatus,
        showOfficeCheckFilters,
        showAcknowledged,
        showReminders,
        showMonitoringType,
        showBirthDate,
      ].filter(Boolean).length,
    [
      showApprovalStatus,
      showDeviceType,
      showMedicalProvider,
      showDeviceManufacturer,
      showEnrollment,
      showInactiveCheck,
      showPairing,
      showPractice,
      showClinician,
      showSearch,
      extraFilter,
      showSigningStatus,
      showAuthorizedStatus,
      showOfficeCheckFilters,
      showAcknowledged,
      showReminders,
      showMonitoringType,
      showBirthDate,
    ],
  )
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={processedInitialValues}
      enableReinitialize
    >
      {({ handleSubmit, resetForm }) => (
        <Form
          layout="inline"
          wrapperCol={wrapperCol}
          className={classes.filtersContainer}
          onSubmit={handleSubmit}
        >
          <SubmitOnChange debounced />
          {showPractice && (
            <PracticeSelect
              {...propsFor('practiceId')}
              containerClassName={classes.filterItem}
            />
          )}
          {showClinician && (
            <ClinicianSelect
              {...propsFor('assigneeId')}
              containerClassName={classes.filterItem}
            />
          )}
          {showSearch && (
            <SearchBar
              {...propsFor('query')}
              containerClassName={classes.filterItem}
            />
          )}
          {showDeviceType && (
            <DeviceTypeSelect
              {...propsFor('deviceTypeId')}
              allowClear
              containerClassName={classes.filterItem}
            />
          )}
          {showMedicalProvider && currentValues.practiceId && (
            <MedicalProviderSelect
              {...propsFor('medicalProviderId')}
              allowClear
              containerClassName={classes.filterItem}
              practiceId={currentValues.practiceId}
            />
          )}
          {showBirthDate && (
            <DatePicker
              {...propsFor('birthDate')}
              showToday
              allowClear
              defaultPickerValue={moment().subtract(40, 'years')}
              placeholder={PLACEHOLDERS.birthDate}
              disabledDate={current =>
                current && current >= moment().endOf('day')
              }
            />
          )}
          {showDeviceManufacturer && (
            <ManufacturerSelect
              {...propsFor('deviceModelManufacturer')}
              containerClassName={classnames(classes.filterItem, classes.small)}
            />
          )}
          {showEnrollment && (
            <EnrollmentStatusSelect
              {...propsFor('enrolled')}
              containerClassName={classnames(classes.filterItem, classes.small)}
            />
          )}
          {showPairing && (
            <PairingStatusSelect
              {...propsFor('paired')}
              containerClassName={classnames(classes.filterItem, classes.small)}
            />
          )}
          {showApprovalStatus && (
            <ApprovalStatusSelect
              {...propsFor('approved')}
              containerClassName={classnames(classes.filterItem, classes.small)}
            />
          )}
          {showMonitoringType && (
            <MonitoringTypeSelect
              // eslint-disable-next-line
              {...propsFor('monitoring')}
              containerClassName={classnames(classes.filterItem, classes.small)}
            />
          )}
          {showAuthorizedStatus && (
            <AuthorizedStatusSelect
              {...propsFor('authorized')}
              className={classnames(classes.filterItem, classes.small)}
            />
          )}
          {showSigningStatus && (
            <SigningStatusSelect
              {...propsFor('signed')}
              className={classnames(classes.filterItem, classes.small)}
            />
          )}
          {showOfficeCheckFilters && (
            <OfficeCheckFilterSelect
              {...propsFor('filters')}
              className={classnames(classes.filterItem, classes.small)}
            />
          )}
          {showAcknowledged && (
            <AcknowledgedSelect
              {...propsFor('acknowledged')}
              className={classnames(classes.filterItem, classes.small)}
            />
          )}
          {showInactiveCheck && (
            <Checkbox
              {...propsFor('showInactive')}
              containerClassName={classnames(classes.filterItem, classes.small)}
            />
          )}
          {showReminders && (
            <>
              <ReminderStatusSelect
                {...propsFor('reminderStatus')}
                className={classnames(classes.filterItem, classes.small)}
              />
              <ReminderTypeSelect
                {...propsFor('reminderType')}
                className={classnames(classes.filterItem, classes.small)}
              />
            </>
          )}
          {extraFilter}
          {filtersQuantity > 1 && (
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => resetForm({ values: defaultValues })}
              >
                Reset
              </Button>
            </Form.Item>
          )}
        </Form>
      )}
    </Formik>
  )
}

const valuesShape = PropTypes.shape({
  approved: PropTypes.string,
  enrolled: PropTypes.string,
  paired: PropTypes.string,
  practiceId: PropTypes.string,
  query: PropTypes.string,
  showInactive: PropTypes.bool,
  monitoring: PropTypes.string,
})

Filters.propTypes = {
  currentValues: valuesShape,
  defaultValues: valuesShape,
  placeholders: valuesShape,
  onSubmit: PropTypes.func.isRequired,
  showApprovalStatus: PropTypes.bool,
  showDeviceType: PropTypes.bool,
  showMedicalProvider: PropTypes.bool,
  showDeviceManufacturer: PropTypes.bool,
  showEnrollment: PropTypes.bool,
  showInactiveCheck: PropTypes.bool,
  showPairing: PropTypes.bool,
  showPractice: PropTypes.bool,
  showSearch: PropTypes.bool,
  showClinician: PropTypes.bool,
  extraFilter: PropTypes.node,
  showSigningStatus: PropTypes.bool,
  showAuthorizedStatus: PropTypes.bool,
  showOfficeCheckFilters: PropTypes.bool,
  showAcknowledged: PropTypes.bool,
  showReminders: PropTypes.bool,
  showMonitoringType: PropTypes.bool,
  showBirthDate: PropTypes.bool,
}

Filters.defaultProps = {
  currentValues: {},
  defaultValues: {},
  placeholders: {},
  showApprovalStatus: false,
  showDeviceType: false,
  showMedicalProvider: false,
  showDeviceManufacturer: false,
  showEnrollment: false,
  showInactiveCheck: false,
  showPairing: false,
  showPractice: false,
  showSearch: false,
  showClinician: false,
  extraFilter: null,
  showSigningStatus: false,
  showAuthorizedStatus: false,
  showOfficeCheckFilters: false,
  showAcknowledged: false,
  showReminders: false,
  showMonitoringType: false,
  showBirthDate: false,
}
