const DEFAULT_MESSAGE =
  'There was a problem processing your request, please try again later'

function build(response, json) {
  const apiError = new Error()
  apiError.code = response.status
  apiError.description = response.statusText
  apiError.message = json.message || DEFAULT_MESSAGE
  apiError.response = json
  return apiError
}

export default {
  build,
}
