import React, { useCallback, useMemo, useState } from 'react'
import { Button, Divider, message, Popconfirm, Table } from 'antd'
import { Link } from 'react-router-dom'
import { ListError, PageHeader } from '../Common'
import { useAsync } from '../../hooks'
import api from '../../api'
import Filters from '../Forms/Filters'
import AddNewItemButton from '../Common/AddNewItemButton'
import { toPath } from '../../routes'

const COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Manufacturer',
    dataIndex: 'manufacturer',
    key: 'manufacturer',
  },
]

function filter(value, search) {
  return value.toLowerCase().includes(search.toLowerCase())
}

export default function DeviceModels() {
  const fetchDeviceModels = useCallback(() => api.deviceModels.list(), [])
  const {
    pending,
    error,
    clearError,
    value: deviceModels,
    execute: fetchList,
  } = useAsync(fetchDeviceModels)
  const [queryFilter, setQueryFilter] = useState('')
  const onSubmit = useCallback(({ query }) => {
    setQueryFilter(query)
  }, [])

  const models = useMemo(() => {
    return (deviceModels || []).filter(
      ({ name, manufacturer }) =>
        filter(name, queryFilter) || filter(manufacturer, queryFilter),
    )
  }, [deviceModels, queryFilter])

  const { pending: deleting, execute: handleDelete } = useAsync(async id => {
    try {
      await api.deviceModels.delete(id)
      message.success('Device model successfully deactivated')
    } catch (e) {
      message.error(
        `There was an error deactivating this device model: ${e.message}`,
      )
    }
    fetchList()
  }, false)

  const columns = useMemo(
    () => [
      ...COLUMNS,
      {
        title: 'Actions',
        key: 'actions',
        render: (text, record) => {
          return (
            <span>
              <Link
                to={toPath.deviceModels.deviceModel.edit({ id: record.id })}
                className="edit-button"
              >
                <Button type="link" icon="edit" />
              </Link>
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure you want to deactivate this device model?"
                onConfirm={() => handleDelete(record.id)}
                okText="Yes"
                cancelText="No"
                placement="topRight"
              >
                <Button type="link" icon="delete" className="delete-button" />
              </Popconfirm>
            </span>
          )
        },
      },
    ],
    [handleDelete],
  )

  return (
    <div>
      <PageHeader
        title="Device Models"
        extra={
          <AddNewItemButton
            itemName="device model"
            path={toPath.deviceModels.new()}
            icon="plus-circle"
          />
        }
      />
      {error && <ListError description={error.message} onClose={clearError} />}
      <Filters onSubmit={onSubmit} showSearch />
      <Table
        columns={columns}
        dataSource={models}
        loading={pending || deleting}
        rowKey="id"
      />
    </div>
  )
}
