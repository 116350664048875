import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { createUseStyles, useTheme } from 'react-jss'
import cn from 'classnames'
import PropTypes from 'prop-types'

import { useAsync } from '../../hooks'
import api from '../../api'

/**
 * Filters an array of entries based on the provided date field and returns the entries grouped by day of the month.
 *
 * @param {Array} arr - The array of entries.
 * @param {Date} monthDate - The month date to filter the entries.
 * @param {string} [dateField = 'created_at'] - The field in the entries to filter by.
 * @returns {Array} - An array of entries grouped by day of the month.
 */
const getMonthEntriesByDay = (arr, monthDate, dateField = 'createdAt') => {
  try {
    // Filter this month entries by provided field and sort them.
    const sorted = _.sortBy(
      arr.filter(entry => {
        return entry?.[dateField]?.startsWith(monthDate) || false
      }),
      [dateField],
    )

    // Group entries by day.
    const entriesPerDay = sorted.reduce((sum, entry) => {
      const date = getDateDay(entry[dateField])
      const index = sum.findIndex(item => item.date === date)
      if (index > -1) {
        sum[index].entries.push(entry)
      } else sum.push({ date, entries: [entry] })
      return sum
    }, [])

    return entriesPerDay
  } catch (e) {
    return []
  }
}

/**
Returns the month portion of the provided date string.
@param {string} date - The date string.
@returns {string} - The month portion of the date string.
*/
const getDateMonth = date => date.substring(0, 7)

/**
Returns the day portion of the provided date string.
@param {string} date - The date string.
@returns {string} - The day portion of the date string.
*/
const getDateDay = date => date.substring(0, 10)

/**
 * Returns the translate percent to position the date inside the month cell.
 * @param {*} date - The date string
 * @returns - The percentage as a string.
 */
const getTranslatePosition = date => {
  const day = moment(date).format('D')
  const daysInMonth = moment(date).daysInMonth()
  return `${Math.floor((day * 100) / daysInMonth)}%`
}

/**
 * Returns an array with the body value of an async call, or an empty array.
 * @param {*} asyncResponse - The result of an useAsync function.
 * @returns - An array with the body or an empty array.
 */
const getAsyncBody = asyncValue => asyncValue?.body || []

const START_DATE = moment()
  .subtract(12, 'months')
  .startOf('month')
  .format('YYYY-MM-DD')
const RANGE = 25
const TD_WIDTH = 200
const TD_WIDTH_PX = `${200}px`

const Timeline = ({ practiceId, patientId }) => {
  const {
    brandPrimary,
    brandSuccess,
    blue,
    grayPrimary,
    brandWarning,
    brandDangerMid,
  } = useTheme()
  const grayBorder = {
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '#EDEDED',
  }
  const css = createUseStyles({
    container: {
      overflowX: 'scroll',
      paddingBottom: '10px',
      scrollbarWidth: 'thin',
      scrollbarColor: `${grayPrimary} transparent`,
      '&::-webkit-scrollbar': {
        '-webkit-appearance': 'none',
        width: 8,
        height: 8,
      },
      '&::-webkit-scrollbar-thumb': {
        borderRadius: 4,
        backgroundColor: grayPrimary,
        '-webkit-box-shadow': '0 0 1px rgba(255,255,255,.5)',
      },
    },
    table: {
      borderCollapse: 'collapse',
      marginLeft: TD_WIDTH_PX,
      '& tr:last-child td': {
        borderBottomWidth: '1px',
      },
    },
    tdGrayBorder: {
      ...grayBorder,
    },
    td: {
      padding: '10px 14px',
      ...grayBorder,
    },
    tdContent: {
      borderBottomWidth: '0',
      borderTopWidth: '0',
      padding: '0',
      height: '60px',
    },
    tdHeadMonth: {
      fontWeight: 'bold',
      '&:nth-child(1)': {
        borderLeftWidth: 0,
      },
    },
    tdHeadYear: {
      fontWeight: 'bold',
      color: brandPrimary,
      border: 'none',
      padding: '0',
      '&:last-child div': {
        borderRadius: '0 8px 0 0',
        borderRightWidth: '1px',
      },
    },
    tdHeadYearDiv: {
      ...grayBorder,
      borderLeftWidth: 0,
      borderBottom: 'none',
      padding: '12px 14px',
    },
    stickyYear: {
      position: 'sticky',
      left: `${TD_WIDTH + 10}px`,
    },
    tbody: {
      backgroundColor: '#F7F7F8',
    },
    tdWrapper: {
      width: TD_WIDTH_PX,
      position: 'relative',
      height: '100%',
      padding: '0 10px',
    },
    dayEntry: {
      borderRadius: '4px',
      transformOrigin: 'center',
      padding: '4px 20px',
      color: 'white',
      fontSize: '1em',
      display: 'inline-block',
      position: 'absolute',
      borderWidth: '1px',
      borderColor: '#F7F7F8',
      borderStyle: 'solid',
      transition: 'font-weight .2s ease',
      '--antd-wave-shadow-color': 'none',
      '&::selection': {
        background: 'none',
      },
      '&:hover': {
        color: 'white',
        zIndex: '32  !important',
      },
    },
    alertDayColor: {
      backgroundColor: brandDangerMid,
    },
    officeCheckColor: {
      backgroundColor: brandSuccess,
    },
    receivedDayColor: {
      backgroundColor: blue,
    },
    missedTransmissionDayColor: {
      backgroundColor: 'white',
      borderColor: brandWarning,
      color: brandWarning,
      '&:hover, &:active': {
        color: brandWarning,
      },
    },
    missedAuthorizationDayColor: {
      backgroundColor: brandWarning,
    },
    tdTitleCell: {
      background: 'none',
      padding: 0,
      position: 'absolute',
      left: 0,
      zIndex: 100,
    },
    tdTitleCellEmpty: {
      background: 'white',
      '& div': {
        borderWidth: '0 1px 0 0',
        width: `${TD_WIDTH + 1}px`,
      },
      '& + td, & + td div': {
        borderLeftWidth: 0,
      },
    },
    titleCell: {
      background: 'white',
      borderRadius: '4px 0 0 4px',
      height: '60px',
      display: 'flex',
      alignContent: 'center',
      fontWeight: 'bold',
      padding: '0 15px',
      fontSize: '.8em',
      flexWrap: 'wrap',
      position: 'relative',
      overflow: 'hidden',
      width: TD_WIDTH_PX,
      borderRightWidth: 0,
      borderBottomWidth: 0,
      '&::before': {
        content: '""',
        position: 'absolute',
        width: '5px',
        height: '100%',
        display: 'block',
        top: '0',
        left: '0',
      },
      ...grayBorder,
    },
    titleCellOfficeChecks: {
      color: brandSuccess,
      borderBottomWidth: 1,
      '&::before': {
        background: brandSuccess,
      },
    },
    titleCellAlerts: {
      color: brandDangerMid,
      '&::before': {
        background: brandDangerMid,
      },
    },
    titleCellTransmissions: {
      color: blue,
      '&::before': {
        background: blue,
        height: '50%',
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '5px',
        height: '100%',
        display: 'block',
        top: '50%',
        left: '0',
        background: brandWarning,
      },
    },
    tdCurrentMonth: {
      background: '#ebe8e8',
    },
    rowLabel: {
      '&::before': {
        content: '""',
        width: 15,
        height: 10,
        display: 'inline-block',
        marginRight: 5,
        borderRadius: 3,
      },
      marginBottom: 2,
    },
    rowLabelReceived: {
      '&::before': {
        backgroundColor: blue,
      },
      color: blue,
    },
    rowLabelNoAuthorization: {
      '&::before': {
        backgroundColor: brandWarning,
      },
      color: brandWarning,
    },
    rowLabelNoTransmission: {
      '&::before': {
        border: `solid 1px ${brandWarning}`,
      },
      color: brandWarning,
    },
    rowLabelAlerts: {
      '&::before': {
        backgroundColor: brandDangerMid,
      },
    },
    rowLabelOfficeChecks: {
      '&::before': {
        backgroundColor: brandSuccess,
      },
    },
  })()

  // Define the columns for the table
  const monthDates = _.range(RANGE)
    .map(i => {
      const date = moment(START_DATE)
        .add(i, 'months')
        .startOf('month')
        .format('YYYY-MM-DD')
      return {
        date,
        year: moment(date).format('YYYY'),
      }
    })
    .reduce((sum, item, i, dates) => {
      // Check if it's the first year appearance
      const find = dates.findIndex(d => d.year === item.year)

      return [
        ...sum,
        {
          ...item,
          // In that case, look for the last appearance and get how many cells the year spans
          span:
            find === i
              ? dates.findLastIndex(d => d.year === item.year) - find + 1
              : 0,
        },
      ]
    }, [])

  // Fetch all dates for the timeline.
  const getReports = useCallback(
    async () => api.patients.getReports(patientId),
    [patientId],
  )
  const getMissedTransmissions = useCallback(
    async () => api.patients.getMissedTransmissions(patientId),
    [patientId],
  )
  const getAlerts = useCallback(async () => api.patients.getAlerts(patientId), [
    patientId,
  ])
  const getOfficeChecks = useCallback(
    async () => api.patients.getOfficeChecks(practiceId, patientId),
    [patientId, practiceId],
  )
  const missedTransmissions = useAsync(getMissedTransmissions)
  const receivedTransmissions = useAsync(getReports)
  const alerts = useAsync(getAlerts)
  const officeChecks = useAsync(getOfficeChecks)

  // Map all entries to it's month.
  const mappedMonthEntries = useMemo(() => {
    const transmissions = {
      received: [],
      missedAuthorizations: [],
      missedTransmissions: [],
    }
    const reports = getAsyncBody(receivedTransmissions.value) || []
    const missed = getAsyncBody(missedTransmissions.value) || []

    // Go through all reports, the ones that have a matching entry in missed transmissions array are
    // missed authorizations, the rest are received reports.
    reports.forEach(report => {
      const m = missed.find(mt => mt?.reportId === report.id)
      if (m) transmissions.missedAuthorizations.push(m)
      else transmissions.received.push(report)
    })

    // Every entry in missed transmissions that doesn't have a matching
    // report is a missed transmission.
    transmissions.missedTransmissions = missed.filter(
      m => !reports.find(report => report.id === m?.reportId),
    )

    return monthDates.map(month => {
      return {
        ...month,
        alerts: getMonthEntriesByDay(
          getAsyncBody(alerts.value),
          getDateMonth(month.date),
        ),
        received: getMonthEntriesByDay(
          transmissions.received,
          getDateMonth(month.date),
          'reportDate',
        ),
        missedTransmissions: getMonthEntriesByDay(
          transmissions.missedTransmissions,
          getDateMonth(month.date),
          'cycleEndedAt',
        ),
        missedAuthorizations: getMonthEntriesByDay(
          transmissions.missedAuthorizations,
          getDateMonth(month.date),
          'cycleEndedAt',
        ),
        officeChecks: getMonthEntriesByDay(
          getAsyncBody(officeChecks.value),
          getDateMonth(month.date),
          'checkDate',
        ),
      }
    })
  }, [
    alerts.value,
    missedTransmissions.value,
    monthDates,
    officeChecks.value,
    receivedTransmissions.value,
  ])

  /**
   * Renders a td for every month and passes the month entry to a rendering prop.
   */
  const MonthColumns = useCallback(
    ({ renderer }) => {
      return mappedMonthEntries.map(monthEntries => {
        return (
          <td
            key={monthEntries.date}
            className={cn([css.td, css.tdContent], {
              [css.tdCurrentMonth]:
                monthEntries.date === `${moment().format('YYYY-MM')}-01`,
            })}
          >
            <div className={css.tdWrapper}>{renderer(monthEntries)}</div>
          </td>
        )
      })
    },
    [
      css.td,
      css.tdContent,
      css.tdCurrentMonth,
      css.tdWrapper,
      mappedMonthEntries,
    ],
  )

  /**
   * Renders all date entries in a month as spans relatively positioned.
   */
  const renderMonthEntries = ({ entries, className, link }) => {
    if (entries.length === 0) return <></>

    return entries.map(dayEntry => {
      const formattedDay = moment(dayEntry.date).format('DD')
      const position = getTranslatePosition(dayEntry.date)
      // We can use a function to build the link or use a link property in the entry.
      let url
      try {
        if (typeof link === 'function') url = link(dayEntry)
        else if (typeof dayEntry?.link === 'string') url = dayEntry.link
        else url = '#'
      } catch {
        url = '#'
      }
      return (
        <a
          href={url}
          title={formattedDay}
          key={dayEntry.date}
          className={cn([css.dayEntry, className])}
          style={{
            left: position,
            top: '50%',
            transform: `translate(-${position}, -50%)`,
            zIndex: moment(dayEntry.date).format('D'),
          }}
        >
          {formattedDay}
        </a>
      )
    })
  }

  const tableRef = useRef(null)

  useEffect(() => {
    if (tableRef.current) tableRef.current.scrollLeft = TD_WIDTH * 12 + 12
  }, [patientId])

  const EmptyCell = () => (
    <td className={cn([css.tdTitleCell, css.tdTitleCellEmpty])}>
      <div className={cn([css.titleCell, css.tdHeadYearDiv])} />
    </td>
  )

  const getLastEntry = entry => entry.entries[entry.entries.length - 1]

  return (
    <div style={{ position: 'relative' }}>
      <div className={cn(['Timeline', css.container])} ref={tableRef}>
        <table className={css.table}>
          <thead>
            <tr className={css.trHeadYear}>
              <EmptyCell />
              {monthDates
                // Only year cells
                .filter(m => m.span)
                .map(date => (
                  <td
                    key={date.year}
                    className={cn([css.td, css.tdHeadYear])}
                    colSpan={date.span}
                  >
                    <div className={css.tdHeadYearDiv}>
                      <span className={css.stickyYear}>{date.year}</span>
                    </div>
                  </td>
                ))}
            </tr>
            <tr>
              <EmptyCell />
              {monthDates.map(date => (
                <td
                  key={date.date}
                  className={cn([css.td, css.tdHeadMonth], {
                    [css.tdCurrentMonth]:
                      date.date === `${moment().format('YYYY-MM')}-01`,
                  })}
                >
                  {moment(date.date).format('MMM')}
                </td>
              ))}
            </tr>
          </thead>
          <tbody className={css.tbody}>
            <tr>
              <td className={cn([css.tdTitleCell])}>
                <div
                  className={cn([css.titleCell, css.titleCellTransmissions])}
                >
                  <div className={cn(css.rowLabel, css.rowLabelReceived)}>
                    BioBridge Reports
                  </div>
                  <div
                    className={cn(css.rowLabel, css.rowLabelNoAuthorization)}
                  >
                    Missing Authorization
                  </div>
                  <div className={cn(css.rowLabel, css.rowLabelNoTransmission)}>
                    Missing Transmission
                  </div>
                </div>
              </td>
              <MonthColumns
                renderer={monthEntry => (
                  <>
                    <div className={css.tdReceived}>
                      {renderMonthEntries({
                        entries: monthEntry.received,
                        className: css.receivedDayColor,
                        link: entry => `/reports/${getLastEntry(entry)?.id}`,
                      })}
                      {renderMonthEntries({
                        entries: monthEntry.missedAuthorizations,
                        className: css.missedAuthorizationDayColor,
                        link: entry =>
                          `/reports/${getLastEntry(entry)?.reportId}`,
                      })}
                      {renderMonthEntries({
                        entries: monthEntry.missedTransmissions,
                        className: css.missedTransmissionDayColor,
                        link: entry =>
                          `/missed-transmissions/${getLastEntry(entry)?.id}`,
                      })}
                    </div>
                  </>
                )}
              />
            </tr>
            <tr>
              <td className={cn([css.tdTitleCell])}>
                <div className={cn([css.titleCell, css.titleCellAlerts])}>
                  <div className={cn(css.rowLabel, css.rowLabelAlerts)}>
                    Alerts
                  </div>
                </div>
              </td>
              <MonthColumns
                renderer={monthEntry =>
                  renderMonthEntries({
                    entries: monthEntry.alerts,
                    className: css.alertDayColor,
                    link: entry => `/alerts/${getLastEntry(entry)?.id}`,
                  })
                }
              />
            </tr>
            <tr>
              <td className={cn([css.tdTitleCell])}>
                <div className={cn([css.titleCell, css.titleCellOfficeChecks])}>
                  <div className={cn(css.rowLabel, css.rowLabelOfficeChecks)}>
                    Office Checks
                  </div>
                </div>
              </td>
              <MonthColumns
                renderer={monthEntry => {
                  return renderMonthEntries({
                    entries: monthEntry.officeChecks,
                    className: css.officeCheckColor,
                    link: entry =>
                      `/practices/${practiceId}/office-checks/${
                        getLastEntry(entry)?.id
                      }`,
                  })
                }}
              />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

Timeline.propTypes = {
  practiceId: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
}

export default Timeline
