import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Icon, Badge } from 'antd'
import { createUseStyles, useTheme } from 'react-jss'

const useStyles = createUseStyles({
  badge: {
    marginLeft: 10,
  },
  title: {},
})

export default function MenuLink({
  route,
  title,
  icon,
  theme,
  badgeCount,
  color,
}) {
  const classes = useStyles()
  const { brandPrimary } = useTheme()
  const col = color || brandPrimary

  return (
    <Link to={route}>
      <Icon type={icon} theme={theme} style={{ fontSize: 18, color: col }} />
      <span style={{ color: col }}>{title}</span>
      {Boolean(badgeCount) && (
        <Badge count={badgeCount} className={classes.badge} />
      )}
    </Link>
  )
}

MenuLink.propTypes = {
  route: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  badgeCount: PropTypes.number,
  theme: PropTypes.string,
  color: PropTypes.string,
}

MenuLink.defaultProps = {
  badgeCount: undefined,
  theme: 'outlined',
  color: '',
}
