import React, { useCallback } from 'react'
import api from '../../api'
import AsyncSelect from './AsyncSelect'

export default function AccountManagerSelect(props) {
  const fetchOptions = useCallback(async () => {
    const admins = await api.users.getUsersByRole()
    return admins.map(adminUser => {
      const value = {
        id: adminUser.id,
      }
      const fullName = `${adminUser.firstName} ${adminUser.lastName}`
      value.name = `${fullName} <${adminUser.email}>`
      return value
    })
  }, [])

  return (
    <AsyncSelect
      fetchOptions={fetchOptions}
      showSearch
      allowClear
      optionFilterProp="children"
      placeholder="Select an Account Manager"
      {...props}
    />
  )
}
