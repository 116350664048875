import React, { useCallback, useMemo } from 'react'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import { message } from 'antd'
import mapValues from 'lodash/mapValues'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import api from '../../../api'
import { useAsync } from '../../../hooks'
import { PageHeader } from '../../Common'
import Loading from '../../Loading'
import { updatePreAuthorizationOfficeCheck } from '../../../redux/slices/pre-authorizations'
import { toPath } from '../../../routes'
import Edit from './Form'

export default function EditOfficeAuthoziation() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const practiceId = searchParams.get('practiceId')
  const getPreAuthorization = useCallback(() => api.preAuthorizations.get(id), [
    id,
  ])
  const { pending, value } = useAsync(getPreAuthorization)
  const preAuthorization = useMemo(
    () => value && { ...value, billingDate: moment(value.billingDate) },
    [value],
  ) // DatePicker in PreAuthorizationForm receives a moment object
  const onSubmit = useCallback(
    async (
      { cptCode, icd10Code, authorized, officeCheckId },
      { setErrors },
    ) => {
      try {
        await dispatch(
          updatePreAuthorizationOfficeCheck(practiceId, id, {
            cptCode,
            icd_10_code: icd10Code, // icd10Code is transformed to icd10_code
            authorized,
            officeCheckId,
          }),
        )
        history.push(
          `${toPath.billing.officeAuthorizations.officeAuthorization({
            id,
          })}?practiceId=${practiceId}`,
        )
      } catch (e) {
        if (e.response && e.response.errors) {
          setErrors(mapValues(e.response.errors, fieldErrors => fieldErrors[0]))
        } else {
          message.error('Oops, we had a problem. Please try submitting again')
        }
      }
    },
    [dispatch, history, id, practiceId],
  )

  if (pending) return <Loading large />

  return (
    <div>
      <PageHeader title="Edit Office Authorization" onBack={history.goBack} />
      <Edit
        onSubmit={onSubmit}
        practiceId={practiceId}
        preAuthorization={{
          patientId: preAuthorization.patient.id,
          deviceModelId: preAuthorization.device.id,
          deviceModelSerialNumber: preAuthorization.device.serialNumber,
          cptCode: preAuthorization.cptCode,
          icd10Code: preAuthorization.icd10Code,
          authorized: preAuthorization.authorized,
          officeCheckId: preAuthorization.officeCheckId,
          billingDate: preAuthorization.billingDate,
        }}
      />
    </div>
  )
}
