import { Tag } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'

export const reportTypeColor = {
  report: 'blue',
  editable: 'geekblue',
  full: 'orange',
}

export default function ReportTag({ reportCheck: { filename, type } }) {
  return (
    <>
      <Tag color={reportTypeColor[type]}>{type}</Tag>
      {filename}
    </>
  )
}

ReportTag.propTypes = {
  reportCheck: PropTypes.shape({
    filename: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
}
