import apiClient from './api-client'

function list() {
  return apiClient.get('teams')
}

function updatePracticeTeams(practiceId, teamIds) {
  return apiClient.put(`practices/${practiceId}/teams`, {
    team_ids: teamIds,
  })
}

function addClinicianToTeam(clinicianId, teamId) {
  return apiClient.post(`teams/${teamId}/users/`, {
    user_id: clinicianId,
  })
}

function removeClinicianFromTeam(clinicianId, teamId) {
  return apiClient.destroy(`teams/${teamId}/users/${clinicianId}`)
}

export default {
  list,
  updatePracticeTeams,
  addClinicianToTeam,
  removeClinicianFromTeam,
}
