import { Row, Col } from 'antd'
import React, { useEffect, useState } from 'react'
import { chunk } from 'lodash'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import api from '../../api'

const useStyles = createUseStyles({
  container: {
    marginBottom: 15,
  },
})

const diseaseRenderer = disease => {
  return (
    <Col className="gutter-row" span={6} key={disease.id}>
      {disease.name}
    </Col>
  )
}

const chunkList = array => {
  return (
    <Row gutter={16} key={array[0].id}>
      {array.map(diseaseRenderer)}
    </Row>
  )
}

export default function DiseasesList({ patientId }) {
  const [diseases, setDiseases] = useState([])
  const classes = useStyles()

  useEffect(() => {
    api.diseases.getDiseases(patientId).then(data => setDiseases(data))
  }, [patientId])

  return (
    <div className={classes.container}>{chunk(diseases, 4).map(chunkList)}</div>
  )
}

DiseasesList.propTypes = {
  patientId: PropTypes.string.isRequired,
}
