import { Form, Select as AntDSelect } from 'antd'
import debounce from 'lodash/debounce'
import { useField } from 'formik'
import isObjectLike from 'lodash/isObjectLike'
import PropTypes from 'prop-types'
import React, { useEffect, useCallback, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import classnames from 'classnames'

import { useAsync } from '../../hooks'
import useClearWhenUnmount from '../../hooks/useClearWhenUnmount'

const { Item } = Form

const DEBOUNCE_MS = 500

const useStyles = createUseStyles({
  fullWidth: {
    '& > div.ant-form-item-control-wrapper:first-child': {
      display: 'block',
    },
  },
})

export default function AsyncSelect({
  asyncSearch,
  clearWhenUnmount,
  containerClassName,
  fetchOptions,
  id,
  initialFetch,
  label,
  labelInValue,
  labelPropName,
  labelCol,
  labelAlign,
  wrapperCol,
  options,
  startWithFirstValue,
  valuePropName,
  fullWidth,
  value,
  filterOption,
  placeholder,
  ...rest
}) {
  const [field, { error, touched }, { setValue, setTouched }] = useField(rest)
  const handleBlur = useCallback(() => setTouched(true), [setTouched])
  const { pending, value: fetchedOptions, execute } = useAsync(
    fetchOptions,
    initialFetch,
  )
  const debouncedExecute = useMemo(() => debounce(execute, DEBOUNCE_MS), [
    execute,
  ])
  const finalOptions = useMemo(
    () =>
      [
        ...(Array.isArray(options) ? options : []),
        ...(Array.isArray(fetchedOptions) ? fetchedOptions : []),
      ].reduce((acc, option) => {
        if (!acc.find(o => o[valuePropName] === option[valuePropName])) {
          acc.push(option)
        }
        return acc
      }, []),
    [fetchedOptions, options, valuePropName],
  )
  // Formik will consider each key in the value as a different value to be touched
  // when you use labelInValue

  const isTouched =
    labelInValue && isObjectLike(touched) ? touched.key : touched
  useEffect(() => {
    if (!startWithFirstValue && !value) return
    const hasValue = labelInValue
      ? field.value && field.value.key
      : !!field.value
    if (!pending && !hasValue && finalOptions && finalOptions.length) {
      if (startWithFirstValue) {
        setValue(
          labelInValue
            ? {
                key: finalOptions[0][valuePropName],
                label: finalOptions[0][labelPropName],
              }
            : finalOptions[0][valuePropName],
        )
      } else {
        setValue(labelInValue ? { key: value.key } : value)
      }
    }
  }, [
    finalOptions,
    labelPropName,
    pending,
    labelInValue,
    startWithFirstValue,
    valuePropName,
    field.value,
    setValue,
    value,
  ])

  useClearWhenUnmount(clearWhenUnmount, setValue, setTouched)

  const hasError = Boolean(isTouched && error)

  const classes = useStyles()

  const classNames = classnames(
    containerClassName,
    fullWidth ? classes.fullWidth : null,
  )

  const defaultFilterOption = useCallback((input, option) => {
    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }, [])
  return (
    <Item
      label={label}
      htmlFor={id}
      hasFeedback={hasError}
      validateStatus={hasError ? 'error' : ''}
      help={hasError && error}
      className={classNames}
      labelCol={labelCol}
      labelAlign={labelAlign}
      wrapperCol={wrapperCol}
    >
      <AntDSelect
        id={id}
        loading={pending}
        onChange={setValue}
        onBlur={handleBlur}
        labelInValue={labelInValue}
        value={field.value}
        onSearch={asyncSearch ? debouncedExecute : undefined}
        filterOption={filterOption || defaultFilterOption}
        placeholder={placeholder}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        {finalOptions?.map(option => {
          const key = option[valuePropName]
          const optionLabel = option[labelPropName]
          return <AntDSelect.Option key={key}>{optionLabel}</AntDSelect.Option>
        })}
      </AntDSelect>
    </Item>
  )
}

AsyncSelect.propTypes = {
  asyncSearch: PropTypes.bool,
  containerClassName: PropTypes.string,
  fetchOptions: PropTypes.func.isRequired,
  id: PropTypes.string,
  initialFetch: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelInValue: PropTypes.bool,
  labelPropName: PropTypes.string,
  labelCol: PropTypes.shape({}),
  labelAlign: PropTypes.string,
  wrapperCol: PropTypes.shape({}),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  valuePropName: PropTypes.string,
  startWithFirstValue: PropTypes.bool,
  clearWhenUnmount: PropTypes.bool,
  fullWidth: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  filterOption: PropTypes.func,
  placeholder: PropTypes.string,
}

AsyncSelect.defaultProps = {
  asyncSearch: false,
  containerClassName: undefined,
  id: undefined,
  initialFetch: true,
  label: undefined,
  labelInValue: false,
  labelPropName: 'name',
  labelCol: undefined,
  labelAlign: 'left',
  wrapperCol: undefined,
  options: undefined,
  valuePropName: 'id',
  startWithFirstValue: false,
  clearWhenUnmount: false,
  fullWidth: false,
  value: undefined,
  filterOption: undefined,
  placeholder: undefined,
}
