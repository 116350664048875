import React, { useCallback } from 'react'
import mapValues from 'lodash/mapValues'
import { message } from 'antd'
import { useHistory, useParams } from 'react-router-dom'
import { PageHeader } from '../../Common'
import { useAsync } from '../../../hooks'
import Loading from '../../Loading'
import api from '../../../api'
import Form from './form'

export default function EditPhoneNumber() {
  const history = useHistory()
  const { id } = useParams()
  const getPhoneNumber = useCallback(
    () => api.letterPhoneNumbers.getLetterPhoneNumber(id),
    [id],
  )
  const { pending, value } = useAsync(getPhoneNumber)

  const onSubmit = useCallback(
    async (data, { setSubmitting, setErrors }) => {
      try {
        await api.letterPhoneNumbers.updateLetterPhoneNumber(id, data)

        message.success('Phone number successfully updated')
        history.goBack()
      } catch (e) {
        if (e.response && e.response.errors) {
          setErrors(mapValues(e.response.errors, fieldErrors => fieldErrors[0]))
        } else {
          message.error('Oops, we had a problem. Please try submitting again')
        }
      }
    },
    [history, id],
  )

  if (pending) return <Loading large />

  return (
    <div>
      <PageHeader title="Edit Phone Number" onBack={history.goBack} />
      <Form phoneNumberData={value} onSubmit={onSubmit} />
    </div>
  )
}
