import React, { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Modal, message } from 'antd'
import moment from 'moment'

import { PageHeader } from '../../Common'
import { toPath } from '../../../routes'
import { useFeatureGuard } from '../../../redux/slices/session'

import api from '../../../api'
import OfficeAuthorizationForm from './Form'
import CreateOfficeAuthorization from './UseCase/CreateOfficeAuthorization'

export default function NewOfficeAuthorizations() {
  const history = useHistory()

  const { practiceId } = useParams()

  const onCreate = useCallback(
    async (
      { patientId, cptCode, icd10Code, officeCheckId, authorized },
      { setErrors },
    ) => {
      try {
        const result = await CreateOfficeAuthorization(patientId, practiceId, {
          cptCode,
          icd10Code,
          authorized,
          officeCheckId,
        })

        if (result.isSuccess()) {
          message.success('Office authorization successfully created')
          history.push(toPath.practices.officeAuthorizations({ practiceId }))
        } else {
          const errors = result.getErrors()

          typeof errors === 'string' ? message.error(errors) : setErrors(errors)
        }
      } catch (e) {
        message.error(`Something went wrong. ${e.message}`)
        throw e
      }
    },
    [history, practiceId],
  )

  const onSubmit = useCallback(
    async (params, { setErrors }) => {
      const { officeCheckId, patientId, firstName, lastName } = params

      const [{ checkDate: dateOfCheck }, { body: result }] = await Promise.all([
        api.officeChecks.get(practiceId, officeCheckId),
        api.officeChecks.byPracticeId({ practiceId, patientId }),
      ])

      const mostRecentCheck = result
        .filter(o => !moment(o.checkDate).isSame(dateOfCheck))
        .sort((o1, o2) => moment(o2.checkDate) - moment(o1.checkDate))[0]

      const isLastCheckWithin90Days =
        mostRecentCheck &&
        moment(mostRecentCheck.checkDate).isBetween(
          moment(dateOfCheck).subtract(90, 'days'),
          moment(dateOfCheck),
        )

      if (isLastCheckWithin90Days) {
        const name = `${firstName} ${lastName}`
        const date = moment(mostRecentCheck.checkDate).format('LL')

        return Modal.info().update({
          title: 'Previous schedule',
          content: `Patient ${name} had a prior check within the last 90 days, with the most recent on ${date}.`,
          onOk: () => onCreate(params, { setErrors }),
        })
      }

      return onCreate(params, { setErrors })
    },
    [onCreate, practiceId],
  )

  useFeatureGuard('in_office_checks')

  return (
    <>
      <PageHeader title="New Office Authorization" onBack={history.goBack} />
      <OfficeAuthorizationForm onSubmit={onSubmit} practiceId={practiceId} />
    </>
  )
}
