import { Button, Divider, Icon, Popconfirm } from 'antd'
import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { toPath } from '../../routes'

const useStyles = createUseStyles({
  active: {
    color: 'green',
  },
  inactive: {
    color: 'red',
  },
  cell: {
    whiteSpace: 'nowrap',
  },
  divider: {
    margin: '0 4px',
  },
})

const BASIC_COLUMNS = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Role',
    dataIndex: 'role.name',
    key: 'role',
  },
  {
    title: 'Position',
    dataIndex: 'positionLabel',
    key: 'position',
  },
]

export default function useColumns(config, handleDelete, handlePasswordReset) {
  const classes = useStyles()
  return useMemo(() => {
    const cols = [...BASIC_COLUMNS]
    if (config.practice) {
      cols.push({
        title: 'Practice',
        dataIndex: 'practice.name',
        key: 'practice',
      })
    }
    if (config.active) {
      cols.push({
        align: 'center',
        title: 'Active?',
        dataIndex: 'active',
        key: 'active',
        render(text, user) {
          return (
            <Icon
              type={`${user.active ? 'check' : 'close'}-circle`}
              className={classes[user.active ? 'active' : 'inactive']}
            />
          )
        },
      })
    }
    cols.push({
      title: 'Actions',
      key: 'actions',
      render: (text, record) => {
        return (
          <span className={classes.cell}>
            <Popconfirm
              title={`Do you want to send the password reset email to ${record.email}?`}
              onConfirm={() => handlePasswordReset(record.email)}
              okText="Yes"
              cancelText="No"
              placement="topRight"
            >
              <Button type="link" icon="message" className="delete-button" />
            </Popconfirm>
            <Divider type="vertical" className={classes.divider} />
            <Link
              to={toPath.users.user.edit({ id: record.id })}
              className="edit-button"
            >
              <Button type="link" icon="edit" />
            </Link>
            <Divider type="vertical" className={classes.divider} />
            <Popconfirm
              title="Are you sure you want to deactivate this user?"
              onConfirm={() => handleDelete(record.id)}
              okText="Yes"
              cancelText="No"
              placement="topRight"
            >
              <Button type="link" icon="delete" className="delete-button" />
            </Popconfirm>
          </span>
        )
      },
    })
    return cols
  }, [
    classes,
    config.active,
    config.practice,
    handleDelete,
    handlePasswordReset,
  ])
}
