import { Button } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

export default function LinkButton({ to, text, icon }) {
  return (
    <Link to={to}>
      <Button icon={icon} size="large" type="link">
        {text}
      </Button>
    </Link>
  )
}

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  text: PropTypes.string,
  icon: PropTypes.string,
}

LinkButton.defaultProps = {
  text: undefined,
  icon: undefined,
}
