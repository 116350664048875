import alerts from './alerts'
import billingOrders from './billing-orders'
import downloads from './downloads'
import entities from './entities'
import missedTransmissions from './missed-transmissions'
import patients from './patients'
import practices from './practices'
import preAuthorizations from './pre-authorizations'
import profile from './profile'
import reports from './reports'
import pendingReports from './pending-reports'
import unreviewedReports from './unreviewed-reports'
import reviewedReports from './reviewed-reports'
import reviewedSummary from './reviewed-summary'
import session from './session'
import users from './users'
import clinicians from './clinicians'
import icdCodes from './icd-codes'
import reminders from './reminders'
import letterPhoneNumbers from './letter-phone-numbers'
import inOfficeBillingOrders from './in-office-billing-orders'
import officeAuthorizations from './office-authorizations'
import officeChecks from './office-checks'
import officeCheckDownloads from './office-check-downloads'
import preAuthorizationDownloads from './pre-authorization-downloads'

const reducers = {}
const slices = [
  alerts,
  billingOrders,
  downloads,
  entities,
  missedTransmissions,
  patients,
  practices,
  preAuthorizations,
  profile,
  reports,
  pendingReports,
  unreviewedReports,
  reviewedReports,
  reviewedSummary,
  session,
  users,
  clinicians,
  icdCodes,
  reminders,
  letterPhoneNumbers,
  inOfficeBillingOrders,
  officeAuthorizations,
  officeChecks,
  officeCheckDownloads,
  preAuthorizationDownloads,
]
slices.forEach(slice => {
  reducers[slice.name] = slice.reducer
})

export default reducers
