import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import Root from './Root'
import * as serviceWorker from './serviceWorker'

// Sentry will be disabled if the REACT_APP_SENTRY_DSN is not provided
Sentry.init({
  environment: process.env.REACT_APP_SENTRY_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled.
      // Distributed tracing helps link multiple BE and FE services to show
      // which parts interact with each other in a single trace. For more info
      // visit https://docs.sentry.io/platforms/javascript/usage/distributed-tracing/
      tracePropagationTargets: [
        /^https:\/\/app-staging\.gobiobridge\.com/,
        /^https:\/\/app\.gobiobridge\.com/,
      ],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

ReactDOM.render(<Root />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
