import set from 'lodash/set'
import api from '../../../api'

class Result {
  constructor(object, err) {
    this.object = object
    this.err = err
  }

  isSuccess() {
    return !!this.object && !this.err
  }

  getErrors() {
    if (!this.err) return []

    if (this.err.response && this.err.response.errors) {
      const { errors } = this.err.response

      Object.keys(errors).forEach(key => {
        switch (key) {
          case 'currentDevice.serialNumber':
            set(errors, 'deviceSerialNumber', errors[key][0])
            delete errors[key]
            break
          case 'currentDevice.deviceModel':
            set(errors, 'deviceModelId', errors[key][0])
            delete errors[key]
            break
          default:
        }
      })

      return errors
    }

    return this.err.message
  }
}

const getOrCreatePatient = async (
  id,
  practiceId,
  {
    firstName,
    lastName,
    dateOfBirth,
    practiceMrn,
    deviceModelId,
    deviceSerialNumber,
    remoteMonitoring,
  },
) => {
  if (id) return Promise.resolve(new Result({ id }))

  try {
    return new Result(
      await api.patients.create({
        practiceId,
        firstName,
        lastName,
        birthDate: dateOfBirth && dateOfBirth.toISOString(),
        practiceMrn,
        currentDeviceAttributes: {
          deviceModelId,
          serialNumber: deviceSerialNumber,
        },
        approved: true,
        remoteMonitoring,
      }),
      undefined,
    )
  } catch (e) {
    return new Result(undefined, e)
  }
}

const createPreAuthorization = async (patient, practiceId, { dateOfCheck }) => {
  try {
    if (!patient?.id)
      return new Result(
        undefined,
        new Error('Something went wrong! Patient is not present.'),
      )

    return new Result(
      await api.officeChecks.create(practiceId, {
        patientId: patient.id,
        dateOfCheck: dateOfCheck && dateOfCheck.toISOString(),
      }),
      undefined,
    )
  } catch (e) {
    return new Result(undefined, e)
  }
}

export default async (patientId, practiceId, attributes) => {
  const {
    firstName,
    lastName,
    dateOfBirth,
    practiceMrn,
    deviceModelId,
    deviceSerialNumber,
    dateOfCheck,
    remoteMonitoring,
  } = attributes

  const patientCreationResult = await getOrCreatePatient(
    patientId,
    practiceId,
    {
      firstName,
      lastName,
      dateOfBirth,
      practiceMrn,
      deviceModelId,
      deviceSerialNumber,
      remoteMonitoring,
    },
  )

  const preAuthorizationCreationResult = await createPreAuthorization(
    patientCreationResult.object,
    practiceId,
    { dateOfCheck },
  )

  return [patientCreationResult, preAuthorizationCreationResult]
}
