import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Icon, Dropdown, Button } from 'antd'
import { listItemPropTypes } from '../prop-types'
import ListItemMenuOptions from './ListItemMenuOptions'

function ListItemMenu({ item: { id }, onEdit, onDelete, deleteConfirmation }) {
  const [visible, setVisible] = useState(false)

  const handleEdit = useCallback(() => {
    onEdit(id)
  }, [id, onEdit])

  const handleDelete = useCallback(() => {
    setVisible(false)
    onDelete(id)
  }, [id, onDelete])

  const handleCancel = useCallback(() => {
    setVisible(false)
  }, [])

  return (
    <Dropdown
      // This is necessary so Ant design works as intended
      overlay={ListItemMenuOptions({
        id,
        onEdit: handleEdit,
        onDelete: handleDelete,
        onCancel: handleCancel,
        deleteConfirmation,
      })}
      onVisibleChange={setVisible}
      visible={visible}
      trigger={['click']}
    >
      <Button>
        <Icon type="ellipsis" />
      </Button>
    </Dropdown>
  )
}

ListItemMenu.propTypes = {
  item: listItemPropTypes.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  deleteConfirmation: PropTypes.string.isRequired,
}

export default ListItemMenu
