import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'
import { Spin, message } from 'antd'

import {
  useAuthorize,
  useClearAuth,
  useCurrentUser,
} from '../redux/slices/session'

const Authenticated = () => {
  const {
    user,
    isAuthenticated,
    getAccessTokenSilently,
    isLoading,
    logout,
  } = useAuth0()

  const authorize = useAuthorize()
  const clearAuth = useClearAuth()
  const history = useHistory()
  const currentUser = useCurrentUser()

  useEffect(() => {
    if (isAuthenticated || currentUser) {
      getAccessTokenSilently()
        .then(token => {
          authorize(token, user)
            .then(() => {
              history.replace(window.location.pathname)
            })
            .catch(e => {
              message.error(
                'Application authorization error. Please authenticate again',
                120,
              )
              logout()
              history.replace('/login')
            })
        })
        .catch(e => {
          message.error(
            'User authentication error. Please, contact the system administrator',
            120,
          )
          history.replace('/login')
        })
    } else if (!isAuthenticated && !isLoading) {
      message.error('You need to login. Redirecting...', 120)
      history.replace('/login')
    }
  }, [
    authorize,
    clearAuth,
    getAccessTokenSilently,
    history,
    isAuthenticated,
    isLoading,
    user,
    currentUser,
    logout,
  ])

  return (
    <Spin
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
      size="large"
    />
  )
}

export default Authenticated
