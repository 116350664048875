import React, { useCallback, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import api from '../../api'
import { buildUrl } from '../../api/api-client'
import { useAsync } from '../../hooks'
import Loading from '../Loading'
import { ListError } from '../Common'

export default function RedirectToMergedFile() {
  const { alertId } = useParams()
  const getAlert = useCallback(() => api.alerts.get(alertId), [alertId])
  const { pending, error, value: alert } = useAsync(getAlert)

  const acknowledgeAlertAndRedirectToPdf = useCallback(async () => {
    if (!alert.acknowledgedAt) {
      await api.alerts.acknowledge(alert.id)
    }

    window.location.replace(buildUrl(alert.mergedFilePath))
  }, [alert])

  useEffect(() => {
    if (alert) {
      acknowledgeAlertAndRedirectToPdf()
    }
  }, [acknowledgeAlertAndRedirectToPdf, alert])

  if (pending) {
    return <Loading large />
  }

  if (error) {
    return (
      <ListError
        message="There was an error loading this alert"
        description={error.message}
      />
    )
  }

  return null
}
