import React, { useEffect, useMemo, useCallback, useState } from 'react'
import { Alert, Col, Form, Row, message } from 'antd'
import { Formik, useFormikContext } from 'formik'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import moment from 'moment'
import { formElementPropsGenerator } from '../../lib/forms'
import { Checkbox, DatePicker, Input, SubmitButton } from '../Forms'

import api from '../../api'

import PatientSelect from '../Forms/PatientSelect'
import DeviceModelSelect from '../Forms/DeviceModelSelect'
import ReactivateModal from '../Patients/ReactivateModal'

const OFFICE_CHECKS_DEFAULTS = {
  patientId: undefined,
  firstName: '',
  lastName: '',
  dateOfBirth: undefined,
  dateOfCheck: undefined,
  deviceModelId: '',
  deviceSerialNumber: '',
}

const OFFICE_CHECKS_LABELS = {
  patientId: 'Patient',
  firstName: 'First Name',
  lastName: 'Last Name',
  dateOfBirth: 'Date of Birth',
  dateOfCheck: 'Date of Check',
  deviceModelId: 'Model #',
  deviceSerialNumber: 'Serial Number',
  cptCode: 'CPT Code',
  icd10Code: 'ICD-10 Code',
  authorized: 'Authorized',
  remoteMonitoring: 'Remote Monitoring',
  inOfficeChecks: 'In-Office Checks',
  practiceMrn: 'Practice MRN',
}

const propsFor = formElementPropsGenerator({
  formName: 'pre-authorization-form',
  labels: OFFICE_CHECKS_LABELS,
})

const labelCol = {
  sm: { span: 6, offset: 0 },
}

const wrapperCol = {
  sm: { span: 15 },
}

const actionsWrapperCol = {
  sm: { span: 4, offset: 10 },
}

const buildValidationSchema = () => {
  const shape = {
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    dateOfBirth: Yup.date()
      .max(new Date())
      .required(),
    deviceModelId: Yup.string().required(),
    deviceSerialNumber: Yup.string().required(),
    dateOfCheck: Yup.date().required(),
  }

  return Yup.object().shape(shape)
}

const ReactivateComponent = ({ patientId, hideReactivateModal, visible }) => {
  const { setValues } = useFormikContext()
  const onCancel = () => {
    setValues({ ...OFFICE_CHECKS_DEFAULTS })
    hideReactivateModal()
  }

  const onSubmit = useCallback(async () => {
    try {
      const response = await api.patients.update(patientId, {
        active: true,
        remote_monitoring: false,
      })
      setValues({
        ...OFFICE_CHECKS_DEFAULTS,
        patientId,
        firstName: response.firstName,
        lastName: response.lastName,
        dateOfBirth: moment(response.birthDate),
        practiceMrn: response.practiceMrn,
        deviceModelId: response.currentDevice?.modelName,
        deviceSerialNumber: response.currentDevice?.serialNumber,
        remoteMonitoring: response.remoteMonitoring,
      })
      hideReactivateModal()
      message.success('Patient reactivated successfully')
    } catch (e) {
      message.error(
        'Patient cannot be reactivated at this time. Please try again later.',
      )
    }
  }, [hideReactivateModal, patientId, setValues])

  return (
    <ReactivateModal
      patientId={patientId}
      onCancel={onCancel}
      visible={visible}
      onSubmit={onSubmit}
    />
  )
}

const CustomPatientSelector = ({
  practiceId,
  showReactivateModal,
  paramsPatientId,
}) => {
  const { values, setValues } = useFormikContext()

  const { patientId } = values
  const id = paramsPatientId || patientId

  useEffect(() => {
    if (id) {
      api.patients.get(id).then(response => {
        if (response.active === false) {
          showReactivateModal()
        } else {
          setValues({
            ...OFFICE_CHECKS_DEFAULTS,
            patientId: response.id,
            firstName: response.firstName,
            lastName: response.lastName,
            dateOfBirth: moment(response.birthDate),
            practiceMrn: response.practiceMrn,
            deviceModelId: response.currentDevice?.modelName,
            deviceSerialNumber: response.currentDevice?.serialNumber,
            remoteMonitoring: response.remoteMonitoring,
          })
        }
      })
    } else {
      setValues({ ...OFFICE_CHECKS_DEFAULTS })
    }
  }, [id, setValues, showReactivateModal])
  if (paramsPatientId) {
    return <></> // hide patient selector if patientId is in the URL
  }

  return (
    <PatientSelect
      {...propsFor('patientId')}
      practiceId={practiceId}
      placeholder="Search by name, device model or serial number"
      allowClear
      showInactive
    />
  )
}

export default function OfficeCheckForm({ practiceId, onSubmit }) {
  const validationSchema = useMemo(() => buildValidationSchema(), [])
  const [userisInactive, setUserisInactive] = useState(false)
  const hideReactivateModal = () => setUserisInactive(false)
  const showReactivateModal = () => setUserisInactive(true)
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const paramsPatientId = searchParams.get('patientId')

  return (
    <>
      <Formik
        initialValues={OFFICE_CHECKS_DEFAULTS}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, submitCount, values }) => (
          <Form
            onSubmit={handleSubmit}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
          >
            <Alert
              message="If no patient exists, completing the form below with patient information will automatically create a new one."
              type="info"
              showIcon
            />

            <CustomPatientSelector
              practiceId={practiceId}
              showReactivateModal={showReactivateModal}
              paramsPatientId={paramsPatientId}
            />

            {values.patientId && userisInactive && (
              <ReactivateComponent
                patientId={values.patientId}
                hideReactivateModal={hideReactivateModal}
                visible={userisInactive}
              />
            )}
            <Row>
              <Col span={5} offset={7}>
                <Checkbox {...propsFor('inOfficeChecks')} checked disabled />
              </Col>
              <Col span={6}>
                <Checkbox
                  {...propsFor('remoteMonitoring')}
                  disabled={!!values.patientId}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Input
                  {...propsFor('firstName')}
                  disabled={!!values.patientId}
                />
              </Col>
              <Col span={12}>
                <Input
                  {...propsFor('lastName')}
                  disabled={!!values.patientId}
                />
              </Col>
              <Col span={12}>
                <DatePicker
                  {...propsFor('dateOfBirth')}
                  disabled={!!values.patientId}
                  showToday={false}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Input
                  {...propsFor('practiceMrn')}
                  disabled={!!values.patientId}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <DeviceModelSelect
                  {...propsFor('deviceModelId')}
                  labelCol={{ span: 6 }}
                  labelAlign="right"
                  wrapperCol={{ span: 15 }}
                  disabled={!!values.patientId}
                />
              </Col>
              <Col span={12}>
                <Input
                  {...propsFor('deviceSerialNumber')}
                  disabled={!!values.patientId}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <DatePicker {...propsFor('dateOfCheck')} />
              </Col>
            </Row>

            <SubmitButton
              isSubmitting={isSubmitting}
              title="Create Office Check"
              isValid
              submitCount={submitCount}
              wrapperCol={actionsWrapperCol}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

CustomPatientSelector.propTypes = {
  practiceId: PropTypes.string,
  showReactivateModal: PropTypes.func.isRequired,
  paramsPatientId: PropTypes.string,
}

CustomPatientSelector.defaultProps = {
  practiceId: undefined,
  paramsPatientId: undefined,
}

ReactivateComponent.propTypes = {
  patientId: PropTypes.string.isRequired,
  hideReactivateModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
}
OfficeCheckForm.propTypes = {
  practiceId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
}

OfficeCheckForm.defaultProps = {
  practiceId: undefined,
}
