import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import api from '../../api'
import AsyncSelect from './AsyncSelect'

export default function OfficeCheckSelect({
  practiceId,
  patientId,
  currentOfficeCheck,
  ...rest
}) {
  const fetchOptions = useCallback(() => {
    if (!practiceId || !patientId) {
      return Promise.resolve([])
    }

    return api.officeChecks
      .byPracticeId({ practiceId, patientId, filters: ['no-pre-auths'] })
      .then(res => {
        const response = res.body.map(({ id, checkDate }) => ({
          id,
          name: `${moment(checkDate).format('MM/DD/YYYY')}`,
        }))

        return currentOfficeCheck.id
          ? [currentOfficeCheck, ...response]
          : response
      })
  }, [currentOfficeCheck, patientId, practiceId])

  return (
    <AsyncSelect
      fetchOptions={fetchOptions}
      showSearch
      optionFilterProp="children"
      placeholder="Pick an office check date"
      {...rest}
    />
  )
}

OfficeCheckSelect.propTypes = {
  practiceId: PropTypes.string.isRequired,
  patientId: PropTypes.string.isRequired,
  currentOfficeCheck: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
}

OfficeCheckSelect.defaultProps = {
  currentOfficeCheck: {
    id: undefined,
    name: undefined,
  },
}
