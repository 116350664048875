/**
 * Parses an input given as a number or string into an integer.
 * @param {Number|String} number - input to parse.
 * @throws {Error} if the input can not be parsed into an integer.
 * @returns {Number} a integer.
 */
export const parseInteger = str => {
  const number = Number.parseInt(str.toString(), 10)
  if (Number.isNaN(number)) throw new Error('Not a number')
  return number
}

/**
 * Sums the value of the digits in a given integer
 * @param {Number|String} integer - parsed as an integer.
 * @throws {Error} if the input can not be parsed into an integer.
 * @returns {Number} the sum of the digits in the integer.
 */
export const sumDigits = num => {
  let integer = parseInteger(num)

  let sum = 0

  while (integer > 0) {
    sum += integer % 10
    integer = Math.floor(integer / 10)
  }

  return sum
}

/**
 * Doubles the value of a given integer
 * @param {Number|String} integer - parsed as an integer.
 * @throws {Error} if the input can not be parsed into an integer.
 * @returns {Number} the integer multiplied by two.
 */
export const doubleInteger = num => {
  const integer = parseInteger(num)
  return integer * 2
}

export function round10(num1) {
  return Math.ceil(num1 / 10) * 10
}

/**
 * Checks if the NPI is valid.
 */
const isValidNpi = string => {
  if (!string || string.length !== 10) return false
  // Get the last character from the string
  const lastCharacter = string.slice(-1)

  // Parse the last character as an integer
  const expectedDigit = parseInteger(lastCharacter)

  // Set the start and end of the NPI in the string
  const endOfNPI = string.length - 1
  const startOfNPI = string.length - 10

  // Get the NPI substring from the raw string
  const npi = string.substring(startOfNPI, endOfNPI)

  let sum = 24
  let isAlternate = true

  let position = npi.length - 1
  while (position >= 0) {
    // Get the NPI character at the current position
    const character = npi.charAt(position)

    // Parse the character as an integer
    const integer = parseInteger(character)

    // If in the alternate position
    if (isAlternate) {
      // Double the integer and sum its digits and then add it to the sum
      sum += sumDigits(doubleInteger(integer))
    }

    // If not in the alternate position add the integer to the sum directly
    else sum += integer

    // Toggle alternate position
    isAlternate = !isAlternate

    // Decrement position without using unary operator
    position -= 1
  }
  // Calculate the nearest 10th digit
  const round10Number = round10(sum)

  // Calculate the check digit from diff of 10th digit and sum
  const checkDigit = round10Number - sum
  return expectedDigit === checkDigit
}

export default isValidNpi
