import React, { useEffect, useMemo, useState } from 'react'
import { Col, Form, Row, message } from 'antd'
import { Formik, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import moment from 'moment'
import * as Yup from 'yup'
import { formElementPropsGenerator } from '../../../lib/forms'
import { DatePicker, Input, SubmitButton } from '../../Forms'
import Switch from '../../Forms/Switch'
import api from '../../../api'

import PatientSelect from '../../Forms/PatientSelect'
import OfficeCheckSelect from '../../Forms/OfficeCheckSelect'
import DeviceModelSelect from '../../Forms/DeviceModelSelect'

const PRE_AUTHORIZATION_DEFAULTS = {
  patientId: undefined,
  firstName: '',
  lastName: '',
  dateOfBirth: undefined,
  dateOfCheck: undefined,
  deviceModelId: '',
  deviceSerialNumber: '',
  cptCode: '',
  icd10Code: '',
  authorized: false,
  officeCheckId: undefined,
}

const PRE_AUTHORIZATION_LABELS = {
  patientId: 'Patient',
  firstName: 'First Name',
  lastName: 'Last Name',
  dateOfBirth: 'Date of Birth',
  dateOfCheck: 'Date of Check',
  deviceModelId: 'Model #',
  deviceSerialNumber: 'Serial Number',
  cptCode: 'CPT Code',
  icd10Code: 'ICD-10 Code',
  authorized: 'Authorized',
  officeCheckId: 'Date of Check',
  practiceMrn: 'Practice MRN',
}

const propsFor = formElementPropsGenerator({
  formName: 'pre-authorization-form',
  labels: PRE_AUTHORIZATION_LABELS,
})

const labelCol = {
  sm: { span: 6, offset: 0 },
}

const wrapperCol = {
  sm: { span: 15 },
}

const actionsWrapperCol = {
  sm: { span: 4, offset: 10 },
}

const buildValidationSchema = () => {
  const shape = {
    cptCode: Yup.string().required(),
    icd10Code: Yup.string().required(),
    authorized: Yup.bool()
      .required()
      .default(false),
    officeCheckId: Yup.string().required(),
  }

  return Yup.object().shape(shape)
}

const CustomPatientSelector = ({ practiceId, preAuthorization }) => {
  const { values, setValues } = useFormikContext()
  const { patientId } = values

  useEffect(() => {
    if (patientId) {
      api.patients.get(patientId).then(response => {
        const defaults = preAuthorization || PRE_AUTHORIZATION_DEFAULTS
        setValues({
          ...defaults,
          patientId,
          firstName: response.firstName,
          lastName: response.lastName,
          dateOfBirth: moment(response.birthDate),
          practiceMrn: response.practiceMrn,
          deviceModelId: response.currentDevice?.modelName,
          deviceSerialNumber: response.currentDevice?.serialNumber,
        })
      })
    } else {
      setValues({ ...PRE_AUTHORIZATION_DEFAULTS })
    }
  }, [patientId, preAuthorization, setValues])
  return (
    <PatientSelect
      {...propsFor('patientId')}
      practiceId={practiceId}
      placeholder="Search by name, device model or serial number"
      allowClear
      initialFetch
    />
  )
}

const CustomOfficeCheckSelect = ({ practiceId, currentOfficeCheck }) => {
  const { values } = useFormikContext()
  const { patientId } = values

  return (
    <OfficeCheckSelect
      practiceId={practiceId}
      patientId={patientId}
      currentOfficeCheck={currentOfficeCheck}
      labelAlign="right"
      wrapperCol={{
        sm: { span: 15 },
      }}
      labelCol={{ span: 6 }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...propsFor('officeCheckId')}
    />
  )
}

const PatientFormView = ({ practiceId }) => {
  return <CustomPatientSelector practiceId={practiceId} />
}

export default function OfficeAuthorizationForm({
  practiceId,
  onSubmit,
  preAuthorization,
}) {
  const validationSchema = useMemo(() => buildValidationSchema(), [])

  const [defaultValues, setDefaultValues] = useState({
    patientId: preAuthorization?.patientId,
  })

  const { patientId } = defaultValues
  const [fetching, setFetching] = useState(false)
  const currentOfficeCheck = {
    id: preAuthorization?.officeCheckId,
    name: preAuthorization?.billingDate?.format('MM/DD/YYYY'),
  }

  useEffect(() => {
    setFetching(true)
    if (patientId) {
      try {
        api.patients.get(patientId).then(response => {
          const defaults = preAuthorization || PRE_AUTHORIZATION_DEFAULTS
          setDefaultValues({
            ...defaults,
            patientId,
            firstName: response.firstName,
            lastName: response.lastName,
            dateOfBirth: moment(response.birthDate),
            deviceModelId: response.currentDevice?.modelName,
            deviceSerialNumber: response.currentDevice?.serialNumber,
          })
          setFetching(false)
        })
      } catch (err) {
        message.error(
          `There was a problem fetching this patient: ${err.message}`,
        )
        setFetching(false)
      }
    } else {
      setFetching(false)
      setDefaultValues({ ...PRE_AUTHORIZATION_DEFAULTS })
    }
  }, [patientId, preAuthorization, setDefaultValues])

  return (
    <>
      {!fetching && (
        <Formik
          initialValues={defaultValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, isSubmitting, submitCount, values }) => (
            <Form
              onSubmit={handleSubmit}
              labelCol={labelCol}
              wrapperCol={wrapperCol}
            >
              {!preAuthorization && (
                <CustomPatientSelector practiceId={practiceId} />
              )}
              <Row>
                <Col span={12}>
                  <Input {...propsFor('firstName')} disabled />
                </Col>
                <Col span={12}>
                  <Input {...propsFor('lastName')} disabled />
                </Col>
                <Col span={12}>
                  <DatePicker
                    {...propsFor('dateOfBirth')}
                    disabled
                    showToday={false}
                    placeholder=""
                  />
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Input
                    {...propsFor('practiceMrn')}
                    disabled={!!values.patientId}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  {!!values.patientId && (
                    <Input
                      {...propsFor('deviceModelId')}
                      disabled={!!values.patientId}
                    />
                  )}
                  {!values.patientId && (
                    <DeviceModelSelect
                      {...propsFor('deviceModelId')}
                      labelCol={{ span: 6 }}
                      labelAlign="right"
                      wrapperCol={{ span: 15 }}
                      disabled={!!values.patientId}
                    />
                  )}
                </Col>
                <Col span={12}>
                  <Input {...propsFor('deviceSerialNumber')} disabled />
                </Col>
                <Col span={12}>
                  <Input {...propsFor('cptCode')} />
                </Col>
                <Col span={12}>
                  <Input {...propsFor('icd10Code')} />
                </Col>
                <Col span={12}>
                  <CustomOfficeCheckSelect
                    practiceId={practiceId}
                    currentOfficeCheck={currentOfficeCheck}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Switch
                    {...propsFor('authorized')}
                    checkedChildren="Authorized"
                    unCheckedChildren="Not&nbsp;authorized"
                  />
                </Col>
              </Row>

              <SubmitButton
                isSubmitting={isSubmitting}
                title={
                  preAuthorization
                    ? 'Edit Authorization'
                    : 'Create Authorization'
                }
                isValid
                submitCount={submitCount}
                wrapperCol={actionsWrapperCol}
              />
            </Form>
          )}
        </Formik>
      )}
    </>
  )
}

CustomPatientSelector.propTypes = {
  practiceId: PropTypes.string.isRequired,
  preAuthorization: PropTypes.shape({
    id: PropTypes.string,
  }),
}

CustomPatientSelector.defaultProps = {
  preAuthorization: undefined,
}

PatientFormView.propTypes = {
  practiceId: PropTypes.string.isRequired,
}

CustomOfficeCheckSelect.propTypes = {
  practiceId: PropTypes.string.isRequired,
  currentOfficeCheck: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
}

CustomOfficeCheckSelect.defaultProps = {
  currentOfficeCheck: {
    id: undefined,
    name: undefined,
  },
}

OfficeAuthorizationForm.propTypes = {
  practiceId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  preAuthorization: PropTypes.shape({
    comment: PropTypes.string,
    id: PropTypes.string,
  }),
}

OfficeAuthorizationForm.defaultProps = {
  practiceId: undefined,
  preAuthorization: undefined,
}
