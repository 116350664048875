import { Button, Icon, Layout } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import { useLogout } from '../../redux/slices/session'
import ProfileLink from './ProfileLink'

const { Header } = Layout

const useStyles = createUseStyles({
  header: {
    display: 'flex',
    background: ({ theme }) => theme.brandBackground,
    padding: '0 16px',
    justifyContent: 'space-between',
  },
  userName: {
    marginRight: '10px',
  },
  trigger: {
    fontSize: '18px',
    lineHeight: '64px',
    padding: '0 24px',
    cursor: 'pointer',
    transition: 'color 0.3s',
    '& :hover': {
      color: '#1890ff',
    },
  },
  rightActions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})

export default function AppHeader({ collapsed, handleToggleCollapsed }) {
  const theme = useTheme()
  const classes = useStyles({ theme })
  const logout = useLogout()

  return (
    <Header className={classes.header}>
      <Icon
        className={classes.trigger}
        type={collapsed ? 'menu-unfold' : 'menu-fold'}
        onClick={handleToggleCollapsed}
      />
      <div className={classes.rightActions}>
        <ProfileLink />
        <Button
          type="primary"
          onClick={async () => {
            await logout()
          }}
        >
          Log Out
        </Button>
      </div>
    </Header>
  )
}

AppHeader.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  handleToggleCollapsed: PropTypes.func.isRequired,
}
