import { message } from 'antd'
import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory, useParams } from 'react-router-dom'
import SplitPane from 'react-split-pane'
import api from '../../api'
import { PageHeader } from '../Common'
import PDFPreview from '../Common/PDFPreview'
import { useAsync } from '../../hooks'
import Loading from '../Loading'
import AlertForm from './AlertForm'

const useStyles = createUseStyles({
  app: { display: 'flex', alignItems: 'stretch', flex: '1 1 auto' },
  pdfBox: {
    maxWidth: 'calc(100% - 456px)',
    flexGrow: '1',
    minWidth: '100%',
    position: 'relative',
    height: 'calc(100vh - 96px - 68px)',
    overflowY: 'scroll',
    overflowX: 'hidden',
    '& canvas': {
      maxWidth: '100%',
    },
  },
  formBox: {
    position: 'relative',
    overflowY: 'scroll',
    height: 'calc(100vh - 96px - 68px)',
    padding: '0 16px',
    '& > form': {
      marginTop: '-16px',
    },
  },
})

export default function Edit() {
  const { id } = useParams()
  const classes = useStyles()
  const history = useHistory()
  const getAlert = useCallback(
    () => api.alerts.get(id, { includeForm: true }),
    [id],
  )
  const { pending, value: alert } = useAsync(getAlert)

  const onSubmit = useCallback(
    async values => {
      try {
        await api.alerts.update(id, values)
        message.success('Alert successfully updated')
        history.goBack()
      } catch (e) {
        message.error('Oops, we had a problem. Please try submitting again')
      }
    },
    [history, id],
  )

  if (pending) return <Loading large />

  return (
    <div className={classes.app}>
      <SplitPane split="vertical">
        <div initialSize="65%" minSize="10%" className={classes.pdfBox}>
          <PageHeader title="Edit Alert" onBack={history.goBack} />

          <PDFPreview path={alert.rawFilePath} perPage={10} />
        </div>
        <div className={classes.formBox}>
          <AlertForm
            form={{ ...alert.form, critical: alert.critical }}
            onSubmit={onSubmit}
          />
        </div>
      </SplitPane>
    </div>
  )
}
