import {
  Divider,
  Button,
  Popconfirm,
  Icon,
  Typography,
  message,
  Tooltip,
} from 'antd'
import React, { useCallback } from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'
import moment from 'moment'
import useQueryParams from '../../hooks/useQueryParams'
import api from '../../api'
import { useAsync } from '../../hooks'
import { toPath } from '../../routes'
import Comments from '../Comments'
import {
  useCurrentUser,
  useFeatureGuard,
  useCanSign,
} from '../../redux/slices/session'
import { ListError, PageHeader } from '../Common'
import ActionButtons from '../Common/ActionButtons'
import PatientSummary from '../Common/PatientSummary'
import PDFPreview from '../Common/PDFPreview'
import Loading from '../Loading'
import DownloadButtons from '../Common/DownloadButtons'
import NavigationButtons from '../Common/NavigationButtons'

const { Text } = Typography

export default function ShowOfficeCheck() {
  const { practiceId, id } = useParams()
  const history = useHistory()
  const [query] = useQueryParams({})
  const getOfficeCheck = useCallback(
    () => api.officeChecks.get(practiceId, id, query),
    [practiceId, id, query],
  )
  const {
    error,
    pending,
    value: officeCheck,
    execute: reloadReport,
  } = useAsync(getOfficeCheck)
  const { role } = useCurrentUser()
  const isClinician = role === 'clinician'
  const canSign = useCanSign()

  useFeatureGuard('in_office_checks')

  const goToOfficeChecks = useCallback(() => {
    history.push({
      pathname: toPath.practices.officeChecks({
        id,
        practiceId,
      }),
    })
  }, [history, practiceId, id])

  const toOfficeCheck = useCallback(
    officeCheckId =>
      officeCheckId && {
        pathname: toPath.practices.officeChecks.officeCheck({
          practiceId,
          id: officeCheckId,
        }),
        search: new URLSearchParams(query).toString(),
      },
    [practiceId, query],
  )

  const toEditReport = () => {
    const path = toPath.practices.officeChecks.officeCheck.edit({
      practiceId,
      id,
    })

    const queryString = new URLSearchParams(query).toString()

    return `${path}?${queryString}`
  }

  const handleDelete = useCallback(async () => {
    try {
      await api.officeChecks.destroy(id, practiceId)
      message.success('Office Check successfully deleted')
      history.goBack()
    } catch (e) {
      message.error(`There was an error removing this report: ${e.message}`)
    }
  }, [history, practiceId, id])

  const { pending: signing, execute: handleSign } = useAsync(async () => {
    try {
      await api.officeChecks.signReport(id, practiceId)
      await reloadReport()
      message.success('The report has been signed')
    } catch (e) {
      if (e.response.errors.bbReport === 'not_present') {
        message.error(
          `There was an error signing this report: Please fill out the BioBridge report before signing.`,
        )
      } else {
        message.error(`There was an error signing this report: ${e.message}`)
      }
    }
  }, false)

  if (pending) {
    return <Loading large />
  }

  if (error) {
    return (
      <ListError
        message="There was an error loading this report"
        description={error.message}
      />
    )
  }

  const { patient } = officeCheck

  const canEdit = () => {
    if (!isClinician) {
      return true
    }

    const now = moment.utc()
    const receivedAt = moment.utc(officeCheck.receivedAt)

    const diffSeconds = moment(now).diff(receivedAt) / 1000

    // Only allowed for one hour after the report edition
    return diffSeconds <= 3600
  }

  return (
    <div>
      <NavigationButtons
        nextPath={toOfficeCheck(officeCheck.nextOfficeCheckId)}
        prevPath={toOfficeCheck(officeCheck.previousOfficeCheckId)}
      />
      <PageHeader
        title={`${patient.firstName} ${patient.lastName}`}
        onBack={!isClinician && goToOfficeChecks}
        extra={
          <>
            {officeCheck.critical && (
              <>
                <Icon type="exclamation-circle" style={{ color: 'red' }} />
                <Text type="danger">Requires Attention</Text>
              </>
            )}
            {canSign &&
              (!officeCheck.biobridgeReportPath ? (
                <Tooltip
                  placement="left"
                  title="Add a report to be able to sign it"
                >
                  <Button
                    type="dashed"
                    icon="highlight"
                    loading={signing}
                    disabled
                  >
                    Sign
                  </Button>
                </Tooltip>
              ) : (
                <Popconfirm
                  title="Are you sure you want to sign this report?"
                  onConfirm={handleSign}
                  okText="Yes"
                  cancelText="No"
                  placement="topRight"
                >
                  <Button type="dashed" icon="highlight" loading={signing}>
                    Sign
                  </Button>
                </Popconfirm>
              ))}
            {canEdit() && (
              <>
                {officeCheck.isArchived ? (
                  <Button type="dashed" disabled>
                    Archived
                  </Button>
                ) : (
                  <Link key="edit" to={toEditReport()}>
                    <Button>Edit Report</Button>
                  </Link>
                )}
                <DownloadButtons resource={officeCheck} />
              </>
            )}
          </>
        }
      />
      <PatientSummary patient={{ ...patient, practiceId }} />
      <Divider />
      {officeCheck.biobridgeReportPath && (
        <PDFPreview path={officeCheck.biobridgeReportPath} />
      )}
      <ActionButtons onDelete={handleDelete} onlyAdmin={false} />
      {!isClinician && (
        <Comments collectionName="reports" id={officeCheck.id} />
      )}
    </div>
  )
}
