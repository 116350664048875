import React, { useCallback, useMemo } from 'react'
import { Table, Button, Popconfirm, message } from 'antd'
import moment from 'moment-timezone'
import { ListError, PageHeader } from '../Common'
import {
  useFetchPendingReports,
  usePendingReportsPagination,
  usePendingReports,
} from '../../redux/slices/pending-reports'
import { useAsync } from '../../hooks'
import { formatDOB } from '../../lib/datetime'
import Filters, { FIELDS_OPTIONS } from '../Forms/Filters'
import useQueryParams from '../../hooks/useQueryParams'
import api from '../../api'

const DEFAULT_FILTERS = {
  approved: FIELDS_OPTIONS.approved[1].id,
  enrolled: FIELDS_OPTIONS.approved[0].id,
  page: 1,
  paired: FIELDS_OPTIONS.approved[0].id,
}

const QUERY_PARAMS_SCHEMA = {
  page: Number,
  showInactive: Boolean,
}

const COLUMNS = [
  {
    title: 'Last Name',
    dataIndex: 'patientSummary.lastName',
    key: 'lastName',
  },
  {
    title: 'First Name',
    dataIndex: 'patientSummary.firstName',
    key: 'firstName',
  },
  {
    title: 'DOB',
    dataIndex: 'patientSummary.birthDate',
    key: 'dob',
    render: formatDOB,
  },
  {
    title: 'Practice',
    dataIndex: 'practice.name',
    key: 'practice',
  },
  {
    title: 'Created At',
    key: 'createdAt',
    dataIndex: 'createdAt',
    render(text) {
      return moment(text).calendar(null, { sameElse: 'lll' })
    },
  },
]

export default function PendingReports() {
  const [conditions, setConditions] = useQueryParams({
    initialValues: DEFAULT_FILTERS,
    schema: QUERY_PARAMS_SCHEMA,
  })

  const { pageSize, total } = usePendingReportsPagination()
  const { pending: loading, error, clearError, execute: fetchList } = useAsync(
    useFetchPendingReports(conditions),
    true,
  )
  const reports = usePendingReports()
  const handlePageChange = useCallback(
    page => setConditions({ ...conditions, page }),
    [conditions, setConditions],
  )

  const handleSubmit = useCallback(
    ({ practiceId, query }) => {
      setConditions({
        page: 1,
        practiceId,
        query,
      })
    },
    [setConditions],
  )

  const { pending: approving, execute: approveAllReports } = useAsync(
    async () => {
      const { practiceId, query } = conditions

      if (!practiceId && !query) {
        message.error('Select at least one filter to approve reports')
        return
      }

      try {
        await api.reports.approveReports({ practiceId, q: query })
        message.success('Reports have been approved')
      } catch (e) {
        message.error(e.message)
      }
      fetchList()
    },
    false,
  )

  const { pending: deleting, execute: handleReject } = useAsync(async id => {
    try {
      await api.reports.rejectReport(id)
      message.success('The report has been rejected')
    } catch (e) {
      message.error(`There was an error rejecting this report: ${e.message}`)
    }
    fetchList()
  }, false)

  const columns = useMemo(
    () => [
      ...COLUMNS,
      {
        title: 'Actions',
        key: 'actions',
        render: (_text, record) => {
          return (
            <Popconfirm
              title="Are you sure you want to reject this report?"
              onConfirm={() => handleReject(record.id)}
              okText="Yes"
              cancelText="No"
              placement="topRight"
            >
              <Button type="link" icon="delete" className="delete-button" />
            </Popconfirm>
          )
        },
      },
    ],
    [handleReject],
  )

  return (
    <div>
      {error && <ListError description={error.message} onClose={clearError} />}
      <PageHeader
        title="Pending Reports"
        extra={
          <Button
            type="secondary"
            onClick={approveAllReports}
            disabled={loading || reports.length === 0}
            loading={approving}
          >
            Approve all
          </Button>
        }
      />
      <Filters
        currentValues={conditions}
        defaultValues={DEFAULT_FILTERS}
        showPractice
        showSearch
        onSubmit={handleSubmit}
      />

      <Table
        size="middle"
        rowKey="id"
        loading={loading || approving || deleting}
        dataSource={reports}
        columns={columns}
        pagination={{
          current: conditions.page || 1,
          pageSize,
          onChange: handlePageChange,
          total,
        }}
      />
    </div>
  )
}
