import { Button } from 'antd'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { useHistory } from 'react-router-dom'

const useStyles = createUseStyles({
  navButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
  },
})

export default function NavigationButtons({ nextPath, prevPath }) {
  const history = useHistory()
  const classes = useStyles()
  const navigateTo = useCallback(path => path && history.replace(path), [
    history,
  ])

  return (
    <div className={classes.navButtons}>
      <Button
        icon="left"
        disabled={!prevPath}
        onClick={() => navigateTo(prevPath)}
      >
        Previous
      </Button>

      <Button
        icon="right"
        disabled={!nextPath}
        onClick={() => navigateTo(nextPath)}
      >
        Next
      </Button>
    </div>
  )
}

NavigationButtons.propTypes = {
  nextPath: PropTypes.string,
  prevPath: PropTypes.string,
}

NavigationButtons.defaultProps = {
  nextPath: null,
  prevPath: null,
}
