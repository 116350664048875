import React from 'react'
import Select from './Select'

export const STATUSES = [
  { id: 'queued', name: 'Queued' },
  { id: 'delivered', name: 'Delivered' },
  { id: 'accepted', name: 'Accepted' },
  { id: 'completed', name: 'Completed' },
  { id: 'error', name: 'Error' },
]

export default function ReminderStatusSelect(props) {
  return <Select {...props} options={STATUSES} allowClear />
}
