import moment from 'moment-timezone'

export function absoluteDiff(date1, date2, unit) {
  return Math.abs(moment(date1).diff(date2, unit))
}

export function formatDOB(date) {
  return moment(date).format('L')
}

export function formatDateTime(datetime) {
  return moment(datetime).format('LL')
}

export function standardDateTime(datetime) {
  return moment(datetime).format('MM/DD/YYYY HH:mm:ss zz')
}
