import { useEffect } from 'react'

export default function useClearWhenUnmount(
  clearWhenUnmount,
  setValue,
  setTouched,
) {
  // this needs to emulate componentWillUnmount so empty dependencies is required
  useEffect(() => {
    if (!clearWhenUnmount) return undefined
    return () => {
      if (clearWhenUnmount) {
        // needed with setTimeout so Formik doesn't do another validation in the middle of changes
        setTimeout(() => {
          setValue(null)
          setTouched(true)
        }, 0)
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}
