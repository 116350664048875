import React from 'react'
import PropTypes from 'prop-types'

export default function PieChartLabel({
  textAnchor,
  x,
  y,
  dx,
  dy,
  color,
  data,
  dataIndex,
}) {
  return (
    <text
      key={data[dataIndex].key}
      textAnchor={textAnchor}
      dominantBaseline="middle"
      x={x}
      y={y}
      dx={dx}
      dy={dy}
      style={{ fill: color, fontSize: '7px' }}
    >
      {data[dataIndex].value}
    </text>
  )
}

PieChartLabel.propTypes = {
  textAnchor: PropTypes.string.isRequired,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  dx: PropTypes.number.isRequired,
  dy: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      key: PropTypes.string.isRequired,
    }),
  ).isRequired,
  dataIndex: PropTypes.number.isRequired,
}
