import PropTypes from 'prop-types'
import { Tag } from 'antd'
import React, { useMemo } from 'react'
import { isCurrentCycle } from '../../lib/cycles'
import { formatDateTime } from '../../lib/datetime'
import { fetchCycles, useCycles } from '../../redux/slices/practices'
import { useAsyncAction } from '../../redux/utils'
import AsyncSelect from './AsyncSelect'

export default function CycleSelect({ practiceId, ...props }) {
  const fetchOptions = useAsyncAction(fetchCycles, practiceId)
  const cycles = useCycles(practiceId)
  const options = useMemo(
    () =>
      cycles.map(cycle => {
        const name = formatDateTime(cycle.endsAt)
        const renderLabel = (
          <React.Fragment key={name}>
            {name} {cycle.longPeriod && <Tag color="geekblue">long</Tag>}{' '}
            {isCurrentCycle(cycle) && <Tag color="green">current</Tag>}
          </React.Fragment>
        )

        return {
          ...cycle,
          name,
          renderLabel,
        }
      }),
    [cycles],
  )
  return (
    <AsyncSelect
      label="Cycle"
      fetchOptions={fetchOptions}
      options={options}
      showSearch
      allowClear
      optionFilterProp="children"
      placeholder="Select cycle"
      labelPropName="renderLabel"
      filterOption={(input, option) => {
        const cycleName = option.props.children.key
        return cycleName.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }}
      {...props}
    />
  )
}

CycleSelect.propTypes = {
  practiceId: PropTypes.string.isRequired,
}
