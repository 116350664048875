import { Radio as AntDRadio, Form } from 'antd'
import { useField } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'

const { Item } = Form

export default function RadioButton({
  containerClassName,
  label,
  options,
  ...rest
}) {
  const [field, { error, touched }] = useField(rest)
  const hasError = Boolean(touched && error)

  return (
    <Item
      className={containerClassName}
      label={label}
      validateStatus={hasError ? 'error' : ''}
      help={hasError && error}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <AntDRadio.Group options={options} {...field} {...rest} />
    </Item>
  )
}

RadioButton.propTypes = {
  containerClassName: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
}

RadioButton.defaultProps = {
  containerClassName: undefined,
  options: [],
  label: '',
}
