import { List, message, Divider, Empty } from 'antd'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import api from '../../api'
import { useAsync } from '../../hooks'
import { getPaginationHeaders } from '../../redux/utils'
import Comment from './Comment'
import CommentForm from './CommentForm'

export default function Comments({ collectionName, containerClassName, id }) {
  const [page, setPage] = useState(1)
  const fetchComments = useCallback(
    () => api.comments.list(collectionName, id, page),
    [collectionName, id, page],
  )
  const { pending, value, execute } = useAsync(fetchComments)
  const handlePost = useCallback(
    async (comment, { resetForm }) => {
      try {
        await api.comments.create(collectionName, id, comment)
        message.success('Comment posted')
        resetForm()
      } catch (e) {
        message.error(
          'There was an error posting your comment. Please try again',
        )
      }
      await execute()
    },
    [collectionName, execute, id],
  )
  const handleUpdate = useCallback(
    async comment => {
      try {
        await api.comments.update(collectionName, id, comment)
        message.success('Comment updated')
      } catch (e) {
        message.error(
          'There was an error updating your comment. Please try again',
        )
      }
      await execute()
    },
    [collectionName, execute, id],
  )
  const handleDelete = useCallback(
    async commentId => {
      try {
        await api.comments.destroy(collectionName, id, commentId)
        message.success('Comment deleted')
      } catch (e) {
        message.error(
          'There was an error deleting your comment. Please try again',
        )
      }
      await execute()
    },
    [collectionName, execute, id],
  )
  const comments = value ? value.body : []
  const { pageSize, total } = value ? getPaginationHeaders(value.headers) : {}
  return (
    <div className={classnames('comments', containerClassName)}>
      {Boolean(total) && (
        <List
          dataSource={comments}
          header={
            Boolean(total) && `${total} ${total > 1 ? 'comments' : 'comment'}`
          }
          pagination={
            total > pageSize && {
              current: page,
              pageSize,
              onChange: setPage,
              total,
            }
          }
          itemLayout="horizontal"
          renderItem={comment => (
            <Comment
              comment={comment}
              onUpdate={handleUpdate}
              onDelete={handleDelete}
            />
          )}
          loading={pending}
        />
      )}
      {!total && (
        <Empty
          description="No comments yet"
          image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
      )}
      <Divider />
      <CommentForm onSubmit={handlePost} />
    </div>
  )
}

Comments.propTypes = {
  id: PropTypes.string.isRequired,
  collectionName: PropTypes.string.isRequired,
  containerClassName: PropTypes.string,
}

Comments.defaultProps = {
  containerClassName: undefined,
}
