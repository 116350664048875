import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { createUseStyles } from 'react-jss'

import { Icon } from 'antd'
import { useDropzone } from 'react-dropzone'
import { dropZone as dropZoneStyles } from '../../theme/shared-styles'

const useStyles = createUseStyles(theme => ({
  ...dropZoneStyles,
}))

export default function ReportUpload({ onUpload, manufacturer }) {
  const styles = useStyles()

  const onDrop = useCallback(
    acceptedFiles => {
      if (!onUpload) return

      onUpload(acceptedFiles)
    },
    [onUpload],
  )

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: true,
    onDrop,
    disabled: false,
  })

  const dragAndDropDefaultMessage =
    'Drag and drop PKG, PDF or LOG files here, or click to select the files from your file browser'
  const abbotMessage =
    'Drag and drop the PDF and LOG files here together, or click to select them from your file browser'

  const dragAndDropMessage =
    manufacturer === 'Abbott' ? abbotMessage : dragAndDropDefaultMessage
  return (
    <>
      <div
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...getRootProps({
          className: classnames(
            styles.dropZone,
            isDragActive && styles.activeDropZone,
          ),
        })}
      >
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <input {...getInputProps()} />
        {isDragActive ? (
          <>
            <p>Drop the file here ...</p>
            <Icon type="file-add" theme="filled" />
          </>
        ) : (
          <>
            <p>{dragAndDropMessage}</p>
            <Icon type="file-zip" />
          </>
        )}
      </div>
    </>
  )
}

ReportUpload.defaultProps = {
  onUpload: () => {},
  manufacturer: '',
}

ReportUpload.propTypes = {
  onUpload: PropTypes.func,
  manufacturer: PropTypes.string,
}
