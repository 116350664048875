import queryString from 'query-string'
import apiClient from './api-client'

function list(cycleId, page) {
  const options = { withHeaders: true }
  const query = page === 'all' ? { all: true } : { page }
  return apiClient.get(
    queryString.stringifyUrl({
      url: `/cycles/${cycleId}/billing-orders`,
      query,
    }),
    options,
  )
}

function create(cycleId, billingOrder) {
  return apiClient.post(`/cycles/${cycleId}/billing-orders`, billingOrder)
}

function update(id, billingOrderData) {
  return apiClient.patch(`billing-orders/${id}`, billingOrderData)
}

function get(id) {
  return apiClient.get(`billing-orders/${id}`)
}

function destroy(id) {
  return apiClient.destroy(`billing-orders/${id}`)
}

function markAsBilled(id, payload) {
  return apiClient.put(`billing-orders/${id}/billed`, payload)
}

export default {
  destroy,
  list,
  get,
  markAsBilled,
  create,
  update,
}
