import {
  Divider,
  Button,
  Popconfirm,
  Icon,
  Typography,
  message,
  Tooltip,
} from 'antd'
import React, { useCallback } from 'react'
import { useHistory, useParams, Link } from 'react-router-dom'
import moment from 'moment'

import useQueryParams from '../../hooks/useQueryParams'
import api from '../../api'
import { useAsync } from '../../hooks'
import { toPath } from '../../routes'
import Comments from '../Comments'
import { useCanSign, useCurrentUser } from '../../redux/slices/session'
import { ListError, PageHeader } from '../Common'
import ActionButtons from '../Common/ActionButtons'
import NavigationButtons from '../Common/NavigationButtons'
import PatientSummary from '../Common/PatientSummary'
import PDFPreview from '../Common/PDFPreview'
import Loading from '../Loading'
import DownloadButtons from '../Common/DownloadButtons'
import { useReports } from '../../redux/slices/reports'

const { Text } = Typography

export default function ShowReport() {
  const { id } = useParams()
  const history = useHistory()
  const [query, , getPathWithQuery] = useQueryParams({})
  const getReport = useCallback(() => api.reports.get(id, query), [id, query])
  const { error, pending, value: report, execute: reloadReport } = useAsync(
    getReport,
  )
  const { role } = useCurrentUser()
  const isClinician = role === 'clinician'
  const canSign = useCanSign()

  const reports = useReports(report?.cycleSummary?.id)

  const reportPath = useCallback(
    reportId =>
      reportId && {
        pathname: toPath.reports.report({ id: reportId }),
        search: new URLSearchParams(query).toString(),
      },
    [query],
  )

  const findPaths = () => {
    const currentIndex = reports.findIndex(r => r.id === id)
    if (currentIndex === 0) {
      return {
        next: reportPath(reports[currentIndex + 1]?.id),
        prev: null,
      }
    }
    if (currentIndex === reports.length - 1) {
      return {
        next: null,
        prev: reportPath(reports[currentIndex - 1]?.id),
      }
    }
    return {
      next: reportPath(reports[currentIndex + 1]?.id),
      prev: reportPath(reports[currentIndex - 1]?.id),
    }
  }

  const goToReports = useCallback(() => {
    const { practiceId, id: cycleId } = report.cycleSummary
    history.push({
      pathname: toPath.practices.reports({
        practiceId,
      }),
      search: new URLSearchParams({ ...query, cycleId }).toString(),
    })
  }, [history, query, report])

  const handleDelete = useCallback(async () => {
    try {
      await api.reports.destroy(id)
      message.success('Report successfully removed')
      history.goBack()
    } catch (e) {
      message.error(`There was an error removing this report: ${e.message}`)
    }
  }, [history, id])

  const { pending: signing, execute: handleSign } = useAsync(async () => {
    try {
      await api.reports.signReport(id)
      await reloadReport()
      message.success('The report has been signed')
    } catch (e) {
      if (e.response.errors.bbReport === 'not_present') {
        message.error(
          `There was an error signing this report: Please fill out the BioBridge report before signing.`,
        )
      } else {
        message.error(`There was an error signing this report: ${e.message}`)
      }
    }
  }, false)

  if (pending) {
    return <Loading large />
  }

  if (error) {
    return (
      <ListError
        message="There was an error loading this report"
        description={error.message}
      />
    )
  }

  const { patient } = report

  const canEdit = () => {
    if (!isClinician) {
      return true
    }

    const now = moment.utc()
    const receivedAt = moment.utc(report.receivedAt)

    const diffSeconds = moment(now).diff(receivedAt) / 1000

    // Only allowed for one hour after the report edition
    return diffSeconds <= 3600
  }

  return (
    <div>
      <NavigationButtons
        nextPath={findPaths().next}
        prevPath={findPaths().prev}
      />
      <PageHeader
        title={`${patient.firstName} ${patient.lastName}`}
        onBack={!isClinician && goToReports}
        extra={
          <>
            {report.critical && (
              <>
                <Icon type="exclamation-circle" style={{ color: 'red' }} />
                <Text type="danger">Requires Attention</Text>
              </>
            )}
            {canSign &&
              (!report.filePath ? (
                <Tooltip
                  placement="left"
                  title="Add a report to be able to sign it"
                >
                  <Button
                    type="dashed"
                    icon="highlight"
                    loading={signing}
                    disabled
                  >
                    Sign
                  </Button>
                </Tooltip>
              ) : (
                <Popconfirm
                  title="Are you sure you want to sign this report?"
                  onConfirm={handleSign}
                  okText="Yes"
                  cancelText="No"
                  placement="topRight"
                >
                  <Button type="dashed" icon="highlight" loading={signing}>
                    Sign
                  </Button>
                </Popconfirm>
              ))}

            {canEdit() && (
              <>
                {report.isArchived ? (
                  <Button type="dashed" disabled>
                    Archived
                  </Button>
                ) : (
                  <Link
                    key="edit"
                    to={getPathWithQuery(
                      toPath.reports.report.edit({ id }),
                      query,
                    )}
                  >
                    <Button>Edit Report</Button>
                  </Link>
                )}
                {!isClinician && <DownloadButtons resource={report} />}
              </>
            )}
          </>
        }
      />
      <PatientSummary patient={patient} />
      <Divider />
      {report.filePath && <PDFPreview path={report.filePath} />}
      <ActionButtons onDelete={handleDelete} />
      {!isClinician && <Comments collectionName="reports" id={report.id} />}
    </div>
  )
}
