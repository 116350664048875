import queryString from 'query-string'
import apiClient from './api-client'

function login(email, password) {
  return apiClient.post('users/tokens', { auth: { email, password } })
}

function getAuthorizationToken(token, payload) {
  return apiClient.post('users/authorize', { auth: { token, payload } })
}

function logout() {
  return apiClient.destroy('users/tokens')
}

function me() {
  return apiClient.get('users/me')
}

function updatePassword(payload) {
  return apiClient.put('users/password', { user: payload })
}

function startPasswordChange(email) {
  return apiClient.post('users/password/change', { user: { email } })
}

function list(page, practiceId, showInactive, q) {
  return apiClient.get(
    queryString.stringifyUrl({
      url: 'users',
      query: {
        page,
        practice_id: practiceId,
        // avoid sending when false (string false is also truthy)
        show_inactive: showInactive || undefined,
        q,
      },
    }),
    { withHeaders: true },
  )
}

function getUsersByRole(role = 'admin') {
  return apiClient.get(`/users?role=${role}`)
}

function create(user) {
  return apiClient.post('users', user)
}

function deleteUser(id) {
  return apiClient.destroy(`users/${id}`)
}

function get(id) {
  return apiClient.get(`users/${id}`)
}

function positions() {
  return apiClient.get('users/positions')
}

function update(id, userData) {
  return apiClient.patch(`users/${id}`, userData)
}

function getClinicians() {
  return apiClient.get('users/clinicians')
}

export default {
  create,
  delete: deleteUser,
  get,
  list,
  login,
  logout,
  me,
  positions,
  update,
  updatePassword,
  getClinicians,
  getAuthorizationToken,
  startPasswordChange,
  getUsersByRole,
}
