import PropTypes from 'prop-types'
import React, { useState } from 'react'

export const SetRouteContext = React.createContext()
export const GetRouteContext = React.createContext()

export default function RouterContext({ children }) {
  const [match, setMatch] = useState()
  return (
    <SetRouteContext.Provider value={setMatch}>
      <GetRouteContext.Provider value={match}>
        {children}
      </GetRouteContext.Provider>
    </SetRouteContext.Provider>
  )
}

RouterContext.propTypes = {
  children: PropTypes.node.isRequired,
}
