import React from 'react'
import Select from './Select'

export const STATUSES = [
  { id: 'device_report', name: 'Device Report' },
  { id: 'complete', name: 'Completed' },
  { id: 'signed', name: 'Signed' },
  { id: 'all', name: 'Status: All' },
]

export default function OfficeCheckFilterSelect(props) {
  return <Select {...props} options={STATUSES} />
}
