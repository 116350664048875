import React from 'react'
import PropTypes from 'prop-types'
import { Input as AntDInput } from 'antd'
import { useField } from 'formik'
import Item from './Item'

const { TextArea: AntDTextArea } = AntDInput

export default function TextArea({ id, label, helperText, ...rest }) {
  const [fields, meta] = useField(rest)
  const hasError = Boolean(meta.touched && meta.error)
  return (
    <Item
      label={label}
      htmlFor={id}
      hasFeedback={hasError}
      validateStatus={hasError ? 'error' : ''}
      help={hasError && meta.error}
      helperText={helperText}
    >
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <AntDTextArea id={id} {...fields} {...rest} />
    </Item>
  )
}

TextArea.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  helperText: PropTypes.string,
}

TextArea.defaultProps = {
  label: undefined,
  id: undefined,
  type: undefined,
  helperText: undefined,
}
