import React, { useCallback } from 'react'
import api from '../../api'
import AsyncSelect from './AsyncSelect'

export default function RoleSelect(props) {
  const fetchOptions = useCallback(() => api.roles.list(), [])
  return (
    <AsyncSelect
      fetchOptions={fetchOptions}
      optionFilterProp="children"
      startWithFirstValue
      labelInValue
      placeholder="Select role"
      {...props}
    />
  )
}
