import React from 'react'
import { createUseStyles } from 'react-jss'
import { useDownloads } from '../../redux/slices/pre-authorization-downloads'
import PreAuthorizationDownload from './PreAuthorizationDownload'

const useStyles = createUseStyles({
  container: {
    position: 'fixed',
    bottom: '4px',
    left: '0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  downloadBox: {
    background: '#1C1C1C',
    padding: '20px 40px',
    width: '400px',
    borderRadius: '4px',
    boxShadow: '0 2px 8px rgb(28 28 28 / 75%)',
  },
})

function PreAuthorizationDownloads() {
  const classes = useStyles()
  const preAuthorizationDownloads = useDownloads()
  const downloadIds = Object.keys(preAuthorizationDownloads)

  if (downloadIds.length === 0) return null

  return (
    <div className={classes.container}>
      <div className={classes.downloadBox}>
        {downloadIds.map(key => {
          const { progress } = preAuthorizationDownloads[key]

          return (
            <PreAuthorizationDownload
              key={key}
              progress={progress}
              downloadId={key}
            />
          )
        })}
      </div>
    </div>
  )
}

export default PreAuthorizationDownloads
