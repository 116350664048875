import React, { useState } from 'react'
import { message } from 'antd'
import PropTypes from 'prop-types'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { createUseStyles } from 'react-jss'
import partition from 'lodash/partition'
import { teamShape, clinicianShape } from '../prop-types'
import api from '../../api'

const NO_TEAM = {
  id: 'no_team',
  name: 'No team',
}

const useStyles = createUseStyles(theme => ({
  board: {
    minHeight: '66vh',
    minWidth: '100vw',
    display: 'inline-flex',
  },
  title: {
    backgroundColor: '#ebecf0',
    color: theme.textColor,
    fontSize: '16px',
    fontStyle: 'inherit',
    lineHeight: '1.25',
    fontWeight: '600',
    letterSpacing: '-0.006em',
    padding: '12px',
    margin: '0',
  },
  column: {
    backgroundColor: '#ebecf0',
    display: 'flex',
    flexDirection: 'column',
    width: '250px',
    minHeight: '60px',
    padding: '12px',
  },
  member: {
    backgroundColor: 'white',
    color: theme.textColor,
    borderRadius: '4px',
    border: '2px solid transparent',
    boxShadow: 'none',
    boxSizing: 'border-box',
    padding: '8px',
    minHeight: '40px',
    marginBottom: '8px',
    userSelect: 'none',
    display: 'flex',
  },
}))

function groupCliniciansByTeam(clinicians, teams) {
  const initialBoards = {}

  teams.forEach(team => {
    initialBoards[team.id] = []
  })

  clinicians.forEach(clinician => {
    const teamId = clinician.userTeam ? clinician.userTeam.teamId : NO_TEAM.id
    initialBoards[teamId].push(clinician)
  })

  return initialBoards
}

function TeamBoard({ team, members = [] }) {
  const classes = useStyles()

  return (
    <div style={{ margin: '8px' }}>
      <h4 className={classes.title}>{team.name}</h4>
      <Droppable droppableId={team.id} key={team.id}>
        {droppableProvided => (
          <div className={classes.column} ref={droppableProvided.innerRef}>
            {members.map((member, index) => {
              return (
                <Draggable
                  key={member.id}
                  draggableId={member.id}
                  index={index}
                >
                  {(provided, snapshot) => {
                    return (
                      <div
                        className={classes.member}
                        ref={provided.innerRef}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...provided.draggableProps}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...provided.dragHandleProps}
                        style={{
                          ...(snapshot.isDragging
                            ? { backgroundColor: '#5175B3', color: '#fff' }
                            : {}),
                          ...provided.draggableProps.style,
                        }}
                      >
                        {member.name}
                      </div>
                    )
                  }}
                </Draggable>
              )
            })}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  )
}

function Clinicians({ teams, clinicians }) {
  const classes = useStyles()
  const allTeams = [NO_TEAM, ...teams]
  const [board, setBoard] = useState(
    groupCliniciansByTeam(clinicians, allTeams),
  )

  return (
    <div>
      <div className={classes.board}>
        <DragDropContext
          onDragEnd={async result => {
            const { source, destination, draggableId: clinicianId } = result

            if (!destination || source.droppableId === destination.droppableId)
              return

            const [oldTeamMembers, [foundClinician]] = partition(
              board[source.droppableId],
              clinician => clinician.id !== clinicianId,
            )
            const newTeamMembers = [...board[destination.droppableId]]
            newTeamMembers.splice(destination.index, 0, foundClinician)

            const prevBoard = { ...board }

            setBoard({
              ...board,
              [source.droppableId]: oldTeamMembers,
              [destination.droppableId]: newTeamMembers,
            })

            try {
              if (source.droppableId !== NO_TEAM.id) {
                await api.teams.removeClinicianFromTeam(
                  clinicianId,
                  source.droppableId,
                )
              }

              if (destination.droppableId !== NO_TEAM.id) {
                await api.teams.addClinicianToTeam(
                  clinicianId,
                  destination.droppableId,
                )
              }
            } catch (error) {
              message.error(
                'There was an error moving this clinician. Please try again',
              )
              setBoard(prevBoard)
            }
          }}
        >
          {allTeams.map(team => (
            <TeamBoard key={team.id} team={team} members={board[team.id]} />
          ))}
        </DragDropContext>
      </div>
    </div>
  )
}

TeamBoard.propTypes = {
  team: teamShape.isRequired,
  members: PropTypes.arrayOf(clinicianShape).isRequired,
}

Clinicians.propTypes = {
  teams: PropTypes.arrayOf(teamShape).isRequired,
  clinicians: PropTypes.arrayOf(clinicianShape).isRequired,
}

export default Clinicians
