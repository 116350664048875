import { Switch } from 'antd'
import React, { useMemo } from 'react'

const BASIC_COLUMNS = [
  {
    title: 'First Name',
    dataIndex: 'firstName',
    key: 'firstName',
  },
  {
    title: 'Last Name',
    dataIndex: 'lastName',
    key: 'lastName',
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Role',
    dataIndex: 'role.name',
    key: 'role',
  },
  {
    title: 'Position',
    dataIndex: 'positionLabel',
    key: 'position',
  },
]

export default function useColumns(practiceId, handleChange) {
  return useMemo(() => {
    const cols = [...BASIC_COLUMNS]
    cols.push({
      title: 'Can receive alerts?',
      key: 'actions',
      render: (text, record) => {
        return (
          <span>
            <Switch
              checked={record.isMedicalProvider}
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={enabled => handleChange(record.id, practiceId, enabled)}
            />
          </span>
        )
      },
    })
    return cols
  }, [practiceId, handleChange])
}
