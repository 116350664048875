import React from 'react'
import PropTypes from 'prop-types'
import { Button, Form } from 'antd'

function SubmitButton({
  children,
  isSubmitting,
  isValid,
  noWrapper,
  submitCount,
  title,
  wrapperCol,
  ...rest
}) {
  const button = (
    <Button
      type="primary"
      htmlType="submit"
      disabled={isSubmitting || (!isValid && submitCount > 0)}
      loading={isSubmitting}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      {children || title}
    </Button>
  )
  if (noWrapper) return button
  return <Form.Item wrapperCol={wrapperCol}>{button}</Form.Item>
}

SubmitButton.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  errors: PropTypes.shape({}),
  isSubmitting: PropTypes.bool.isRequired,
  isValid: PropTypes.bool.isRequired,
  noWrapper: PropTypes.bool,
  submitCount: PropTypes.number,
  style: PropTypes.shape({}),
  title: PropTypes.string,
  wrapperCol: PropTypes.shape({}),
}

SubmitButton.defaultProps = {
  children: null,
  className: '',
  errors: {},
  noWrapper: false,
  submitCount: 1,
  style: {},
  title: undefined,
  wrapperCol: undefined,
}

export default SubmitButton
