import { Button, Popconfirm, Table, Tag } from 'antd'
import flatMap from 'lodash/flatMap'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { isCurrentCycle } from '../../lib/cycles'
import { formatDateTime } from '../../lib/datetime'
import { DatePicker } from '../Forms'
import { cycleShape } from '../prop-types'

function cellWithSpan(cycle, content) {
  return cycle.longPeriod
    ? {
        children: content,
        props: { rowSpan: cycle.subCycle ? 0 : 2 },
      }
    : content
}

export default function PracticeCyclesList({
  pagination,
  loading,
  dataSource,
  onSubmit,
  editableCycle,
  onEditCycle,
}) {
  const expandedDataSource = useMemo(() => {
    return flatMap(dataSource, cycle =>
      cycle.longPeriod
        ? [
            cycle,
            {
              ...cycle,
              id: `${cycle.id}-long`,
              startsAt: cycle.startsAtLong,
              endsAt: cycle.endsAtLong,
              reportDate: cycle.reportDateLong,
              subCycle: true,
            },
          ]
        : cycle,
    )
  }, [dataSource])
  // pagination needs to be altered since we are adding 4 virtual rows to each page
  const expandedPagination = useMemo(
    () => ({
      ...pagination,
      pageSize: pagination.pageSize + 4,
      total: (pagination.total * 4) / 3,
    }),
    [pagination],
  )
  const currentPage = pagination.current
  const columns = useMemo(
    () =>
      [
        {
          title: 'Yearly period',
          key: 'yearlyPeriod',
          render(text, cycle, index) {
            const realIndex = dataSource.findIndex(item => item.id === cycle.id)
            return cellWithSpan(
              cycle,
              <>
                <span>{realIndex + 1}</span>{' '}
                {isCurrentCycle(cycle) && <Tag color="green">current</Tag>}
              </>,
            )
          },
        },
        {
          title: 'Start',
          dataIndex: 'startsAt',
          key: 'startsAt',
          render(text, record, index) {
            if (
              editableCycle === record.id &&
              index === 0 &&
              currentPage === 1
            ) {
              return (
                <DatePicker id="startsAt" name="startsAt" allowClear={false} />
              )
            }
            return formatDateTime(text)
          },
        },
        {
          title: 'Ends',
          dataIndex: 'endsAt',
          key: 'endsAt',
          render(text, record) {
            if (editableCycle !== record.id) return formatDateTime(text)
            return <DatePicker id="endsAt" name="endsAt" allowClear={false} />
          },
        },
        {
          title: 'Remote Authorizations',
          dataIndex: 'preAuthorizationDate',
          key: 'preAuthorizationDate',
          render: formatDateTime,
        },
        {
          title: 'Reports',
          dataIndex: 'reportDate',
          key: 'reportDate',
          render: formatDateTime,
        },
        {
          title: 'Checks',
          key: 'checks',
          render(text, cycle) {
            return !cycle.longPeriod || !cycle.subCycle ? (
              <Tag color="cyan">30 days</Tag>
            ) : (
              <Tag color="purple">90 days</Tag>
            )
          },
        },
        onSubmit && {
          title: 'Operation',
          key: 'operation',
          render(text, cycle) {
            const content =
              editableCycle !== cycle.id ? (
                <Button
                  type="link"
                  onClick={() => onEditCycle(cycle.id)}
                  disabled={editableCycle}
                >
                  Edit
                </Button>
              ) : (
                <>
                  <Popconfirm
                    title="This will update the dates for all subsequent cycles. Are you sure?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={onSubmit}
                    placement="topRight"
                  >
                    <Button type="link">Save</Button>
                  </Popconfirm>
                  <Button type="link" onClick={() => onEditCycle(null)}>
                    Cancel
                  </Button>
                </>
              )
            return cellWithSpan(cycle, content)
          },
        },
      ].filter(Boolean),
    [currentPage, dataSource, editableCycle, onEditCycle, onSubmit],
  )
  return (
    <Table
      columns={columns}
      dataSource={expandedDataSource}
      loading={loading}
      pagination={expandedPagination}
      rowKey="id"
    />
  )
}

PracticeCyclesList.propTypes = {
  pagination: PropTypes.shape({
    current: PropTypes.number,
    pageSize: PropTypes.number,
    onChange: PropTypes.func.isRequired,
    total: PropTypes.number,
  }).isRequired,
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  dataSource: PropTypes.arrayOf(cycleShape),
  editableCycle: PropTypes.string,
  onEditCycle: PropTypes.func.isRequired,
}

PracticeCyclesList.defaultProps = {
  loading: true,
  dataSource: undefined,
  editableCycle: undefined,
  onSubmit: undefined,
}
