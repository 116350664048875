import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createSlice, createSelector } from '@reduxjs/toolkit'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'
import api from '../../api'

function buildSessionFromToken() {
  const decoded = api.apiClient.getCurrentUser()
  return {
    loggedIn: Boolean(decoded),
    isAdmin: Boolean(decoded && decoded.is_admin),
    userRole: decoded ? decoded.role : undefined,
    practiceId: decoded ? decoded.practice_id : undefined,
    userId: '',
  }
}

const sessionSlice = createSlice({
  name: 'session',
  initialState: buildSessionFromToken(),
  reducers: {
    setUser: (
      state,
      {
        payload: {
          id,
          active,
          email,
          firstName,
          lastName,
          isAdmin,
          practice,
          role,
          securityId,
        },
      },
    ) => {
      state.user = {
        id,
        active,
        firstName,
        lastName,
        email,
        isAdmin: role === 'admin',
        loggedIn: Boolean(id),
        practice,
        userRole: role,
        securityId,
      }
    },
    login: (
      state,
      { payload: { isAdmin, loggedIn, userId, practiceId, userRole } },
    ) => {
      state.loggedIn = loggedIn
      state.isAdmin = isAdmin
      state.practiceId = practiceId
      state.userId = userId
      state.userRole = userRole
    },
    logout: state => {
      state.loggedIn = false
      state.isAdmin = false
      state.practiceId = undefined
      state.userId = undefined
      state.userRole = undefined
    },
  },
})

const { actions } = sessionSlice

const selectSessionState = state => state[sessionSlice.name]

const getAuthToken = createSelector(
  selectSessionState,
  state => state.user?.loggedIn,
)

const getCurrentUserId = createSelector(
  selectSessionState,
  state => state.user?.id,
)

export function useIsLoggedIn() {
  const user = useCurrentUser()
  return Boolean(useSelector(getAuthToken)) || Boolean(user)
}

export function useIsAdmin() {
  const user = useCurrentUser()
  return Boolean(user.role === 'admin')
}

export function useIsPractitioner() {
  const user = useCurrentUser()
  return Boolean(user.role === 'practitioner')
}

export function useCurrentUserId() {
  return useSelector(getCurrentUserId)
}

export function useCurrentUserPracticeId() {
  const user = useCurrentUser()
  return user?.practice?.id
}

export function useCurrentUserRole() {
  const user = useCurrentUser()
  return user?.role
}

export function useCurrentUser() {
  return api.apiClient.getCurrentUser()
}

export function useCanSign() {
  const { role, position } = useCurrentUser()
  const isPractitioner = role === 'practitioner'
  const isProvider = position === 'provider'
  const isTechnician = position === 'technician'
  return isPractitioner && (isProvider || isTechnician)
}

function updateToken(jwt) {
  return dispatch => {
    api.apiClient.setToken(jwt)
  }
}

function updateCurrentUser(user) {
  return dispatch => {
    api.apiClient.setCurrentUser(user)
    dispatch(actions.setUser(user))
  }
}

function setAuthenticatedUser(token, user) {
  return dispatch => {
    dispatch(updateToken(token))
    dispatch(updateCurrentUser(user))
  }
}

function login(email, password) {
  return async dispatch => {
    throw new Error('Deprecated authentication method')
  }
}

function authorize(authToken, payload) {
  return async dispatch => {
    const { token, user } = await api.users.getAuthorizationToken(
      authToken,
      payload,
    )
    dispatch(setAuthenticatedUser(token, user))
  }
}

export function useUpdateToken() {
  const dispatch = useDispatch()
  return useCallback(({ token }) => dispatch(updateToken(token)), [dispatch])
}

export function useLogin() {
  const dispatch = useDispatch()
  const callback = useCallback(
    (email, password) => dispatch(login(email, password)),
    [dispatch],
  )
  return callback
}

export function useAuthorize() {
  const dispatch = useDispatch()
  const callback = useCallback(
    (authToken, payload) => dispatch(authorize(authToken, payload)),
    [dispatch],
  )
  return callback
}

export function useLogout(redirectPath = '/logout') {
  const { logout } = useAuth0()

  const callback = useCallback(async () => {
    api.apiClient.clearToken(true)
    api.apiClient.clearCurrentUser(true)
    await logout({ returnTo: `${window.location.origin}${redirectPath}` })
  }, [logout, redirectPath])
  return callback
}

export function useClearAuth() {
  const dispatch = useDispatch()
  const { logout } = useAuth0()

  const callback = useCallback(async () => {
    api.apiClient.clearToken()
    dispatch(actions.logout())
    await logout({ returnTo: `${window.location.origin}/logout` })
  }, [dispatch, logout])
  return callback
}

export function useFeatureEnabled(featureName) {
  const user = useCurrentUser()
  const features = user?.practice?.featuresEnabled
  return features ? features?.includes(featureName) : true
}

export function useFeatureGuard(featureName) {
  const history = useHistory()
  if (!useFeatureEnabled(featureName)) history.push('/404')
}

export default sessionSlice
