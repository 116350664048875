import React from 'react'
import PropTypes from 'prop-types'
import { Input as AntDInput } from 'antd'
import { useField } from 'formik'
import useClearWhenUnmount from '../../hooks/useClearWhenUnmount'
import Item from './Item'

export default function Input({
  clearWhenUnmount,
  id,
  label,
  labelAlign,
  labelCol,
  wrapperCol,
  helperText,
  ...rest
}) {
  const [fields, { error, touched }, { setValue, setTouched }] = useField(rest)
  const InputComp = rest.type === 'password' ? AntDInput.Password : AntDInput
  const hasError = Boolean(touched && error)
  const itemProps = {
    hasFeedback: hasError,
    help: hasError && error,
    htmlFor: id,
    label,
    labelAlign,
    helperText,
    validateStatus: hasError ? 'error' : '',
    // This is needed since even sending undefined will override Form's props regarding this
    // so we leave null/false to override, and undefined to keep ignoring (as it should be probably)
    ...(labelCol !== undefined && { labelCol }),
    ...(wrapperCol !== undefined && { wrapperCol }),
  }

  useClearWhenUnmount(clearWhenUnmount, setValue, setTouched)

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Item {...itemProps}>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <InputComp id={id} {...fields} {...rest} />
    </Item>
  )
}

Input.propTypes = {
  clearWhenUnmount: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  labelAlign: PropTypes.string,
  labelCol: PropTypes.shape({}),
  id: PropTypes.string,
  type: PropTypes.string,
  helperText: PropTypes.string,
  wrapperCol: PropTypes.shape({}),
}

Input.defaultProps = {
  clearWhenUnmount: false,
  label: undefined,
  labelAlign: undefined,
  labelCol: undefined,
  helperText: undefined,
  id: undefined,
  type: undefined,
  wrapperCol: undefined,
}
