import React from 'react'
import {
  useFetchClinicians,
  useClinicians,
} from '../../redux/slices/clinicians'
import AsyncSelect from './AsyncSelect'

export default function ClinicianSelect(props) {
  const fetchOptions = useFetchClinicians()
  const clinicians = useClinicians()

  return (
    <AsyncSelect
      fetchOptions={fetchOptions}
      options={clinicians}
      showSearch
      allowClear
      optionFilterProp="children"
      placeholder="Select clinician"
      {...props}
    />
  )
}
