import { createSelector } from '@reduxjs/toolkit'
import { denormalize, normalize } from 'normalizr'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import api from '../../api'
import config from '../../config'
import { cyclesSchema, reportsSchema } from '../normalizr'
import { getPaginationHeaders, useAsyncAction } from '../utils'
import createSlice from '../create-slice'
import { getCycles, getEntities } from './entities'

const reportsInitialState = {
  cycles: {},
}

const reportsSlice = createSlice({
  name: 'reports',
  initialState: reportsInitialState,
  reducers: {
    setReports: (state, { payload: { cycleId, result, pageSize, total } }) => {
      state.cycles[cycleId] = state.cycles[cycleId] || {}
      state.cycles[cycleId].ids = result
      state.cycles[cycleId].pageSize = pageSize
      state.cycles[cycleId].total = total
    },
  },
})

const { actions } = reportsSlice

const selectState = state => state[reportsSlice.name]

function getReportUrl({ filePath }) {
  if (!filePath) return null
  const path = filePath.startsWith('/') ? filePath : `/${filePath}`
  return `${config.apiUrl}${path}`
}

const selectReportsInCycle = createSelector(
  selectState,
  getEntities,
  (state, cycleId) => cycleId,
  (state, entities, cycleId) =>
    denormalize(
      get(state.cycles, [cycleId, 'ids'], []),
      [reportsSchema],
      entities,
    ).map(report => ({
      ...report,
      url: getReportUrl(report),
    })),
)

export function useReports(cycleId) {
  return useSelector(state => selectReportsInCycle(state, cycleId))
}

export function useReportsPagination(cycleId) {
  const { cycles } = useSelector(selectState)
  const { pageSize, total } = get(cycles, [cycleId], {})
  return { pageSize, total }
}

function fetchReports(practiceId, cycleId, page, filters, perPage) {
  return async dispatch => {
    const { body, headers } = await api.reports.list(
      practiceId,
      cycleId,
      page,
      filters,
      perPage,
    )
    dispatch(
      actions.setReports({
        cycleId,
        ...normalize(body, [reportsSchema]),
        ...getPaginationHeaders(headers),
      }),
    )
  }
}

export function useFetchReports(practiceId, cycleId, page, filters, perPage) {
  return useAsyncAction(
    fetchReports,
    practiceId,
    cycleId,
    page,
    filters,
    perPage,
  )
}

export function useIsAcknowledged(cycleId) {
  const cycles = useSelector(getCycles)
  const acknowledgedAt = get(cycles, [cycleId, 'acknowledgedAt'])
  return !!acknowledgedAt
}

export function acknowledgeReports(practiceId, cycleId) {
  const type = 'ACKNOWLEDGE_REPORTS' // Just to update entities slice
  return async dispatch => {
    const body = await api.reports.acknowledge(practiceId, cycleId)
    return dispatch({
      type,
      payload: normalize(body, cyclesSchema),
    })
  }
}
export default reportsSlice
