import { useCallback } from 'react'
import useQueryParams from './useQueryParams'

const QUERY_PARAMS_SCHEMA = { page: Number }

export default function useFilters(initialValues = {}) {
  // eslint-disable-next-line no-unused-vars
  const [conditions, setConditions] = useQueryParams({
    initialValues,
    schema: QUERY_PARAMS_SCHEMA,
  })

  const handleFilterChange = useCallback(
    args => {
      setConditions({ ...conditions, checkDate: args.month, page: 1 })
    },
    [conditions, setConditions],
  )

  const handlePageChange = useCallback(
    page => setConditions({ ...conditions, page }),
    [conditions, setConditions],
  )

  return {
    conditions,
    setConditions,
    handlePageChange,
    handleFilterChange,
  }
}
