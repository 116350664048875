import moment from 'moment'
import get from 'lodash/get'
import mapValues from 'lodash/mapValues'
import lowerCase from 'lodash/lowerCase'
import capitalize from 'lodash/capitalize'
import { Yup, createYupSchema } from '../../lib/validations'

// convert our dataTypes to yup types
const DATA_TYPES_MAPPER = {
  text: 'string',
  long_text: 'string',
  integer: 'number',
  float: 'decimal',
  date: 'date',
  bool: 'boolean',
  choice: 'string',
  multiple_choice: 'array',
}

function buildFieldValidations(fieldValidations = {}) {
  const validations = []

  if (fieldValidations.required) {
    validations.push({ type: 'required' })
  }

  if (fieldValidations.in) {
    validations.push({ type: 'min', params: [fieldValidations.in[0]] })
    validations.push({ type: 'max', params: [fieldValidations.in[1]] })
  }

  return validations
}

export const OTHER_OPTION_VALUE = 'Other'
export const OTHERS_SET_KEY = 'others'

export function getOptions(sets, formType) {
  if (!sets) return []
  return sets[formType] || sets.default
}

export function getFields(formSections = []) {
  return formSections.reduce((acc, section) => {
    section.fields.forEach(field => {
      acc[field.itemId] = field
    })
    return acc
  }, {})
}

export function getLabels(formSections) {
  return mapValues(getFields(formSections), value =>
    value.itemLabel === 'unlabeled'
      ? undefined
      : value.itemLabel || capitalize(lowerCase(value.itemName)),
  )
}

export function getPlaceholders(formSections) {
  const fields = getFields(formSections)

  return mapValues(fields, (_value, key) => {
    if (fields[key].dataType === 'date') return 'Select date'
    if (fields[key].dataType === 'choice') return 'Select an option'

    return ''
  })
}

const getMonthsAsYears = num => {
  const float = parseFloat(num / 12).toFixed(1)
  return float % 1 === 0 ? parseInt(float, 10) : float
}

function numberToDate(num) {
  // A number with decimals means years
  if (num % 1 !== 0) return `${num} year${num === 1 ? '' : 's'}`

  if (num > 11) {
    // Convert months to years
    const years = getMonthsAsYears(num)
    return `${years} year${years === 1 ? '' : 's'}`
  }

  // Less than 12, it's in months
  return `${num} month${num === 1 ? '' : 's'}`
}

export function formatBatteryValue(val) {
  if (!val) return ''

  const str = String(val)
    .trim()
    .toLowerCase()

  // does not change value when it contains month(s) or year(s)
  if (/^(\d*?\.?\d*) (year(s?)|month(s?))$/.test(str)) {
    return val
  }

  // does not change value when it is a percentage number
  if (/^\d*?\.?\d*%$/.test(str)) {
    return val
  }

  const num = Number(val)

  if (!Number.isNaN(num) && num >= 0) {
    // field is a number, add "months" or "years" to the number
    return numberToDate(num)
  }

  return String(val)
}

export function formatInitialValues(fieldValues, formSections, formType) {
  const fields = getFields(formSections)
  const others = {}
  const formatValues = mapValues(fields, (field, key) => {
    const value = fieldValues[key]
    const dataType = get(field, 'dataType')
    if (!value) return undefined

    if (dataType === 'date') return moment(value)
    if (dataType === 'choice') {
      const options = getOptions(field.options, formType)
      if (options.includes(value)) return value

      others[key] = value || ''
      return OTHER_OPTION_VALUE
    }

    return value
  })

  return { ...formatValues, [OTHERS_SET_KEY]: others }
}

export function formatSavedValues(values, formSections) {
  const { [OTHERS_SET_KEY]: others, ...restValues } = values
  const fields = getFields(formSections)
  const formatValues = mapValues(restValues, (value, key) => {
    if (get(fields, [key, 'itemName']) === 'estimated_battery_longevity') {
      return formatBatteryValue(value)
    }
    const dataType = get(fields, [key, 'dataType'])
    if (dataType === 'date' && value) {
      return value.toISOString()
    }

    if (dataType === 'choice' && value === OTHER_OPTION_VALUE) {
      return others[key]
    }

    return value
  })

  const response = Object.entries(formatValues).map(([itemId, value]) => ({
    itemId,
    value,
  }))

  return response
}

export function buildValidationSchema(formSections) {
  const fields = getFields(formSections)
  const schema = Object.entries(fields)
    .map(([key, field]) => {
      return {
        id: key,
        validationType: DATA_TYPES_MAPPER[field.dataType],
        validations: buildFieldValidations(field.validations),
        label: capitalize(lowerCase(field.itemName)),
      }
    })
    .reduce(createYupSchema, {})

  return Yup.object().shape(schema)
}
