import nanoid from 'nanoid'

export class PromiseCancelled extends Error {}

export function buildResolveOnlyLastPromise(promiseFn) {
  let ongoingId
  return async (...args) => {
    const currentId = nanoid()
    ongoingId = currentId
    const result = await promiseFn(...args)
    if (ongoingId !== currentId) {
      throw new PromiseCancelled()
    }
    return result
  }
}
