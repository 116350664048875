import { useFormikContext } from 'formik'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'
import PropTypes from 'prop-types'
import { useEffect, useMemo, useRef, useState } from 'react'

const DEBOUNCE_DELAY = 300

export default function SubmitOnChange({ debounced }) {
  const [currentValues, setCurrentValues] = useState()
  const initialRender = useRef(true)
  const { submitForm, values } = useFormikContext()
  const submit = useMemo(() => {
    if (debounced) {
      return debounce(() => submitForm(), DEBOUNCE_DELAY)
    }
    return () => submitForm()
  }, [debounced, submitForm])
  useEffect(() => {
    if (initialRender.current) {
      setCurrentValues(values)
      initialRender.current = false
    } else if (!isEqual(currentValues, values)) {
      setCurrentValues(values)
      submit()
    }
  }, [currentValues, values, submit])

  return null
}

SubmitOnChange.propTypes = {
  debounced: PropTypes.bool,
}

SubmitOnChange.defaultProps = {
  debounced: false,
}
