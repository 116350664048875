import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import api from '../../api'
import AsyncSelect from './AsyncSelect'

function formatOption(insuranceProvider) {
  return {
    id: insuranceProvider.id,
    name: insuranceProvider.name,
  }
}

export default function InsuranceProviderSelect(props) {
  const { value } = props
  const fetchOptions = useCallback(
    q =>
      api.insuranceProviders
        .getAll({ q })
        .then(res => res.body.map(formatOption)),
    [],
  )

  return (
    <AsyncSelect
      asyncSearch
      fetchOptions={fetchOptions}
      initialFetch={false}
      showSearch
      allowClear
      optionFilterProp="children"
      placeholder="Start typing to find an Insurance Provider"
      {...props}
      options={value ? [value] : []}
    />
  )
}

InsuranceProviderSelect.propTypes = {
  defaultOption: PropTypes.shape({}),
  value: PropTypes.shape({}),
}

InsuranceProviderSelect.defaultProps = {
  defaultOption: null,
  value: null,
}
