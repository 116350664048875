import React from 'react'
import { PageHeader } from '../../Common'
import { toPath } from '../../../routes'
import PracticeSelectView from '../../Practices/PracticeSelectView'

export default function OfficeBillingOrders() {
  return (
    <div>
      <PageHeader title="Office Billing Orders" />
      <PracticeSelectView routeMethod={toPath.practices.officeBillingOrders} />
    </div>
  )
}
