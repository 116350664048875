import React, { useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { message, Table, Button } from 'antd'
import get from 'lodash/get'
import { createUseStyles } from 'react-jss'
import { ListError, PageHeader } from '../Common'
import Filters from '../Forms/Filters'
import api from '../../api'
import useQueryParams from '../../hooks/useQueryParams'
import { useAsync } from '../../hooks'
import {
  useFetchUsers,
  useUsers,
  useUsersPagination,
} from '../../redux/slices/users'

import { updateProvider } from '../../redux/slices/practices'
import useColumns from './useColumns'

const useStyles = createUseStyles({
  center: {
    textAlign: 'center',
  },
})

const QUERY_PARAMS_SCHEMA = {
  page: Number,
  practiceId: String,
  showInactive: Boolean,
}

export default function PracticeHealthProviders() {
  const { practiceId } = useParams()
  const dispatch = useDispatch()
  const classes = useStyles()
  const history = useHistory()

  const getPractice = useCallback(
    () =>
      api.practices.getPractice(practiceId).then(practice => {
        practice.logo = practice.logoPath
        return practice
      }),
    [practiceId],
  )

  const { value: practice } = useAsync(getPractice)

  const [conditions, setConditions] = useQueryParams({
    initialValues: { page: 1, practiceId },
    schema: QUERY_PARAMS_SCHEMA,
  })

  const { pending, error, clearError } = useAsync(
    useFetchUsers(conditions, true),
  )

  const handleChange = useCallback(
    async (id, pId, enabled) => {
      try {
        await dispatch(updateProvider(id, pId, { enabled }))
        message.success('User updated successfully')
      } catch (e) {
        message.error(
          get(
            e,
            'response.errors.authorized',
            'Oops, we had a problem. Please try submitting again',
          ),
        )
      }
    },
    [dispatch],
  )

  const handlePageChange = useCallback(
    page => setConditions({ ...conditions, page }),
    [conditions, setConditions],
  )

  const handleSubmit = useCallback(
    ({ id, query, showInactive }) => {
      setConditions({
        page: 1,
        practiceId: id,
        query,
        showInactive: showInactive || undefined,
      })
    },
    [setConditions],
  )

  const providersDone = useCallback(() => {
    history.replace(`/practices/${practiceId}/edit`)
  }, [history, practiceId])

  const { pageSize, total } = useUsersPagination()
  const columns = useColumns(practiceId, handleChange)

  const users = useUsers(practiceId)

  return (
    <div>
      {error && <ListError description={error.message} onClose={clearError} />}
      <PageHeader
        title={`Users - ${practice ? practice.name : ''}`}
        onBack={history.goBack}
      />
      <Filters currentValues={conditions} onSubmit={handleSubmit} showSearch />
      <Table
        columns={columns}
        dataSource={users}
        loading={pending}
        pagination={{
          current: conditions.page || 1,
          pageSize,
          onChange: handlePageChange,
          total,
        }}
        rowKey="id"
      />
      <div className={classes.center}>
        <Button type="primary" onClick={providersDone}>
          Done
        </Button>
      </div>
    </div>
  )
}
