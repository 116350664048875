import { Form } from 'antd'
import { Formik } from 'formik'
import mapValues from 'lodash/mapValues'
import PropTypes from 'prop-types'
import React from 'react'
import { formElementPropsGenerator } from '../../lib/forms'
import { Yup } from '../../lib/validations'
import { Input, SubmitButton } from '../Forms'

const CHANGE_PASSWORD_LABELS = {
  currentPassword: 'Current Password',
  password: 'New Password',
  passwordConfirmation: 'Confirm Password',
}

const INITIAL_VALUES = mapValues(CHANGE_PASSWORD_LABELS, () => undefined)

const propsFor = formElementPropsGenerator({
  formName: 'change-password-form',
  labels: CHANGE_PASSWORD_LABELS,
})

function buildValidationSchema(hideCurrentPassword) {
  const schema = {
    password: Yup.string().required(),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], "Passwords don't match")
      .required(),
  }

  if (!hideCurrentPassword) {
    schema.currentPassword = Yup.string().required()
  }

  return Yup.object(schema)
}

const validationSchema = buildValidationSchema()
const validationSchemaWithoutCurrent = buildValidationSchema(true)

export default function ChangePasswordForm({ onSubmit, hideCurrentPassword }) {
  // DO NOT USE THIS COMPONENT
  // This component is deprecated in favor of Auth0's password reset flow and will be removed
  // once the integration with Auth0 is tested

  return (
    <div>
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={
          hideCurrentPassword
            ? validationSchemaWithoutCurrent
            : validationSchema
        }
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, isValid, submitCount }) => (
          <Form onSubmit={handleSubmit}>
            {!hideCurrentPassword && (
              <Input type="password" {...propsFor('currentPassword')} />
            )}
            <Input type="password" {...propsFor('password')} />
            <Input type="password" {...propsFor('passwordConfirmation')} />
            <SubmitButton
              isSubmitting={isSubmitting}
              isValid={isValid}
              title="Save Password"
              submitCount={submitCount}
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}

ChangePasswordForm.propTypes = {
  hideCurrentPassword: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
}

ChangePasswordForm.defaultProps = {
  hideCurrentPassword: false,
}
