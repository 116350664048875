import { Button, Dropdown, Icon, Menu } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'
import DownloadLink from './DownloadLink'

const useStyles = createUseStyles({
  icon: {
    marginRight: 10,
  },
})

export default function DownloadButtons({ resource }) {
  const classes = useStyles()
  const { editableFilePath, filePath, rawFilePath, mergedFilePath } = resource
  const { deviceReportPath, fullReportPath } = resource

  const fullReportLink = fullReportPath || mergedFilePath || filePath
  const deviceReportLink = deviceReportPath || rawFilePath

  return (
    <>
      {deviceReportLink && (
        <DownloadLink path={deviceReportLink}>
          <Button icon="download">Full Report</Button>
        </DownloadLink>
      )}
      {fullReportLink && (
        <Dropdown
          overlay={
            <Menu>
              {fullReportLink && (
                <Menu.Item key="1">
                  <DownloadLink path={fullReportLink}>
                    <Icon type="file-pdf" className={classes.icon} />
                    <span>PDF Report</span>
                  </DownloadLink>
                </Menu.Item>
              )}
              {editableFilePath && (
                <Menu.Item key="2">
                  <DownloadLink path={editableFilePath}>
                    <Icon type="file-word" className={classes.icon} />
                    <span>Word Report</span>
                  </DownloadLink>
                </Menu.Item>
              )}
            </Menu>
          }
        >
          <Button>
            Download <Icon type="down" />
          </Button>
        </Dropdown>
      )}
    </>
  )
}

DownloadButtons.propTypes = {
  resource: PropTypes.shape({
    editableFilePath: PropTypes.string,
    filePath: PropTypes.string,
    rawFilePath: PropTypes.string,
    mergedFilePath: PropTypes.string,
    deviceReportPath: PropTypes.string,
    fullReportPath: PropTypes.string,
  }).isRequired,
}
