import { Row, Checkbox, Col, message } from 'antd'
import React, { useCallback, useState, useEffect } from 'react'
import { chunk } from 'lodash'
import PropTypes from 'prop-types'
import api from '../../api'

function Disease({ id, name, isPresent, patientId }) {
  const [checked, setChecked] = useState(isPresent)
  const [request, setSendRequest] = useState(false)

  const onChange = useCallback(
    async event => {
      try {
        const currentChecked = event.target.checked
        if (currentChecked) {
          await api.diseases.addDiseaseToPatient(patientId, id)
        } else {
          await api.diseases.removeDiseaseFromPatient(patientId, id)
        }
        setChecked(event.target.checked)
      } catch (error) {
        message.error("Coulnd't update this patient disease, try again later.")
      } finally {
        setSendRequest(false)
      }
    },
    [id, patientId],
  )

  return (
    <Col className="gutter-row" span={6}>
      <Checkbox
        onChange={onChange}
        checked={checked}
        key={id}
        disabled={request}
      >
        {name}
      </Checkbox>
    </Col>
  )
}

const diseaseChunkRender = (array, patientId) => {
  return array.map(disease => (
    <Disease
      id={disease.id}
      name={disease.name}
      isPresent={disease.isPresent}
      patientId={patientId}
      key={disease.id}
    />
  ))
}

export default function DiseasesEdit({ patientId }) {
  const [diseases, setDiseases] = useState([])

  useEffect(() => {
    async function fetchData() {
      try {
        const allDiseases = await api.diseases.getAllDiseases()
        const patientDiseases = await api.diseases.getDiseases(patientId)
        const finalArray = allDiseases.map(disease => {
          const foundDisease = patientDiseases.find(
            patientDisease => patientDisease.id === disease.id,
          )
          disease.isPresent = !!foundDisease
          return disease
        })
        setDiseases(finalArray)
      } catch (err) {
        message.error('There was a problem while fetching patient diseases')
      }
    }
    fetchData()
  }, [patientId])

  return (
    <Row>
      {chunk(diseases, 4).map(array => diseaseChunkRender(array, patientId))}
    </Row>
  )
}

Disease.propTypes = {
  isPresent: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  patientId: PropTypes.string.isRequired,
}

DiseasesEdit.propTypes = {
  patientId: PropTypes.string.isRequired,
}
