import humps from 'humps'
import queryString from 'query-string'
import apiClient from './api-client'

function list(cycleId, page, filters) {
  const options = { withHeaders: true }
  const pageFilter = page === 'all' ? { all: true } : { page }
  const query = humps.decamelizeKeys({ ...filters, ...pageFilter })
  return apiClient.get(
    queryString.stringifyUrl({
      url: `cycles/${cycleId}/pre-authorizations`,
      query,
    }),
    options,
  )
}

function create(cycleId, preAuthorization) {
  return apiClient.post(`cycles/${cycleId}/pre-authorizations`, {
    preAuthorization,
  })
}

function update(id, preAuthorization) {
  return apiClient.patch(`pre-authorizations/${id}`, { preAuthorization })
}

function updateInOffice(practiceId, id, preAuthorization) {
  return apiClient.patch(
    `/practices/${practiceId}/in-office-authorizations/${id}`,
    { preAuthorization },
  )
}

function get(id) {
  return apiClient.get(`pre-authorizations/${id}`)
}

function destroy(id) {
  return apiClient.destroy(`pre-authorizations/${id}`)
}

function getBatch(cycleId) {
  return apiClient.get(`/cycles/${cycleId}/pre-authorizations/reports/batch`)
}

function createBatch(cycleId) {
  return apiClient.post(`/cycles/${cycleId}/pre-authorizations/reports/batch`)
}

function downloadFile(cycleId) {
  return apiClient.get(`/cycles/${cycleId}/pre-authorizations/reports/file`)
}

export default {
  destroy,
  list,
  get,
  create,
  update,
  updateInOffice,
  getBatch,
  createBatch,
  downloadFile,
}
