import React, { useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { message } from 'antd'
import mapValues from 'lodash/mapValues'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import api from '../../api'
import { useAsync } from '../../hooks'
import { PageHeader } from '../Common'
import Loading from '../Loading'
import { updatePreAuthorization } from '../../redux/slices/pre-authorizations'
import { toPath } from '../../routes'
import PreAuthorizationForm from './Form'

export default function EditPreAuthorization() {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()
  const getPreAuthorization = useCallback(() => api.preAuthorizations.get(id), [
    id,
  ])
  const { pending, value } = useAsync(getPreAuthorization)
  const preAuthorization = useMemo(
    () => value && { ...value, billingDate: moment(value.billingDate) },
    [value],
  ) // DatePicker in PreAuthorizationForm receives a moment object

  const onSubmit = useCallback(
    async (
      { cptCode, icd10Code, billingDate, authorized, medicalProviderId },
      { setErrors },
    ) => {
      try {
        await dispatch(
          updatePreAuthorization(id, {
            cptCode,
            icd_10_code: icd10Code, // icd10Code is transformed to icd10_code
            billingDate: billingDate.format(moment.HTML5_FMT.DATE),
            authorized,
            medicalProviderId,
          }),
        )
        message.success('Remote authorization successfully updated')
        history.push(toPath.preAuthorizations.preAuthorization({ id }))
      } catch (e) {
        if (e.response && e.response.errors) {
          setErrors(mapValues(e.response.errors, fieldErrors => fieldErrors[0]))
        } else {
          message.error('Oops, we had a problem. Please try submitting again')
        }
      }
    },
    [dispatch, history, id],
  )

  if (pending) return <Loading large />

  return (
    <div>
      <PageHeader title="Edit Remote Authorization" onBack={history.goBack} />
      <PreAuthorizationForm
        onSubmit={onSubmit}
        preAuthorization={preAuthorization}
      />
    </div>
  )
}
