import downloadjs from 'downloadjs'
import get from 'lodash/get'
import apiClient from '../api/api-client'

const MIME = {
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  pdf: 'application/pdf',
  zip: 'application/zip',
}

function getFileNameFromResponse(response, extension) {
  const contentDisposition = response.headers.get('content-disposition')
  const result = /filename="(.+?)"/.exec(contentDisposition)
  return get(result, '[1]', `export.${extension}`)
}

async function downloadFile(path, extension) {
  const response = await apiClient.get(path, {
    headers: {
      Accept: MIME[extension],
    },
  })
  const blob = await response.blob()
  const filename = getFileNameFromResponse(response, extension)
  downloadjs(blob, filename, MIME[extension])
}

export function downloadPDF(path) {
  return downloadFile(path, 'pdf')
}

export function downloadExcel(path) {
  return downloadFile(path, 'xlsx')
}

export async function downloadZip(blob, filename) {
  downloadjs(blob, filename, MIME.zip)
}
