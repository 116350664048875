import { DatePicker as AntDDatePicker, Form } from 'antd'
import { useField } from 'formik'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

const { Item } = Form

export default function DatePicker({
  containerClassName,
  label,
  helperText,
  ...rest
}) {
  const [field, { error, touched }, { setTouched, setValue }] = useField(rest)
  delete field.onBlur // antd doesn't pass id/name so blur causes warnings
  const handleBlur = useCallback(() => setTouched(true), [setTouched])
  const hasError = Boolean(touched && error)
  if (typeof field.value == 'string') {
    field.value = moment(field.value)
  }
  return (
    <Item
      className={containerClassName}
      label={label}
      hasFeedback={hasError}
      validateStatus={hasError ? 'error' : ''}
      help={hasError && error}
    >
      <AntDDatePicker
        {...field} // eslint-disable-line react/jsx-props-no-spreading
        onChange={setValue}
        onBlur={handleBlur}
        format="L"
        {...rest} // eslint-disable-line react/jsx-props-no-spreading
      />
    </Item>
  )
}

DatePicker.propTypes = {
  containerClassName: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
}

DatePicker.defaultProps = {
  containerClassName: undefined,
  label: '',
  helperText: undefined,
}
