/* eslint-disable react/no-danger */
import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'antd'
import { createUseStyles } from 'react-jss'

const { Item: AntDItem } = Form

const useStyles = createUseStyles(theme => ({
  helperText: {
    lineHeight: '1.5',
    fontSize: '12px',
    marginBottom: '1em',
    textRendering: 'optimizeLegibility',
    color: theme.textGrayColor,
    letterSpacing: '.2px',
    marginTop: '-8px',
  },
  hideIcon: {
    '& .ant-form-item-children-icon': {
      display: 'none',
    },
  },
}))

export default function Item({ helperText, children, ...rest }) {
  const classes = useStyles()

  return (
    <AntDItem
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      className={classes.hideIcon}
    >
      {helperText && (
        <div
          className={classes.helperText}
          dangerouslySetInnerHTML={{ __html: helperText }}
        />
      )}
      {children}
    </AntDItem>
  )
}

Item.propTypes = {
  ...Item.propTypes,
  helperText: PropTypes.string,
}

Item.defaultProps = {
  helperText: undefined,
}
