export default [
  {
    name: 'CPT Codes 30',
    attribute: 'cptCodes30',
  },
  {
    name: 'CPT Codes 90 (order 1)',
    attribute: 'cptCodes90Order1',
  },
  {
    name: 'CPT Codes 90 (order 2)',
    attribute: 'cptCodes90Order2',
  },
  {
    name: 'ICD-10',
    attribute: 'icd10Code',
  },
  {
    name: 'ICD-10 (order 2)',
    attribute: 'icd10Code90Order2',
  },
]
