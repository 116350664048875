import queryString from 'query-string'
import apiClient from './api-client'

function list(practiceId, cycleId, page) {
  const options = { withHeaders: true }
  return apiClient.get(
    queryString.stringifyUrl({
      url: `cycles/${cycleId}/missed-transmissions`,
      query: { page },
    }),
    options,
  )
}

function create(preAuthorizationId, data) {
  return apiClient.post(
    `pre-authorizations/${preAuthorizationId}/missed-transmission`,
    data,
  )
}

function update(id, data) {
  return apiClient.patch(`missed-transmissions/${id}`, data)
}

function get(id) {
  return apiClient.get(`missed-transmissions/${id}`)
}

function destroy(id) {
  return apiClient.destroy(`missed-transmissions/${id}`)
}

export default {
  destroy,
  list,
  get,
  create,
  update,
}
