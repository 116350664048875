/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import mapValues from 'lodash/mapValues'
import { Modal, Form, Popconfirm, message, Button } from 'antd'
import noop from 'lodash/noop'
import { Formik } from 'formik'
import { insuranceAuthorizationShape } from '../prop-types'
import { formElementPropsGenerator } from '../../lib/forms'
import { Yup } from '../../lib/validations'
import { Input, UploadFormikFile } from '../Forms'
import api from '../../api'

const SUPPORTED_TYPE = 'application/pdf'

const LABELS = {
  insuranceAuthorizationNumber: 'Insurance authorization #',
  insuranceAuthorizationFilePath: 'Insurance authorization file',
}

const INITIAL_VALUES = mapValues(LABELS, () => undefined)

const VALIDATION_SCHEMA = Yup.object().shape({
  insuranceAuthorizationNumber: Yup.string()
    .required('Insurance authorization number is required')
    .nullable(),
})

const propsFor = formElementPropsGenerator({
  formName: 'insurance-auth-form',
  labels: LABELS,
  placeholders: { insuranceAuthorizationNumber: 'AR12345' },
})

export default function InsuranceAuthorizationModal({
  preAuthorizationId,
  onFinish,
  visible,
  insuranceAuth,
}) {
  const handleSave = useCallback(
    async ({
      insuranceAuthorizationNumber,
      insuranceAuthorizationFilePath,
    }) => {
      try {
        const fd = new FormData()
        fd.append(
          'insurance_authorization_number',
          insuranceAuthorizationNumber,
        )

        if (Array.isArray(insuranceAuthorizationFilePath)) {
          fd.append(
            'insurance_authorization_file',
            insuranceAuthorizationFilePath[0],
          )
        }

        await api.insuranceAuthorization.update(preAuthorizationId, fd)
        await onFinish(true)

        message.success('Insurance authorization was added successfully.')
      } catch (e) {
        message.error(
          `There was an error adding the insurance authorization: ${e.message}`,
        )
      }
    },
    [onFinish, preAuthorizationId],
  )
  const handleCancel = useCallback(() => onFinish(false), [onFinish])
  const handleDeleteInsurance = useCallback(async () => {
    try {
      await api.insuranceAuthorization.destroy(preAuthorizationId)
      await onFinish(true)

      message.success('Insurance authorization successfully removed')
    } catch (e) {
      message.error(
        `There was an error removing this Insurance authorization: ${e.message}`,
      )
    }
  }, [onFinish, preAuthorizationId])

  return (
    <Formik
      initialValues={insuranceAuth || INITIAL_VALUES}
      onSubmit={handleSave}
      validationSchema={VALIDATION_SCHEMA}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Modal
          title="Insurance Authorization"
          visible={visible}
          onOk={handleSubmit}
          onCancel={handleCancel}
          okText="Save"
          confirmLoading={isSubmitting}
          centered
          destroyOnClose
        >
          <Form onSubmit={handleSubmit}>
            <Input {...propsFor('insuranceAuthorizationNumber')} />
            <UploadFormikFile
              name="insuranceAuthorizationFilePath"
              label={LABELS.insuranceAuthorizationFilePath}
              supportFiles={[SUPPORTED_TYPE]}
            />
            {insuranceAuth && (
              <Popconfirm
                title="Are you sure？"
                okText="Yes"
                cancelText="No"
                onConfirm={handleDeleteInsurance}
              >
                <Button size="small" type="link" icon="delete">
                  Delete Authorization
                </Button>
              </Popconfirm>
            )}
          </Form>
        </Modal>
      )}
    </Formik>
  )
}

InsuranceAuthorizationModal.propTypes = {
  preAuthorizationId: PropTypes.string.isRequired,
  insuranceAuth: insuranceAuthorizationShape,
  onFinish: PropTypes.func,
  visible: PropTypes.bool,
}

InsuranceAuthorizationModal.defaultProps = {
  onFinish: noop,
  insuranceAuth: null,
  visible: false,
}
