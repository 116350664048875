import React, { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { message } from 'antd'
import mapValues from 'lodash/mapValues'
import api from '../../api'
import { useAsync } from '../../hooks'
import { PageHeader } from '../Common'
import Loading from '../Loading'
import MissedTransmissionsForm from './Form'

export default function EditMissedTransmission() {
  const history = useHistory()
  const { id } = useParams()
  const getMissedTransmission = useCallback(
    () => api.missedTransmissions.get(id),
    [id],
  )
  const { pending, value } = useAsync(getMissedTransmission)
  const onSubmit = useCallback(
    async ({ comment }, { setErrors }) => {
      try {
        await api.missedTransmissions.update(id, { comment })
        message.success('Missed Transmission successfully updated')
        history.goBack()
      } catch (e) {
        if (e.response && e.response.errors) {
          setErrors(mapValues(e.response.errors, fieldErrors => fieldErrors[0]))
        } else {
          message.error('Oops, we had a problem. Please try submitting again')
        }
      }
    },
    [history, id],
  )

  if (pending) return <Loading large />

  return (
    <div>
      <PageHeader title="Edit Missed Transmission" onBack={history.goBack} />
      <MissedTransmissionsForm onSubmit={onSubmit} missedTransmission={value} />
    </div>
  )
}
