import { Button, Icon, Table, Typography, Tooltip } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useMemo, useEffect, useCallback, forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import { formatDOB } from '../../lib/datetime'
import { PAGE_SIZE_OPTIONS, PAGE_SIZE } from '../../lib/constants'
import {
  useIsAcknowledged,
  useReports,
  useReportsPagination,
} from '../../redux/slices/reports'
import {
  useCanSign,
  useFeatureEnabled,
  useIsAdmin,
} from '../../redux/slices/session'
import { toPath } from '../../routes'
import DiseasesTags from '../Common/DiseasesTags'
import useQueryParams from '../../hooks/useQueryParams'
import useLocalStorage from '../../hooks/useLocalStorage'
import Acknowledge from './Acknowledge'

const { Text } = Typography

const useStyles = createUseStyles({
  present: {
    color: 'green',
  },
  notPresent: {
    color: 'orange',
  },
  criticalIcon: {
    color: 'red',
    paddingTop: '0.3em',
  },
})

const BASE_COLUMNS = [
  {
    title: 'DOB',
    dataIndex: 'patientSummary.birthDate',
    key: 'dob',
    render: formatDOB,
  },
  {
    title: 'Diseases',
    key: 'diseases',
    render(text, report) {
      const { patientSummary } = report
      return <DiseasesTags patientSummary={patientSummary} />
    },
  },
  {
    title: 'Device Type',
    dataIndex: 'device.deviceModel.deviceType.name',
    key: 'deviceType',
  },
  {
    title: 'CPT Code',
    dataIndex: 'cptCode',
    key: 'cptCode',
  },
  {
    title: 'ICD-10 Code',
    dataIndex: 'icd10Code',
    key: 'icd10Code',
  },
  {
    title: 'Report Date',
    key: 'date',
    render(text, report) {
      return (
        <span>
          <Text>{moment(report.reportDate).format('ll')}</Text>
        </span>
      )
    },
  },
]

const LOCALE = {
  emptyText: 'This cycle doesn’t have the reports ready yet',
}

const Reports = forwardRef((props, ref) => {
  const {
    cycleId,
    practiceId,
    onPageChange,
    page,
    filters,
    selected,
    onSelect,
    fetchReports,
    pendingFetch,
  } = props
  const classes = useStyles()
  const reports = useReports(cycleId)
  const { total } = useReportsPagination(cycleId)
  const isAdmin = useIsAdmin()
  const isAcknowledged = useIsAcknowledged(cycleId)
  const [, , getPathWithQuery] = useQueryParams({})
  const canSign = useCanSign()
  const batchEnabled = useFeatureEnabled('report_batch_signatures')
  const rowSelection =
    canSign && batchEnabled
      ? {
          selectedRowKeys: selected,
          onChange: onSelect,
          getCheckboxProps: record => ({
            disabled: !!record.signedAt,
          }),
        }
      : null
  const [pageSize, setPageSize] = useLocalStorage('pageSize', PAGE_SIZE)

  const reportPath = useCallback(
    reportId => {
      return getPathWithQuery(toPath.reports.report({ id: reportId }), {
        cycleId,
        page,
        ...filters,
      })
    },
    [cycleId, filters, getPathWithQuery, page],
  )

  useEffect(() => {
    fetchReports(practiceId, cycleId, page, filters, pageSize)
  }, [fetchReports, practiceId, cycleId, page, filters, pageSize])

  const columns = useMemo(
    () => [
      {
        title: '',
        dataIndex: 'critical',
        key: 'critical',
        align: 'center',
        render(_text, { critical }) {
          return (
            critical && (
              <Tooltip title="Requires Attention">
                <Icon
                  type="exclamation-circle"
                  className={classes.criticalIcon}
                />
              </Tooltip>
            )
          )
        },
      },
      {
        title: 'Full Name',
        dataIndex: 'patientSummary.lastName',
        key: 'lastName',
        render(_text, report) {
          return (
            <Link
              to={toPath.patients.patient({ id: report.patientSummary.id })}
            >
              {report.patientSummary.lastName},{' '}
              {report.patientSummary.firstName}
            </Link>
          )
        },
      },
      ...BASE_COLUMNS,
      {
        title: 'Signed',
        dataIndex: 'signed',
        key: 'signedAt',
        align: 'center',
        render(_text, { signedAt }) {
          return (
            <Icon
              type={`${signedAt ? 'check' : 'close'}-circle`}
              className={classes[signedAt ? 'present' : 'notPresent']}
            />
          )
        },
      },
      {
        title: 'PDF',
        dataIndex: 'hasFile',
        key: 'hasFile',
        align: 'center',
        render(_text, { hasFile }) {
          return (
            <Icon
              type={`${hasFile ? 'check' : 'close'}-circle`}
              className={classes[hasFile ? 'present' : 'notPresent']}
            />
          )
        },
      },
      {
        title: 'Full',
        dataIndex: 'hasRawFile',
        key: 'hasRawFile',
        align: 'center',
        render(_text, { hasRawFile }) {
          return (
            <Icon
              type={`${hasRawFile ? 'check' : 'close'}-circle`}
              className={classes[hasRawFile ? 'present' : 'notPresent']}
            />
          )
        },
      },
      {
        title: 'Actions',
        key: 'actions',
        render(_text, report) {
          return (
            <span>
              <Link to={reportPath(report.id)}>
                <Button type="link" icon="file-search" />
              </Link>
            </span>
          )
        },
      },
    ],
    [classes, reportPath],
  )

  const onShowSizeChange = useCallback(
    (current, size) => {
      setPageSize(size)
      fetchReports(practiceId, cycleId, current, filters, size)
    },
    [setPageSize, fetchReports, practiceId, cycleId, filters],
  )

  if (!pendingFetch && !isAdmin && !isAcknowledged && reports.length) {
    return <Acknowledge practiceId={practiceId} cycleId={cycleId} />
  }

  return (
    <Table
      columns={columns}
      dataSource={reports}
      rowKey="id"
      loading={pendingFetch}
      locale={LOCALE}
      size="middle"
      rowSelection={rowSelection}
      pagination={{
        current: page,
        pageSize,
        onChange: onPageChange,
        total,
        showSizeChanger: true,
        onShowSizeChange,
        pageSizeOptions: PAGE_SIZE_OPTIONS,
      }}
    />
  )
})

Reports.propTypes = {
  cycleId: PropTypes.string.isRequired,
  practiceId: PropTypes.string.isRequired,
  onPageChange: PropTypes.func.isRequired,
  fetchReports: PropTypes.func.isRequired,
  pendingFetch: PropTypes.bool,
  page: PropTypes.number,
  filters: PropTypes.shape({}),
  onSelect: PropTypes.func,
  selected: PropTypes.arrayOf(PropTypes.string),
}

Reports.defaultProps = {
  page: 1,
  filters: {},
  onSelect: () => {},
  selected: [],
  pendingFetch: false,
}

export default Reports
