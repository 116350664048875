import { Button, Table } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { useAsync } from '../../hooks'
import {
  useFetchMissedTransmissions,
  useMissedTransmissions,
  useMissedTransmissionsPagination,
} from '../../redux/slices/missed-transmissions'
import { toPath } from '../../routes'
import { ListError } from '../Common'
import { formatDOB } from '../../lib/datetime'

const COLUMNS = [
  {
    title: 'Last Name',
    dataIndex: 'patient.lastName',
    key: 'lastName',
  },
  {
    title: 'First Name',
    dataIndex: 'patient.firstName',
    key: 'firstName',
  },
  {
    title: 'DOB',
    key: 'dob',
    dataIndex: 'patient.birthDate',
    render: formatDOB,
  },
  {
    title: 'Device Type',
    dataIndex: 'device.deviceModel.deviceType.name',
    key: 'deviceType',
  },
  {
    title: 'Reason',
    dataIndex: 'comment',
    key: 'reason',
  },
  {
    title: 'Actions',
    key: 'actions',
    render(text, missedTransmission) {
      return (
        <Link
          to={toPath.missedTransmissions.missedTransmission({
            id: missedTransmission.id,
          })}
        >
          <Button type="link" icon="file-search">
            Comments / More
          </Button>
        </Link>
      )
    },
  },
]

export default function MissedTransmissions({
  cycleId,
  practiceId,
  onPageChange,
  page,
}) {
  const reports = useMissedTransmissions(cycleId)
  const { pageSize, total } = useMissedTransmissionsPagination(cycleId)
  const { pending, error, clearError } = useAsync(
    useFetchMissedTransmissions(practiceId, cycleId, page),
  )
  return (
    <>
      {error && <ListError description={error.message} onClose={clearError} />}
      <Table
        size="middle"
        columns={COLUMNS}
        dataSource={reports}
        rowKey="id"
        loading={pending}
        pagination={{
          current: page,
          pageSize,
          onChange: onPageChange,
          total,
        }}
      />
    </>
  )
}

MissedTransmissions.propTypes = {
  cycleId: PropTypes.string.isRequired,
  practiceId: PropTypes.string.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number,
}

MissedTransmissions.defaultProps = {
  page: 1,
}
