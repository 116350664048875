import React from 'react'
import PropTypes from 'prop-types'
import { buildUrl } from '../../api/api-client'

export default function DownloadLink({ children, path }) {
  return (
    <a href={buildUrl(path)} target="_blank" rel="noopener noreferrer">
      {children || path}
    </a>
  )
}

DownloadLink.propTypes = {
  children: PropTypes.node,
  path: PropTypes.string.isRequired,
}

DownloadLink.defaultProps = {
  children: null,
}
