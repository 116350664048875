import { Button, Modal, message } from 'antd'
import React, { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { useAsync } from '../../hooks'
import { useIsAdmin } from '../../redux/slices/session'
import { toPath } from '../../routes'
import { signReports } from '../../api/reports'
import { ListError, PageHeader } from '../Common'
import CyclesFilter from '../Common/CyclesFilter'
import usePracticeCycles from '../Common/usePracticeCycles'
import useFilters from '../Cycles/useFilters'
import { useIsAcknowledged, useFetchReports } from '../../redux/slices/reports'
import Filters, { FIELDS_OPTIONS } from '../Forms/Filters'
import Reports from './Reports'
import BatchDownloadButton from './BatchDownloadButton'

const DEFAULT_FILTERS = {
  signed: FIELDS_OPTIONS.signed[0].id,
  query: '',
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    '& > form:last-child': {
      flex: '1',
      minWidth: '135px',
    },
  },
})

export default function PracticeReports() {
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const isAdmin = useIsAdmin()
  const classes = useStyles()
  const [
    { error, clearError },
    { practice, pending, practiceId },
  ] = usePracticeCycles()
  const {
    conditions,
    setConditions,
    handleFilterChange,
    handlePageChange,
  } = useFilters({ ...DEFAULT_FILTERS, practiceId })
  const isAcknowledged = useIsAcknowledged(conditions.cycleId)
  const reportFilters = useMemo(() => {
    return {
      q: conditions.query,
      signed: conditions.signed,
      medicalProviderId: conditions.medicalProviderId,
      deviceTypeId: conditions.deviceTypeId,
    }
  }, [conditions])
  const { cycleId, page, perPage } = conditions

  const { pending: reportPending, execute: fetchReports } = useAsync(
    useFetchReports(practiceId, cycleId, page, reportFilters, perPage),
    false,
  )

  const sign = async () => {
    try {
      const res = await signReports(practiceId, selectedRowKeys)
      setIsModalVisible(false)
      const { length } = res
      message.success(`${length} report${length !== 1 ? 's' : ''} signed.`)
      setSelectedRowKeys([])
      fetchReports(practiceId, cycleId, page, reportFilters)
    } catch (e) {
      message.error('Failed to sign reports, please try again.')
    }
  }

  if (cycleId && !pending) {
    return (
      <div>
        <PageHeader
          title="Remote Reports"
          subTitle={isAdmin && practice.name}
          extra={[
            selectedRowKeys.length > 0 && (
              <Button key="sign" onClick={() => setIsModalVisible(true)}>
                {`Sign ${selectedRowKeys.length} report${
                  selectedRowKeys.length !== 1 ? 's' : ''
                }`}
              </Button>
            ),
            isAdmin && (
              <Link
                key="upload"
                to={toPath.cycles.cycle.reports.upload({ id: cycleId })}
              >
                <Button>Upload reports</Button>
              </Link>
            ),
            (isAcknowledged || isAdmin) && (
              <BatchDownloadButton key="download" cycleId={cycleId} />
            ),
          ]}
        />
        <div className={classes.container}>
          <CyclesFilter
            practiceId={practiceId}
            onSubmit={handleFilterChange}
            currentCycleId={cycleId}
          />
          <Filters
            defaultValues={DEFAULT_FILTERS}
            currentValues={conditions}
            placeholders={{ query: 'Search by name or device serial number' }}
            onSubmit={({ query, signed, medicalProviderId, deviceTypeId }) =>
              setConditions({
                cycleId,
                query,
                signed,
                medicalProviderId,
                deviceTypeId,
                page: 1,
              })
            }
            showDeviceType
            showMedicalProvider
            showSigningStatus
            showSearch
          />
        </div>
        <Modal
          title="Sign reports"
          visible={isModalVisible}
          onOk={sign}
          onCancel={() => setIsModalVisible(false)}
          okText="Sign"
          cancelText="Cancel"
        >
          <p>{`You are about to sign ${
            selectedRowKeys.length !== 1
              ? `a batch of ${selectedRowKeys.length} reports`
              : '1 report'
          }.`}</p>
        </Modal>
        <Reports
          practiceId={practiceId}
          cycleId={cycleId}
          page={page}
          filters={reportFilters}
          onPageChange={handlePageChange}
          selected={selectedRowKeys}
          onSelect={setSelectedRowKeys}
          fetchReports={fetchReports}
          pendingFetch={reportPending}
        />
      </div>
    )
  }

  return (
    <div>
      {error && <ListError description={error.message} onClose={clearError} />}
    </div>
  )
}
