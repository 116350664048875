import { denormalize, normalize } from 'normalizr'
import { useSelector } from 'react-redux'
import get from 'lodash/get'
import api from '../../api'
import { inOfficebillingOrdersSchema } from '../normalizr'
import { getPaginationHeaders, useAsyncAction } from '../utils'
import createSlice from '../create-slice'
import { getEntities } from './entities'

const billingOrdersInitialState = {
  officeBillingOrders: {},
}

const inOfficeBillingOrdersSlice = createSlice({
  name: 'inOfficebillingOrders',
  initialState: billingOrdersInitialState,
  reducers: {
    setBillingOrders: (
      state,
      { payload: { checkDate, result, pageSize, total } },
    ) => {
      state.officeBillingOrders[checkDate] =
        state.officeBillingOrders[checkDate] || {}
      state.officeBillingOrders[checkDate].ids = result
      state.officeBillingOrders[checkDate].pageSize = pageSize
      state.officeBillingOrders[checkDate].total = total
    },
  },
})

const { actions } = inOfficeBillingOrdersSlice

const selectState = state => state[inOfficeBillingOrdersSlice.name]

export function useBillingOrders(checkDate) {
  const { officeBillingOrders } = useSelector(selectState)
  const entities = useSelector(getEntities)
  return denormalize(
    get(officeBillingOrders, [checkDate, 'ids'], []),
    [inOfficebillingOrdersSchema],
    entities,
  )
}

export function useBillingOrdersPagination(checkDate) {
  const { officeBillingOrders } = useSelector(selectState)
  const { pageSize, total } = get(officeBillingOrders, [checkDate], {})
  return { pageSize, total }
}

function fetchBillingOrders(practiceId, checkDate, page) {
  return async dispatch => {
    const { body, headers } = await api.inOfficeBillingOrders.list(
      practiceId,
      checkDate,
      page,
    )
    dispatch(
      actions.setBillingOrders({
        checkDate,
        ...normalize(body, [inOfficebillingOrdersSchema]),
        ...getPaginationHeaders(headers),
      }),
    )
  }
}

export function useFetchBillingOrders(practiceId, checkDate, page) {
  return useAsyncAction(fetchBillingOrders, practiceId, checkDate, page)
}

export function markBillingOrderAsBilled(id, billed) {
  const type = 'MARK_AS_BILLED' // Just to update entities slice
  return async dispatch => {
    const body = await api.billingOrders.markAsBilled(id, { billed })
    return dispatch({
      type,
      payload: normalize(body, inOfficebillingOrdersSchema),
    })
  }
}

export default inOfficeBillingOrdersSlice
