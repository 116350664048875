import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'antd'
import { useField } from 'formik'
import { has } from 'lodash'

import Item from './Item'

const { Group } = Checkbox

export default function MultipleCheckboxes({
  id,
  label,
  options,
  helperText,
  columns,
  ...rest
}) {
  const [field, { error, touched }, { setValue }] = useField(rest)
  const { wrapperCol, labelCol, labelAlign } = rest
  const hasError = Boolean(touched && error)
  const defaultValues = field.value || []

  return (
    <Item
      label={label}
      hasFeedback={hasError}
      validateStatus={hasError ? 'error' : ''}
      help={hasError && error}
      helperText={helperText}
      wrapperCol={wrapperCol}
      labelCol={labelCol}
      labelAlign={labelAlign}
    >
      <Group
        onChange={setValue}
        defaultValue={defaultValues}
        style={{ width: '100%' }}
      >
        <div className="multiple-checkboxes-row">
          {options.map(option => (
            <Checkbox value={has(option, 'value') ? option.value : option}>
              {has(option, 'label') ? option.label : option}
            </Checkbox>
          ))}
        </div>
      </Group>
      <style>{`
        .multiple-checkboxes-row {
          display: grid;
          grid-template-columns: repeat(${columns}, 1fr);
        }
        .multiple-checkboxes-row .ant-checkbox-wrapper {
          margin-left: 0;
        }
      `}</style>
    </Item>
  )
}

MultipleCheckboxes.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  helperText: PropTypes.string,
  columns: PropTypes.number,
}

MultipleCheckboxes.defaultProps = {
  label: undefined,
  id: undefined,
  type: undefined,
  options: [],
  helperText: undefined,
  columns: 3,
}
