import { denormalize, normalize } from 'normalizr'
import { useSelector } from 'react-redux'
import api from '../../api'
import { reportsSchema } from '../normalizr'
import { getPaginationHeaders, useAsyncAction } from '../utils'
import createSlice from '../create-slice'
import { getEntities } from './entities'

const pendingReportsInitialState = {
  reportIds: [],
  pageSize: 0,
  total: 0,
}

const pendingReportsSlice = createSlice({
  name: 'pending-reports',
  initialState: pendingReportsInitialState,
  reducers: {
    setReports: (state, { payload: { result, pageSize, total } }) => {
      state.reportIds = result
      state.pageSize = pageSize
      state.total = total
    },
  },
})

const { actions } = pendingReportsSlice

const selectState = state => state[pendingReportsSlice.name]

export function usePendingReports() {
  const { reportIds } = useSelector(selectState)
  const entities = useSelector(getEntities)
  const pendingReports = denormalize(reportIds, [reportsSchema], entities)
  return pendingReports
}

export function usePendingReportsPagination() {
  const { pageSize, total } = useSelector(selectState)
  return { pageSize, total }
}

export function fetchPendingReports(page, practiceId, q) {
  return async dispatch => {
    const { body, headers } = await api.reports.getPendings({
      page,
      practiceId,
      q,
    })
    dispatch(
      actions.setReports({
        ...normalize(body, [reportsSchema]),
        ...getPaginationHeaders(headers),
      }),
    )
  }
}

export function useFetchPendingReports({ page, practiceId, query } = {}) {
  return useAsyncAction(fetchPendingReports, page, practiceId, query)
}

export default pendingReportsSlice
