import { denormalize, normalize } from 'normalizr'
import { useSelector } from 'react-redux'
import api from '../../api'
import { reportsSchema } from '../normalizr'
import { getPaginationHeaders, useAsyncAction } from '../utils'
import createSlice from '../create-slice'
import { getEntities } from './entities'

const reviewedReportsInitialState = {
  reports: [],
  pageSize: 0,
  total: 0,
}

const reviewedReportsSlice = createSlice({
  name: 'reviewedReports',
  initialState: reviewedReportsInitialState,
  reducers: {
    setReports: (state, { payload: { result, page, pageSize, total } }) => {
      state.reports = result
      state.currentPage = page
      state.pageSize = pageSize
      state.total = total
    },
  },
})

const { actions } = reviewedReportsSlice

const selectState = state => {
  return state[reviewedReportsSlice.name]
}

export function useReviewedReports() {
  const result = useSelector(selectState)
  const { reports } = result
  const entities = useSelector(getEntities)
  return denormalize(reports, [reportsSchema], entities)
}

export function useReviewedReportsPagination() {
  const { currentPage, pageSize, total } = useSelector(selectState)
  return { currentPage, pageSize, total }
}

export function fetchReviewedReports(
  page,
  assigneeId,
  deviceTypeId,
  fromDate,
  toDate,
) {
  return async dispatch => {
    const { body, headers } = await api.reports.getReviewed({
      page,
      assigneeId,
      deviceTypeId,
      fromDate,
      toDate,
    })
    dispatch(
      actions.setReports({
        ...normalize(body, [reportsSchema]),
        ...getPaginationHeaders(headers),
      }),
    )
  }
}

export function useFetchReviewedReports({
  page,
  assigneeId,
  deviceTypeId,
  fromDate,
  toDate,
} = {}) {
  return useAsyncAction(
    fetchReviewedReports,
    page,
    assigneeId,
    deviceTypeId,
    fromDate,
    toDate,
  )
}

export default reviewedReportsSlice
