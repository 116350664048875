import { Form } from 'antd'
import { Formik } from 'formik'
import mapValues from 'lodash/mapValues'
import PropTypes from 'prop-types'
import React from 'react'
import { formElementPropsGenerator } from '../../lib/forms'
import { Yup } from '../../lib/validations'
import { Input, SubmitButton } from '../Forms'
import PreAuthorizationSelect from '../Forms/PreAuthorizationSelect'

const MISSED_TRANSMISSION_LABELS = {
  comment: 'Comment',
  preAuthorizationId: 'Patient',
}

const INITIAL_VALUES = mapValues(MISSED_TRANSMISSION_LABELS, () => undefined)

const propsFor = formElementPropsGenerator({
  formName: 'missed-transmission-edit-form',
  labels: MISSED_TRANSMISSION_LABELS,
})

const labelCol = {
  sm: { span: 4, offset: 2 },
}

const wrapperCol = {
  sm: { span: 16 },
}

const actionsWrapperCol = {
  sm: { span: 4, offset: labelCol.sm.span + labelCol.sm.offset },
}

function buildValidationSchema(isNew = false) {
  const shape = {
    comment: Yup.string().required(),
  }

  if (isNew) {
    shape.preAuthorizationId = Yup.string().required()
  }

  return Yup.object().shape(shape)
}

const validationSchemaForEdition = buildValidationSchema()
const validationSchemaForCreation = buildValidationSchema(true)

export default function MissedTransmissionsForm({
  cycleId,
  missedTransmission,
  onSubmit,
}) {
  const isNew = !missedTransmission
  return (
    <div>
      <Formik
        initialValues={missedTransmission || INITIAL_VALUES}
        validationSchema={
          isNew ? validationSchemaForCreation : validationSchemaForEdition
        }
        onSubmit={onSubmit}
      >
        {({ handleSubmit, isSubmitting, isValid, submitCount }) => (
          <Form
            onSubmit={handleSubmit}
            labelCol={labelCol}
            wrapperCol={wrapperCol}
          >
            {isNew && (
              <PreAuthorizationSelect
                cycleId={cycleId}
                {...propsFor('preAuthorizationId')}
                clearWhenUnmount
                autoFocus
              />
            )}
            <Input {...propsFor('comment')} autoFocus={!isNew} />
            <SubmitButton
              isSubmitting={isSubmitting}
              isValid={isValid}
              title={
                isNew
                  ? 'Create Missed Transmission'
                  : 'Save Missed Transmission'
              }
              submitCount={submitCount}
              wrapperCol={actionsWrapperCol}
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}

MissedTransmissionsForm.propTypes = {
  cycleId: PropTypes.string,
  missedTransmission: PropTypes.shape({
    comment: PropTypes.string,
    id: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
}

MissedTransmissionsForm.defaultProps = {
  cycleId: undefined,
  missedTransmission: undefined,
}
