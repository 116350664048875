/* eslint no-underscore-dangle: ["error", { "allow": ["_token", "_loadedFromStorage", "_cacheToken", "_currentUser", "_cacheCurrentUser", "_key"]}] */
import keyMirror from 'keymirror'
import humps from 'humps'
import getProp from 'lodash/get'
import { newMacaroon } from 'macaroon'
import moment from 'moment'
import config from '../config'
import tokenStorage from '../lib/token-storage'
import { isUUID } from '../lib/uuid'
import apiError from './api-error'

const defaultJsonHeaders = {
  Accept: 'application/json',
}

export const methods = keyMirror({
  GET: undefined,
  POST: undefined,
  PATCH: undefined,
  PUT: undefined,
  DELETE: undefined,
})

export function buildUrl(path) {
  return `${config.apiUrl}${path.startsWith('/') ? '' : '/'}${path}`
}

async function jsonFetch(url, options = {}) {
  const finalOptions = options
  const hasJSONBody =
    finalOptions.body && !(finalOptions.body instanceof FormData)
  finalOptions.headers = {
    ...defaultJsonHeaders,
    ...(hasJSONBody && { 'Content-Type': 'application/json' }),
    ...(finalOptions.headers || {}),
  }
  if (hasJSONBody) {
    const decamelizedBody = humps.decamelizeKeys(finalOptions.body)
    const stringBody = JSON.stringify(decamelizedBody)
    finalOptions.body = stringBody
  }
  const response = await fetch(url, finalOptions)
  if (response.status === 204) return undefined
  const contentType = response.headers.get('content-type')
  const body =
    contentType && contentType.includes('application/json')
      ? humps.camelizeKeys(await response.json(), (key, convert) =>
          isUUID(key) ? key : convert(key),
        )
      : response.text()

  if (response.status === 401) {
    throw apiError.build(response, body)
  }

  if (!response.ok) throw apiError.build(response, body)

  return { body, headers: response.headers }
}

const apiClient = {
  _loadedFromStorage: false,
  _token: undefined,
  _currentUser: undefined,
  _signKey: undefined,

  _cacheToken: function _cacheToken(token) {
    this._loadedFromStorage = true
    this._token = token
  },

  _cacheCurrentUser: function _cacheCurrentUser(user) {
    this._currentUser = user
  },

  getToken: function getToken() {
    return tokenStorage.geToken()
  },

  getCurrentUser: function getCurrentUser() {
    if (!this._currentUser) {
      const user = tokenStorage.getCurrentUser()
      this._cacheCurrentUser(user || undefined)
    }
    return this._currentUser
  },

  setToken: function setToken(token) {
    this.clearToken(true)
    tokenStorage.setToken(token)
    this._cacheToken(token)
  },

  setCurrentUser: function setCurrentUser(user) {
    this.clearCurrentUser(true)
    tokenStorage.setCurrentUser(user)
    this._cacheCurrentUser(user)
  },

  clearToken: function clearToken(preventEvent = false) {
    this._cacheToken(undefined)
    tokenStorage.removeToken(preventEvent)
  },

  clearCurrentUser: function clearCurrentUser(preventEvent = false) {
    this._cacheCurrentUser(undefined)
    tokenStorage.removeCurrentUser(preventEvent)
  },

  fetch: function fetch(path, options = {}) {
    return this.fetchUrl(buildUrl(path), options)
  },

  fetchUrl: function fetchUrl(url, options = {}) {
    const finalOptions = options
    return jsonFetch(url, finalOptions)
  },

  authFetch: function authFetch(path, options = {}) {
    return this.authFetchUrl(buildUrl(path), options)
  },

  authFetchUrl: async function authFetchUrl(url, options = {}) {
    const { withHeaders, ...fetchOptions } = options
    const token = this.getAuthzToken()

    const finalOptions = {
      credentials: 'include',
      mode: 'cors',
      ...fetchOptions,
    }
    finalOptions.headers = {
      Authorization: `Bearer ${token}`,
      ...fetchOptions.headers,
    }
    if (
      getProp(finalOptions, 'headers.Accept', defaultJsonHeaders.Accept) !==
      defaultJsonHeaders.Accept
    ) {
      return fetch(url, finalOptions)
    }
    const response = await jsonFetch(url, finalOptions)
    return withHeaders ? response : response && response.body
  },

  getAuthzToken: function getAuthzToken() {
    const token = this.getToken()
    if (!token) return undefined

    const padding = parseInt(token[0], 10)
    const now = moment.now() / 1000 // Use seconds by API design

    this._key = token.substring(padding + 1, token.length - padding)

    const mac = newMacaroon({
      identifier: this._currentUser?.id || 'anonymous',
      location: 'cardiac.gobiobridge.com',
      rootKey: this._key,
      version: 1,
    })

    mac.addFirstPartyCaveat(`timestamp=${now}`)

    return btoa(JSON.stringify(mac.exportJSON()))
  },

  get: function get(path, options = {}) {
    return this.authFetch(path, { ...options, method: methods.GET })
  },

  post: function post(path, body = {}, options = {}) {
    return this.authFetch(path, {
      ...options,
      body,
      method: methods.POST,
    })
  },

  patch: function patch(path, body = {}, options = {}) {
    return this.authFetch(path, {
      ...options,
      body,
      method: methods.PATCH,
    })
  },

  put: function put(path, body = {}, options = {}) {
    return this.authFetch(path, {
      ...options,
      body,
      method: methods.PUT,
    })
  },

  destroy: function destroy(path, options = {}) {
    return this.authFetch(path, { ...options, method: methods.DELETE })
  },
}

export default apiClient
