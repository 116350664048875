import React from 'react'
import PropTypes from 'prop-types'
import { Icon, Menu, Popconfirm } from 'antd'
import noop from 'lodash/noop'

const overlayStyle = {
  zIndex: 1061,
}

function ListItemMenuOptions({
  id,
  onEdit,
  onDelete,
  onCancel,
  deleteConfirmation,
}) {
  return (
    <Menu>
      <Menu.Item key={`${id}_edit`} onClick={onEdit}>
        <Icon type="edit" />
        Edit
      </Menu.Item>
      <Menu.Item key={`${id}_delete`}>
        <Popconfirm
          title={deleteConfirmation}
          onConfirm={onDelete}
          onCancel={onCancel}
          okText="Yes"
          cancelText="No"
          placement="topRight"
          overlayStyle={overlayStyle}
        >
          <Icon type="delete" />
          Remove
        </Popconfirm>
      </Menu.Item>
    </Menu>
  )
}

ListItemMenuOptions.propTypes = {
  id: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  deleteConfirmation: PropTypes.string.isRequired,
}

ListItemMenuOptions.defaultProps = {
  onCancel: noop,
}

export default ListItemMenuOptions
