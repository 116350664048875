import React from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import { DatePicker, Input } from '../Forms'
import Select from '../Forms/Select'
import TextArea from '../Forms/TextArea'
import MultipleCheckboxes from '../Forms/MultipleCheckboxes'
import RadioButton from '../Forms/RadioButton'
import Checkbox from '../Forms/Checkbox'
import { OTHER_OPTION_VALUE, OTHERS_SET_KEY, getOptions } from './form-utils'

const TEXT_AREA_SIZE = { minRows: 2, maxRows: 8 }

function formatSelectOptions(options) {
  return (options || []).map(name => ({
    id: name,
    name,
  }))
}

export default function FormItem({ field, propsFor, formType }) {
  const { values } = useFormikContext()
  const { dataType, itemName, itemId, options, description } = field

  if (dataType === 'date') {
    return (
      <DatePicker
        {...propsFor(itemId)}
        helperText={description}
        allowClear={false}
      />
    )
  }

  if (dataType === 'long_text') {
    return (
      <TextArea
        {...propsFor(itemId)}
        helperText={description}
        autoSize={TEXT_AREA_SIZE}
      />
    )
  }

  if (dataType === 'choice') {
    const hasOtherOption = values[itemId] === OTHER_OPTION_VALUE

    return (
      <>
        <Select
          {...propsFor(itemId)}
          options={formatSelectOptions(getOptions(options, formType))}
          helperText={description}
        />
        {hasOtherOption && (
          <Input
            name={`${OTHERS_SET_KEY}.${itemId}`}
            {...propsFor(`${OTHERS_SET_KEY}.${itemId}`)}
          />
        )}
      </>
    )
  }

  if (dataType === 'multiple_choice') {
    return (
      <MultipleCheckboxes
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...propsFor(itemId)}
        options={getOptions(options, formType)}
        helperText={description}
      />
    )
  }

  if (dataType === 'single_choice') {
    return (
      <RadioButton
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...propsFor(itemId)}
        options={getOptions(options, formType)}
        helperText={description}
      />
    )
  }

  if (dataType === 'yes_or_no') {
    return (
      <Checkbox
        {...propsFor(itemId)}
        options={getOptions(options, formType)}
        helperText={description}
      />
    )
  }

  return (
    <>
      <Input
        name={itemId}
        placeholder={itemName}
        helperText={description}
        {...propsFor(itemId)}
      />
    </>
  )
}

FormItem.propTypes = {
  field: PropTypes.shape({
    dataType: PropTypes.string.isRequired,
    itemName: PropTypes.string.isRequired,
    itemId: PropTypes.string.isRequired,
    options: PropTypes.shape(),
    description: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  formType: PropTypes.string.isRequired,
  propsFor: PropTypes.func.isRequired,
}
