import defaults from 'lodash/defaults'
import isEqual from 'lodash/isEqual'
import fromPairs from 'lodash/fromPairs'
import { useCallback, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

export default function useQueryParams({ initialValues = {}, schema = {} }) {
  const history = useHistory()
  const location = useLocation()
  const [previousQuery, setPreviousQuery] = useState()
  const query = useMemo(() => {
    const searchParams = new URLSearchParams(location.search)
    const searchParamsPO = defaults(
      fromPairs(Array.from(searchParams.entries())),
      initialValues,
    )
    Object.keys(schema).forEach(key => {
      if (searchParamsPO[key] !== undefined) {
        switch (schema[key]) {
          case Boolean:
            searchParamsPO[key] = searchParamsPO[key] === 'true'
            break
          case Number:
            searchParamsPO[key] = Number(searchParamsPO[key])
            break
          default:
            break
        }
      }
    })
    if (isEqual(searchParamsPO, previousQuery)) return previousQuery
    setPreviousQuery(searchParamsPO)
    return searchParamsPO
  }, [initialValues, location.search, previousQuery, schema])
  const setQuery = useCallback(
    values => {
      const newQuery = new URLSearchParams()
      Object.entries(values).forEach(([key, value]) => {
        if (value !== undefined) {
          newQuery.set(key, value)
        }
      })
      history.replace(`${location.pathname}?${newQuery.toString()}`)
    },
    [history, location.pathname],
  )
  const getPathWithQuery = useCallback((path, values) => {
    const newQuery = new URLSearchParams()
    const url = new URL(path, window.location.origin)
    Object.entries(values).forEach(([key, value]) => {
      if (value !== undefined) {
        newQuery.set(key, value)
      }
    })
    return `${url.pathname}?${newQuery.toString()}`
  }, [])
  return [query, setQuery, getPathWithQuery]
}
