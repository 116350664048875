import React, { useCallback } from 'react'
import { Tabs } from 'antd'
import { useAsync } from '../../hooks'
import api from '../../api'
import Loading from '../Loading'
import { PageHeader } from '../Common'
import Practices from './Practices'
import Clinicians from './Clinicians'

const { TabPane } = Tabs

function Teams() {
  const getTeams = useCallback(() => api.teams.list(), [])
  const getClinicians = useCallback(() => api.users.getClinicians(), [])
  const { pending, value: teams } = useAsync(getTeams)
  const { pending: pendingClinicians, value: clinicians } = useAsync(
    getClinicians,
  )

  if (pending || pendingClinicians) return <Loading large />

  return (
    <div>
      <PageHeader title="Teams" />
      <Tabs defaultActiveKey="clinicians" type="card">
        <TabPane tab="Clinicians" key="clinicians">
          <Clinicians teams={teams} clinicians={clinicians} />
        </TabPane>
        <TabPane tab="Practices" key="practices">
          <Practices teams={teams} />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default Teams
