import { Icon, Layout, Menu } from 'antd'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React, { useContext, useState, useEffect, useCallback } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import logo from '../../assets/logo.svg'
import { useUnacknowledgedAlertsCount } from '../../redux/slices/alerts'
import {
  useCurrentUserPracticeId,
  useFeatureEnabled,
} from '../../redux/slices/session'
import routes, { toPath } from '../../routes'
import { GetRouteContext } from '../Router/RouterContext'
import { MENU_KEYS } from '../../lib/authorization'
import MenuLink from './MenuLink'
import MenuItem from './MenuItem'
import SubMenu from './SubMenu'

const { Sider } = Layout

const WIDTH = 230

const useStyles = createUseStyles({
  logo: {
    padding: '16px',
    '& img': {
      verticalAlign: 'top',
    },
    background: 'white',
  },
  menu: {
    '& li:first-child': {
      marginTop: 0,
    },
  },
  menuLink: {
    color: 'white',
  },
  subMenuLink: {
    color: 'white',
  },
  menuIcon: {
    fontSize: 18,
    color: 'white',
  },
})

/**
 * A list of each path that needs to be matched to a specific menu item key.
 * Two things are very important to consider:
 * 1. If all subpaths of a specific path will also need to match to the same key,
 * you can just include the parent path (since all subpaths will be included with this)
 * 2. If a more specific subpath needs to be associated with a different key than a parent path,
 * you must put the specific path first, since the search will stop after finding the
 * first path that's equal or a a parent path of the checked path
 */
const PATHS_TO_MENU_KEYS = [
  [routes.alerts(), MENU_KEYS.alerts],
  [routes.billing(), MENU_KEYS.remoteBillingOrders],
  [routes.cycles.cycle.reports.upload(), MENU_KEYS.scheduleReports],
  [
    routes.cycles.cycle.missedTransmissions.new(),
    MENU_KEYS.patientsMissedTransmissions,
  ],
  [
    routes.cycles.cycle.preAuthorizations.new(),
    MENU_KEYS.billingPreauthorizations,
  ],
  [routes.cycles(), MENU_KEYS.scheduleCalendar],
  [routes.deviceModels(), MENU_KEYS.devicesModels],
  [routes.deviceTypes(), MENU_KEYS.devicesTypes],
  [routes.missedTransmissions(), MENU_KEYS.patientsMissedTransmissions],
  [routes.practices.billingOrders(), MENU_KEYS.remoteBillingOrders],
  [routes.practices.cycles(), MENU_KEYS.scheduleCalendar],
  [
    routes.practices.missedTransmissions(),
    MENU_KEYS.patientsMissedTransmissions,
  ],
  [routes.practices.preAuthorizations(), MENU_KEYS.billingPreauthorizations],
  [routes.practices.reports(), MENU_KEYS.scheduleReports],
  [routes.practices(), MENU_KEYS.practices],
  [routes.patients(), MENU_KEYS.patientsList],
  [routes.preAuthorizations(), MENU_KEYS.billingPreauthorizations],
  [routes.reports(), MENU_KEYS.scheduleReports],
  [routes.pendingReports(), MENU_KEYS.schedulePendingReports],
  [routes.users(), MENU_KEYS.users],
  [routes.home(), MENU_KEYS.dashboard],
  [routes.unreviewedReports(), MENU_KEYS.unreviewedReports],
]

function SideBar({ collapsed }) {
  const classes = useStyles()
  const practiceId = useCurrentUserPracticeId()
  const { count: unacknowledgedAlertsCount } = useUnacknowledgedAlertsCount()
  const [selectedKeys, setSelectedKeys] = useState([])
  const [openKeys, setOpenKeys] = useState([])
  const match = useContext(GetRouteContext)
  const { brandPrimary } = useTheme()

  useEffect(() => {
    const currentKey =
      match &&
      get(
        PATHS_TO_MENU_KEYS.find(pathKey => match.path.includes(pathKey[0])),
        1,
      )
    const openKey =
      currentKey &&
      Object.values(MENU_KEYS).find(key => currentKey.includes(key))
    setSelectedKeys([currentKey])
    setOpenKeys(prevOpenKeys => [...new Set(prevOpenKeys).add(openKey)])
  }, [match])

  const onSelect = useCallback((item, key) => setSelectedKeys([key]), [])
  const onOpenChange = useCallback(keys => setOpenKeys(keys), [])

  return (
    <Sider
      theme="dark"
      style={{ background: brandPrimary }}
      trigger={null}
      width={WIDTH}
      collapsible
      collapsed={collapsed}
    >
      <div className={classes.logo}>
        <img src={logo} alt="BioBridge Logo" />
      </div>
      <Menu
        mode="inline"
        selectedKeys={selectedKeys}
        onOpenChange={onOpenChange}
        onSelect={onSelect}
        className={classes.menu}
        style={{ background: brandPrimary }}
        // if collapsed, we need to avoid passing any value for openKeys (even undefined) or it won't work properly
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(!collapsed && { openKeys })}
      >
        <MenuItem item={MENU_KEYS.dashboard}>
          <MenuLink
            route={toPath.home()}
            title="Dashboard"
            icon="dashboard"
            theme="filled"
            color="white"
          />
        </MenuItem>
        <SubMenu
          item={MENU_KEYS.patients}
          color="white"
          theme="dark"
          title={
            <span>
              <Icon type="idcard" theme="filled" className={classes.menuIcon} />
              <span className={classes.menuLink}>Patients</span>
            </span>
          }
        >
          <MenuItem item={MENU_KEYS.patientsList}>
            <MenuLink
              route={toPath.patients()}
              title="Patients list"
              icon="unordered-list"
            />
          </MenuItem>
          <MenuItem item={MENU_KEYS.patientsMissedTransmissions}>
            <MenuLink
              route={toPath.missedTransmissions()}
              title="Missed transmissions"
              icon="disconnect"
            />
          </MenuItem>
        </SubMenu>
        <SubMenu
          item={MENU_KEYS.billing}
          title={
            <span>
              <Icon
                type="dollar-circle"
                theme="filled"
                className={classes.menuIcon}
              />
              <span className={classes.menuLink}>Billing</span>
            </span>
          }
        >
          <MenuItem item={MENU_KEYS.billingRemoteAuthorizations}>
            <MenuLink
              route={toPath.preAuthorizations()}
              title="Remote Authorizations"
              icon="file-protect"
            />
          </MenuItem>
          <MenuItem item={MENU_KEYS.billingRemoteBillingOrders}>
            <MenuLink
              route={toPath.billing.remoteBillingOrders()}
              title="Remote Billing Orders"
              icon="container"
            />
          </MenuItem>
          {useFeatureEnabled('in_office_checks') && (
            <MenuItem item={MENU_KEYS.billingOfficeAuthorizations}>
              <MenuLink
                route={toPath.billing.officeAuthorizations()}
                title="Office Authorizations"
                icon="file-protect"
              />
            </MenuItem>
          )}
          {useFeatureEnabled('in_office_checks') && (
            <MenuItem item={MENU_KEYS.billingOfficeBillingOrders}>
              <MenuLink
                route={toPath.billing.officeBillingOrders()}
                title="Office Billing Orders"
                icon="container"
              />
            </MenuItem>
          )}
        </SubMenu>
        <SubMenu
          item={MENU_KEYS.schedule}
          title={
            <span>
              <Icon
                type="schedule"
                theme="filled"
                className={classes.menuIcon}
              />
              <span className={classes.menuLink}>Remote Monitoring</span>
            </span>
          }
        >
          <MenuItem item={MENU_KEYS.scheduleCalendar}>
            <MenuLink
              route={
                practiceId
                  ? toPath.practices.cycles({ practiceId })
                  : toPath.cycles()
              }
              title="Remote Schedule"
              icon="calendar"
            />
          </MenuItem>
          <MenuItem item={MENU_KEYS.scheduleReports}>
            <MenuLink
              route={toPath.reports()}
              title="Remote Reports"
              icon="form"
            />
          </MenuItem>
          <MenuItem item={MENU_KEYS.schedulePendingReports}>
            <MenuLink
              route={toPath.pendingReports()}
              title="Pending Reports"
              icon="ordered-list"
            />
          </MenuItem>
        </SubMenu>
        <SubMenu
          item={MENU_KEYS.clinicians}
          title={
            <span>
              <Icon
                type="experiment"
                theme="filled"
                className={classes.menuIcon}
              />
              <span className={classes.menuLink}>Clinicians</span>
            </span>
          }
        >
          <MenuItem item={MENU_KEYS.unreviewedReports}>
            <MenuLink
              route={toPath.unreviewedReports()}
              title="Unreviewed Reports"
              icon="issues-close"
            />
          </MenuItem>
          <MenuItem item={MENU_KEYS.reviewedSummary}>
            <MenuLink
              route={toPath.reviewedSummary()}
              title="Reviewed Reports"
              icon="table"
            />
          </MenuItem>
        </SubMenu>

        {useFeatureEnabled('in_office_checks') && (
          <SubMenu
            item={MENU_KEYS.officeChecks}
            title={
              <span>
                <Icon
                  type="snippets"
                  theme="filled"
                  className={classes.menuIcon}
                />
                <span className={classes.menuLink}>Office Checks</span>
              </span>
            }
          >
            <MenuItem item={MENU_KEYS.officeChecksAddOfficeCheck}>
              <MenuLink
                route={toPath.officeChecks.new()}
                title="Add Office Check"
                icon="file-add"
              />
            </MenuItem>
            <MenuItem item={MENU_KEYS.officeChecksOfficeCheckReports}>
              <MenuLink
                route={toPath.officeChecks()}
                title="Office Check Reports"
                icon="database"
              />
            </MenuItem>
          </SubMenu>
        )}

        <MenuItem item={MENU_KEYS.alerts}>
          <MenuLink
            route={toPath.alerts()}
            title="Alerts"
            badgeCount={unacknowledgedAlertsCount}
            icon="alert"
            theme="filled"
            color="white"
          />
        </MenuItem>
        <MenuItem item={MENU_KEYS.practices}>
          <MenuLink
            route={toPath.practices()}
            title="Practices"
            icon="medicine-box"
            theme="filled"
            color="white"
          />
        </MenuItem>
        <MenuItem item={MENU_KEYS.users} className="white-menu">
          <MenuLink
            color="white"
            route={toPath.users()}
            title="Users"
            icon="team"
          />
        </MenuItem>
        <MenuItem item={MENU_KEYS.teams}>
          <MenuLink
            color="white"
            route={toPath.teams()}
            title="Teams"
            icon="appstore"
          />
        </MenuItem>
        <SubMenu
          item={MENU_KEYS.devices}
          color="white"
          theme="dark"
          title={
            <span>
              <Icon type="mobile" theme="filled" className={classes.menuIcon} />
              <span className={classes.menuLink}>Devices</span>
            </span>
          }
        >
          <MenuItem item={MENU_KEYS.devicesModels}>
            <MenuLink
              route={toPath.deviceModels()}
              title="Device models"
              icon="unordered-list"
            />
          </MenuItem>
          <MenuItem item={MENU_KEYS.devicesTypes}>
            <MenuLink
              route={toPath.deviceTypes()}
              title="CPT Codes"
              icon="unordered-list"
            />
          </MenuItem>
        </SubMenu>
        <SubMenu
          item={MENU_KEYS.welcomeLetters}
          color="white"
          theme="dark"
          title={
            <span>
              <Icon
                type="snippets"
                theme="filled"
                className={classes.menuIcon}
              />
              <span className={classes.menuLink}>Welcome Letters</span>
            </span>
          }
        >
          <MenuItem item={MENU_KEYS.welcomeLettersPhoneNumbers}>
            <MenuLink
              route={toPath.welcomeLetters.phoneNumbers()}
              title="Phone Numbers"
              icon="phone"
            />
          </MenuItem>
        </SubMenu>
        <MenuItem item={MENU_KEYS.reminders}>
          <MenuLink
            route={toPath.reminders()}
            title="Reminders"
            icon="message"
            color="white"
          />
        </MenuItem>
      </Menu>
      <style>
        {`
        .ant-menu-submenu-inline.white-menu .ant-menu-submenu-title > i.ant-menu-submenu-arrow::before,
        .ant-menu-submenu-inline.white-menu .ant-menu-submenu-title > i.ant-menu-submenu-arrow::after {
          background-image: none;
        }
        .ant-menu-submenu-inline.white-menu .ant-menu-submenu-title:hover > i.ant-menu-submenu-arrow::before,
        .ant-menu-submenu-inline.white-menu .ant-menu-submenu-title:hover > i.ant-menu-submenu-arrow::after {
          background: white;
        }
        `}
      </style>
    </Sider>
  )
}

SideBar.propTypes = {
  collapsed: PropTypes.bool.isRequired,
}

export default SideBar
