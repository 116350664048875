import { Col, List, Row } from 'antd'
import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link } from 'react-router-dom'
import api from '../../api'
import { formatDateTime } from '../../lib/datetime'
import { toPath } from '../../routes'
import AlertsReportsList from './AlertsReportsList'

const useStyles = createUseStyles({
  container: {
    '& a': {
      textDecoration: 'underline',
    },
  },
})

export default function AlertsReports({ patientId, targetBlank, layout }) {
  const classes = useStyles()
  const target = targetBlank ? 'blank' : null
  const colSize = layout === 'vertical' ? 24 : 12

  return (
    <Row gutter={30} className={classes.container}>
      <Col span={colSize}>
        <AlertsReportsList
          header="Alerts"
          patientId={patientId}
          renderItem={item => (
            <List.Item>
              <Link to={toPath.alerts.alert({ id: item.id })} target={target}>
                Sent at {formatDateTime(item.createdAt)}
              </Link>
            </List.Item>
          )}
          fetchFn={api.patients.getAlerts}
        />
      </Col>
      <Col span={colSize}>
        <AlertsReportsList
          header="Reports"
          patientId={patientId}
          renderItem={item => (
            <List.Item>
              <Link to={toPath.reports.report({ id: item.id })} target={target}>
                Cycle {formatDateTime(item.reportDate)}
              </Link>
            </List.Item>
          )}
          fetchFn={api.patients.getReports}
        />
      </Col>
    </Row>
  )
}

AlertsReports.propTypes = {
  patientId: PropTypes.string.isRequired,
  targetBlank: PropTypes.bool,
  layout: PropTypes.oneOf(['vertical', 'horizontal']),
}

AlertsReports.defaultProps = {
  targetBlank: false,
  layout: 'horizontal',
}
