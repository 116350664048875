import { Pagination } from 'antd'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { buildUrl } from '../../api/api-client'
import Loading from '../Loading'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const useStyles = createUseStyles({
  multiPagination: {
    position: 'sticky',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    bottom: '16px',
    zIndex: '100',
    margin: 'auto',
    '& .ant-pagination': {
      padding: '8px 20px',
      borderRadius: '4px',
      boxShadow: 'rgb(26 26 26 / 20%) 0px 0px 24px',
      background: 'whitesmoke',
    },
  },
  container: {
    textAlign: 'center',
  },
  document: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
    width: '100%',
    '& .react-pdf__Page__textContent': {
      marginTop: -2,
    },
  },
})

export default function PDFPreview({ url: urlProp, path, perPage }) {
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState()
  const paginationRef = useRef(null)
  const classes = useStyles()

  const url = useMemo(() => {
    if (urlProp) return urlProp
    return buildUrl(path)
  }, [path, urlProp])
  const handleLoadSuccess = useCallback(({ numPages }) => {
    setTotalPages(numPages)
  }, [])
  const handleChange = newPage => {
    setPage(newPage)
    if (perPage && paginationRef.current) {
      paginationRef.current.parentElement.scroll(0, 0)
    }
  }
  const getCurrentPages = () => {
    const isLastPage = totalPages < page * perPage

    if (isLastPage) {
      return totalPages % perPage
    }

    return perPage
  }

  const scale = 1.5
  const isMultiPagination = perPage > 1
  const startPage = (page - 1) * perPage + 1
  const total = Math.ceil(totalPages / perPage)

  const pagination = (
    <div
      className={
        isMultiPagination ? classes.multiPagination : classes.container
      }
      ref={paginationRef}
    >
      <Pagination
        current={page}
        pageSize={1}
        total={total}
        onChange={handleChange}
      />
    </div>
  )

  return (
    <>
      {!isMultiPagination && Boolean(total) && pagination}
      <Document
        className={classes.document}
        externalLinkTarget="_blank"
        file={url}
        onLoadSuccess={handleLoadSuccess}
        loading={<Loading large />}
      >
        {totalPages
          ? Array.from({ length: getCurrentPages() }).map((_, i) => (
              <Page
                // eslint-disable-next-line react/no-array-index-key
                key={startPage + i}
                pageNumber={startPage + i}
                scale={scale}
              />
            ))
          : null}
      </Document>
      {Boolean(total) && pagination}
    </>
  )
}

PDFPreview.propTypes = {
  url: PropTypes.string,
  path: PropTypes.string,
  perPage: PropTypes.number,
}

PDFPreview.defaultProps = {
  url: undefined,
  path: undefined,
  perPage: 1,
}
