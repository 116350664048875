import React from 'react'
import get from 'lodash/get'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { useCurrentUser } from './redux/slices/session'
import Alerts from './components/Alerts'
import NewAlert from './components/Alerts/New'
import EditAlert from './components/Alerts/Edit'
import ShowAlert from './components/Alerts/Show'
import RedirectToMergedFile from './components/Alerts/RedirectToMergedFile'
import BillingOrders from './components/Billing/BillingOrders'
import BillingOrderDetails from './components/Billing/BillingOrders/Details'
import PracticeBillingOrders from './components/Billing/BillingOrders/PracticeBillingOrders'
import NewOfficeAuthorizations from './components/Billing/OfficeAuthorizations/New'
import OfficeCheckAuthorizations from './components/Billing/OfficeAuthorizations'
import OfficeAuthorizationsDetails from './components/Billing/OfficeAuthorizations/Details'
import OfficeAuthorizationsEdit from './components/Billing/OfficeAuthorizations/Edit'
import OfficeAuthorizations from './components/Billing/OfficeAuthorizations/Authorizations'
import OfficeBillingOrders from './components/Billing/OfficeBillingOrders'
import OfficeBillingOrdersList from './components/Billing/OfficeBillingOrders/OfficeBillingOrders'
import OfficeBillingOrdersDetail from './components/Billing/OfficeBillingOrders/Details'
import Cycles from './components/Cycles'
import PracticeCycles from './components/Cycles/PracticeCycles'
import Dashboard from './components/Dashboard'
import DeviceModels from './components/DeviceModels'
import EditDeviceModel from './components/DeviceModels/Edit'
import NewDeviceModel from './components/DeviceModels/New'
import DeviceTypes from './components/DeviceTypes'
import EditDeviceType from './components/DeviceTypes/Edit'
import Layout from './components/Layout'
import MissedTransmissions from './components/MissedTransmissions'
import ShowMissedTransmission from './components/MissedTransmissions/Details'
import EditMissedTransmission from './components/MissedTransmissions/Edit'
import NewMissedTransmission from './components/MissedTransmissions/New'
import PracticeMissedTransmissions from './components/MissedTransmissions/PracticeMissedTransmissions'
import Patients from './components/Patients'
import ShowPatient from './components/Patients/Details'
import EditPatient from './components/Patients/Edit'
import NewPatient from './components/Patients/New'
import Practices from './components/Practices'
import EditPractice from './components/Practices/EditPractice'
import NewPractice from './components/Practices/NewPractice'
import PracticeHealthProviders from './components/Practices/PracticeHealthProviders'
import PreAuthorizations from './components/PreAuthorizations'
import PreAuthorizationDetails from './components/PreAuthorizations/Details'
import EditPreAuthorization from './components/PreAuthorizations/EditPreAuthorization'
import NewPreAuthorization from './components/PreAuthorizations/New'
import PracticePreAuthorizations from './components/PreAuthorizations/PracticePreAuthorizations'
import Profile from './components/Profile'
import Reports from './components/Reports'
import PracticeReports from './components/Reports/PracticeReports'
import PendingReports from './components/Reports/PendingReports'
import UnreviewedReports from './components/Reports/UnreviewedReports'
import ReviewedReportsSummary from './components/Reports/ReviewedReportsSummary'
import ShowReport from './components/Reports/ShowReport'
import UploadReports from './components/Reports/Upload'
import EditReport from './components/Reports/EditReport'
import MainRoute from './components/Router/MainRoute'
import RouterContext from './components/Router/RouterContext'
import Teams from './components/Teams'
import Users from './components/Users'
import EditUser from './components/Users/EditUser'
import NewUser from './components/Users/NewUser'

import routes, { toPath } from './routes'
import PasswordRecovery from './components/Session/PasswordRecovery'
import PasswordReset from './components/Session/PasswordRecovery/PasswordReset'
import AdminRoute from './components/Router/AdminRoute'
import Authenticated from './components/Authenticated'
import Auth0LoginRedirect from './components/Session/Auth0LoginRedirect'
import Logout from './components/Session/Logout'
import Reminders from './components/Reminders'
import PhoneNumbers from './components/WelcomeLetters/PhoneNumbers'
import NewPhoneNumber from './components/WelcomeLetters/PhoneNumbers/new'
import EditPhoneNumber from './components/WelcomeLetters/PhoneNumbers/edit'
import AddOfficeCheck from './components/OfficeChecks/AddOfficeCheck'
import EditOfficeCheck from './components/OfficeChecks/EditOfficeCheck'
import NewOfficeCheck from './components/OfficeChecks/NewOfficeCheck'
import OfficeCheckReports from './components/OfficeChecks/OfficeCheckReports'
import OfficeChecks from './components/OfficeChecks'
import ShowOfficeCheck from './components/OfficeChecks/ShowOfficeCheck'
import Error404 from './components/Error404'
import OfficeCheckDownloads from './components/OfficeCheckDownloads'
import PreAuthorizationDownloads from './components/PreAuthorizationDownloads'
import Downloads from './components/Downloads'
import AutoLogout from './components/AutoLogout'
import LoggedOut from './components/LoggedOut'

function App() {
  const currentUser = useCurrentUser()
  const isLogged = Boolean(currentUser)
  const location = useLocation()
  const userRole = currentUser?.role

  const isClinician = userRole === 'clinician'
  const isAdmin = userRole === 'admin'

  const homePath = () => {
    if (isClinician) {
      return toPath.unreviewedReports()
    }

    return get(location, 'state.referrer', toPath.home())
  }

  if (!isLogged) {
    const referrer = `${location.pathname}${location.search}${location.hash}`
    return (
      <Switch>
        <Route path={routes.logout()} component={Logout} />
        <Route path={routes.login()} component={Auth0LoginRedirect} />
        <Route
          path={routes.password.reset()}
          exact
          component={PasswordRecovery}
        />
        <Route path={routes.password.reset.token()} component={PasswordReset} />
        <Route path={routes.authenticated()} component={Authenticated} exact />
        <Route path={toPath.loggedOut()} component={LoggedOut} />
        <Redirect to={{ pathname: toPath.login(), state: { referrer } }} />
      </Switch>
    )
  }

  return (
    <RouterContext>
      <Downloads />
      <OfficeCheckDownloads />
      <PreAuthorizationDownloads />
      <AutoLogout />
      {location.pathname !== '/404' && (
        <Layout>
          <Switch>
            <Redirect from={routes.authenticated()} to={homePath()} />

            <MainRoute path={routes.alerts()} exact>
              <Alerts />
            </MainRoute>
            <AdminRoute path={routes.alerts.new()}>
              <NewAlert />
            </AdminRoute>
            <MainRoute path={routes.alerts.alert.edit()}>
              <EditAlert />
            </MainRoute>
            <MainRoute path={routes.alerts.redirectToMergedFile()}>
              <RedirectToMergedFile />
            </MainRoute>
            <MainRoute path={routes.alerts.alert()}>
              <ShowAlert />
            </MainRoute>
            <AdminRoute path={routes.deviceModels()} exact>
              <DeviceModels />
            </AdminRoute>
            <AdminRoute path={routes.deviceModels.new()}>
              <NewDeviceModel />
            </AdminRoute>
            <AdminRoute path={routes.deviceModels.deviceModel.edit()}>
              <EditDeviceModel />
            </AdminRoute>
            <AdminRoute path={routes.deviceTypes()} exact>
              <DeviceTypes />
            </AdminRoute>
            <AdminRoute path={routes.deviceTypes.deviceType.edit()} exact>
              <EditDeviceType />
            </AdminRoute>
            <MainRoute path={routes.preAuthorizations()} exact>
              <PreAuthorizations />
            </MainRoute>
            <MainRoute path={routes.preAuthorizations.preAuthorization.edit()}>
              <EditPreAuthorization />
            </MainRoute>
            <MainRoute path={routes.practices.preAuthorizations()}>
              <PracticePreAuthorizations />
            </MainRoute>
            <MainRoute path={routes.practices.officeAuthorizations.new()}>
              <NewOfficeAuthorizations />
            </MainRoute>
            <MainRoute path={routes.practices.officeAuthorizations()}>
              <OfficeAuthorizations />
            </MainRoute>
            <AdminRoute path={routes.practices()} exact>
              <Practices />
            </AdminRoute>
            <AdminRoute path={routes.practices.new()}>
              <NewPractice />
            </AdminRoute>
            <AdminRoute path={routes.practices.practice.edit()}>
              <EditPractice />
            </AdminRoute>
            <AdminRoute path={routes.teams()} exact>
              <Teams />
            </AdminRoute>
            <AdminRoute path={routes.users()} exact>
              <Users />
            </AdminRoute>
            <AdminRoute path={routes.users.new()}>
              <NewUser />
            </AdminRoute>
            <AdminRoute path={routes.users.user.edit()}>
              <EditUser />
            </AdminRoute>
            <MainRoute path={routes.reports.report.edit()}>
              <EditReport />
            </MainRoute>
            <MainRoute path={routes.patients()} exact>
              <Patients />
            </MainRoute>
            <MainRoute path={routes.patients.new()}>
              <NewPatient />
            </MainRoute>
            <MainRoute path={routes.patients.patient.edit()}>
              <EditPatient />
            </MainRoute>
            <MainRoute path={routes.patients.patient()}>
              <ShowPatient />
            </MainRoute>
            <MainRoute
              path={routes.billing.remoteBillingOrders.remoteBillingOrder()}
            >
              <BillingOrderDetails />
            </MainRoute>
            <MainRoute path={routes.billing.remoteBillingOrders()}>
              <BillingOrders />
            </MainRoute>
            <MainRoute
              path={routes.billing.officeAuthorizations.officeAuthorization.edit()}
            >
              <OfficeAuthorizationsEdit />
            </MainRoute>
            <MainRoute
              path={routes.billing.officeAuthorizations.officeAuthorization()}
            >
              <OfficeAuthorizationsDetails />
            </MainRoute>
            <MainRoute path={routes.billing.officeAuthorizations()}>
              <OfficeCheckAuthorizations />
            </MainRoute>
            <MainRoute
              path={routes.billing.officeBillingOrders.officeBillingOrder()}
            >
              <OfficeBillingOrdersDetail />
            </MainRoute>
            <MainRoute path={routes.billing.officeBillingOrders()}>
              <OfficeBillingOrders />
            </MainRoute>
            <MainRoute path={routes.practices.billingOrders()}>
              <PracticeBillingOrders />
            </MainRoute>
            <MainRoute path={routes.practices.officeBillingOrders()}>
              <OfficeBillingOrdersList />
            </MainRoute>
            <MainRoute path={routes.practices.healthProviders()}>
              <PracticeHealthProviders />
            </MainRoute>
            <MainRoute path={routes.cycles.cycle.preAuthorizations.new()}>
              <NewPreAuthorization />
            </MainRoute>
            <MainRoute path={routes.cycles()} exact>
              <Cycles />
            </MainRoute>
            <AdminRoute path={routes.cycles.cycle.reports.upload()}>
              <UploadReports />
            </AdminRoute>
            <MainRoute path={routes.officeChecks.new()}>
              {isClinician || isAdmin ? (
                <AddOfficeCheck />
              ) : (
                <Redirect
                  to={
                    currentUser.practice?.id
                      ? toPath.practices.officeChecks.new({
                          practiceId: currentUser.practice.id,
                        })
                      : homePath()
                  }
                />
              )}
            </MainRoute>
            <MainRoute path={routes.practices.officeChecks.new()}>
              <NewOfficeCheck />
            </MainRoute>
            <MainRoute path={routes.practices.officeChecks.officeCheck.edit()}>
              <EditOfficeCheck />
            </MainRoute>
            <MainRoute path={routes.practices.officeChecks.officeCheck()}>
              <ShowOfficeCheck />
            </MainRoute>
            <MainRoute path={routes.officeChecks()} exact>
              <OfficeChecks />
            </MainRoute>
            <MainRoute path={routes.practices.officeChecks()} exact>
              <OfficeCheckReports />
            </MainRoute>
            <MainRoute path={routes.cycles.cycle.missedTransmissions.new()}>
              <NewMissedTransmission />
            </MainRoute>
            <MainRoute path={routes.practices.cycles()}>
              <PracticeCycles />
            </MainRoute>
            <MainRoute path={routes.reports()} exact>
              <Reports />
            </MainRoute>
            <AdminRoute path={routes.pendingReports()}>
              <PendingReports />
            </AdminRoute>
            <MainRoute path={routes.unreviewedReports()}>
              {isClinician || isAdmin ? (
                <UnreviewedReports />
              ) : (
                <Redirect to={toPath.home()} />
              )}
            </MainRoute>
            <MainRoute path={routes.reviewedSummary()} exact>
              <ReviewedReportsSummary />
            </MainRoute>
            <MainRoute path={routes.reports.report()}>
              <ShowReport />
            </MainRoute>

            <MainRoute path={routes.practices.reports()}>
              <PracticeReports />
            </MainRoute>
            <MainRoute path={routes.preAuthorizations.preAuthorization()} exact>
              <PreAuthorizationDetails />
            </MainRoute>
            <MainRoute path={routes.missedTransmissions()} exact>
              <MissedTransmissions />
            </MainRoute>
            <MainRoute path={routes.practices.missedTransmissions()}>
              <PracticeMissedTransmissions />
            </MainRoute>
            <MainRoute
              path={routes.missedTransmissions.missedTransmission.edit()}
            >
              <EditMissedTransmission />
            </MainRoute>
            <MainRoute path={routes.missedTransmissions.missedTransmission()}>
              <ShowMissedTransmission />
            </MainRoute>
            <MainRoute path={routes.profile()}>
              <Profile />
            </MainRoute>

            <AdminRoute path={routes.welcomeLetters.phoneNumbers()} exact>
              <PhoneNumbers />
            </AdminRoute>
            <AdminRoute path={routes.welcomeLetters.phoneNumbers.new()}>
              <NewPhoneNumber />
            </AdminRoute>
            <AdminRoute
              path={routes.welcomeLetters.phoneNumbers.phoneNumber.edit()}
            >
              <EditPhoneNumber />
            </AdminRoute>

            <AdminRoute path={routes.reminders()} exact>
              <Reminders />
            </AdminRoute>
            <MainRoute path={routes.home()} exact>
              {isClinician ? <Redirect to={homePath()} /> : <Dashboard />}
            </MainRoute>
          </Switch>
        </Layout>
      )}
      <Switch>
        <Route path={routes.error404()} exact>
          <Error404 />
        </Route>
      </Switch>
    </RouterContext>
  )
}

export default App
