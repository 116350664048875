import React from 'react'
import sortBy from 'lodash/sortBy'
import { COMPANIES_TRANSLATIONS } from '../../lib/companies'
import Select from './Select'

export const MANUFACTURERS = [
  {
    id: 'all',
    name: 'Manufacturers: all',
  },
  ...sortBy(
    Object.keys(COMPANIES_TRANSLATIONS).map(key => ({
      id: key,
      name: COMPANIES_TRANSLATIONS[key],
    })),
    'name',
  ),
]

export default function ManufacturerSelect(props) {
  return <Select options={MANUFACTURERS} {...props} />
}
