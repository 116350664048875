import React from 'react'
import { useIsAdmin } from '../../../redux/slices/session'
import { ListError, PageHeader } from '../../Common'
import CyclesFilter from '../../Common/CyclesFilter'
import usePracticeCycles from '../../Common/usePracticeCycles'
import useFilters from '../../Cycles/useFilters'
import BillingOrders from './BillingOrders'

export default function PracticeBillingOrders() {
  const isAdmin = useIsAdmin()
  const [
    { error, pendingCycles, clearError },
    { practice, pending, practiceId },
  ] = usePracticeCycles()
  const { handleFilterChange, handlePageChange, conditions } = useFilters()
  const { cycleId, page } = conditions

  if (cycleId && !pending && !pendingCycles) {
    return (
      <div>
        <PageHeader
          title="Remote Billing Orders"
          subTitle={isAdmin && practice.name}
        />
        <CyclesFilter
          practiceId={practiceId}
          onSubmit={handleFilterChange}
          currentCycleId={cycleId}
        />
        <BillingOrders
          cycleId={cycleId}
          onPageChange={handlePageChange}
          page={page}
        />
      </div>
    )
  }

  return (
    <div>
      {error && <ListError description={error.message} onClose={clearError} />}
    </div>
  )
}
