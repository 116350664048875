const HOME_MONITOR_MODELS = {
  Abbott: [
    'EX1150',
    'MyMerlin App (APP1000 and APP1001)',
    'MyMerlin Pulse App (APP1004 and APP1005)',
  ],
  Biotronik: ['CardioMessenger Smart'],
  'Boston Scientific': ['6280', '6290', 'myLux App'],
  Medtronic: [
    '2490h',
    '2490g',
    '24950',
    '24952',
    '25000',
    '24960',
    'MyCareLink Heart App',
  ],
}

const PATIENTS_PLACEHOLDERS = {
  firstName: 'example: John',
  lastName: 'example: Doe',
  email: 'example: john.doe@example.org',
  mobilePhone: 'example: +1 555 555 5555',
  homePhone: 'example: +1 555 555 5555',
  addressStreet: 'example: 42 Wallaby Way',
  addressApt: 'example: 403',
  addressCity: 'example: Miami',
  addressZipCode: 'example: 90009',
  insurancePolicyNumber: 'example: 1EG4-TE5-MK72',
  provider: 'example: Eli Blackwell',
  pcpName: 'example: John Smith MD',
  pcpPhoneNumber: 'example: (555) 555 5555',
  pcpFaxNumber: 'example: (666) 666 6666',
  icdCodeIds: 'Select ICD Codes',
  practiceMrn: 'example: 123456789',
}

const PATIENTS_LABELS = {
  practiceId: 'For practice',
  firstName: 'First Name',
  lastName: 'Last Name',
  birthDate: 'Date of birth',
  email: 'E-mail',
  mobilePhone: 'Mobile phone',
  homePhone: 'Landline phone',
  addressStreet: 'Street',
  addressApt: 'Apt/Suite',
  addressCity: 'City',
  addressState: 'State',
  addressCountry: 'Country',
  addressZipCode: 'Zip code',
  atrialFibrilationFlutterDisease: 'Atrial Fibrillation / Flutter',
  anticoagulatedDisease: 'Anticoagulated',
  enrolled:
    'Is the patient enrolled into Medtronic, St Jude / Abbott, Boston Scientific or Biotronic web site?',
  paired: 'Is the patient paired with a home monitor?',
  homeMonitorSerialNumber: 'Home monitor model',
  currentDeviceAttributes: {
    deviceModelId: 'Device model/brand',
    serialNumber: 'Serial number',
  },
  insuranceProviderId: 'Insurance provider',
  insurancePolicyNumber: 'Insurance Policy #',
  medicalProviderId: 'Medical provider',
  provider: 'Provider',
  pcpName: 'PCP Name',
  pcpPhoneNumber: 'PCP phone number',
  pcpFaxNumber: 'PCP fax number',
  alertsRecipientId: 'Send Alerts to',
  icdCodeIds: 'ICD Codes',
  remoteMonitoring: 'Remote Monitoring',
  inOfficeChecks: 'In-Office Checks',
  practiceMrn: 'MRN',
}

const USERS_LABELS = {
  email: 'E-mail',
  firstName: 'First Name',
  lastName: 'Last Name',
  password: 'Password',
  active: 'Status',
  practiceId: 'Practice',
  roleId: 'Role',
  position: 'Position',
  nationalProviderId: 'National Provider Identifier',
}

const USERS_PLACEHOLDERS = {
  email: 'john.doe@example.org',
  firstName: 'John',
  lastName: 'Doe',
}

const USERS_LETTER_STATUS = {
  '0': 'Not sent',
  '1': 'Queued',
  '2': 'Sent',
  '3': 'Error sending the welcome letter',
}

const USER_ROLES = {
  ADMIN: 'admin',
  CLINICIAN: 'clinician',
  PRACTITIONER: 'practitioner',
  NURSE: 'nurse',
  TECHNICIAN: 'technician',
}

const PAGE_SIZE = 50

const PAGE_SIZE_OPTIONS = ['10', '20', '30', '40', '50', '75', '100']

export {
  HOME_MONITOR_MODELS,
  PATIENTS_PLACEHOLDERS,
  PATIENTS_LABELS,
  USERS_LABELS,
  USERS_PLACEHOLDERS,
  USERS_LETTER_STATUS,
  USER_ROLES,
  PAGE_SIZE,
  PAGE_SIZE_OPTIONS,
}
