import React, { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { Button } from 'antd'
import moment from 'moment'
import {
  useIsAdmin,
  useIsPractitioner,
  useFeatureGuard,
} from '../../../redux/slices/session'
import { ListError, PageHeader } from '../../Common'
import Filters from '../../Forms/Filters'
import { useOfficeAuthorizations } from '../../../redux/slices/office-authorizations'
import { toPath } from '../../../routes'
import RemindersModal from '../../Common/RemindersModal'
import api from '../../../api'
import { useAsync } from '../../../hooks'
import MonthFilter from '../../Common/MonthFilter'
import AddNewItemButton from '../../Common/AddNewItemButton'
import OfficeAuthorizations from './OfficeAuthorizations'
import useFilters from './useFilters'

const DEFAULT_FILTERS = {
  query: '',
  noFullReports: false,
  checkDate: moment()
    .startOf('month')
    .format('YYYY-MM'),
}

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    '& > form': {
      flex: '1',
      minWidth: '135px',
    },
  },
  filterContainer: {
    marginBottom: '25px',
  },
})

export default function Authorizations() {
  const { practiceId } = useParams()
  const isAdmin = useIsAdmin()
  const isPractitioner = useIsPractitioner()
  const classes = useStyles()
  const getPractice = useCallback(() => api.practices.getPractice(practiceId), [
    practiceId,
  ])
  const { pending, value: practice, error, clearError } = useAsync(getPractice)

  const {
    handleFilterChange,
    handlePageChange,
    conditions,
    setConditions,
  } = useFilters(DEFAULT_FILTERS)
  const preAuthFilters = useMemo(() => {
    return {
      q: conditions.query,
      noFullReports: conditions.noFullReports,
      checkDate: conditions.checkDate,
    }
  }, [conditions])
  const { page, checkDate } = conditions

  const officeAuthorizations = useOfficeAuthorizations()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [selectedPatients, setSelectedPatients] = useState([])
  const hasSelected = selectedRowKeys.length > 0

  const onSelectChange = rowsIdSelection => {
    setSelectedRowKeys(rowsIdSelection)

    const preAuths = officeAuthorizations.filter(pa =>
      rowsIdSelection.includes(pa.id),
    )

    setSelectedPatients(preAuths.map(pa => pa.patient.id))
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const [remindersOptionsVisible, setRemindersOptionsVisible] = useState(false)
  const hideRemindersOptions = useCallback(
    () => setRemindersOptionsVisible(false),
    [],
  )

  const showRemindersOptions = useCallback(() => {
    setRemindersOptionsVisible(true)
  }, [])

  useFeatureGuard('in_office_checks')

  if (!pending) {
    return (
      <div>
        <PageHeader
          title="Office Checks Authorizations"
          subTitle={isAdmin && practice.name}
          extra={
            (isAdmin || isPractitioner) && [
              <AddNewItemButton
                key="1"
                itemName="office authorization"
                path={toPath.practices.officeAuthorizations.new({
                  practiceId,
                })}
                icon="plus-circle"
              />,
            ]
          }
        />
        <div className={classes.container}>
          <MonthFilter onSubmit={handleFilterChange} currentValue={checkDate} />
          <Filters
            defaultValues={DEFAULT_FILTERS}
            currentValues={conditions}
            placeholders={{ query: 'Search by name or device serial number' }}
            onSubmit={({ query }) => setConditions({ query, page: 1 })}
            showSearch
          />
          {/* <div className={classes.filterContainer}>
            <Checkbox onChange={showNoFullReports}>
              Without full reports
            </Checkbox>
          </div> */}
          <div className={classes.filterContainer}>
            <Button
              icon="phone"
              disabled={!hasSelected}
              onClick={showRemindersOptions}
            >
              Send reminder
            </Button>
          </div>
        </div>
        <OfficeAuthorizations
          checkDate={checkDate}
          onPageChange={handlePageChange}
          page={page}
          filters={preAuthFilters}
          rowSelection={rowSelection}
        />
        <RemindersModal
          patientIds={selectedPatients}
          visible={remindersOptionsVisible}
          onCancel={hideRemindersOptions}
        />
      </div>
    )
  }

  return (
    <div>
      {error && <ListError description={error.message} onClose={clearError} />}
    </div>
  )
}
