import { message } from 'antd'
import mapValues from 'lodash/mapValues'
import React, { useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import api from '../../api'
import { ListError, PageHeader } from '../Common'
import { toPath } from '../../routes'
import { useAsync } from '../../hooks'
import Loading from '../Loading'
import DeviceTypeForm from './Form'

export default function EditDeviceType() {
  const history = useHistory()
  const { id } = useParams()
  const getDeviceType = useCallback(() => api.deviceTypes.get(id), [id])
  const { pending, value, error, clearError } = useAsync(getDeviceType)
  const goToList = useCallback(() => history.push(toPath.deviceTypes()), [
    history,
  ])

  const onSubmit = useCallback(
    async (
      {
        cptCodes30,
        cptCodes90Order1,
        cptCodes90Order2,
        icd10Code,
        icd10Code90Order2,
      },
      { setErrors },
    ) => {
      try {
        await api.deviceTypes.update(id, {
          cpt_codes_30: cptCodes30,
          cpt_codes_90_order_1: cptCodes90Order1,
          cpt_codes_90_order_2: cptCodes90Order2,
          icd_10_code: icd10Code,
          icd_10_code_90_order_2: icd10Code90Order2,
        })
        message.success('CPT Codes successfully updated')
        goToList()
      } catch (e) {
        if (e.response && e.response.errors) {
          setErrors(mapValues(e.response.errors, fieldErrors => fieldErrors[0]))
        } else {
          message.error(e.message)
        }
      }
    },
    [goToList, id],
  )

  if (pending) return <Loading large />

  return (
    <div>
      <PageHeader
        title="Edit CPT Codes"
        subTitle={value.name}
        onBack={history.goBack}
      />
      {error && <ListError description={error.message} onClose={clearError} />}
      <DeviceTypeForm deviceType={value} onSubmit={onSubmit} />
    </div>
  )
}
