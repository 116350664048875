import { Form, Switch as AntDSwitch } from 'antd'
import { useField } from 'formik'
import PropTypes from 'prop-types'
import React from 'react'
import KeyMirror from 'keymirror'

const { Item } = Form

export const VALUE_LABELS_SCHEMA = KeyMirror({
  yesNo: null,
  activeInactive: null,
})

export default function Switch({
  extra,
  id,
  label,
  labelAlign,
  labelCol,
  wrapperCol,
  valueLabelsSchema,
  ...rest
}) {
  const [field, { touched, error }, { setValue }] = useField({
    ...rest,
    type: 'checkbox',
  })
  const hasError = touched && error
  const itemProps = {
    extra,
    hasFeedback: hasError,
    help: hasError && error,
    htmlFor: id,
    label,
    labelAlign,
    validateStatus: hasError ? 'error' : '',
    // This is needed since even sending undefined will override Form's props regarding this
    // so we leave null/false to override, and undefined to keep ignoring (as it should be probably)
    ...(labelCol !== undefined && { labelCol }),
    ...(wrapperCol !== undefined && { wrapperCol }),
  }
  let valueLabels = null
  switch (valueLabelsSchema) {
    case VALUE_LABELS_SCHEMA.activeInactive:
      valueLabels = { checkedChildren: 'Active', unCheckedChildren: 'Inactive' }
      break
    case VALUE_LABELS_SCHEMA.yesNo:
      valueLabels = { checkedChildren: 'Yes', unCheckedChildren: 'No' }
      break
    default:
  }
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Item {...itemProps}>
      {/* eslint-disable react/jsx-props-no-spreading */}
      <AntDSwitch
        id={id}
        {...field}
        {...valueLabels}
        {...rest}
        onChange={setValue}
      />
    </Item>
  )
}

Switch.propTypes = {
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.string,
  labelAlign: PropTypes.string,
  labelCol: PropTypes.shape({}),
  id: PropTypes.string,
  valueLabelsSchema: PropTypes.oneOf(Object.values(VALUE_LABELS_SCHEMA)),
  wrapperCol: PropTypes.shape({}),
}

Switch.defaultProps = {
  extra: undefined,
  label: undefined,
  labelAlign: undefined,
  labelCol: undefined,
  id: undefined,
  valueLabelsSchema: VALUE_LABELS_SCHEMA.activeInactive,
  wrapperCol: undefined,
}
