import { Form, message, Typography } from 'antd'
import { Formik } from 'formik'
import times from 'lodash/times'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'
import api from '../../api'
import { formElementPropsGenerator } from '../../lib/forms'
import { Yup } from '../../lib/validations'
import { DatePicker, SubmitButton } from '../Forms'
import Select from '../Forms/Select'

const LABELS = {
  startsAt: 'First cycle start date',
  firstLongPeriodIndex: 'First cycle with 90 days check',
}

const propsFor = formElementPropsGenerator({
  formName: 'cycles-form',
  labels: LABELS,
})

const validationSchema = Yup.object().shape({
  startsAt: Yup.date()
    .nullable()
    .required(),
  firstLongPeriodIndex: Yup.number().required(),
})

const firstPeriodOptions = times(3, i => ({ id: i, name: i + 1 }))

export default function CyclesCreation({ afterCreationCallback, practiceId }) {
  const INITIAL_VALUES = useMemo(
    () => ({
      startsAt: moment(),
      firstLongPeriodIndex: '2',
    }),
    [],
  )
  const createCycles = useCallback(
    ({ startsAt, firstLongPeriodIndex }) =>
      api.cycles
        .createCycles({
          practiceId,
          startsAt: startsAt.format(moment.HTML5_FMT.DATE),
          firstLongPeriodIndex,
        })
        .then(() => {
          message.success('Cycles successfully created')
          afterCreationCallback()
        })
        .catch(e => message.error(`Error creating cycles: ${e.message}`)),
    [afterCreationCallback, practiceId],
  )
  return (
    <div>
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={createCycles}
        validationSchema={validationSchema}
      >
        {({ handleSubmit, isSubmitting, isValid, submitCount }) => (
          <Form layout="inline" onSubmit={handleSubmit}>
            <Typography.Paragraph>
              You can start by creating this practice&apos;s first schedule
            </Typography.Paragraph>
            <DatePicker {...propsFor('startsAt')} allowClear={false} />
            <Select
              {...propsFor('firstLongPeriodIndex')}
              options={firstPeriodOptions}
            />
            <SubmitButton
              isSubmitting={isSubmitting}
              isValid={isValid}
              submitCount={submitCount}
              title="Create Schedule"
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}

CyclesCreation.propTypes = {
  afterCreationCallback: PropTypes.func.isRequired,
  practiceId: PropTypes.string.isRequired,
}
