import { useSelector } from 'react-redux'
import api from '../../api'
import createSlice from '../create-slice'
import { buildResolveOnlyLastPromise } from '../../lib/promises'
import { useAsyncAction } from '../utils'

const letterPhoneNumbersInitialState = {
  results: [],
  pageSize: 0,
  total: 0,
}

const letterPhoneNumbersSlice = createSlice({
  name: 'letterPhoneNumbers',
  initialState: letterPhoneNumbersInitialState,
  reducers: {
    setLetterPhoneNumbers: (state, { payload: results }) => {
      state.results = results
    },
  },
})

const { actions } = letterPhoneNumbersSlice

const selectState = state => state[letterPhoneNumbersSlice.name]

const onlyKeepLastLetterPhoneNumbersList = buildResolveOnlyLastPromise(
  api.letterPhoneNumbers.getLetterPhoneNumbers,
)

export function fetchLetterPhoneNumbers(useOnlyLastResponse) {
  return async dispatch => {
    const reqFn = useOnlyLastResponse
      ? onlyKeepLastLetterPhoneNumbersList
      : api.letterPhoneNumbers.getLetterPhoneNumbers

    const results = await reqFn()

    dispatch(actions.setLetterPhoneNumbers(results))
  }
}

export function useLetterPhoneNumbers() {
  const { results } = useSelector(selectState)

  return results
}

export function useFetchLetterPhoneNumbers(useOnlyLastResponse = false) {
  return useAsyncAction(fetchLetterPhoneNumbers, useOnlyLastResponse)
}

export default letterPhoneNumbersSlice
