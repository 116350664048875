import KeyMirror from 'keymirror'

/**
 * Represents all the keys for all the menus/submenus.
 * It's important that each submenu has a key that starts with
 * the same characters as the parent menu it belongs to, to properly
 * find the needed "open keys" according to the selected item
 */
export const MENU_KEYS = KeyMirror({
  dashboard: null,
  patients: null,
  patientsList: null,
  patientsMissedTransmissions: null,
  billing: null,
  billingRemoteAuthorizations: null,
  billingRemoteBillingOrders: null,
  billingOfficeAuthorizations: null,
  billingOfficeBillingOrders: null,
  schedule: null,
  scheduleCalendar: null,
  scheduleReports: null,
  schedulePendingReports: null,
  officeChecks: null,
  officeChecksAddOfficeCheck: null,
  officeChecksOfficeCheckReports: null,
  clinicians: null,
  unreviewedReports: null,
  reviewedSummary: null,
  alerts: null,
  resources: null,
  practices: null,
  users: null,
  teams: null,
  devices: null,
  devicesModels: null,
  devicesTypes: null,
  reminders: null,
  welcomeLetters: null,
  welcomeLettersPhoneNumbers: null,
})

const menuPermissions = {
  clinician: [
    MENU_KEYS.unreviewedReports,
    MENU_KEYS.reviewedSummary,
    MENU_KEYS.clinicians,
  ],
  practitioner: [
    MENU_KEYS.dashboard,
    MENU_KEYS.patients,
    MENU_KEYS.patientsList,
    MENU_KEYS.patientsMissedTransmissions,
    MENU_KEYS.billing,
    MENU_KEYS.billingRemoteAuthorizations,
    MENU_KEYS.billingRemoteBillingOrders,
    MENU_KEYS.billingOfficeAuthorizations,
    MENU_KEYS.billingOfficeBillingOrders,
    MENU_KEYS.officeChecks,
    MENU_KEYS.officeChecksAddOfficeCheck,
    MENU_KEYS.officeChecksOfficeCheckReports,
    MENU_KEYS.schedule,
    MENU_KEYS.scheduleCalendar,
    MENU_KEYS.scheduleReports,
    MENU_KEYS.alerts,
    MENU_KEYS.resources,
    MENU_KEYS.billingPre,
  ],
}

export function canAccess(userRole, item) {
  return (
    userRole === 'admin' ||
    (menuPermissions[userRole] && menuPermissions[userRole].includes(item))
  )
}
