import { denormalize, normalize } from 'normalizr'
import { useSelector } from 'react-redux'
import api from '../../api'
import { buildResolveOnlyLastPromise } from '../../lib/promises'
import { usersSchema } from '../normalizr'
import { getPaginationHeaders, useAsyncAction } from '../utils'
import createSlice from '../create-slice'
import { getEntities } from './entities'

const usersInitialState = {
  usersIds: [],
  pageSize: 0,
  total: 0,
}

const usersSlice = createSlice({
  name: 'users',
  initialState: usersInitialState,
  reducers: {
    setUsers: (state, { payload: { result, pageSize, total } }) => {
      state.usersIds = result
      state.pageSize = pageSize
      state.total = total
    },
  },
})

const { actions } = usersSlice

const selectState = state => state[usersSlice.name]

export function useUsers() {
  const { usersIds } = useSelector(selectState)
  const entities = useSelector(getEntities)
  const users = denormalize(usersIds, [usersSchema], entities)
  return users
}

export function useUsersPagination() {
  const { pageSize, total } = useSelector(selectState)
  return { pageSize, total }
}

const onlyKeepLastUserList = buildResolveOnlyLastPromise(api.users.list)

export function fetchUsers(
  page,
  practiceId,
  showInactive,
  query,
  useOnlyLastResponse,
) {
  return async dispatch => {
    const reqFn = useOnlyLastResponse ? onlyKeepLastUserList : api.users.list
    const { body, headers } = await reqFn(page, practiceId, showInactive, query)
    dispatch(
      actions.setUsers({
        ...normalize(body, [usersSchema]),
        ...getPaginationHeaders(headers),
      }),
    )
  }
}

export function useFetchUsers(
  { page, practiceId, showInactive, query },
  useOnlyLastResponse = false,
) {
  return useAsyncAction(
    fetchUsers,
    page,
    practiceId,
    showInactive,
    query,
    useOnlyLastResponse,
  )
}

export default usersSlice
